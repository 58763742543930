import { Box } from '@biom3/react';
import { useStore } from 'src/context/store-context';

export const BlurMask = () => {
  const [{ walletOpen, connecting }] = useStore();
  const visible = walletOpen || connecting;

  return (
    <Box
      sx={{
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        position: 'absolute',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'transparent',
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'auto' : 'none',
        transition: 'opacity 0.3s ease-in-out'
      }}
    />
  );
};
