import { Grid } from '@biom3/react';
import { useProductsContext } from '../../context/products-context';
import { useBuyProduct } from '../../hooks/use-buy-product';
import { ProductCard } from '../product-card/product-card';

export const ThreeColumnsGrid = () => {
  const { products } = useProductsContext();
  const { buyProduct } = useBuyProduct();

  return (
    <Grid
      minColumnWidth="25%"
      gap="base.spacing.x6"
      sx={{
        marginTop: 'base.spacing.x6',
        justifyItems: 'center',
        gridTemplateColumns: [
          'auto',
          'auto',
          'repeat(auto-fill, minmax(50%, 1fr))',
          'repeat(auto-fill, minmax(40%, 1fr))',
          'repeat(auto-fill, minmax(25%, 1fr))'
        ]
      }}
    >
      {products.map((product) => (
        <ProductCard key={`${product.id}${product.sku}`} product={product} onClick={buyProduct} />
      ))}
    </Grid>
  );
};
