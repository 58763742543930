import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useCallback, useEffect } from 'react';
import { useStore } from 'src/context/store-context';
import { useWidgets } from 'src/context/widgets-context';
import {
  WALLET_WIDGETS_ROOT,
  lsReconnectingWallet,
  lsWalletProviderName,
  notifyError
} from '../utils';
import { useScrollTo } from './use-scroll-to';

export const useConnectWidget = () => {
  const [{ factory, checkout, provider, walletProviderName, connectWidget }, widgetsDispatch] =
    useWidgets();
  const [{ walletAddress, connected, connecting }, storeDispatch] = useStore();
  const scrollToWidget = useScrollTo(WALLET_WIDGETS_ROOT);

  const connectFromWalletProviderName = useCallback(
    async (
      walletProviderName: ImtblCheckout.WalletProviderName,
      onSuccess?: () => void,
      onError?: () => void
    ) => {
      if (!checkout) return;

      lsReconnectingWallet.write(true);
      try {
        storeDispatch({ payload: { type: 'SET_WALLET_CONNECTING', connecting: true } });
        const { provider: web3Provider } = await checkout.createProvider({
          walletProviderName
        });
        await checkout.connect({ provider: web3Provider });
        const { walletAddress } = await checkout.checkIsWalletConnected({
          provider: web3Provider
        });

        widgetsDispatch({
          payload: { type: 'SET_PROVIDER', provider: web3Provider, walletProviderName }
        });
        storeDispatch({ payload: { type: 'SET_WALLET_CONNECTED', connected: true } });
        storeDispatch({ payload: { type: 'SET_WALLET_ADDRESS', address: walletAddress } });
        onSuccess?.();
      } catch (err) {
        onError?.();
        notifyError(err);
        storeDispatch({ payload: { type: 'SET_WALLET_CONNECTED', connected: false } });
      } finally {
        storeDispatch({ payload: { type: 'SET_WALLET_CONNECTING', connecting: false } });
        lsReconnectingWallet.write(false);
      }
    },
    [checkout, widgetsDispatch, storeDispatch]
  );

  const handleConnectWallet = (
    providerName?: ImtblCheckout.WalletProviderName,
    onSuccess?: () => void
  ) => {
    if (providerName) {
      connectFromWalletProviderName(providerName, onSuccess);
      return;
    }

    if (!connectWidget) return;

    storeDispatch({ payload: { type: 'SET_WALLET_CONNECTING', connecting: true } });
    connectWidget?.mount(WALLET_WIDGETS_ROOT);
    scrollToWidget();
  };

  const handleDisconnectWallet = useCallback(() => {
    connectWidget?.unmount();
    storeDispatch({ payload: { type: 'SET_WALLET_CONNECTING', connecting: false } });
  }, [connectWidget, storeDispatch]);

  useEffect(() => {
    // reconnect wallet reload
    const walletProviderName = lsWalletProviderName.read();
    const reconnecting = lsReconnectingWallet.read();

    if ((!walletProviderName || !checkout || !factory || provider) && reconnecting) {
      lsReconnectingWallet.write(false);
      return;
    }
    if (!walletProviderName || !checkout || !factory || provider || reconnecting) return;

    connectFromWalletProviderName(walletProviderName);
  }, [checkout, factory, provider, connectFromWalletProviderName]);

  useEffect(() => {
    // reset after wallet is disconnected
    if (!provider && !walletProviderName) {
      storeDispatch({ payload: { type: 'SET_WALLET_DISCONNECTED' } });
    }
  }, [provider, walletProviderName, connected, storeDispatch]);

  useEffect(() => {
    // retrieve wallet address after wallet is connected
    if (!provider || !checkout || walletAddress) return;

    (async () => {
      try {
        const { walletAddress } = await checkout.checkIsWalletConnected({ provider });
        storeDispatch({ payload: { type: 'SET_WALLET_ADDRESS', address: walletAddress } });
      } catch (err) {
        notifyError(err);
      }
    })();
  }, [provider, checkout, walletAddress, storeDispatch]);

  useEffect(() => {
    // handle connect widget events
    if (!connectWidget) return;

    connectWidget.addListener(ImtblCheckout.ConnectEventType.CLOSE_WIDGET, () => {
      handleDisconnectWallet();
    });

    connectWidget.addListener(
      ImtblCheckout.ConnectEventType.SUCCESS,
      (payload: ImtblCheckout.ConnectionSuccess) => {
        widgetsDispatch({
          payload: {
            type: 'SET_PROVIDER',
            provider: payload.provider,
            walletProviderName: payload.walletProviderName
          }
        });
        storeDispatch({ payload: { type: 'SET_WALLET_CONNECTED', connected: true } });
        storeDispatch({ payload: { type: 'SET_WALLET_CONNECTING', connecting: false } });
      }
    );

    return () => {
      connectWidget.removeListener(ImtblCheckout.ConnectEventType.CLOSE_WIDGET);
      connectWidget.removeListener(ImtblCheckout.ConnectEventType.SUCCESS);
    };
  }, [connectWidget, widgetsDispatch, storeDispatch, handleDisconnectWallet]);

  useEffect(() => {
    // create connect widget
    if (!factory || connectWidget) return;

    (async () => {
      try {
        const widget = factory.create(ImtblCheckout.WidgetType.CONNECT);
        widgetsDispatch({ payload: { type: 'SET_WIDGET', widget: { connectWidget: widget } } });
      } catch (error) {
        notifyError(error);
      }
    })();
  }, [widgetsDispatch, factory, connectWidget]);

  return {
    connected,
    connecting,
    connectWidget,
    walletAddress,
    connectWallet: handleConnectWallet,
    disconnectWallet: handleDisconnectWallet
  };
};
