import { Body, Box } from '@biom3/react';
import { useProductsContext } from 'src/context/products-context';
import { BlurMask } from './blur-mask';
import { ThreeColumnsGrid } from './three-columns-grid';

export const CollectionView = () => {
  const { products } = useProductsContext();

  const noProducts = products.length === 0;
  const noProductsCopy = 'No products found for sale at the moment.';

  // TODO: apply a different grid layout for each storefrontType

  if (noProducts) {
    return <Body sx={{ paddingY: 'base.spacing.x4' }}>{noProductsCopy}</Body>;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <ThreeColumnsGrid />
      <BlurMask />
    </Box>
  );
};
