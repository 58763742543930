import { Banner, Body, Box, BoxProps, Button } from '@biom3/react';
import Link from 'next/link';
import { useProductsContext } from 'src/context/products-context';
import { useStore } from 'src/context/store-context';
import { useDelayedValue, useTimer } from '../hooks/use-timer';
import { gameConfig } from '../utils';

// time banner will be displayed after open
const BANNER_TIMEOUT = 30000;
// delay before banner is displayed to allow animation
const BANNER_DELAY_TIMEOUT = 300;

export const AllowlistBanner = () => {
  const [{ connected }] = useStore();
  const { storeDiscount, allowlisted } = useProductsContext();

  const initialState = storeDiscount > 0 || !allowlisted;
  const [display, cancelDisplay, remainingSeconds] = useTimer(initialState, BANNER_TIMEOUT);

  const visible = useDelayedValue(display, BANNER_DELAY_TIMEOUT);

  if (!display) {
    return null;
  }

  if (!allowlisted) {
    return (
      <Wrapper display={display} visible={!!visible}>
        <Banner variant="attention">
          <Banner.Caption
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Body sx={{ color: 'inherit' }} weight="bold" size="large">
              Looks like we don’t have you on the list for this pre sale.
            </Body>
            <Body sx={{ color: 'inherit' }} weight="regular" size="medium">
              Please come back for our public sale launching soon!
            </Body>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-end',
                mt: 'base.spacing.x4'
              }}
            >
              <Button
                rc={<Link href={gameConfig.gameFaqsUrl} target="_blank" />}
                variant="tertiary/inverse"
                size="small"
                sx={{ mr: 'base.spacing.x2' }}
              >
                Visit our FAQs
              </Button>
              <Button variant="primary/inverse" size="small" onClick={() => cancelDisplay()}>
                Close ({remainingSeconds}s)
              </Button>
            </Box>
          </Banner.Caption>
        </Banner>
      </Wrapper>
    );
  }

  if (storeDiscount > 0 && connected) {
    return (
      <Wrapper display={display} visible={!!visible}>
        <Banner variant="guidance" sx={{ cursor: 'pointer' }} onClick={() => cancelDisplay()}>
          <Banner.Caption sx={{ pointerEvents: 'none' }} weight="bold" size="large">
            {storeDiscount}% off applied!
          </Banner.Caption>
        </Banner>
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = ({
  visible,
  display,
  sx,
  ...props
}: BoxProps & { display: boolean; visible: boolean }) => (
  <Box
    sx={{
      position: 'fixed',
      width: '100%',
      top: '0',
      left: '0',
      zIndex: '20',
      mx: 'auto',
      display: display ? 'block' : 'none',
      opacity: visible ? '1' : '0',
      transitionProperty: 'opacity',
      transitionDuration: 'base.motion.normal.fast.cssDuration',
      transitionTimingFunction: 'base.motion.normal.fast.cssEase',
      ...sx
    }}
    {...props}
  />
);
