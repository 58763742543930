/* eslint-disable no-unused-vars */
import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useAnalytics } from 'src/context/analytics-context';
import { useStore } from 'src/context/store-context';
import { useWidgets } from 'src/context/widgets-context';
import { getExtras } from 'src/utils/analytics';

enum METHOD {
  EXECUTE = 'execute',
  APPROVE = 'approve'
}

export const useTrackingComfirmedEvent = () => {
  const { track } = useAnalytics();

  const [{ selectedProduct, walletAddress, selectedQuantity }] = useStore();
  const [{ walletProviderName, userInfo }] = useWidgets();

  const trackComfirmedEvent = (saleResult: ImtblCheckout.SaleSuccess) => {
    const extras = getExtras(
      selectedProduct,
      walletAddress,
      walletProviderName,
      userInfo,
      selectedQuantity
    );

    const executeTransaction = saleResult.transactions.find((item) =>
      item.method.includes(METHOD.EXECUTE)
    );

    const approveTransaction = saleResult.transactions.find((item) =>
      item.method.includes(METHOD.APPROVE)
    );

    track({
      userJourney: 'PrimarySale',
      screen: 'landing',
      extras: {
        ...extras,
        txn_hash_approve: approveTransaction?.hash,
        txn_hash_execute: executeTransaction?.hash
      },
      control: 'Completed',
      controlType: 'Event',
      action: 'Confirmation'
    });

    const {
      userInfo: usrInfo,
      walletAddress: wallet,
      walletProviderName: provider,
      ...eventData
    } = extras;

    window.dataLayer?.push({
      event: 'gogPrimarySaleLanding_CompletedEventConfirmation',
      ...eventData
    });
  };

  return { trackComfirmedEvent };
};
