// @ts-nocheck
import { GraphQLError, print } from 'graphql';
import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** A Field Group registered by ACF */
export type AcfFieldGroup = {
  /** The name of the ACF Field Group */
  fieldGroupName: Maybe<Scalars['String']['output']>;
};

/** Input for the addCartItems mutation. */
export type AddCartItemsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Cart items to be added */
  items: InputMaybe<Array<InputMaybe<CartItemInput>>>;
};

/** The payload for the addCartItems mutation. */
export type AddCartItemsPayload = {
  __typename?: 'AddCartItemsPayload';
  added: Maybe<Array<Maybe<CartItem>>>;
  cart: Maybe<Cart>;
  cartErrors: Maybe<Array<Maybe<CartItemError>>>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the addFee mutation. */
export type AddFeeInput = {
  /** Fee amount */
  amount: InputMaybe<Scalars['Float']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Unique name for the fee. */
  name: Scalars['String']['input'];
  /** The tax class for the fee if taxable. */
  taxClass: InputMaybe<TaxClassEnum>;
  /** Is the fee taxable? */
  taxable: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the addFee mutation. */
export type AddFeePayload = {
  __typename?: 'AddFeePayload';
  cart: Maybe<Cart>;
  cartFee: Maybe<CartFee>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the addToCart mutation. */
export type AddToCartInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** JSON string representation of extra cart item data */
  extraData: InputMaybe<Scalars['String']['input']>;
  /** Cart item product database ID or global ID */
  productId: Scalars['Int']['input'];
  /** Cart item quantity */
  quantity: InputMaybe<Scalars['Int']['input']>;
  /** Cart item product variation attributes */
  variation: InputMaybe<Array<InputMaybe<ProductAttributeInput>>>;
  /** Cart item product variation database ID or global ID */
  variationId: InputMaybe<Scalars['Int']['input']>;
};

/** The payload for the addToCart mutation. */
export type AddToCartPayload = {
  __typename?: 'AddToCartPayload';
  cart: Maybe<Cart>;
  cartItem: Maybe<CartItem>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Coupon applied to the shopping cart. */
export type AppliedCoupon = {
  __typename?: 'AppliedCoupon';
  /** Coupon code */
  code: Scalars['String']['output'];
  /** Description of applied coupon */
  description: Maybe<Scalars['String']['output']>;
  /** Discount applied with this coupon */
  discountAmount: Scalars['String']['output'];
  /** Taxes on discount applied with this coupon */
  discountTax: Scalars['String']['output'];
};

/** Coupon applied to the shopping cart. */
export type AppliedCouponDiscountAmountArgs = {
  excludeTax: InputMaybe<Scalars['Boolean']['input']>;
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Coupon applied to the shopping cart. */
export type AppliedCouponDiscountTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Input for the applyCoupon mutation. */
export type ApplyCouponInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Code of coupon being applied */
  code: Scalars['String']['input'];
};

/** The payload for the applyCoupon mutation. */
export type ApplyCouponPayload = {
  __typename?: 'ApplyCouponPayload';
  applied: Maybe<AppliedCoupon>;
  cart: Maybe<Cart>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Attribute object */
export type Attribute = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Name of attribute */
  name: Maybe<Scalars['String']['output']>;
  /** Selected value of attribute */
  value: Maybe<Scalars['String']['output']>;
};

/** Product attribute terms count */
export type AttributeCount = {
  __typename?: 'AttributeCount';
  /** Attribute taxonomy */
  label: Scalars['String']['output'];
  /** Attribute name */
  name: Scalars['String']['output'];
  /** Attribute name */
  slug: ProductAttributeEnum;
  /** Attribute terms */
  terms: Maybe<Array<Maybe<SingleAttributeCount>>>;
};

/** Collection statistic attributes operators */
export enum AttributeOperatorEnum {
  And = 'AND',
  In = 'IN',
  NotIn = 'NOT_IN'
}

/** Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from. */
export type Avatar = {
  __typename?: 'Avatar';
  /** URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo). */
  default: Maybe<Scalars['String']['output']>;
  /** HTML attributes to insert in the IMG element. Is not sanitized. */
  extraAttr: Maybe<Scalars['String']['output']>;
  /** Whether to always show the default image, never the Gravatar. */
  forceDefault: Maybe<Scalars['Boolean']['output']>;
  /** Whether the avatar was successfully found. */
  foundAvatar: Maybe<Scalars['Boolean']['output']>;
  /** Height of the avatar image. */
  height: Maybe<Scalars['Int']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order. */
  rating: Maybe<Scalars['String']['output']>;
  /** Type of url scheme to use. Typically HTTP vs. HTTPS. */
  scheme: Maybe<Scalars['String']['output']>;
  /** The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image. */
  size: Maybe<Scalars['Int']['output']>;
  /** URL for the gravatar image source. */
  url: Maybe<Scalars['String']['output']>;
  /** Width of the avatar image. */
  width: Maybe<Scalars['Int']['output']>;
};

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  Pg = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X'
}

/** Product backorder enumeration */
export enum BackordersEnum {
  No = 'NO',
  Notify = 'NOTIFY',
  Yes = 'YES'
}

/** The banner type */
export type Banner = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Banner';
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    bannerId: Scalars['Int']['output'];
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Banner configuration&quot; was set to Show in GraphQL. */
    bannerInfo: Maybe<Banner_Bannerinfo>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the shop_banner object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the shop_banner object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** The password for the shop_banner object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the Banner type and the banner type */
    preview: Maybe<BannerToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The banner type */
export type BannerEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The banner type */
export type BannerEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The banner type */
export type BannerTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to banner Nodes */
export type BannerConnection = {
  /** A list of edges (relational context) between RootQuery and connected banner Nodes */
  edges: Array<BannerConnectionEdge>;
  /** A list of connected banner Nodes */
  nodes: Array<Banner>;
  /** Information about pagination in a connection. */
  pageInfo: BannerConnectionPageInfo;
};

/** Edge between a Node and a connected banner */
export type BannerConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected banner Node */
  node: Banner;
};

/** Page Info on the connected BannerConnectionEdge */
export type BannerConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum BannerIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the Banner type and the banner type */
export type BannerToPreviewConnectionEdge = BannerConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'BannerToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Banner;
  };

/** Field Group */
export type Banner_Bannerinfo = AcfFieldGroup & {
  __typename?: 'Banner_Bannerinfo';
  /** Description that will appear in the banner while active */
  copy: Maybe<Scalars['String']['output']>;
  /** Description that will appear in the banner when it ends */
  copyEnd: Maybe<Scalars['String']['output']>;
  /** End date and time for the banner */
  endDate: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName: Maybe<Scalars['String']['output']>;
  /** Upload an image for the confirmation screen */
  image: Maybe<MediaItem>;
  /** Start date and time for the banner */
  startDate: Maybe<Scalars['String']['output']>;
  /** Title that will appear in the banner while active */
  title: Maybe<Scalars['String']['output']>;
  /** Title that will appear in the banner when it ends */
  titleEnd: Maybe<Scalars['String']['output']>;
  /** Select banner video */
  video: Maybe<MediaItem>;
};

/** The cart object */
export type Cart = {
  __typename?: 'Cart';
  /** Coupons applied to the cart */
  appliedCoupons: Maybe<Array<Maybe<AppliedCoupon>>>;
  /** Available shipping methods for this order. */
  availableShippingMethods: Maybe<Array<Maybe<ShippingPackage>>>;
  /** Shipping method chosen for this order. */
  chosenShippingMethods: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Connection between the Cart type and the CartItem type */
  contents: Maybe<CartToCartItemConnection>;
  /** Cart contents tax */
  contentsTax: Maybe<Scalars['String']['output']>;
  /** Cart contents total */
  contentsTotal: Maybe<Scalars['String']['output']>;
  /** Cart discount tax */
  discountTax: Maybe<Scalars['String']['output']>;
  /** Cart discount total */
  discountTotal: Maybe<Scalars['String']['output']>;
  /** Do display prices include taxes */
  displayPricesIncludeTax: Maybe<Scalars['Boolean']['output']>;
  /** Cart fee tax */
  feeTax: Maybe<Scalars['String']['output']>;
  /** Cart fee total */
  feeTotal: Maybe<Scalars['String']['output']>;
  /** Additional fees on the cart. */
  fees: Maybe<Array<Maybe<CartFee>>>;
  /** Is cart empty */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** Is customer shipping address needed */
  needsShippingAddress: Maybe<Scalars['Boolean']['output']>;
  /** Cart shipping tax */
  shippingTax: Maybe<Scalars['String']['output']>;
  /** Cart shipping total */
  shippingTotal: Maybe<Scalars['String']['output']>;
  /** Cart subtotal */
  subtotal: Maybe<Scalars['String']['output']>;
  /** Cart subtotal tax */
  subtotalTax: Maybe<Scalars['String']['output']>;
  /** Cart total after calculation */
  total: Maybe<Scalars['String']['output']>;
  /** Cart total tax amount */
  totalTax: Maybe<Scalars['String']['output']>;
  /** Cart total taxes itemized */
  totalTaxes: Maybe<Array<Maybe<CartTax>>>;
};

/** The cart object */
export type CartContentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CartToCartItemConnectionWhereArgs>;
};

/** The cart object */
export type CartContentsTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartContentsTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartDiscountTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartDiscountTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartFeeTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartFeeTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartShippingTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartShippingTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartSubtotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartSubtotalTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** The cart object */
export type CartTotalTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** An error that occurred when updating the cart */
export type CartError = {
  /** Reason for error */
  reasons: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Type of error */
  type: CartErrorType;
};

/** Cart error type enumeration */
export enum CartErrorType {
  InvalidCartItem = 'INVALID_CART_ITEM',
  InvalidCoupon = 'INVALID_COUPON',
  InvalidShippingMethod = 'INVALID_SHIPPING_METHOD',
  Unknown = 'UNKNOWN'
}

/** An additional fee */
export type CartFee = {
  __typename?: 'CartFee';
  /** Fee amount */
  amount: Maybe<Scalars['Float']['output']>;
  /** Fee ID */
  id: Scalars['ID']['output'];
  /** Fee name */
  name: Scalars['String']['output'];
  /** Fee tax class */
  taxClass: Maybe<TaxClassEnum>;
  /** Is fee taxable? */
  taxable: Maybe<Scalars['Boolean']['output']>;
  /** Fee total */
  total: Maybe<Scalars['Float']['output']>;
};

/** Cart item interface. */
export type CartItem = {
  /** Object meta data */
  extraData: Maybe<Array<Maybe<MetaData>>>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** CartItem ID */
  key: Scalars['ID']['output'];
  /** Connection between the CartItem type and the Product type */
  product: Maybe<CartItemToProductConnectionEdge>;
  /** Quantity of the product */
  quantity: Maybe<Scalars['Int']['output']>;
  /** Item&#039;s subtotal */
  subtotal: Maybe<Scalars['String']['output']>;
  /** Item&#039;s subtotal tax */
  subtotalTax: Maybe<Scalars['String']['output']>;
  /** Item&#039;s tax */
  tax: Maybe<Scalars['String']['output']>;
  /** Item&#039;s total */
  total: Maybe<Scalars['String']['output']>;
  /** Connection between the CartItem type and the ProductVariation type */
  variation: Maybe<CartItemToProductVariationConnectionEdge>;
};

/** Cart item interface. */
export type CartItemExtraDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Cart item interface. */
export type CartItemProductArgs = {
  where: InputMaybe<CartItemToProductConnectionWhereArgs>;
};

/** Cart item interface. */
export type CartItemSubtotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Cart item interface. */
export type CartItemSubtotalTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Cart item interface. */
export type CartItemTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Cart item interface. */
export type CartItemTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Connection to CartItem Nodes */
export type CartItemConnection = {
  /** A list of edges (relational context) between Cart and connected CartItem Nodes */
  edges: Array<CartItemConnectionEdge>;
  /** A list of connected CartItem Nodes */
  nodes: Array<CartItem>;
  /** Information about pagination in a connection. */
  pageInfo: CartItemConnectionPageInfo;
};

/** Edge between a Node and a connected CartItem */
export type CartItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected CartItem Node */
  node: CartItem;
};

/** Page Info on the connected CartItemConnectionEdge */
export type CartItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Error that occurred when adding an item to the cart. */
export type CartItemError = CartError & {
  __typename?: 'CartItemError';
  /** JSON string representation of extra cart item data */
  extraData: Maybe<Scalars['String']['output']>;
  /** Cart item product database ID or global ID */
  productId: Scalars['Int']['output'];
  /** Cart item quantity */
  quantity: Maybe<Scalars['Int']['output']>;
  /** Reason for error */
  reasons: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Type of error */
  type: CartErrorType;
  /** Cart item product variation attributes */
  variation: Maybe<Array<Maybe<ProductAttributeOutput>>>;
  /** Cart item product variation database ID or global ID */
  variationId: Maybe<Scalars['Int']['output']>;
};

/** Cart item quantity */
export type CartItemInput = {
  /** JSON string representation of extra cart item data */
  extraData: InputMaybe<Scalars['String']['input']>;
  /** Cart item product database ID or global ID */
  productId: Scalars['Int']['input'];
  /** Cart item quantity */
  quantity: InputMaybe<Scalars['Int']['input']>;
  /** Cart item product variation attributes */
  variation: InputMaybe<Array<InputMaybe<ProductAttributeInput>>>;
  /** Cart item product variation database ID or global ID */
  variationId: InputMaybe<Scalars['Int']['input']>;
};

/** Cart item quantity */
export type CartItemQuantityInput = {
  /** Cart item being updated */
  key: Scalars['ID']['input'];
  /** Cart item's new quantity */
  quantity: Scalars['Int']['input'];
};

/** Connection between the CartItem type and the Product type */
export type CartItemToProductConnectionEdge = Edge &
  OneToOneConnection &
  ProductConnectionEdge & {
    __typename?: 'CartItemToProductConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Product;
    /** Simple variation attribute data */
    simpleVariations: Maybe<Array<Maybe<SimpleAttribute>>>;
  };

/** Arguments for filtering the CartItemToProductConnection connection */
export type CartItemToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the CartItem type and the ProductVariation type */
export type CartItemToProductVariationConnectionEdge = Edge &
  OneToOneConnection &
  ProductVariationConnectionEdge & {
    __typename?: 'CartItemToProductVariationConnectionEdge';
    /** Attributes of the variation. */
    attributes: Maybe<Array<Maybe<VariationAttribute>>>;
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ProductVariation;
  };

/** An itemized cart tax item */
export type CartTax = {
  __typename?: 'CartTax';
  /** Tax amount */
  amount: Maybe<Scalars['String']['output']>;
  /** Tax Rate ID */
  id: Scalars['ID']['output'];
  /** Is tax compound? */
  isCompound: Maybe<Scalars['Boolean']['output']>;
  /** Tax label */
  label: Scalars['String']['output'];
};

/** An itemized cart tax item */
export type CartTaxAmountArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Connection between the Cart type and the CartItem type */
export type CartToCartItemConnection = CartItemConnection &
  Connection & {
    __typename?: 'CartToCartItemConnection';
    /** Edges for the CartToCartItemConnection connection */
    edges: Array<CartToCartItemConnectionEdge>;
    /** Total number of items in the cart. */
    itemCount: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CartItem>;
    /** Information about pagination in a connection. */
    pageInfo: CartToCartItemConnectionPageInfo;
    /** Total number of different products in the cart */
    productCount: Maybe<Scalars['Int']['output']>;
  };

/** An edge in a connection */
export type CartToCartItemConnectionEdge = CartItemConnectionEdge &
  Edge & {
    __typename?: 'CartToCartItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: CartItem;
  };

/** Page Info on the &quot;CartToCartItemConnection&quot; */
export type CartToCartItemConnectionPageInfo = CartItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CartToCartItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CartToCartItemConnection connection */
export type CartToCartItemConnectionWhereArgs = {
  /** Limit results to cart items that require shipping */
  needsShipping: InputMaybe<Scalars['Boolean']['input']>;
};

/** Product catalog visibility enumeration */
export enum CatalogVisibilityEnum {
  Catalog = 'CATALOG',
  Hidden = 'HIDDEN',
  Search = 'SEARCH',
  Visible = 'VISIBLE'
}

/** The category type */
export type Category = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Category';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors: Maybe<CategoryToAncestorsCategoryConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    categoryId: Maybe<Scalars['Int']['output']>;
    /** Connection between the category type and its children categories. */
    children: Maybe<CategoryToCategoryConnection>;
    /** Connection between the Category type and the ContentNode type */
    contentNodes: Maybe<CategoryToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /** Connection between the category type and its parent category. */
    parent: Maybe<CategoryToParentCategoryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Category type and the post type */
    posts: Maybe<CategoryToPostConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the Category type and the Taxonomy type */
    taxonomy: Maybe<CategoryToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The category type */
export type CategoryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The category type */
export type CategoryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CategoryToCategoryConnectionWhereArgs>;
};

/** The category type */
export type CategoryContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CategoryToContentNodeConnectionWhereArgs>;
};

/** The category type */
export type CategoryEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The category type */
export type CategoryEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The category type */
export type CategoryPostsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CategoryToPostConnectionWhereArgs>;
};

/** Connection to category Nodes */
export type CategoryConnection = {
  /** A list of edges (relational context) between RootQuery and connected category Nodes */
  edges: Array<CategoryConnectionEdge>;
  /** A list of connected category Nodes */
  nodes: Array<Category>;
  /** Information about pagination in a connection. */
  pageInfo: CategoryConnectionPageInfo;
};

/** Edge between a Node and a connected category */
export type CategoryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected category Node */
  node: Category;
};

/** Page Info on the connected CategoryConnectionEdge */
export type CategoryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the Category type and the category type */
export type CategoryToAncestorsCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'CategoryToAncestorsCategoryConnection';
    /** Edges for the CategoryToAncestorsCategoryConnection connection */
    edges: Array<CategoryToAncestorsCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToAncestorsCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToAncestorsCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'CategoryToAncestorsCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;CategoryToAncestorsCategoryConnection&quot; */
export type CategoryToAncestorsCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToAncestorsCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the Category type and the category type */
export type CategoryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'CategoryToCategoryConnection';
    /** Edges for the CategoryToCategoryConnection connection */
    edges: Array<CategoryToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'CategoryToCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;CategoryToCategoryConnection&quot; */
export type CategoryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Category type and the ContentNode type */
export type CategoryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'CategoryToContentNodeConnection';
    /** Edges for the CategoryToContentNodeConnection connection */
    edges: Array<CategoryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'CategoryToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;CategoryToContentNodeConnection&quot; */
export type CategoryToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Category type and the category type */
export type CategoryToParentCategoryConnectionEdge = CategoryConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CategoryToParentCategoryConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Category;
  };

/** Connection between the Category type and the post type */
export type CategoryToPostConnection = Connection &
  PostConnection & {
    __typename?: 'CategoryToPostConnection';
    /** Edges for the CategoryToPostConnection connection */
    edges: Array<CategoryToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'CategoryToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;CategoryToPostConnection&quot; */
export type CategoryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Category type and the Taxonomy type */
export type CategoryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'CategoryToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Input for the checkout mutation. */
export type CheckoutInput = {
  /** Create new customer account */
  account: InputMaybe<CreateAccountInput>;
  /** Order billing address */
  billing: InputMaybe<CustomerAddressInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Order customer note */
  customerNote: InputMaybe<Scalars['String']['input']>;
  /** Define if the order is paid. It will set the status to processing and reduce stock items. */
  isPaid: InputMaybe<Scalars['Boolean']['input']>;
  /** Order meta data */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Payment method ID. */
  paymentMethod: InputMaybe<Scalars['String']['input']>;
  /** Ship to a separate address */
  shipToDifferentAddress: InputMaybe<Scalars['Boolean']['input']>;
  /** Order shipping address */
  shipping: InputMaybe<CustomerAddressInput>;
  /** Order shipping method */
  shippingMethod: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Order transaction ID */
  transactionId: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the checkout mutation. */
export type CheckoutPayload = {
  __typename?: 'CheckoutPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Maybe<Customer>;
  order: Maybe<Order>;
  redirect: Maybe<Scalars['String']['output']>;
  result: Maybe<Scalars['String']['output']>;
};

/** Data about a collection of products */
export type CollectionStats = {
  __typename?: 'CollectionStats';
  /** Returns number of products within attribute terms */
  attributeCounts: Maybe<Array<Maybe<AttributeCount>>>;
  /** Min and max prices found in collection of products, provided using the smallest unit of the currency */
  priceRange: Maybe<PriceRange>;
  /** Returns number of products with each average rating */
  ratingCounts: Maybe<Array<Maybe<RatingCount>>>;
  /** Returns number of products with each stock status */
  stockStatusCounts: Maybe<Array<Maybe<StockStatusCount>>>;
};

/** Data about a collection of products */
export type CollectionStatsAttributeCountsArgs = {
  page: InputMaybe<Scalars['Int']['input']>;
  perPage: InputMaybe<Scalars['Int']['input']>;
};

/** Data about a collection of products */
export type CollectionStatsRatingCountsArgs = {
  page: InputMaybe<Scalars['Int']['input']>;
  perPage: InputMaybe<Scalars['Int']['input']>;
};

/** Data about a collection of products */
export type CollectionStatsStockStatusCountsArgs = {
  page: InputMaybe<Scalars['Int']['input']>;
  perPage: InputMaybe<Scalars['Int']['input']>;
};

/** Taxonomy query */
export type CollectionStatsQueryInput = {
  /** Taxonomy relation to query */
  relation: RelationEnum;
  /** Product Taxonomy */
  taxonomy: ProductAttributeEnum;
};

/** Arguments used to filter the collection results */
export type CollectionStatsWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attributes: InputMaybe<Array<InputMaybe<ProductAttributeFilterInput>>>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** A Comment object */
export type Comment = DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'Comment';
    /** User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL. */
    agent: Maybe<Scalars['String']['output']>;
    /**
     * The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL.
     * @deprecated Deprecated in favor of the `status` field
     */
    approved: Maybe<Scalars['Boolean']['output']>;
    /** The author of the comment */
    author: Maybe<CommentToCommenterConnectionEdge>;
    /** IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL. */
    authorIp: Maybe<Scalars['String']['output']>;
    /**
     * ID for the comment, unique among comments.
     * @deprecated Deprecated in favor of databaseId
     */
    commentId: Maybe<Scalars['Int']['output']>;
    /** Connection between the Comment type and the ContentNode type */
    commentedOn: Maybe<CommentToContentNodeConnectionEdge>;
    /** Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL. */
    content: Maybe<Scalars['String']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL. */
    date: Maybe<Scalars['String']['output']>;
    /** Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier for the comment object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL. */
    karma: Maybe<Scalars['Int']['output']>;
    /** The permalink of the comment */
    link: Maybe<Scalars['String']['output']>;
    /** Connection between the Comment type and the Comment type */
    parent: Maybe<CommentToParentCommentConnectionEdge>;
    /** The database id of the parent comment node or null if it is the root comment */
    parentDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent comment node. */
    parentId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Comment type and the Comment type */
    replies: Maybe<CommentToCommentConnection>;
    /** The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL. */
    status: Maybe<CommentStatusEnum>;
    /** Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL. */
    type: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** A Comment object */
export type CommentContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A Comment object */
export type CommentParentArgs = {
  where: InputMaybe<CommentToParentCommentConnectionWhereArgs>;
};

/** A Comment object */
export type CommentRepliesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CommentToCommentConnectionWhereArgs>;
};

/** A Comment Author object */
export type CommentAuthor = Commenter &
  DatabaseIdentifier &
  Node & {
    __typename?: 'CommentAuthor';
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar: Maybe<Avatar>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The email for the comment author */
    email: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier for the comment author object */
    id: Scalars['ID']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** The name for the comment author. */
    name: Maybe<Scalars['String']['output']>;
    /** The url the comment author. */
    url: Maybe<Scalars['String']['output']>;
  };

/** A Comment Author object */
export type CommentAuthorAvatarArgs = {
  forceDefault: InputMaybe<Scalars['Boolean']['input']>;
  rating: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to Comment Nodes */
export type CommentConnection = {
  /** A list of edges (relational context) between RootQuery and connected Comment Nodes */
  edges: Array<CommentConnectionEdge>;
  /** A list of connected Comment Nodes */
  nodes: Array<Comment>;
  /** Information about pagination in a connection. */
  pageInfo: CommentConnectionPageInfo;
};

/** Edge between a Node and a connected Comment */
export type CommentConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Comment Node */
  node: Comment;
};

/** Page Info on the connected CommentConnectionEdge */
export type CommentConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** The status of the comment object. */
export enum CommentStatusEnum {
  /** Comments with the Approved status */
  Approve = 'APPROVE',
  /** Comments with the Unapproved status */
  Hold = 'HOLD',
  /** Comments with the Spam status */
  Spam = 'SPAM',
  /** Comments with the Trash status */
  Trash = 'TRASH'
}

/** Connection between the Comment type and the Comment type */
export type CommentToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'CommentToCommentConnection';
    /** Edges for the CommentToCommentConnection connection */
    edges: Array<CommentToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: CommentToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type CommentToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'CommentToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;CommentToCommentConnection&quot; */
export type CommentToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CommentToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Comment type and the Commenter type */
export type CommentToCommenterConnectionEdge = CommenterConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToCommenterConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Commenter;
  };

/** Connection between the Comment type and the ContentNode type */
export type CommentToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToContentNodeConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** Connection between the Comment type and the Comment type */
export type CommentToParentCommentConnectionEdge = CommentConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToParentCommentConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Comment;
  };

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** The author of a comment */
export type Commenter = {
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar: Maybe<Avatar>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output'];
  /** The email address of the author of a comment. */
  email: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier for the comment author. */
  id: Scalars['ID']['output'];
  /** Whether the author information is considered restricted. (not fully public) */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** The name of the author of a comment. */
  name: Maybe<Scalars['String']['output']>;
  /** The url of the author of a comment. */
  url: Maybe<Scalars['String']['output']>;
};

/** Edge between a Node and a connected Commenter */
export type CommenterConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Commenter Node */
  node: Commenter;
};

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = 'COMMENT_AGENT',
  /** Order by approval status of the comment. */
  CommentApproved = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  CommentAuthor = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  CommentContent = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  CommentDate = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  CommentId = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = 'COMMENT_IN',
  /** Order by the comment karma score. */
  CommentKarma = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  CommentParent = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  CommentPostId = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = 'COMMENT_TYPE',
  /** Order by the user ID. */
  UserId = 'USER_ID'
}

/** The confirmationScreen type */
export type ConfirmationScreen = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'ConfirmationScreen';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Configuration&quot; was set to Show in GraphQL. */
    config: Maybe<ConfirmationScreen_Config>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    confirmationScreenId: Scalars['Int']['output'];
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Content fields&quot; was set to Show in GraphQL. */
    fields: Maybe<ConfirmationScreen_Fields>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the confirmation_screen object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the confirmation_screen object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** The password for the confirmation_screen object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the ConfirmationScreen type and the confirmationScreen type */
    preview: Maybe<ConfirmationScreenToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The confirmationScreen type */
export type ConfirmationScreenEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The confirmationScreen type */
export type ConfirmationScreenEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The confirmationScreen type */
export type ConfirmationScreenTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to confirmationScreen Nodes */
export type ConfirmationScreenConnection = {
  /** A list of edges (relational context) between RootQuery and connected confirmationScreen Nodes */
  edges: Array<ConfirmationScreenConnectionEdge>;
  /** A list of connected confirmationScreen Nodes */
  nodes: Array<ConfirmationScreen>;
  /** Information about pagination in a connection. */
  pageInfo: ConfirmationScreenConnectionPageInfo;
};

/** Edge between a Node and a connected confirmationScreen */
export type ConfirmationScreenConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected confirmationScreen Node */
  node: ConfirmationScreen;
};

/** Page Info on the connected ConfirmationScreenConnectionEdge */
export type ConfirmationScreenConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ConfirmationScreenIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the ConfirmationScreen type and the confirmationScreen type */
export type ConfirmationScreenToPreviewConnectionEdge = ConfirmationScreenConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'ConfirmationScreenToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ConfirmationScreen;
  };

/** Field Group */
export type ConfirmationScreen_Config = AcfFieldGroup & {
  __typename?: 'ConfirmationScreen_Config';
  collectionType: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName: Maybe<Scalars['String']['output']>;
  wallet: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type ConfirmationScreen_Fields = AcfFieldGroup & {
  __typename?: 'ConfirmationScreen_Fields';
  /** Button displayed on the confirmation screen */
  cta: Maybe<Scalars['String']['output']>;
  ctaText: Maybe<Scalars['String']['output']>;
  /** Useful for providing instructions for the user. Use &lt;em&gt;{product}&lt;/em&gt; to insert the product name. */
  description: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName: Maybe<Scalars['String']['output']>;
  /** Upload an image for the confirmation screen */
  image: Maybe<MediaItem>;
};

/** A plural connection from one Node Type in the Graph to another Node Type, with support for relational data via &quot;edges&quot;. */
export type Connection = {
  /** A list of edges (relational context) between connected nodes */
  edges: Array<Edge>;
  /** A list of connected nodes */
  nodes: Array<Node>;
  /** Information about pagination in a connection. */
  pageInfo: PageInfo;
};

/** Nodes used to manage content */
export type ContentNode = {
  /** Connection between the ContentNode type and the ContentType type */
  contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output'];
  /** The ID of the node in the database. */
  databaseId: Scalars['Int']['output'];
  /** Post publishing date. */
  date: Maybe<Scalars['String']['output']>;
  /** The publishing date set in GMT. */
  dateGmt: Maybe<Scalars['String']['output']>;
  /** The desired slug of the post */
  desiredSlug: Maybe<Scalars['String']['output']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is a node in the preview state */
  isPreview: Maybe<Scalars['Boolean']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The user that most recently edited the node */
  lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link: Maybe<Scalars['String']['output']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified: Maybe<Scalars['String']['output']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt: Maybe<Scalars['String']['output']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId: Maybe<Scalars['ID']['output']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug: Maybe<Scalars['String']['output']>;
  /** The current status of the object */
  status: Maybe<Scalars['String']['output']>;
  /** The template assigned to a node of content */
  template: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
};

/** Nodes used to manage content */
export type ContentNodeEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Nodes used to manage content */
export type ContentNodeEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to ContentNode Nodes */
export type ContentNodeConnection = {
  /** A list of edges (relational context) between ContentType and connected ContentNode Nodes */
  edges: Array<ContentNodeConnectionEdge>;
  /** A list of connected ContentNode Nodes */
  nodes: Array<ContentNode>;
  /** Information about pagination in a connection. */
  pageInfo: ContentNodeConnectionPageInfo;
};

/** Edge between a Node and a connected ContentNode */
export type ContentNodeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ContentNode Node */
  node: ContentNode;
};

/** Page Info on the connected ContentNodeConnectionEdge */
export type ContentNodeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the ContentNode type and the ContentType type */
export type ContentNodeToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'ContentNodeToContentTypeConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ContentType;
  };

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLastConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'ContentNodeToEditLastConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLockConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'ContentNodeToEditLockConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The timestamp for when the node was last edited */
    lockTimestamp: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** Connection between the ContentNode type and the EnqueuedScript type */
export type ContentNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'ContentNodeToEnqueuedScriptConnection';
    /** Edges for the ContentNodeToEnqueuedScriptConnection connection */
    edges: Array<ContentNodeToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: ContentNodeToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'ContentNodeToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;ContentNodeToEnqueuedScriptConnection&quot; */
export type ContentNodeToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ContentNodeToEnqueuedScriptConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the ContentNode type and the EnqueuedStylesheet type */
export type ContentNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'ContentNodeToEnqueuedStylesheetConnection';
    /** Edges for the ContentNodeToEnqueuedStylesheetConnection connection */
    edges: Array<ContentNodeToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'ContentNodeToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;ContentNodeToEnqueuedStylesheetConnection&quot; */
export type ContentNodeToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentNodeToEnqueuedStylesheetConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** The template assigned to a node of content */
export type ContentTemplate = {
  /** The name of the template */
  templateName: Maybe<Scalars['String']['output']>;
};

/** An Post Type object */
export type ContentType = Node &
  UniformResourceIdentifiable & {
    __typename?: 'ContentType';
    /** Whether this content type should can be exported. */
    canExport: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the ContentType type and the Taxonomy type */
    connectedTaxonomies: Maybe<ContentTypeToTaxonomyConnection>;
    /** Connection between the ContentType type and the ContentNode type */
    contentNodes: Maybe<ContentTypeToContentNodeConnection>;
    /** Whether content of this type should be deleted when the author of it is deleted from the system. */
    deleteWithUser: Maybe<Scalars['Boolean']['output']>;
    /** Description of the content type. */
    description: Maybe<Scalars['String']['output']>;
    /** Whether to exclude nodes of this content type from front end search results. */
    excludeFromSearch: Maybe<Scalars['Boolean']['output']>;
    /** The plural name of the content type within the GraphQL Schema. */
    graphqlPluralName: Maybe<Scalars['String']['output']>;
    /** The singular name of the content type within the GraphQL Schema. */
    graphqlSingleName: Maybe<Scalars['String']['output']>;
    /** Whether this content type should have archives. Content archives are generated by type and by date. */
    hasArchive: Maybe<Scalars['Boolean']['output']>;
    /** Whether the content type is hierarchical, for example pages. */
    hierarchical: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the post-type object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Display name of the content type. */
    label: Maybe<Scalars['String']['output']>;
    /** Details about the content type labels. */
    labels: Maybe<PostTypeLabelDetails>;
    /** The name of the icon file to display as a menu icon. */
    menuIcon: Maybe<Scalars['String']['output']>;
    /** The position of this post type in the menu. Only applies if show_in_menu is true. */
    menuPosition: Maybe<Scalars['Int']['output']>;
    /** The internal name of the post type. This should not be used for display purposes. */
    name: Maybe<Scalars['String']['output']>;
    /** Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention. */
    public: Maybe<Scalars['Boolean']['output']>;
    /** Whether queries can be performed on the front end for the content type as part of parse_request(). */
    publiclyQueryable: Maybe<Scalars['Boolean']['output']>;
    /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
    restBase: Maybe<Scalars['String']['output']>;
    /** The REST Controller class assigned to handling this content type. */
    restControllerClass: Maybe<Scalars['String']['output']>;
    /** Makes this content type available via the admin bar. */
    showInAdminBar: Maybe<Scalars['Boolean']['output']>;
    /** Whether to add the content type to the GraphQL Schema. */
    showInGraphql: Maybe<Scalars['Boolean']['output']>;
    /** Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that. */
    showInMenu: Maybe<Scalars['Boolean']['output']>;
    /** Makes this content type available for selection in navigation menus. */
    showInNavMenus: Maybe<Scalars['Boolean']['output']>;
    /** Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace. */
    showInRest: Maybe<Scalars['Boolean']['output']>;
    /** Whether to generate and allow a UI for managing this content type in the admin. */
    showUi: Maybe<Scalars['Boolean']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** An Post Type object */
export type ContentTypeConnectedTaxonomiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** An Post Type object */
export type ContentTypeContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ContentTypeToContentNodeConnectionWhereArgs>;
};

/** Connection to ContentType Nodes */
export type ContentTypeConnection = {
  /** A list of edges (relational context) between RootQuery and connected ContentType Nodes */
  edges: Array<ContentTypeConnectionEdge>;
  /** A list of connected ContentType Nodes */
  nodes: Array<ContentType>;
  /** Information about pagination in a connection. */
  pageInfo: ContentTypeConnectionPageInfo;
};

/** Edge between a Node and a connected ContentType */
export type ContentTypeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ContentType Node */
  node: ContentType;
};

/** Page Info on the connected ContentTypeConnectionEdge */
export type ContentTypeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  Attachment = 'ATTACHMENT',
  /** The Type of Content object */
  ConfirmationScreen = 'CONFIRMATION_SCREEN',
  /** The Type of Content object */
  GraphqlDocument = 'GRAPHQL_DOCUMENT',
  /** The Type of Content object */
  Page = 'PAGE',
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Product = 'PRODUCT',
  /** The Type of Content object */
  ShopBanner = 'SHOP_BANNER'
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the content type. */
  Name = 'NAME'
}

/** Connection between the ContentType type and the ContentNode type */
export type ContentTypeToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'ContentTypeToContentNodeConnection';
    /** Edges for the ContentTypeToContentNodeConnection connection */
    edges: Array<ContentTypeToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ContentTypeToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentTypeToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'ContentTypeToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ContentTypeToContentNodeConnection&quot; */
export type ContentTypeToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ContentTypeToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the ContentType type and the Taxonomy type */
export type ContentTypeToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: 'ContentTypeToTaxonomyConnection';
    /** Edges for the ContentTypeToTaxonomyConnection connection */
    edges: Array<ContentTypeToTaxonomyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Taxonomy>;
    /** Information about pagination in a connection. */
    pageInfo: ContentTypeToTaxonomyConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentTypeToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: 'ContentTypeToTaxonomyConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Taxonomy;
  };

/** Page Info on the &quot;ContentTypeToTaxonomyConnection&quot; */
export type ContentTypeToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ContentTypeToTaxonomyConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Post = 'POST'
}

/** Allowed Content Types of the GraphqlDocumentGroup taxonomy. */
export enum ContentTypesOfGraphqlDocumentGroupEnum {
  /** The Type of Content object */
  GraphqlDocument = 'GRAPHQL_DOCUMENT'
}

/** Allowed Content Types of the PaDefault taxonomy. */
export enum ContentTypesOfPaDefaultEnum {
  /** The Type of Content object */
  Product = 'PRODUCT'
}

/** Allowed Content Types of the PaPrimaryCurrency taxonomy. */
export enum ContentTypesOfPaPrimaryCurrencyEnum {
  /** The Type of Content object */
  Product = 'PRODUCT'
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = 'POST'
}

/** Allowed Content Types of the ProductType taxonomy. */
export enum ContentTypesOfProductTypeEnum {
  /** The Type of Content object */
  Product = 'PRODUCT'
}

/** Allowed Content Types of the ShippingClass taxonomy. */
export enum ContentTypesOfShippingClassEnum {
  /** The Type of Content object */
  Product = 'PRODUCT'
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Post = 'POST'
}

/** Allowed Content Types of the VisibleProduct taxonomy. */
export enum ContentTypesOfVisibleProductEnum {
  /** The Type of Content object */
  Product = 'PRODUCT'
}

/** Countries enumeration */
export enum CountriesEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/** shipping country state object */
export type CountryState = {
  __typename?: 'CountryState';
  /** Country state code */
  code: Scalars['String']['output'];
  /** Country state name */
  name: Scalars['String']['output'];
};

/** A coupon object */
export type Coupon = Node & {
  __typename?: 'Coupon';
  /** Amount off provided by the coupon */
  amount: Maybe<Scalars['Float']['output']>;
  /** Coupon code */
  code: Maybe<Scalars['String']['output']>;
  /** The ID of the coupon in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** Date coupon created */
  date: Maybe<Scalars['String']['output']>;
  /** Date coupon expires */
  dateExpiry: Maybe<Scalars['String']['output']>;
  /** Explanation of what the coupon does */
  description: Maybe<Scalars['String']['output']>;
  /** Type of discount */
  discountType: Maybe<DiscountTypeEnum>;
  /** Only customers with a matching email address can use the coupon */
  emailRestrictions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Excluding sale items mean this coupon cannot be used on items that are on sale (or carts that contain on sale items) */
  excludeSaleItems: Maybe<Scalars['Boolean']['output']>;
  /** Connection between the Coupon type and the productCategory type */
  excludedProductCategories: Maybe<CouponToExcludedProductCategoriesConnection>;
  /** Connection between the Coupon type and the ProductUnion type */
  excludedProducts: Maybe<CouponToExcludedProductsConnection>;
  /** Does this coupon grant free shipping? */
  freeShipping: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique identifier for the coupon */
  id: Scalars['ID']['output'];
  /** Individual use means this coupon cannot be used in conjunction with other coupons */
  individualUse: Maybe<Scalars['Boolean']['output']>;
  /** The number of products in your cart this coupon can apply to (for product discounts) */
  limitUsageToXItems: Maybe<Scalars['Int']['output']>;
  /** Maximum spend amount that must be met before this coupon can be used  */
  maximumAmount: Maybe<Scalars['Float']['output']>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Minimum spend amount that must be met before this coupon can be used */
  minimumAmount: Maybe<Scalars['Float']['output']>;
  /** Date coupon modified */
  modified: Maybe<Scalars['String']['output']>;
  /** Connection between the Coupon type and the productCategory type */
  productCategories: Maybe<CouponToProductCategoryConnection>;
  /** Connection between the Coupon type and the ProductUnion type */
  products: Maybe<CouponToProductUnionConnection>;
  /** How many times the coupon has been used */
  usageCount: Maybe<Scalars['Int']['output']>;
  /** Amount of times this coupon can be used globally */
  usageLimit: Maybe<Scalars['Int']['output']>;
  /** Amount of times this coupon can be used by a customer */
  usageLimitPerUser: Maybe<Scalars['Int']['output']>;
  /** Connection between the Coupon type and the Customer type */
  usedBy: Maybe<CouponToCustomerConnection>;
};

/** A coupon object */
export type CouponExcludedProductCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CouponToExcludedProductCategoriesConnectionWhereArgs>;
};

/** A coupon object */
export type CouponExcludedProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CouponToExcludedProductsConnectionWhereArgs>;
};

/** A coupon object */
export type CouponMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A coupon object */
export type CouponProductCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CouponToProductCategoryConnectionWhereArgs>;
};

/** A coupon object */
export type CouponProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CouponToProductUnionConnectionWhereArgs>;
};

/** A coupon object */
export type CouponUsedByArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CouponToCustomerConnectionWhereArgs>;
};

/** Connection to Coupon Nodes */
export type CouponConnection = {
  /** A list of edges (relational context) between RootQuery and connected Coupon Nodes */
  edges: Array<CouponConnectionEdge>;
  /** A list of connected Coupon Nodes */
  nodes: Array<Coupon>;
  /** Information about pagination in a connection. */
  pageInfo: CouponConnectionPageInfo;
};

/** Edge between a Node and a connected Coupon */
export type CouponConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Coupon Node */
  node: Coupon;
};

/** Page Info on the connected CouponConnectionEdge */
export type CouponConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single Coupon. Default is ID. */
export enum CouponIdTypeEnum {
  /** Coupon code. */
  Code = 'CODE',
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** a coupon line object */
export type CouponLine = Node & {
  __typename?: 'CouponLine';
  /** Line&#039;s Coupon code */
  code: Maybe<Scalars['String']['output']>;
  /** Line&#039;s Coupon */
  coupon: Maybe<Coupon>;
  /** The ID of the order item in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** Line&#039;s Discount total */
  discount: Maybe<Scalars['String']['output']>;
  /** Line&#039;s Discount total tax */
  discountTax: Maybe<Scalars['String']['output']>;
  /** The ID of the order item in the database */
  id: Scalars['ID']['output'];
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** The Id of the order the order item belongs to. */
  orderId: Maybe<Scalars['Int']['output']>;
};

/** a coupon line object */
export type CouponLineMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection to CouponLine Nodes */
export type CouponLineConnection = {
  /** A list of edges (relational context) between Order and connected CouponLine Nodes */
  edges: Array<CouponLineConnectionEdge>;
  /** A list of connected CouponLine Nodes */
  nodes: Array<CouponLine>;
  /** Information about pagination in a connection. */
  pageInfo: CouponLineConnectionPageInfo;
};

/** Edge between a Node and a connected CouponLine */
export type CouponLineConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected CouponLine Node */
  node: CouponLine;
};

/** Page Info on the connected CouponLineConnectionEdge */
export type CouponLineConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Connection between the Coupon type and the Customer type */
export type CouponToCustomerConnection = Connection &
  CustomerConnection & {
    __typename?: 'CouponToCustomerConnection';
    /** Edges for the CouponToCustomerConnection connection */
    edges: Array<CouponToCustomerConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Customer>;
    /** Information about pagination in a connection. */
    pageInfo: CouponToCustomerConnectionPageInfo;
  };

/** An edge in a connection */
export type CouponToCustomerConnectionEdge = CustomerConnectionEdge &
  Edge & {
    __typename?: 'CouponToCustomerConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Customer;
  };

/** Page Info on the &quot;CouponToCustomerConnection&quot; */
export type CouponToCustomerConnectionPageInfo = CustomerConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CouponToCustomerConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CouponToCustomerConnection connection */
export type CouponToCustomerConnectionWhereArgs = {
  /** Limit result set to resources with a specific email. */
  email: InputMaybe<Scalars['String']['input']>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Order of results. */
  order: InputMaybe<OrderEnum>;
  /** Order results by a specific field. */
  orderby: InputMaybe<CustomerConnectionOrderbyEnum>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Coupon type and the productCategory type */
export type CouponToExcludedProductCategoriesConnection = Connection &
  ProductCategoryConnection & {
    __typename?: 'CouponToExcludedProductCategoriesConnection';
    /** Edges for the CouponToExcludedProductCategoriesConnection connection */
    edges: Array<CouponToExcludedProductCategoriesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductCategory>;
    /** Information about pagination in a connection. */
    pageInfo: CouponToExcludedProductCategoriesConnectionPageInfo;
  };

/** An edge in a connection */
export type CouponToExcludedProductCategoriesConnectionEdge = Edge &
  ProductCategoryConnectionEdge & {
    __typename?: 'CouponToExcludedProductCategoriesConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductCategory;
  };

/** Page Info on the &quot;CouponToExcludedProductCategoriesConnection&quot; */
export type CouponToExcludedProductCategoriesConnectionPageInfo = PageInfo &
  ProductCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CouponToExcludedProductCategoriesConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CouponToExcludedProductCategoriesConnection connection */
export type CouponToExcludedProductCategoriesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Coupon type and the ProductUnion type */
export type CouponToExcludedProductsConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'CouponToExcludedProductsConnection';
    /** Edges for the CouponToExcludedProductsConnection connection */
    edges: Array<CouponToExcludedProductsConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: CouponToExcludedProductsConnectionPageInfo;
  };

/** An edge in a connection */
export type CouponToExcludedProductsConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'CouponToExcludedProductsConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;CouponToExcludedProductsConnection&quot; */
export type CouponToExcludedProductsConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CouponToExcludedProductsConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CouponToExcludedProductsConnection connection */
export type CouponToExcludedProductsConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the Coupon type and the productCategory type */
export type CouponToProductCategoryConnection = Connection &
  ProductCategoryConnection & {
    __typename?: 'CouponToProductCategoryConnection';
    /** Edges for the CouponToProductCategoryConnection connection */
    edges: Array<CouponToProductCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductCategory>;
    /** Information about pagination in a connection. */
    pageInfo: CouponToProductCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CouponToProductCategoryConnectionEdge = Edge &
  ProductCategoryConnectionEdge & {
    __typename?: 'CouponToProductCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductCategory;
  };

/** Page Info on the &quot;CouponToProductCategoryConnection&quot; */
export type CouponToProductCategoryConnectionPageInfo = PageInfo &
  ProductCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CouponToProductCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CouponToProductCategoryConnection connection */
export type CouponToProductCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Coupon type and the ProductUnion type */
export type CouponToProductUnionConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'CouponToProductUnionConnection';
    /** Edges for the CouponToProductUnionConnection connection */
    edges: Array<CouponToProductUnionConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: CouponToProductUnionConnectionPageInfo;
  };

/** An edge in a connection */
export type CouponToProductUnionConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'CouponToProductUnionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;CouponToProductUnionConnection&quot; */
export type CouponToProductUnionConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CouponToProductUnionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CouponToProductUnionConnection connection */
export type CouponToProductUnionConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Customer account credentials */
export type CreateAccountInput = {
  /** Customer password */
  password: Scalars['String']['input'];
  /** Customer username */
  username: Scalars['String']['input'];
};

/** Input for the createBanner mutation. */
export type CreateBannerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createBanner mutation. */
export type CreateBannerPayload = {
  __typename?: 'CreateBannerPayload';
  /** The Post object mutation type. */
  banner: Maybe<Banner>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the createCategory mutation. */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the category object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the category object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the category that should be set as the parent */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createCategory mutation. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  /** The created category */
  category: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the createComment mutation. */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved: InputMaybe<Scalars['String']['input']>;
  /** The name of the comment's author. */
  author: InputMaybe<Scalars['String']['input']>;
  /** The email of the comment's author. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** The url of the comment's author. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn: InputMaybe<Scalars['Int']['input']>;
  /** Content of the comment. */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** Parent comment ID of current comment. */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** The approval status of the comment */
  status: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createComment mutation. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The comment that was created */
  comment: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success: Maybe<Scalars['Boolean']['output']>;
};

/** Input for the createConfirmationScreen mutation. */
export type CreateConfirmationScreenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createConfirmationScreen mutation. */
export type CreateConfirmationScreenPayload = {
  __typename?: 'CreateConfirmationScreenPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  confirmationScreen: Maybe<ConfirmationScreen>;
};

/** Input for the createCoupon mutation. */
export type CreateCouponInput = {
  /** The amount of discount. Should always be numeric, even if setting a percentage. */
  amount: InputMaybe<Scalars['Float']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Coupon code. */
  code: Scalars['String']['input'];
  /** The date the coupon expires, in the site's timezone. */
  dateExpires: InputMaybe<Scalars['String']['input']>;
  /** The date the coupon expires, as GMT. */
  dateExpiresGmt: InputMaybe<Scalars['String']['input']>;
  /** Coupon description. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Determines the type of discount that will be applied. */
  discountType: InputMaybe<DiscountTypeEnum>;
  /** List of email addresses that can use this coupon. */
  emailRestrictions: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** If true, this coupon will not be applied to items that have sale prices. */
  excludeSaleItems: InputMaybe<Scalars['Boolean']['input']>;
  /** List of category IDs the coupon does not apply to. */
  excludedProductCategories: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** List of product IDs the coupon cannot be used on. */
  excludedProductIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** If true and if the free shipping method requires a coupon, this coupon will enable free shipping. */
  freeShipping: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, the coupon can only be used individually. Other applied coupons will be removed from the cart. */
  individualUse: InputMaybe<Scalars['Boolean']['input']>;
  /** Max number of items in the cart the coupon can be applied to. */
  limitUsageToXItems: InputMaybe<Scalars['Int']['input']>;
  /** Maximum order amount allowed when using the coupon. */
  maximumAmount: InputMaybe<Scalars['String']['input']>;
  /** Meta data. */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Minimum order amount that needs to be in the cart before coupon applies. */
  minimumAmount: InputMaybe<Scalars['String']['input']>;
  /** List of category IDs the coupon applies to. */
  productCategories: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** List of product IDs the coupon can be used on. */
  productIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** How many times the coupon can be used in total. */
  usageLimit: InputMaybe<Scalars['Int']['input']>;
  /** How many times the coupon can be used per customer. */
  usageLimitPerUser: InputMaybe<Scalars['Int']['input']>;
};

/** The payload for the createCoupon mutation. */
export type CreateCouponPayload = {
  __typename?: 'CreateCouponPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  coupon: Maybe<Coupon>;
};

/** Input for the createGraphqlDocumentGroup mutation. */
export type CreateGraphqlDocumentGroupInput = {
  /** The slug that the graphql_document_group will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the graphql_document_group object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the graphql_document_group object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createGraphqlDocumentGroup mutation. */
export type CreateGraphqlDocumentGroupPayload = {
  __typename?: 'CreateGraphqlDocumentGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created graphql_document_group */
  graphqlDocumentGroup: Maybe<GraphqlDocumentGroup>;
};

/** Input for the createGraphqlDocument mutation. */
export type CreateGraphqlDocumentInput = {
  /** Alias names for saved GraphQL query documents */
  alias: InputMaybe<Array<Scalars['String']['input']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** Description for the saved GraphQL document */
  description: InputMaybe<Scalars['String']['input']>;
  /** Allow, deny or default access grant for specific query */
  grant: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the graphqlDocument and graphqlDocumentGroups */
  graphqlDocumentGroups: InputMaybe<GraphqlDocumentGraphqlDocumentGroupsInput>;
  /** HTTP Cache-Control max-age directive for a saved GraphQL document */
  maxAgeHeader: InputMaybe<Scalars['Int']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createGraphqlDocument mutation. */
export type CreateGraphqlDocumentPayload = {
  __typename?: 'CreateGraphqlDocumentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  graphqlDocument: Maybe<GraphqlDocument>;
};

/** Input for the createMediaItem mutation. */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText: InputMaybe<Scalars['String']['input']>;
  /** The userId to assign as the author of the mediaItem */
  authorId: InputMaybe<Scalars['ID']['input']>;
  /** The caption for the mediaItem */
  caption: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the mediaItem */
  commentStatus: InputMaybe<Scalars['String']['input']>;
  /** The date of the mediaItem */
  date: InputMaybe<Scalars['String']['input']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt: InputMaybe<Scalars['String']['input']>;
  /** Description of the mediaItem */
  description: InputMaybe<Scalars['String']['input']>;
  /** The file name of the mediaItem */
  filePath: InputMaybe<Scalars['String']['input']>;
  /** The file type of the mediaItem */
  fileType: InputMaybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** The ping status for the mediaItem */
  pingStatus: InputMaybe<Scalars['String']['input']>;
  /** The slug of the mediaItem */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the mediaItem */
  status: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createMediaItem mutation. */
export type CreateMediaItemPayload = {
  __typename?: 'CreateMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The MediaItem object mutation type. */
  mediaItem: Maybe<MediaItem>;
};

/** Input for the createOrder mutation. */
export type CreateOrderInput = {
  /** Order billing address */
  billing: InputMaybe<CustomerAddressInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Coupons codes to be applied to order */
  coupons: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Currency the order was created with, in ISO format. */
  currency: InputMaybe<Scalars['String']['input']>;
  /** Order customer ID */
  customerId: InputMaybe<Scalars['Int']['input']>;
  /** Note left by customer during checkout. */
  customerNote: InputMaybe<Scalars['String']['input']>;
  /** Order shipping lines */
  feeLines: InputMaybe<Array<InputMaybe<FeeLineInput>>>;
  /** Define if the order is paid. It will set the status to processing and reduce stock items. */
  isPaid: InputMaybe<Scalars['Boolean']['input']>;
  /** Order line items */
  lineItems: InputMaybe<Array<InputMaybe<LineItemInput>>>;
  /** Order meta data */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Parent order ID. */
  parentId: InputMaybe<Scalars['Int']['input']>;
  /** Payment method ID. */
  paymentMethod: InputMaybe<Scalars['String']['input']>;
  /** Payment method title. */
  paymentMethodTitle: InputMaybe<Scalars['String']['input']>;
  /** Order shipping address */
  shipping: InputMaybe<CustomerAddressInput>;
  /** Order shipping lines */
  shippingLines: InputMaybe<Array<InputMaybe<ShippingLineInput>>>;
  /** Order status */
  status: InputMaybe<OrderStatusEnum>;
  /** Order transaction ID */
  transactionId: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createOrder mutation. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  orderId: Maybe<Scalars['Int']['output']>;
};

/** Input for the createPaDefault mutation. */
export type CreatePaDefaultInput = {
  /** The slug that the pa_default will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the pa_default object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the pa_default object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPaDefault mutation. */
export type CreatePaDefaultPayload = {
  __typename?: 'CreatePaDefaultPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created pa_default */
  paDefault: Maybe<PaDefault>;
};

/** Input for the createPaPrimaryCurrency mutation. */
export type CreatePaPrimaryCurrencyInput = {
  /** The slug that the pa_primary-currency will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the pa_primary-currency object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the pa_primary-currency object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPaPrimaryCurrency mutation. */
export type CreatePaPrimaryCurrencyPayload = {
  __typename?: 'CreatePaPrimaryCurrencyPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created pa_primary-currency */
  paPrimaryCurrency: Maybe<PaPrimaryCurrency>;
};

/** Input for the createPage mutation. */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPage mutation. */
export type CreatePagePayload = {
  __typename?: 'CreatePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  page: Maybe<Page>;
};

/** Input for the createPostFormat mutation. */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_format object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the post_format object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPostFormat mutation. */
export type CreatePostFormatPayload = {
  __typename?: 'CreatePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created post_format */
  postFormat: Maybe<PostFormat>;
};

/** Input for the createPost mutation. */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId: InputMaybe<Scalars['ID']['input']>;
  /** Set connections between the post and categories */
  categories: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The excerpt of the object */
  excerpt: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The ping status for the object */
  pingStatus: InputMaybe<Scalars['String']['input']>;
  /** URLs that have been pinged. */
  pinged: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Set connections between the post and postFormats */
  postFormats: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
  /** URLs queued to be pinged. */
  toPing: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the createPost mutation. */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  post: Maybe<Post>;
};

/** Input for the createProductCategory mutation. */
export type CreateProductCategoryInput = {
  /** The slug that the product_cat will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_cat object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the product_cat object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the product_cat that should be set as the parent */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createProductCategory mutation. */
export type CreateProductCategoryPayload = {
  __typename?: 'CreateProductCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_cat */
  productCategory: Maybe<ProductCategory>;
};

/** Input for the createProduct mutation. */
export type CreateProductInput = {
  /** Set connections between the Product and allPaDefault */
  allPaDefault: InputMaybe<ProductAllPaDefaultInput>;
  /** Set connections between the Product and allPaPrimaryCurrency */
  allPaPrimaryCurrency: InputMaybe<ProductAllPaPrimaryCurrencyInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The excerpt of the object */
  excerpt: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the Product and productTypes */
  productTypes: InputMaybe<ProductProductTypesInput>;
  /** Set connections between the Product and shippingClasses */
  shippingClasses: InputMaybe<ProductShippingClassesInput>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the Product and visibleProducts */
  visibleProducts: InputMaybe<ProductVisibleProductsInput>;
};

/** The payload for the createProduct mutation. */
export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  product: Maybe<Product>;
};

/** Input for the createProductTag mutation. */
export type CreateProductTagInput = {
  /** The slug that the product_tag will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_tag object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the product_tag object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createProductTag mutation. */
export type CreateProductTagPayload = {
  __typename?: 'CreateProductTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_tag */
  productTag: Maybe<ProductTag>;
};

/** Input for the createProductType mutation. */
export type CreateProductTypeInput = {
  /** The slug that the product_type will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_type object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the product_type object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createProductType mutation. */
export type CreateProductTypePayload = {
  __typename?: 'CreateProductTypePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_type */
  productType: Maybe<ProductType>;
};

/** Input for the createShippingClass mutation. */
export type CreateShippingClassInput = {
  /** The slug that the product_shipping_class will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_shipping_class object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the product_shipping_class object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createShippingClass mutation. */
export type CreateShippingClassPayload = {
  __typename?: 'CreateShippingClassPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_shipping_class */
  shippingClass: Maybe<ShippingClass>;
};

/** Input for the createTag mutation. */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_tag object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the post_tag object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTag mutation. */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created post_tag */
  tag: Maybe<Tag>;
};

/** Input for the createUser mutation. */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** User's Jabber account. */
  jabber: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale: InputMaybe<Scalars['String']['input']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password: InputMaybe<Scalars['String']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered: InputMaybe<Scalars['String']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing: InputMaybe<Scalars['String']['input']>;
  /** An array of roles to be assigned to the user. */
  roles: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars['String']['input'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createUser mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user: Maybe<User>;
};

/** Input for the createVisibleProduct mutation. */
export type CreateVisibleProductInput = {
  /** The slug that the product_visibility will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_visibility object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The name of the product_visibility object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createVisibleProduct mutation. */
export type CreateVisibleProductPayload = {
  __typename?: 'CreateVisibleProductPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_visibility */
  visibleProduct: Maybe<VisibleProduct>;
};

/** A customer object */
export type Customer = Node & {
  __typename?: 'Customer';
  /** Customer&#039;s stored payment tokens. */
  availablePaymentMethods: Maybe<Array<Maybe<PaymentToken>>>;
  /** Customer&#039;s stored payment tokens. */
  availablePaymentMethodsCC: Maybe<Array<Maybe<PaymentTokenCc>>>;
  /** Customer&#039;s stored payment tokens. */
  availablePaymentMethodsEC: Maybe<Array<Maybe<PaymentTokenECheck>>>;
  /** Return the date customer billing address properties */
  billing: Maybe<CustomerAddress>;
  /** Has customer calculated shipping? */
  calculatedShipping: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the customer in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** Return the date customer was created */
  date: Maybe<Scalars['String']['output']>;
  /** Return the customer&#039;s display name. */
  displayName: Maybe<Scalars['String']['output']>;
  /** Connection between the Customer type and the DownloadableItem type */
  downloadableItems: Maybe<CustomerToDownloadableItemConnection>;
  /** Return the customer&#039;s email. */
  email: Maybe<Scalars['String']['output']>;
  /** Return the customer&#039;s first name. */
  firstName: Maybe<Scalars['String']['output']>;
  /** Has calculated shipping? */
  hasCalculatedShipping: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique identifier for the customer */
  id: Scalars['ID']['output'];
  /** Return the date customer was last updated */
  isPayingCustomer: Maybe<Scalars['Boolean']['output']>;
  /** Is customer VAT exempt? */
  isVatExempt: Maybe<Scalars['Boolean']['output']>;
  /** Return the customer&#039;s last name. */
  lastName: Maybe<Scalars['String']['output']>;
  /** Gets the customers last order. */
  lastOrder: Maybe<Order>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Return the date customer was last updated */
  modified: Maybe<Scalars['String']['output']>;
  /** Return the number of orders this customer has. */
  orderCount: Maybe<Scalars['Int']['output']>;
  /** Connection between the Customer type and the Order type */
  orders: Maybe<CustomerToOrderConnection>;
  /** Connection between the Customer type and the Refund type */
  refunds: Maybe<CustomerToRefundConnection>;
  /** Return the customer&#039;s user role. */
  role: Maybe<Scalars['String']['output']>;
  /** Session data for the viewing customer */
  session: Maybe<Array<Maybe<MetaData>>>;
  /** A JWT token that can be used in future requests to for WooCommerce session identification */
  sessionToken: Maybe<Scalars['String']['output']>;
  /** Return the date customer shipping address properties */
  shipping: Maybe<CustomerAddress>;
  /** Return how much money this customer has spent. */
  totalSpent: Maybe<Scalars['Float']['output']>;
  /** Return the customer&#039;s username. */
  username: Maybe<Scalars['String']['output']>;
};

/** A customer object */
export type CustomerDownloadableItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CustomerToDownloadableItemConnectionWhereArgs>;
};

/** A customer object */
export type CustomerMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A customer object */
export type CustomerOrdersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CustomerToOrderConnectionWhereArgs>;
};

/** A customer object */
export type CustomerRefundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<CustomerToRefundConnectionWhereArgs>;
};

/** A customer address object */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** Address 1 */
  address1: Maybe<Scalars['String']['output']>;
  /** Address 2 */
  address2: Maybe<Scalars['String']['output']>;
  /** City */
  city: Maybe<Scalars['String']['output']>;
  /** Company */
  company: Maybe<Scalars['String']['output']>;
  /** Country */
  country: Maybe<CountriesEnum>;
  /** E-mail */
  email: Maybe<Scalars['String']['output']>;
  /** First name */
  firstName: Maybe<Scalars['String']['output']>;
  /** Last name */
  lastName: Maybe<Scalars['String']['output']>;
  /** Phone */
  phone: Maybe<Scalars['String']['output']>;
  /** Zip Postal Code */
  postcode: Maybe<Scalars['String']['output']>;
  /** State */
  state: Maybe<Scalars['String']['output']>;
};

/** Customer address information */
export type CustomerAddressInput = {
  /** Address 1 */
  address1: InputMaybe<Scalars['String']['input']>;
  /** Address 2 */
  address2: InputMaybe<Scalars['String']['input']>;
  /** City */
  city: InputMaybe<Scalars['String']['input']>;
  /** Company */
  company: InputMaybe<Scalars['String']['input']>;
  /** Country */
  country: InputMaybe<CountriesEnum>;
  /** E-mail */
  email: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** Last name */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** Clear old address data */
  overwrite: InputMaybe<Scalars['Boolean']['input']>;
  /** Phone */
  phone: InputMaybe<Scalars['String']['input']>;
  /** Zip Postal Code */
  postcode: InputMaybe<Scalars['String']['input']>;
  /** State */
  state: InputMaybe<Scalars['String']['input']>;
};

/** Connection to Customer Nodes */
export type CustomerConnection = {
  /** A list of edges (relational context) between RootQuery and connected Customer Nodes */
  edges: Array<CustomerConnectionEdge>;
  /** A list of connected Customer Nodes */
  nodes: Array<Customer>;
  /** Information about pagination in a connection. */
  pageInfo: CustomerConnectionPageInfo;
};

/** Edge between a Node and a connected Customer */
export type CustomerConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Customer Node */
  node: Customer;
};

/** Field to order the connection by */
export enum CustomerConnectionOrderbyEnum {
  /** Order by customer email */
  Email = 'EMAIL',
  /** Order by customer ID */
  Id = 'ID',
  /** Order by include field */
  Include = 'INCLUDE',
  /** Order by customer display name */
  Name = 'NAME',
  /** Order by customer registration date */
  RegisteredDate = 'REGISTERED_DATE',
  /** Order by customer username */
  Username = 'USERNAME'
}

/** Page Info on the connected CustomerConnectionEdge */
export type CustomerConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Connection between the Customer type and the DownloadableItem type */
export type CustomerToDownloadableItemConnection = Connection &
  DownloadableItemConnection & {
    __typename?: 'CustomerToDownloadableItemConnection';
    /** Edges for the CustomerToDownloadableItemConnection connection */
    edges: Array<CustomerToDownloadableItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<DownloadableItem>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerToDownloadableItemConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerToDownloadableItemConnectionEdge = DownloadableItemConnectionEdge &
  Edge & {
    __typename?: 'CustomerToDownloadableItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: DownloadableItem;
  };

/** Page Info on the &quot;CustomerToDownloadableItemConnection&quot; */
export type CustomerToDownloadableItemConnectionPageInfo = DownloadableItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CustomerToDownloadableItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerToDownloadableItemConnection connection */
export type CustomerToDownloadableItemConnectionWhereArgs = {
  /** Limit results to downloadable items that can be downloaded now. */
  active: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit results to downloadable items that are expired. */
  expired: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit results to downloadable items that have downloads remaining. */
  hasDownloadsRemaining: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Customer type and the Order type */
export type CustomerToOrderConnection = Connection &
  OrderConnection & {
    __typename?: 'CustomerToOrderConnection';
    /** Edges for the CustomerToOrderConnection connection */
    edges: Array<CustomerToOrderConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Order>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerToOrderConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerToOrderConnectionEdge = Edge &
  OrderConnectionEdge & {
    __typename?: 'CustomerToOrderConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Order;
  };

/** Page Info on the &quot;CustomerToOrderConnection&quot; */
export type CustomerToOrderConnectionPageInfo = OrderConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CustomerToOrderConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerToOrderConnection connection */
export type CustomerToOrderConnectionWhereArgs = {
  /** Limit result set to orders assigned a specific billing email. */
  billingEmail: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to orders assigned a specific customer. */
  customerId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to orders assigned a specific group of customers. */
  customersIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<OrdersOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to orders assigned a specific product. */
  productId: InputMaybe<Scalars['Int']['input']>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to orders assigned a specific status. */
  statuses: InputMaybe<Array<InputMaybe<OrderStatusEnum>>>;
};

/** Connection between the Customer type and the Refund type */
export type CustomerToRefundConnection = Connection &
  RefundConnection & {
    __typename?: 'CustomerToRefundConnection';
    /** Edges for the CustomerToRefundConnection connection */
    edges: Array<CustomerToRefundConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Refund>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerToRefundConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerToRefundConnectionEdge = Edge &
  RefundConnectionEdge & {
    __typename?: 'CustomerToRefundConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Refund;
  };

/** Page Info on the &quot;CustomerToRefundConnection&quot; */
export type CustomerToRefundConnectionPageInfo = PageInfo &
  RefundConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CustomerToRefundConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerToRefundConnection connection */
export type CustomerToRefundConnectionWhereArgs = {
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to refunds from a specific group of order IDs. */
  orderIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostTypeOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to refunds assigned a specific status. */
  statuses: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Object that can be identified with a Database ID */
export type DatabaseIdentifier = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
};

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day: InputMaybe<Scalars['Int']['input']>;
  /** Month number (from 1 to 12) */
  month: InputMaybe<Scalars['Int']['input']>;
  /** 4 digit year (e.g. 2017) */
  year: InputMaybe<Scalars['Int']['input']>;
};

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after: InputMaybe<DateInput>;
  /** Nodes should be returned before this date */
  before: InputMaybe<DateInput>;
  /** Column to query against */
  column: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare: InputMaybe<Scalars['String']['input']>;
  /** Day of the month (from 1 to 31) */
  day: InputMaybe<Scalars['Int']['input']>;
  /** Hour (from 0 to 23) */
  hour: InputMaybe<Scalars['Int']['input']>;
  /** For after/before, whether exact value should be matched or not */
  inclusive: InputMaybe<Scalars['Boolean']['input']>;
  /** Minute (from 0 to 59) */
  minute: InputMaybe<Scalars['Int']['input']>;
  /** Month number (from 1 to 12) */
  month: InputMaybe<Scalars['Int']['input']>;
  /** OR or AND, how the sub-arrays should be compared */
  relation: InputMaybe<RelationEnum>;
  /** Second (0 to 59) */
  second: InputMaybe<Scalars['Int']['input']>;
  /** Week of the year (from 0 to 53) */
  week: InputMaybe<Scalars['Int']['input']>;
  /** 4 digit year (e.g. 2017) */
  year: InputMaybe<Scalars['Int']['input']>;
};

/** The template assigned to the node */
export type DefaultTemplate = ContentTemplate & {
  __typename?: 'DefaultTemplate';
  /** The name of the template */
  templateName: Maybe<Scalars['String']['output']>;
};

/** Input for the deleteBanner mutation. */
export type DeleteBannerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the banner to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteBanner mutation. */
export type DeleteBannerPayload = {
  __typename?: 'DeleteBannerPayload';
  /** The object before it was deleted */
  banner: Maybe<Banner>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteCategory mutation. */
export type DeleteCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteCategory mutation. */
export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  /** The deleted term object */
  category: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteComment mutation. */
export type DeleteCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The deleted comment ID */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteComment mutation. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The deleted comment object */
  comment: Maybe<Comment>;
  /** The deleted comment ID */
  deletedId: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteConfirmationScreen mutation. */
export type DeleteConfirmationScreenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the confirmationScreen to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteConfirmationScreen mutation. */
export type DeleteConfirmationScreenPayload = {
  __typename?: 'DeleteConfirmationScreenPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The object before it was deleted */
  confirmationScreen: Maybe<ConfirmationScreen>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteCoupon mutation. */
export type DeleteCouponInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Delete the object. Set to "false" by default. */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** Unique identifier for the object. */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteCoupon mutation. */
export type DeleteCouponPayload = {
  __typename?: 'DeleteCouponPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  coupon: Maybe<Coupon>;
};

/** Input for the deleteGraphqlDocumentGroup mutation. */
export type DeleteGraphqlDocumentGroupInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the graphqlDocumentGroup to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteGraphqlDocumentGroup mutation. */
export type DeleteGraphqlDocumentGroupPayload = {
  __typename?: 'DeleteGraphqlDocumentGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  graphqlDocumentGroup: Maybe<GraphqlDocumentGroup>;
};

/** Input for the deleteGraphqlDocument mutation. */
export type DeleteGraphqlDocumentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the graphqlDocument to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteGraphqlDocument mutation. */
export type DeleteGraphqlDocumentPayload = {
  __typename?: 'DeleteGraphqlDocumentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  graphqlDocument: Maybe<GraphqlDocument>;
};

/** Input for the deleteMediaItem mutation. */
export type DeleteMediaItemInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the mediaItem to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteMediaItem mutation. */
export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted mediaItem */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The mediaItem before it was deleted */
  mediaItem: Maybe<MediaItem>;
};

/** Input for the deleteOrder mutation. */
export type DeleteOrderInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Delete or simply place in trash. */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** Order global ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** Order WP ID */
  orderId: InputMaybe<Scalars['Int']['input']>;
};

/** Input for the deleteOrderItems mutation. */
export type DeleteOrderItemsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Order global ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** ID Order items being deleted */
  itemIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Order WP ID */
  orderId: InputMaybe<Scalars['Int']['input']>;
};

/** The payload for the deleteOrderItems mutation. */
export type DeleteOrderItemsPayload = {
  __typename?: 'DeleteOrderItemsPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
};

/** The payload for the deleteOrder mutation. */
export type DeleteOrderPayload = {
  __typename?: 'DeleteOrderPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
};

/** Input for the deletePaDefault mutation. */
export type DeletePaDefaultInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the paDefault to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deletePaDefault mutation. */
export type DeletePaDefaultPayload = {
  __typename?: 'DeletePaDefaultPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  paDefault: Maybe<PaDefault>;
};

/** Input for the deletePaPrimaryCurrency mutation. */
export type DeletePaPrimaryCurrencyInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the paPrimaryCurrency to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deletePaPrimaryCurrency mutation. */
export type DeletePaPrimaryCurrencyPayload = {
  __typename?: 'DeletePaPrimaryCurrencyPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  paPrimaryCurrency: Maybe<PaPrimaryCurrency>;
};

/** Input for the deletePage mutation. */
export type DeletePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the page to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deletePage mutation. */
export type DeletePagePayload = {
  __typename?: 'DeletePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  page: Maybe<Page>;
};

/** Input for the deletePaymentMethod mutation. */
export type DeletePaymentMethodInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Token ID of the payment token being deleted. */
  tokenId: Scalars['Int']['input'];
};

/** The payload for the deletePaymentMethod mutation. */
export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Status of the request */
  status: Maybe<Scalars['String']['output']>;
};

/** Input for the deletePostFormat mutation. */
export type DeletePostFormatInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the postFormat to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deletePostFormat mutation. */
export type DeletePostFormatPayload = {
  __typename?: 'DeletePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  postFormat: Maybe<PostFormat>;
};

/** Input for the deletePost mutation. */
export type DeletePostInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the post to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deletePost mutation. */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  post: Maybe<Post>;
};

/** Input for the deleteProductCategory mutation. */
export type DeleteProductCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productCategory to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteProductCategory mutation. */
export type DeleteProductCategoryPayload = {
  __typename?: 'DeleteProductCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  productCategory: Maybe<ProductCategory>;
};

/** Input for the deleteProduct mutation. */
export type DeleteProductInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Product to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteProduct mutation. */
export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  product: Maybe<Product>;
};

/** Input for the deleteProductTag mutation. */
export type DeleteProductTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productTag to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteProductTag mutation. */
export type DeleteProductTagPayload = {
  __typename?: 'DeleteProductTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  productTag: Maybe<ProductTag>;
};

/** Input for the deleteProductType mutation. */
export type DeleteProductTypeInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productType to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteProductType mutation. */
export type DeleteProductTypePayload = {
  __typename?: 'DeleteProductTypePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  productType: Maybe<ProductType>;
};

/** Input for the deleteReview mutation. */
export type DeleteReviewInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Whether the product review should be force deleted instead of being moved to the trash */
  forceDelete: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the target product review */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteReview mutation. */
export type DeleteReviewPayload = {
  __typename?: 'DeleteReviewPayload';
  /** The affected product review ID */
  affectedId: Maybe<Scalars['ID']['output']>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The product rating of the affected product review */
  rating: Maybe<Scalars['Float']['output']>;
  /** The affected product review */
  review: Maybe<Comment>;
};

/** Input for the deleteShippingClass mutation. */
export type DeleteShippingClassInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the shippingClass to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteShippingClass mutation. */
export type DeleteShippingClassPayload = {
  __typename?: 'DeleteShippingClassPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  shippingClass: Maybe<ShippingClass>;
};

/** Input for the deleteTag mutation. */
export type DeleteTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tag to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteTag mutation. */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  tag: Maybe<Tag>;
};

/** Input for the deleteUser mutation. */
export type DeleteUserInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user you want to delete */
  id: Scalars['ID']['input'];
  /** Reassign posts and links to new User ID. */
  reassignId: InputMaybe<Scalars['ID']['input']>;
};

/** The payload for the deleteUser mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the user that you just deleted */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted user object */
  user: Maybe<User>;
};

/** Input for the deleteVisibleProduct mutation. */
export type DeleteVisibleProductInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the visibleProduct to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteVisibleProduct mutation. */
export type DeleteVisibleProductPayload = {
  __typename?: 'DeleteVisibleProductPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  visibleProduct: Maybe<VisibleProduct>;
};

/** Coupon discount type enumeration */
export enum DiscountTypeEnum {
  /** Fixed cart discount */
  FixedCart = 'FIXED_CART',
  /** Fixed product discount */
  FixedProduct = 'FIXED_PRODUCT',
  /** Percentage discount */
  Percent = 'PERCENT'
}

/** The discussion setting type */
export type DiscussionSettings = {
  __typename?: 'DiscussionSettings';
  /** Allow people to submit comments on new posts. */
  defaultCommentStatus: Maybe<Scalars['String']['output']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  defaultPingStatus: Maybe<Scalars['String']['output']>;
};

/** A downloadable item */
export type DownloadableItem = Node & {
  __typename?: 'DownloadableItem';
  /** The date the downloadable item expires */
  accessExpires: Maybe<Scalars['String']['output']>;
  /** ProductDownload of the downloadable item */
  download: Maybe<ProductDownload>;
  /** Downloadable item ID. */
  downloadId: Scalars['String']['output'];
  /** Number of times the item can be downloaded. */
  downloadsRemaining: Maybe<Scalars['Int']['output']>;
  /** Downloadable item unique identifier */
  id: Scalars['ID']['output'];
  /** Name of the downloadable item. */
  name: Maybe<Scalars['String']['output']>;
  /** Product of downloadable item. */
  product: Maybe<Product>;
  /** Download URL of the downloadable item. */
  url: Maybe<Scalars['String']['output']>;
};

/** Connection to DownloadableItem Nodes */
export type DownloadableItemConnection = {
  /** A list of edges (relational context) between Order and connected DownloadableItem Nodes */
  edges: Array<DownloadableItemConnectionEdge>;
  /** A list of connected DownloadableItem Nodes */
  nodes: Array<DownloadableItem>;
  /** Information about pagination in a connection. */
  pageInfo: DownloadableItemConnectionPageInfo;
};

/** Edge between a Node and a connected DownloadableItem */
export type DownloadableItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected DownloadableItem Node */
  node: DownloadableItem;
};

/** Page Info on the connected DownloadableItemConnectionEdge */
export type DownloadableItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** A downloadable product. */
export type DownloadableProduct = {
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Download expiry */
  downloadExpiry: Maybe<Scalars['Int']['output']>;
  /** Download limit */
  downloadLimit: Maybe<Scalars['Int']['output']>;
  /** Is downloadable? */
  downloadable: Maybe<Scalars['Boolean']['output']>;
  /** Product downloads */
  downloads: Maybe<Array<Maybe<ProductDownload>>>;
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Is product virtual? */
  virtual: Maybe<Scalars['Boolean']['output']>;
};

/** Relational context between connected nodes */
export type Edge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected node */
  node: Node;
};

/** Input for the emptyCart mutation. */
export type EmptyCartInput = {
  clearPersistentCart: InputMaybe<Scalars['Boolean']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the emptyCart mutation. */
export type EmptyCartPayload = {
  __typename?: 'EmptyCartPayload';
  cart: Maybe<Cart>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  deletedCart: Maybe<Cart>;
};

/** Asset enqueued by the CMS */
export type EnqueuedAsset = {
  /** The inline code to be run after the asset is loaded. */
  after: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Deprecated
   * @deprecated Use `EnqueuedAsset.media` instead.
   */
  args: Maybe<Scalars['Boolean']['output']>;
  /** The inline code to be run before the asset is loaded. */
  before: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
  conditional: Maybe<Scalars['String']['output']>;
  /** Dependencies needed to use this asset */
  dependencies: Maybe<Array<Maybe<EnqueuedAsset>>>;
  /**
   * Extra information needed for the script
   * @deprecated Use `EnqueuedScript.extraData` instead.
   */
  extra: Maybe<Scalars['String']['output']>;
  /** The handle of the enqueued asset */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the enqueued asset */
  id: Scalars['ID']['output'];
  /** The source of the asset */
  src: Maybe<Scalars['String']['output']>;
  /** The version of the enqueued asset */
  version: Maybe<Scalars['String']['output']>;
};

/** Script enqueued by the CMS */
export type EnqueuedScript = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedScript';
    /** The inline code to be run after the asset is loaded. */
    after: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * Deprecated
     * @deprecated Use `EnqueuedAsset.media` instead.
     */
    args: Maybe<Scalars['Boolean']['output']>;
    /** The inline code to be run before the asset is loaded. */
    before: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
    conditional: Maybe<Scalars['String']['output']>;
    /** Dependencies needed to use this asset */
    dependencies: Maybe<Array<Maybe<EnqueuedScript>>>;
    /**
     * Extra information needed for the script
     * @deprecated Use `EnqueuedScript.extraData` instead.
     */
    extra: Maybe<Scalars['String']['output']>;
    /** Extra data supplied to the enqueued script */
    extraData: Maybe<Scalars['String']['output']>;
    /** The handle of the enqueued asset */
    handle: Maybe<Scalars['String']['output']>;
    /** The global ID of the enqueued script */
    id: Scalars['ID']['output'];
    /** The source of the asset */
    src: Maybe<Scalars['String']['output']>;
    /** The loading strategy to use on the script tag */
    strategy: Maybe<ScriptLoadingStrategyEnum>;
    /** The version of the enqueued script */
    version: Maybe<Scalars['String']['output']>;
  };

/** Connection to EnqueuedScript Nodes */
export type EnqueuedScriptConnection = {
  /** A list of edges (relational context) between ContentNode and connected EnqueuedScript Nodes */
  edges: Array<EnqueuedScriptConnectionEdge>;
  /** A list of connected EnqueuedScript Nodes */
  nodes: Array<EnqueuedScript>;
  /** Information about pagination in a connection. */
  pageInfo: EnqueuedScriptConnectionPageInfo;
};

/** Edge between a Node and a connected EnqueuedScript */
export type EnqueuedScriptConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected EnqueuedScript Node */
  node: EnqueuedScript;
};

/** Page Info on the connected EnqueuedScriptConnectionEdge */
export type EnqueuedScriptConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Stylesheet enqueued by the CMS */
export type EnqueuedStylesheet = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedStylesheet';
    /** The inline code to be run after the asset is loaded. */
    after: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * Deprecated
     * @deprecated Use `EnqueuedAsset.media` instead.
     */
    args: Maybe<Scalars['Boolean']['output']>;
    /** The inline code to be run before the asset is loaded. */
    before: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
    conditional: Maybe<Scalars['String']['output']>;
    /** Dependencies needed to use this asset */
    dependencies: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
    /**
     * Extra information needed for the script
     * @deprecated Use `EnqueuedScript.extraData` instead.
     */
    extra: Maybe<Scalars['String']['output']>;
    /** The handle of the enqueued asset */
    handle: Maybe<Scalars['String']['output']>;
    /** The global ID of the enqueued stylesheet */
    id: Scalars['ID']['output'];
    /** Whether the enqueued style is RTL or not */
    isRtl: Maybe<Scalars['Boolean']['output']>;
    /** The media attribute to use for the link */
    media: Maybe<Scalars['String']['output']>;
    /** The absolute path to the enqueued style. Set when the stylesheet is meant to load inline. */
    path: Maybe<Scalars['String']['output']>;
    /** The `rel` attribute to use for the link */
    rel: Maybe<Scalars['String']['output']>;
    /** The source of the asset */
    src: Maybe<Scalars['String']['output']>;
    /** Optional suffix, used in combination with RTL */
    suffix: Maybe<Scalars['String']['output']>;
    /** The title of the enqueued style. Used for preferred/alternate stylesheets. */
    title: Maybe<Scalars['String']['output']>;
    /** The version of the enqueued style */
    version: Maybe<Scalars['String']['output']>;
  };

/** Connection to EnqueuedStylesheet Nodes */
export type EnqueuedStylesheetConnection = {
  /** A list of edges (relational context) between ContentNode and connected EnqueuedStylesheet Nodes */
  edges: Array<EnqueuedStylesheetConnectionEdge>;
  /** A list of connected EnqueuedStylesheet Nodes */
  nodes: Array<EnqueuedStylesheet>;
  /** Information about pagination in a connection. */
  pageInfo: EnqueuedStylesheetConnectionPageInfo;
};

/** Edge between a Node and a connected EnqueuedStylesheet */
export type EnqueuedStylesheetConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected EnqueuedStylesheet Node */
  node: EnqueuedStylesheet;
};

/** Page Info on the connected EnqueuedStylesheetConnectionEdge */
export type EnqueuedStylesheetConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** A external product object */
export type ExternalProduct = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  Product &
  ProductUnion &
  ProductWithAttributes &
  ProductWithPricing &
  UniformResourceIdentifiable & {
    __typename?: 'ExternalProduct';
    /** Connection between the Product type and the paDefault type */
    allPaDefault: Maybe<ProductToPaDefaultConnection>;
    /** Connection between the Product type and the paPrimaryCurrency type */
    allPaPrimaryCurrency: Maybe<ProductToPaPrimaryCurrencyConnection>;
    /** Connection between the Product type and the ProductAttribute type */
    attributes: Maybe<ProductToProductAttributeConnection>;
    /** Product average count */
    averageRating: Maybe<Scalars['Float']['output']>;
    /** External product Buy button text */
    buttonText: Maybe<Scalars['String']['output']>;
    /** Catalog visibility */
    catalogVisibility: Maybe<CatalogVisibilityEnum>;
    /** NFT collection type */
    collectionType: Maybe<Scalars['String']['output']>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** Product or variation ID */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Date on sale from */
    dateOnSaleFrom: Maybe<Scalars['String']['output']>;
    /** Date on sale to */
    dateOnSaleTo: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductWithAttributes type and the VariationAttribute type */
    defaultAttributes: Maybe<ProductWithAttributesToVariationAttributeConnection>;
    /** Product description */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt: Maybe<Scalars['String']['output']>;
    /** External product url */
    externalUrl: Maybe<Scalars['String']['output']>;
    /** If the product is featured */
    featured: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Product type and the MediaItem type */
    galleryImages: Maybe<ProductToMediaItemConnection>;
    /** Connection between the Product type and the GlobalProductAttribute type */
    globalAttributes: Maybe<ProductToGlobalProductAttributeConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the product object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** Product or variation global ID */
    id: Scalars['ID']['output'];
    /** Main image */
    image: Maybe<MediaItem>;
    /** Check if user is allowlist to see the product */
    isAllowlisted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the LocalProductAttribute type */
    localAttributes: Maybe<ProductToLocalProductAttributeConnection>;
    /** Menu order */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** Object meta data */
    metaData: Maybe<Array<Maybe<MetaData>>>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** Product name */
    name: Maybe<Scalars['String']['output']>;
    /** Is product on sale? */
    onSale: Maybe<Scalars['Boolean']['output']>;
    /** The password for the product object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the Product type */
    preview: Maybe<ProductToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** Product&#039;s active price */
    price: Maybe<Scalars['String']['output']>;
    /** Pricing group with group discounts */
    pricing: Maybe<ProductWithGroupDiscount>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    productId: Scalars['Int']['output'];
    /** Connection between the Product type and the productType type */
    productTypes: Maybe<ProductToProductTypeConnection>;
    /** Can product be purchased? */
    purchasable: Maybe<Scalars['Boolean']['output']>;
    /** Purchase note */
    purchaseNote: Maybe<Scalars['String']['output']>;
    /** Product&#039;s regular price */
    regularPrice: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the ProductUnion type */
    related: Maybe<ProductToProductUnionConnection>;
    /** Product review count */
    reviewCount: Maybe<Scalars['Int']['output']>;
    /** Connection between the Product type and the Comment type */
    reviews: Maybe<ProductToCommentConnection>;
    /** If reviews are allowed */
    reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s sale price */
    salePrice: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the shippingClass type */
    shippingClasses: Maybe<ProductToShippingClassConnection>;
    /** Product short description */
    shortDescription: Maybe<Scalars['String']['output']>;
    /** Product SKU */
    sku: Maybe<Scalars['String']['output']>;
    /** Product slug */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** Tax class */
    taxClass: Maybe<TaxClassEnum>;
    /** Tax status */
    taxStatus: Maybe<TaxStatusEnum>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** Connection between the Product type and the TermNode type */
    terms: Maybe<ProductToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** Number total of sales */
    totalSales: Maybe<Scalars['Int']['output']>;
    /** Product type */
    type: Maybe<ProductTypesEnum>;
    /** Connection between the Product type and the ProductUnion type */
    upsell: Maybe<ProductToUpsellConnection>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the visibleProduct type */
    visibleProducts: Maybe<ProductToVisibleProductConnection>;
  };

/** A external product object */
export type ExternalProductAllPaDefaultArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaDefaultConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductAllPaPrimaryCurrencyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaPrimaryCurrencyConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductAttributeConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductDefaultAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A external product object */
export type ExternalProductDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A external product object */
export type ExternalProductEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A external product object */
export type ExternalProductExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductGalleryImagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToMediaItemConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductGlobalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A external product object */
export type ExternalProductIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A external product object */
export type ExternalProductLocalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A external product object */
export type ExternalProductMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A external product object */
export type ExternalProductPreviewArgs = {
  where: InputMaybe<ProductToPreviewConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A external product object */
export type ExternalProductProductTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductTypeConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductRelatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductUnionConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductReviewsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToCommentConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductShippingClassesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToShippingClassConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToTermNodeConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A external product object */
export type ExternalProductUpsellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToUpsellConnectionWhereArgs>;
};

/** A external product object */
export type ExternalProductVisibleProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToVisibleProductConnectionWhereArgs>;
};

/** a fee line object */
export type FeeLine = Node & {
  __typename?: 'FeeLine';
  /** Fee amount */
  amount: Maybe<Scalars['String']['output']>;
  /** The ID of the order item in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** The ID of the order item in the database */
  id: Scalars['ID']['output'];
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Fee name */
  name: Maybe<Scalars['String']['output']>;
  /** The Id of the order the order item belongs to. */
  orderId: Maybe<Scalars['Int']['output']>;
  /** Line tax class */
  taxClass: Maybe<TaxClassEnum>;
  /** Tax status of fee */
  taxStatus: Maybe<TaxStatusEnum>;
  /** Line taxes */
  taxes: Maybe<Array<Maybe<OrderItemTax>>>;
  /** Line total (after discounts) */
  total: Maybe<Scalars['String']['output']>;
  /** Line total tax (after discounts) */
  totalTax: Maybe<Scalars['String']['output']>;
};

/** a fee line object */
export type FeeLineMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection to FeeLine Nodes */
export type FeeLineConnection = {
  /** A list of edges (relational context) between Order and connected FeeLine Nodes */
  edges: Array<FeeLineConnectionEdge>;
  /** A list of connected FeeLine Nodes */
  nodes: Array<FeeLine>;
  /** Information about pagination in a connection. */
  pageInfo: FeeLineConnectionPageInfo;
};

/** Edge between a Node and a connected FeeLine */
export type FeeLineConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected FeeLine Node */
  node: FeeLine;
};

/** Page Info on the connected FeeLineConnectionEdge */
export type FeeLineConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Fee line data. */
export type FeeLineInput = {
  /** Fee amount. */
  amount: InputMaybe<Scalars['String']['input']>;
  /** Fee Line ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** Fee name. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Tax class of fee. */
  taxClass: InputMaybe<TaxClassEnum>;
  /** Tax status of fee. */
  taxStatus: InputMaybe<TaxStatusEnum>;
  /** Line total (after discounts). */
  total: InputMaybe<Scalars['String']['input']>;
};

/** Input for the fillCart mutation. */
export type FillCartInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Coupons to be applied to the cart */
  coupons: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Cart items to be added */
  items: InputMaybe<Array<InputMaybe<CartItemInput>>>;
  /** Shipping methods to be used. */
  shippingMethods: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the fillCart mutation. */
export type FillCartPayload = {
  __typename?: 'FillCartPayload';
  added: Maybe<Array<Maybe<CartItem>>>;
  applied: Maybe<Array<Maybe<AppliedCoupon>>>;
  cart: Maybe<Cart>;
  cartErrors: Maybe<Array<Maybe<CartError>>>;
  chosenShippingMethods: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** The general setting type */
export type GeneralSettings = {
  __typename?: 'GeneralSettings';
  /** A date format for all date strings. */
  dateFormat: Maybe<Scalars['String']['output']>;
  /** Site tagline. */
  description: Maybe<Scalars['String']['output']>;
  /** This address is used for admin purposes, like new user notification. */
  email: Maybe<Scalars['String']['output']>;
  /** WordPress locale code. */
  language: Maybe<Scalars['String']['output']>;
  /** A day number of the week that the week should start on. */
  startOfWeek: Maybe<Scalars['Int']['output']>;
  /** A time format for all time strings. */
  timeFormat: Maybe<Scalars['String']['output']>;
  /** A city in the same timezone as you. */
  timezone: Maybe<Scalars['String']['output']>;
  /** Site title. */
  title: Maybe<Scalars['String']['output']>;
  /** Site URL. */
  url: Maybe<Scalars['String']['output']>;
};

/** A product attribute object */
export type GlobalProductAttribute = Node &
  ProductAttribute & {
    __typename?: 'GlobalProductAttribute';
    /** Attribute ID */
    attributeId: Scalars['Int']['output'];
    /** Attribute Global ID */
    id: Scalars['ID']['output'];
    /** Attribute label */
    label: Maybe<Scalars['String']['output']>;
    /** Product attribute name */
    name: Maybe<Scalars['String']['output']>;
    /** Attribute options */
    options: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Attribute position */
    position: Maybe<Scalars['Int']['output']>;
    /** Product attribute scope. */
    scope: ProductAttributeTypesEnum;
    /** Product attribute slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the GlobalProductAttribute type and the TermNode type */
    terms: Maybe<GlobalProductAttributeToTermNodeConnection>;
    /** Is attribute on product variation */
    variation: Maybe<Scalars['Boolean']['output']>;
    /** Is attribute visible */
    visible: Maybe<Scalars['Boolean']['output']>;
  };

/** A product attribute object */
export type GlobalProductAttributeTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<GlobalProductAttributeToTermNodeConnectionWhereArgs>;
};

/** Connection to GlobalProductAttribute Nodes */
export type GlobalProductAttributeConnection = {
  /** A list of edges (relational context) between Product and connected GlobalProductAttribute Nodes */
  edges: Array<GlobalProductAttributeConnectionEdge>;
  /** A list of connected GlobalProductAttribute Nodes */
  nodes: Array<GlobalProductAttribute>;
  /** Information about pagination in a connection. */
  pageInfo: GlobalProductAttributeConnectionPageInfo;
};

/** Edge between a Node and a connected GlobalProductAttribute */
export type GlobalProductAttributeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected GlobalProductAttribute Node */
  node: GlobalProductAttribute;
};

/** Page Info on the connected GlobalProductAttributeConnectionEdge */
export type GlobalProductAttributeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Connection between the GlobalProductAttribute type and the TermNode type */
export type GlobalProductAttributeToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'GlobalProductAttributeToTermNodeConnection';
    /** Edges for the GlobalProductAttributeToTermNodeConnection connection */
    edges: Array<GlobalProductAttributeToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: GlobalProductAttributeToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type GlobalProductAttributeToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'GlobalProductAttributeToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;GlobalProductAttributeToTermNodeConnection&quot; */
export type GlobalProductAttributeToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'GlobalProductAttributeToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the GlobalProductAttributeToTermNodeConnection connection */
export type GlobalProductAttributeToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** The graphqlDocument type */
export type GraphqlDocument = ContentNode &
  DatabaseIdentifier &
  Node &
  NodeWithContentEditor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'GraphqlDocument';
    /** Alias names for saved GraphQL query documents */
    alias: Maybe<Array<Scalars['String']['output']>>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Description for the saved GraphQL document */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Allow, deny or default access grant for specific query */
    grant: Maybe<Scalars['String']['output']>;
    /** Connection between the GraphqlDocument type and the graphqlDocumentGroup type */
    graphqlDocumentGroups: Maybe<GraphqlDocumentToGraphqlDocumentGroupConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    graphqlDocumentId: Scalars['Int']['output'];
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the graphql_document object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the graphql_document object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** HTTP Cache-Control max-age directive for a saved GraphQL document */
    maxAgeHeader: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** The password for the graphql_document object. */
    password: Maybe<Scalars['String']['output']>;
    /**
     * Connection between the GraphqlDocument type and the graphqlDocument type
     * @deprecated The &quot;GraphqlDocument&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
     */
    preview: Maybe<GraphqlDocumentToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** Connection between the GraphqlDocument type and the TermNode type */
    terms: Maybe<GraphqlDocumentToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The graphqlDocument type */
export type GraphqlDocumentContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The graphqlDocument type */
export type GraphqlDocumentEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocument type */
export type GraphqlDocumentEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocument type */
export type GraphqlDocumentGraphqlDocumentGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<GraphqlDocumentToGraphqlDocumentGroupConnectionWhereArgs>;
};

/** The graphqlDocument type */
export type GraphqlDocumentTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<GraphqlDocumentToTermNodeConnectionWhereArgs>;
};

/** The graphqlDocument type */
export type GraphqlDocumentTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to graphqlDocument Nodes */
export type GraphqlDocumentConnection = {
  /** A list of edges (relational context) between RootQuery and connected graphqlDocument Nodes */
  edges: Array<GraphqlDocumentConnectionEdge>;
  /** A list of connected graphqlDocument Nodes */
  nodes: Array<GraphqlDocument>;
  /** Information about pagination in a connection. */
  pageInfo: GraphqlDocumentConnectionPageInfo;
};

/** Edge between a Node and a connected graphqlDocument */
export type GraphqlDocumentConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected graphqlDocument Node */
  node: GraphqlDocument;
};

/** Page Info on the connected GraphqlDocumentConnectionEdge */
export type GraphqlDocumentConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Set relationships between the graphqlDocument to graphqlDocumentGroups */
export type GraphqlDocumentGraphqlDocumentGroupsInput = {
  /** If true, this will append the graphqlDocumentGroup to existing related graphqlDocumentGroups. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<GraphqlDocumentGraphqlDocumentGroupsNodeInput>>>;
};

/** List of graphqlDocumentGroups to connect the graphqlDocument to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type GraphqlDocumentGraphqlDocumentGroupsNodeInput = {
  /** The description of the graphqlDocumentGroup. This field is used to set a description of the graphqlDocumentGroup if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the graphqlDocumentGroup. If present, this will be used to connect to the graphqlDocument. If no existing graphqlDocumentGroup exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the graphqlDocumentGroup. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the graphqlDocumentGroup. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroup = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'GraphqlDocumentGroup';
    /** Connection between the GraphqlDocumentGroup type and the ContentNode type */
    contentNodes: Maybe<GraphqlDocumentGroupToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    graphqlDocumentGroupId: Maybe<Scalars['Int']['output']>;
    /** Connection between the GraphqlDocumentGroup type and the graphqlDocument type */
    graphqlDocuments: Maybe<GraphqlDocumentGroupToGraphqlDocumentConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the GraphqlDocumentGroup type and the Taxonomy type */
    taxonomy: Maybe<GraphqlDocumentGroupToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<GraphqlDocumentGroupToContentNodeConnectionWhereArgs>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupGraphqlDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<GraphqlDocumentGroupToGraphqlDocumentConnectionWhereArgs>;
};

/** Connection to graphqlDocumentGroup Nodes */
export type GraphqlDocumentGroupConnection = {
  /** A list of edges (relational context) between RootQuery and connected graphqlDocumentGroup Nodes */
  edges: Array<GraphqlDocumentGroupConnectionEdge>;
  /** A list of connected graphqlDocumentGroup Nodes */
  nodes: Array<GraphqlDocumentGroup>;
  /** Information about pagination in a connection. */
  pageInfo: GraphqlDocumentGroupConnectionPageInfo;
};

/** Edge between a Node and a connected graphqlDocumentGroup */
export type GraphqlDocumentGroupConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected graphqlDocumentGroup Node */
  node: GraphqlDocumentGroup;
};

/** Page Info on the connected GraphqlDocumentGroupConnectionEdge */
export type GraphqlDocumentGroupConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentGroupIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the GraphqlDocumentGroup type and the ContentNode type */
export type GraphqlDocumentGroupToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'GraphqlDocumentGroupToContentNodeConnection';
    /** Edges for the GraphqlDocumentGroupToContentNodeConnection connection */
    edges: Array<GraphqlDocumentGroupToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentGroupToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentGroupToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'GraphqlDocumentGroupToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;GraphqlDocumentGroupToContentNodeConnection&quot; */
export type GraphqlDocumentGroupToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'GraphqlDocumentGroupToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the GraphqlDocumentGroupToContentNodeConnection connection */
export type GraphqlDocumentGroupToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfGraphqlDocumentGroupEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the GraphqlDocumentGroup type and the graphqlDocument type */
export type GraphqlDocumentGroupToGraphqlDocumentConnection = Connection &
  GraphqlDocumentConnection & {
    __typename?: 'GraphqlDocumentGroupToGraphqlDocumentConnection';
    /** Edges for the GraphqlDocumentGroupToGraphqlDocumentConnection connection */
    edges: Array<GraphqlDocumentGroupToGraphqlDocumentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocument>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentGroupToGraphqlDocumentConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionEdge = Edge &
  GraphqlDocumentConnectionEdge & {
    __typename?: 'GraphqlDocumentGroupToGraphqlDocumentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocument;
  };

/** Page Info on the &quot;GraphqlDocumentGroupToGraphqlDocumentConnection&quot; */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionPageInfo =
  GraphqlDocumentConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'GraphqlDocumentGroupToGraphqlDocumentConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the GraphqlDocumentGroupToGraphqlDocumentConnection connection */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the GraphqlDocumentGroup type and the Taxonomy type */
export type GraphqlDocumentGroupToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'GraphqlDocumentGroupToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the GraphqlDocument type and the graphqlDocumentGroup type */
export type GraphqlDocumentToGraphqlDocumentGroupConnection = Connection &
  GraphqlDocumentGroupConnection & {
    __typename?: 'GraphqlDocumentToGraphqlDocumentGroupConnection';
    /** Edges for the GraphqlDocumentToGraphqlDocumentGroupConnection connection */
    edges: Array<GraphqlDocumentToGraphqlDocumentGroupConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocumentGroup>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentToGraphqlDocumentGroupConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionEdge = Edge &
  GraphqlDocumentGroupConnectionEdge & {
    __typename?: 'GraphqlDocumentToGraphqlDocumentGroupConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocumentGroup;
  };

/** Page Info on the &quot;GraphqlDocumentToGraphqlDocumentGroupConnection&quot; */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionPageInfo =
  GraphqlDocumentGroupConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'GraphqlDocumentToGraphqlDocumentGroupConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the GraphqlDocumentToGraphqlDocumentGroupConnection connection */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the GraphqlDocument type and the graphqlDocument type */
export type GraphqlDocumentToPreviewConnectionEdge = Edge &
  GraphqlDocumentConnectionEdge &
  OneToOneConnection & {
    __typename?: 'GraphqlDocumentToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /**
     * The node of the connection, without the edges
     * @deprecated The &quot;GraphqlDocument&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
     */
    node: GraphqlDocument;
  };

/** Connection between the GraphqlDocument type and the TermNode type */
export type GraphqlDocumentToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'GraphqlDocumentToTermNodeConnection';
    /** Edges for the GraphqlDocumentToTermNodeConnection connection */
    edges: Array<GraphqlDocumentToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'GraphqlDocumentToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;GraphqlDocumentToTermNodeConnection&quot; */
export type GraphqlDocumentToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'GraphqlDocumentToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the GraphqlDocumentToTermNodeConnection connection */
export type GraphqlDocumentToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** A group product object */
export type GroupProduct = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  Product &
  ProductUnion &
  ProductWithAttributes &
  ProductWithPricing &
  UniformResourceIdentifiable & {
    __typename?: 'GroupProduct';
    /** Product&#039;s add to cart button text description */
    addToCartDescription: Maybe<Scalars['String']['output']>;
    /** Product&#039;s add to cart button text description */
    addToCartText: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the paDefault type */
    allPaDefault: Maybe<ProductToPaDefaultConnection>;
    /** Connection between the Product type and the paPrimaryCurrency type */
    allPaPrimaryCurrency: Maybe<ProductToPaPrimaryCurrencyConnection>;
    /** Connection between the Product type and the ProductAttribute type */
    attributes: Maybe<ProductToProductAttributeConnection>;
    /** Product average count */
    averageRating: Maybe<Scalars['Float']['output']>;
    /** Catalog visibility */
    catalogVisibility: Maybe<CatalogVisibilityEnum>;
    /** NFT collection type */
    collectionType: Maybe<Scalars['String']['output']>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** Product or variation ID */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Date on sale from */
    dateOnSaleFrom: Maybe<Scalars['String']['output']>;
    /** Date on sale to */
    dateOnSaleTo: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductWithAttributes type and the VariationAttribute type */
    defaultAttributes: Maybe<ProductWithAttributesToVariationAttributeConnection>;
    /** Product description */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt: Maybe<Scalars['String']['output']>;
    /** If the product is featured */
    featured: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Product type and the MediaItem type */
    galleryImages: Maybe<ProductToMediaItemConnection>;
    /** Connection between the Product type and the GlobalProductAttribute type */
    globalAttributes: Maybe<ProductToGlobalProductAttributeConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the product object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** Product or variation global ID */
    id: Scalars['ID']['output'];
    /** Main image */
    image: Maybe<MediaItem>;
    /** Check if user is allowlist to see the product */
    isAllowlisted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the LocalProductAttribute type */
    localAttributes: Maybe<ProductToLocalProductAttributeConnection>;
    /** Menu order */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** Object meta data */
    metaData: Maybe<Array<Maybe<MetaData>>>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** Product name */
    name: Maybe<Scalars['String']['output']>;
    /** Is product on sale? */
    onSale: Maybe<Scalars['Boolean']['output']>;
    /** The password for the product object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the Product type */
    preview: Maybe<ProductToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** Products&#039; price range */
    price: Maybe<Scalars['String']['output']>;
    /** Pricing group with group discounts */
    pricing: Maybe<ProductWithGroupDiscount>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    productId: Scalars['Int']['output'];
    /** Connection between the Product type and the productType type */
    productTypes: Maybe<ProductToProductTypeConnection>;
    /** Connection between the GroupProduct type and the ProductUnion type */
    products: Maybe<GroupProductToProductUnionConnection>;
    /** Can product be purchased? */
    purchasable: Maybe<Scalars['Boolean']['output']>;
    /** Purchase note */
    purchaseNote: Maybe<Scalars['String']['output']>;
    /** Product&#039;s regular price */
    regularPrice: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the ProductUnion type */
    related: Maybe<ProductToProductUnionConnection>;
    /** Product review count */
    reviewCount: Maybe<Scalars['Int']['output']>;
    /** Connection between the Product type and the Comment type */
    reviews: Maybe<ProductToCommentConnection>;
    /** If reviews are allowed */
    reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s sale price */
    salePrice: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the shippingClass type */
    shippingClasses: Maybe<ProductToShippingClassConnection>;
    /** Product short description */
    shortDescription: Maybe<Scalars['String']['output']>;
    /** Product SKU */
    sku: Maybe<Scalars['String']['output']>;
    /** Product slug */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** Tax class */
    taxClass: Maybe<TaxClassEnum>;
    /** Tax status */
    taxStatus: Maybe<TaxStatusEnum>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** Connection between the Product type and the TermNode type */
    terms: Maybe<ProductToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** Number total of sales */
    totalSales: Maybe<Scalars['Int']['output']>;
    /** Product type */
    type: Maybe<ProductTypesEnum>;
    /** Connection between the Product type and the ProductUnion type */
    upsell: Maybe<ProductToUpsellConnection>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the visibleProduct type */
    visibleProducts: Maybe<ProductToVisibleProductConnection>;
  };

/** A group product object */
export type GroupProductAllPaDefaultArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaDefaultConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductAllPaPrimaryCurrencyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaPrimaryCurrencyConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductAttributeConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A group product object */
export type GroupProductDefaultAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A group product object */
export type GroupProductDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A group product object */
export type GroupProductEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A group product object */
export type GroupProductEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A group product object */
export type GroupProductExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A group product object */
export type GroupProductGalleryImagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToMediaItemConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductGlobalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A group product object */
export type GroupProductIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A group product object */
export type GroupProductLocalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A group product object */
export type GroupProductMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A group product object */
export type GroupProductPreviewArgs = {
  where: InputMaybe<ProductToPreviewConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A group product object */
export type GroupProductPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A group product object */
export type GroupProductProductTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductTypeConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<GroupProductToProductUnionConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A group product object */
export type GroupProductRelatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductUnionConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductReviewsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToCommentConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A group product object */
export type GroupProductShippingClassesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToShippingClassConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A group product object */
export type GroupProductTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToTermNodeConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A group product object */
export type GroupProductUpsellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToUpsellConnectionWhereArgs>;
};

/** A group product object */
export type GroupProductVisibleProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToVisibleProductConnectionWhereArgs>;
};

/** Connection between the GroupProduct type and the ProductUnion type */
export type GroupProductToProductUnionConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'GroupProductToProductUnionConnection';
    /** Edges for the GroupProductToProductUnionConnection connection */
    edges: Array<GroupProductToProductUnionConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: GroupProductToProductUnionConnectionPageInfo;
  };

/** An edge in a connection */
export type GroupProductToProductUnionConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'GroupProductToProductUnionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;GroupProductToProductUnionConnection&quot; */
export type GroupProductToProductUnionConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'GroupProductToProductUnionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the GroupProductToProductUnionConnection connection */
export type GroupProductToProductUnionConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNode = {
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** Post publishing date. */
  date: Maybe<Scalars['String']['output']>;
  /** The publishing date set in GMT. */
  dateGmt: Maybe<Scalars['String']['output']>;
  /** The desired slug of the post */
  desiredSlug: Maybe<Scalars['String']['output']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is a node in the preview state */
  isPreview: Maybe<Scalars['Boolean']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The user that most recently edited the node */
  lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link: Maybe<Scalars['String']['output']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified: Maybe<Scalars['String']['output']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt: Maybe<Scalars['String']['output']>;
  /** The parent of the node. The parent object can be of various types */
  parent: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the parent node. */
  parentId: Maybe<Scalars['ID']['output']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId: Maybe<Scalars['ID']['output']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug: Maybe<Scalars['String']['output']>;
  /** The current status of the object */
  status: Maybe<Scalars['String']['output']>;
  /** The template assigned to a node of content */
  template: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeAncestorsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnection';
    /** Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
    edges: Array<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
  };

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge';
      /** A cursor for use in pagination */
      cursor: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;HierarchicalContentNodeToContentNodeAncestorsConnection&quot; */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeChildrenConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
    /** Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection */
    edges: Array<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
  };

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;HierarchicalContentNodeToContentNodeChildrenConnection&quot; */
export type HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToParentContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** Node with hierarchical (parent/child) relationships */
export type HierarchicalNode = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Database id of the parent node */
  parentDatabaseId: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the parent node. */
  parentId: Maybe<Scalars['ID']['output']>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNode = {
  /** The number of objects connected to the object */
  count: Maybe<Scalars['Int']['output']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** The description of the object */
  description: Maybe<Scalars['String']['output']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The link to the term */
  link: Maybe<Scalars['String']['output']>;
  /** The human friendly name of the object. */
  name: Maybe<Scalars['String']['output']>;
  /** Database id of the parent node */
  parentDatabaseId: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the parent node. */
  parentId: Maybe<Scalars['ID']['output']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug: Maybe<Scalars['String']['output']>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName: Maybe<Scalars['String']['output']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId: Maybe<Scalars['Int']['output']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId: Maybe<Scalars['Int']['output']>;
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNodeEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNodeEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product with stock information. */
export type InventoriedProduct = {
  /** Product backorders status */
  backorders: Maybe<BackordersEnum>;
  /** Can product be backordered? */
  backordersAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Low stock amount */
  lowStockAmount: Maybe<Scalars['Int']['output']>;
  /** If product manage stock */
  manageStock: Maybe<ManageStockEnum>;
  /** If should be sold individually */
  soldIndividually: Maybe<Scalars['Boolean']['output']>;
  /** Number of items available for sale */
  stockQuantity: Maybe<Scalars['Int']['output']>;
  /** Product stock status */
  stockStatus: Maybe<StockStatusEnum>;
};

/** a line item object */
export type LineItem = Node & {
  __typename?: 'LineItem';
  /** The ID of the order item in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** The ID of the order item in the database */
  id: Scalars['ID']['output'];
  /** Line item&#039;s taxes */
  itemDownloads: Maybe<Array<Maybe<ProductDownload>>>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** The Id of the order the order item belongs to. */
  orderId: Maybe<Scalars['Int']['output']>;
  /** Connection between the LineItem type and the Product type */
  product: Maybe<LineItemToProductConnectionEdge>;
  /** Line item&#039;s product ID */
  productId: Maybe<Scalars['Int']['output']>;
  /** Line item&#039;s product quantity */
  quantity: Maybe<Scalars['Int']['output']>;
  /** Line item&#039;s subtotal */
  subtotal: Maybe<Scalars['String']['output']>;
  /** Line item&#039;s subtotal tax */
  subtotalTax: Maybe<Scalars['String']['output']>;
  /** Line item&#039;s tax class */
  taxClass: Maybe<TaxClassEnum>;
  /** Line item&#039;s taxes */
  taxStatus: Maybe<TaxStatusEnum>;
  /** Line item&#039;s taxes */
  taxes: Maybe<Array<Maybe<OrderItemTax>>>;
  /** Line item&#039;s total */
  total: Maybe<Scalars['String']['output']>;
  /** Line item&#039;s total tax */
  totalTax: Maybe<Scalars['String']['output']>;
  /** Connection between the LineItem type and the ProductVariation type */
  variation: Maybe<LineItemToProductVariationConnectionEdge>;
  /** Line item&#039;s product variation ID */
  variationId: Maybe<Scalars['Int']['output']>;
};

/** a line item object */
export type LineItemMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** a line item object */
export type LineItemProductArgs = {
  where: InputMaybe<LineItemToProductConnectionWhereArgs>;
};

/** Connection to LineItem Nodes */
export type LineItemConnection = {
  /** A list of edges (relational context) between Order and connected LineItem Nodes */
  edges: Array<LineItemConnectionEdge>;
  /** A list of connected LineItem Nodes */
  nodes: Array<LineItem>;
  /** Information about pagination in a connection. */
  pageInfo: LineItemConnectionPageInfo;
};

/** Edge between a Node and a connected LineItem */
export type LineItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected LineItem Node */
  node: LineItem;
};

/** Page Info on the connected LineItemConnectionEdge */
export type LineItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Meta data. */
export type LineItemInput = {
  /** Line Item ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** Meta data. */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Line name */
  name: InputMaybe<Scalars['String']['input']>;
  /** Product ID. */
  productId: InputMaybe<Scalars['Int']['input']>;
  /** Quantity ordered. */
  quantity: InputMaybe<Scalars['Int']['input']>;
  /** Product SKU. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Line subtotal (before discounts). */
  subtotal: InputMaybe<Scalars['String']['input']>;
  /** Tax class of product. */
  taxClass: InputMaybe<TaxClassEnum>;
  /** Line total (after discounts). */
  total: InputMaybe<Scalars['String']['input']>;
  /** Variation ID, if applicable. */
  variationId: InputMaybe<Scalars['Int']['input']>;
};

/** Connection between the LineItem type and the Product type */
export type LineItemToProductConnectionEdge = Edge &
  OneToOneConnection &
  ProductConnectionEdge & {
    __typename?: 'LineItemToProductConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Product;
  };

/** Arguments for filtering the LineItemToProductConnection connection */
export type LineItemToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the LineItem type and the ProductVariation type */
export type LineItemToProductVariationConnectionEdge = Edge &
  OneToOneConnection &
  ProductVariationConnectionEdge & {
    __typename?: 'LineItemToProductVariationConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ProductVariation;
  };

/** A product attribute object */
export type LocalProductAttribute = Node &
  ProductAttribute & {
    __typename?: 'LocalProductAttribute';
    /** Attribute ID */
    attributeId: Scalars['Int']['output'];
    /** Attribute Global ID */
    id: Scalars['ID']['output'];
    /** Attribute label */
    label: Maybe<Scalars['String']['output']>;
    /** Attribute name */
    name: Maybe<Scalars['String']['output']>;
    /** Attribute options */
    options: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Attribute position */
    position: Maybe<Scalars['Int']['output']>;
    /** Product attribute scope. */
    scope: ProductAttributeTypesEnum;
    /** Is attribute on product variation */
    variation: Maybe<Scalars['Boolean']['output']>;
    /** Is attribute visible */
    visible: Maybe<Scalars['Boolean']['output']>;
  };

/** Connection to LocalProductAttribute Nodes */
export type LocalProductAttributeConnection = {
  /** A list of edges (relational context) between Product and connected LocalProductAttribute Nodes */
  edges: Array<LocalProductAttributeConnectionEdge>;
  /** A list of connected LocalProductAttribute Nodes */
  nodes: Array<LocalProductAttribute>;
  /** Information about pagination in a connection. */
  pageInfo: LocalProductAttributeConnectionPageInfo;
};

/** Edge between a Node and a connected LocalProductAttribute */
export type LocalProductAttributeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected LocalProductAttribute Node */
  node: LocalProductAttribute;
};

/** Page Info on the connected LocalProductAttributeConnectionEdge */
export type LocalProductAttributeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Maintenance Mode */
export type MaintenanceMode = Node & {
  __typename?: 'MaintenanceMode';
  /** Maintenance Mode Image */
  background: Maybe<Scalars['String']['output']>;
  /** Maintenance Mode Enabled */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Maintenance Mode Image */
  image: Maybe<Scalars['String']['output']>;
  /** Maintenance Mode Message */
  message: Maybe<Scalars['String']['output']>;
  /** Maintenance Mode Title */
  title: Maybe<Scalars['String']['output']>;
};

/** Product manage stock enumeration */
export enum ManageStockEnum {
  False = 'FALSE',
  Parent = 'PARENT',
  True = 'TRUE'
}

/** File details for a Media Item */
export type MediaDetails = {
  __typename?: 'MediaDetails';
  /** The filename of the mediaItem */
  file: Maybe<Scalars['String']['output']>;
  /** The height of the mediaItem */
  height: Maybe<Scalars['Int']['output']>;
  /** Meta information associated with the mediaItem */
  meta: Maybe<MediaItemMeta>;
  /** The available sizes of the mediaItem */
  sizes: Maybe<Array<Maybe<MediaSize>>>;
  /** The width of the mediaItem */
  width: Maybe<Scalars['Int']['output']>;
};

/** File details for a Media Item */
export type MediaDetailsSizesArgs = {
  exclude: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
  include: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
};

/** The mediaItem type */
export type MediaItem = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'MediaItem';
    /** Alternative text to display when resource is not displayed */
    altText: Maybe<Scalars['String']['output']>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId: Maybe<Scalars['ID']['output']>;
    /** The caption for the resource */
    caption: Maybe<Scalars['String']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** Connection between the MediaItem type and the Comment type */
    comments: Maybe<MediaItemToCommentConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Description of the image (stored as post_content) */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The filesize in bytes of the resource */
    fileSize: Maybe<Scalars['Int']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the attachment object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the attachment object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** Details about the mediaItem */
    mediaDetails: Maybe<MediaDetails>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    mediaItemId: Scalars['Int']['output'];
    /** Url of the mediaItem */
    mediaItemUrl: Maybe<Scalars['String']['output']>;
    /** Type of resource */
    mediaType: Maybe<Scalars['String']['output']>;
    /** The mime type of the mediaItem */
    mimeType: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId: Maybe<Scalars['ID']['output']>;
    /** The password for the attachment object. */
    password: Maybe<Scalars['String']['output']>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** The sizes attribute value for an image. */
    sizes: Maybe<Scalars['String']['output']>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug: Maybe<Scalars['String']['output']>;
    /** Url of the mediaItem */
    sourceUrl: Maybe<Scalars['String']['output']>;
    /** The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths. */
    srcSet: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** The template assigned to a node of content */
    template: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The mediaItem type */
export type MediaItemAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemCaptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The mediaItem type */
export type MediaItemChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<MediaItemToCommentConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The mediaItem type */
export type MediaItemEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The mediaItem type */
export type MediaItemEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The mediaItem type */
export type MediaItemFileSizeArgs = {
  size: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSizesArgs = {
  size: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSourceUrlArgs = {
  size: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSrcSetArgs = {
  size: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to mediaItem Nodes */
export type MediaItemConnection = {
  /** A list of edges (relational context) between RootQuery and connected mediaItem Nodes */
  edges: Array<MediaItemConnectionEdge>;
  /** A list of connected mediaItem Nodes */
  nodes: Array<MediaItem>;
  /** Information about pagination in a connection. */
  pageInfo: MediaItemConnectionPageInfo;
};

/** Edge between a Node and a connected mediaItem */
export type MediaItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected mediaItem Node */
  node: MediaItem;
};

/** Page Info on the connected MediaItemConnectionEdge */
export type MediaItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a media item by its source url */
  SourceUrl = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Meta connected to a MediaItem */
export type MediaItemMeta = {
  __typename?: 'MediaItemMeta';
  /** Aperture measurement of the media item. */
  aperture: Maybe<Scalars['Float']['output']>;
  /** Information about the camera used to create the media item. */
  camera: Maybe<Scalars['String']['output']>;
  /** The text string description associated with the media item. */
  caption: Maybe<Scalars['String']['output']>;
  /** Copyright information associated with the media item. */
  copyright: Maybe<Scalars['String']['output']>;
  /** The date/time when the media was created. */
  createdTimestamp: Maybe<Scalars['Int']['output']>;
  /** The original creator of the media item. */
  credit: Maybe<Scalars['String']['output']>;
  /** The focal length value of the media item. */
  focalLength: Maybe<Scalars['Float']['output']>;
  /** The ISO (International Organization for Standardization) value of the media item. */
  iso: Maybe<Scalars['Int']['output']>;
  /** List of keywords used to describe or identfy the media item. */
  keywords: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The vertical or horizontal aspect of the media item. */
  orientation: Maybe<Scalars['String']['output']>;
  /** The shutter speed information of the media item. */
  shutterSpeed: Maybe<Scalars['Float']['output']>;
  /** A useful title for the media item. */
  title: Maybe<Scalars['String']['output']>;
};

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  Large = 'LARGE',
  /** MediaItem with the medium size */
  Medium = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MediumLarge = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  Thumbnail = 'THUMBNAIL',
  /** MediaItem with the woocommerce_gallery_thumbnail size */
  WoocommerceGalleryThumbnail = 'WOOCOMMERCE_GALLERY_THUMBNAIL',
  /** MediaItem with the woocommerce_single size */
  WoocommerceSingle = 'WOOCOMMERCE_SINGLE',
  /** MediaItem with the woocommerce_thumbnail size */
  WoocommerceThumbnail = 'WOOCOMMERCE_THUMBNAIL',
  /** MediaItem with the 1536x1536 size */
  '1536X1536' = '_1536X1536',
  /** MediaItem with the 2048x2048 size */
  '2048X2048' = '_2048X2048'
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the trash status */
  Trash = 'TRASH'
}

/** Connection between the MediaItem type and the Comment type */
export type MediaItemToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'MediaItemToCommentConnection';
    /** Edges for the MediaItemToCommentConnection connection */
    edges: Array<MediaItemToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: MediaItemToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type MediaItemToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'MediaItemToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;MediaItemToCommentConnection&quot; */
export type MediaItemToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MediaItemToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Details of an available size for a media item */
export type MediaSize = {
  __typename?: 'MediaSize';
  /** The filename of the referenced size */
  file: Maybe<Scalars['String']['output']>;
  /** The filesize of the resource */
  fileSize: Maybe<Scalars['Int']['output']>;
  /** The height of the referenced size */
  height: Maybe<Scalars['String']['output']>;
  /** The mime type of the referenced size */
  mimeType: Maybe<Scalars['String']['output']>;
  /** The referenced size name */
  name: Maybe<Scalars['String']['output']>;
  /** The url of the referenced size */
  sourceUrl: Maybe<Scalars['String']['output']>;
  /** The width of the referenced size */
  width: Maybe<Scalars['String']['output']>;
};

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type Menu = DatabaseIdentifier &
  Node & {
    __typename?: 'Menu';
    /** The number of items in the menu */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The globally unique identifier of the nav menu object. */
    id: Scalars['ID']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** The locations a menu is assigned to */
    locations: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /**
     * WP ID of the nav menu.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuId: Maybe<Scalars['Int']['output']>;
    /** Connection between the Menu type and the MenuItem type */
    menuItems: Maybe<MenuToMenuItemConnection>;
    /** Display name of the menu. Equivalent to WP_Term-&gt;name. */
    name: Maybe<Scalars['String']['output']>;
    /** The url friendly name of the menu. Equivalent to WP_Term-&gt;slug */
    slug: Maybe<Scalars['String']['output']>;
  };

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type MenuMenuItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<MenuToMenuItemConnectionWhereArgs>;
};

/** Connection to Menu Nodes */
export type MenuConnection = {
  /** A list of edges (relational context) between RootQuery and connected Menu Nodes */
  edges: Array<MenuConnectionEdge>;
  /** A list of connected Menu Nodes */
  nodes: Array<Menu>;
  /** Information about pagination in a connection. */
  pageInfo: MenuConnectionPageInfo;
};

/** Edge between a Node and a connected Menu */
export type MenuConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Menu Node */
  node: Menu;
};

/** Page Info on the connected MenuConnectionEdge */
export type MenuConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItem = DatabaseIdentifier &
  Node & {
    __typename?: 'MenuItem';
    /** Connection between the MenuItem type and the MenuItem type */
    childItems: Maybe<MenuItemToMenuItemConnection>;
    /** Connection from MenuItem to it&#039;s connected node */
    connectedNode: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
    /**
     * The object connected to this menu item.
     * @deprecated Deprecated in favor of the connectedNode field
     */
    connectedObject: Maybe<MenuItemObjectUnion>;
    /** Class attribute for the menu item link */
    cssClasses: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Description of the menu item. */
    description: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier of the nav menu item object. */
    id: Scalars['ID']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Label or title of the menu item. */
    label: Maybe<Scalars['String']['output']>;
    /** Link relationship (XFN) of the menu item. */
    linkRelationship: Maybe<Scalars['String']['output']>;
    /** The locations the menu item&#039;s Menu is assigned to */
    locations: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /** The Menu a MenuItem is part of */
    menu: Maybe<MenuItemToMenuConnectionEdge>;
    /**
     * WP ID of the menu item.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuItemId: Maybe<Scalars['Int']['output']>;
    /** Menu item order */
    order: Maybe<Scalars['Int']['output']>;
    /** The database id of the parent menu item or null if it is the root */
    parentDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent nav menu item object. */
    parentId: Maybe<Scalars['ID']['output']>;
    /** Path for the resource. Relative path for internal resources. Absolute path for external resources. */
    path: Maybe<Scalars['String']['output']>;
    /** Target attribute for the menu item link. */
    target: Maybe<Scalars['String']['output']>;
    /** Title attribute for the menu item link */
    title: Maybe<Scalars['String']['output']>;
    /** The uri of the resource the menu item links to */
    uri: Maybe<Scalars['String']['output']>;
    /** URL or destination of the menu item. */
    url: Maybe<Scalars['String']['output']>;
  };

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItemChildItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<MenuItemToMenuItemConnectionWhereArgs>;
};

/** Connection to MenuItem Nodes */
export type MenuItemConnection = {
  /** A list of edges (relational context) between RootQuery and connected MenuItem Nodes */
  edges: Array<MenuItemConnectionEdge>;
  /** A list of connected MenuItem Nodes */
  nodes: Array<MenuItem>;
  /** Information about pagination in a connection. */
  pageInfo: MenuItemConnectionPageInfo;
};

/** Edge between a Node and a connected MenuItem */
export type MenuItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected MenuItem Node */
  node: MenuItem;
};

/** Page Info on the connected MenuItemConnectionEdge */
export type MenuItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Nodes that can be linked to as Menu Items */
export type MenuItemLinkable = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
};

/** Edge between a Node and a connected MenuItemLinkable */
export type MenuItemLinkableConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected MenuItemLinkable Node */
  node: MenuItemLinkable;
};

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Deprecated in favor of MenuItemLinkeable Interface */
export type MenuItemObjectUnion =
  | Banner
  | Category
  | ConfirmationScreen
  | Page
  | Post
  | ProductCategory
  | ProductTag
  | Tag;

/** Connection between the MenuItem type and the Menu type */
export type MenuItemToMenuConnectionEdge = Edge &
  MenuConnectionEdge &
  OneToOneConnection & {
    __typename?: 'MenuItemToMenuConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Menu;
  };

/** Connection between the MenuItem type and the MenuItem type */
export type MenuItemToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'MenuItemToMenuItemConnection';
    /** Edges for the MenuItemToMenuItemConnection connection */
    edges: Array<MenuItemToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: MenuItemToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type MenuItemToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'MenuItemToMenuItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;MenuItemToMenuItemConnection&quot; */
export type MenuItemToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MenuItemToMenuItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** The menu location for the menu being queried */
  location: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent menu object */
  parentId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the MenuItem type and the MenuItemLinkable type */
export type MenuItemToMenuItemLinkableConnectionEdge = Edge &
  MenuItemLinkableConnectionEdge &
  OneToOneConnection & {
    __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: MenuItemLinkable;
  };

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Empty menu location */
  Empty = 'EMPTY'
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a menu node by the slug of menu location to which it is assigned */
  Location = 'LOCATION',
  /** Identify a menu node by its name */
  Name = 'NAME',
  /** Identify a menu node by its slug */
  Slug = 'SLUG'
}

/** Connection between the Menu type and the MenuItem type */
export type MenuToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'MenuToMenuItemConnection';
    /** Edges for the MenuToMenuItemConnection connection */
    edges: Array<MenuToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: MenuToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type MenuToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'MenuToMenuItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;MenuToMenuItemConnection&quot; */
export type MenuToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MenuToMenuItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** The menu location for the menu being queried */
  location: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent menu object */
  parentId: InputMaybe<Scalars['ID']['input']>;
};

/** Extra data defined on the WC object */
export type MetaData = {
  __typename?: 'MetaData';
  /** Meta ID. */
  id: Maybe<Scalars['ID']['output']>;
  /** Meta key. */
  key: Scalars['String']['output'];
  /** Meta value. */
  value: Maybe<Scalars['String']['output']>;
};

/** Meta data. */
export type MetaDataInput = {
  /** Meta ID. */
  id: InputMaybe<Scalars['String']['input']>;
  /** Meta key. */
  key: Scalars['String']['input'];
  /** Meta value. */
  value: Scalars['String']['input'];
};

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** application/java mime type. */
  ApplicationJava = 'APPLICATION_JAVA',
  /** application/msword mime type. */
  ApplicationMsword = 'APPLICATION_MSWORD',
  /** application/octet-stream mime type. */
  ApplicationOctetStream = 'APPLICATION_OCTET_STREAM',
  /** application/onenote mime type. */
  ApplicationOnenote = 'APPLICATION_ONENOTE',
  /** application/oxps mime type. */
  ApplicationOxps = 'APPLICATION_OXPS',
  /** application/pdf mime type. */
  ApplicationPdf = 'APPLICATION_PDF',
  /** application/rar mime type. */
  ApplicationRar = 'APPLICATION_RAR',
  /** application/rtf mime type. */
  ApplicationRtf = 'APPLICATION_RTF',
  /** application/ttaf+xml mime type. */
  ApplicationTtafXml = 'APPLICATION_TTAF_XML',
  /** application/vnd.apple.keynote mime type. */
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** application/vnd.apple.numbers mime type. */
  ApplicationVndAppleNumbers = 'APPLICATION_VND_APPLE_NUMBERS',
  /** application/vnd.apple.pages mime type. */
  ApplicationVndApplePages = 'APPLICATION_VND_APPLE_PAGES',
  /** application/vnd.ms-access mime type. */
  ApplicationVndMsAccess = 'APPLICATION_VND_MS_ACCESS',
  /** application/vnd.ms-excel mime type. */
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  /** application/vnd.ms-excel.addin.macroEnabled.12 mime type. */
  ApplicationVndMsExcelAddinMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.binary.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** application/vnd.ms-excel.template.macroEnabled.12 mime type. */
  ApplicationVndMsExcelTemplateMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-powerpoint mime type. */
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  /** application/vnd.ms-powerpoint.addin.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointAddinMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.presentation.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slideshow.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slide.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.template.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointTemplateMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-project mime type. */
  ApplicationVndMsProject = 'APPLICATION_VND_MS_PROJECT',
  /** application/vnd.ms-word.document.macroEnabled.12 mime type. */
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** application/vnd.ms-word.template.macroEnabled.12 mime type. */
  ApplicationVndMsWordTemplateMacroenabled_12 = 'APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-write mime type. */
  ApplicationVndMsWrite = 'APPLICATION_VND_MS_WRITE',
  /** application/vnd.ms-xpsdocument mime type. */
  ApplicationVndMsXpsdocument = 'APPLICATION_VND_MS_XPSDOCUMENT',
  /** application/vnd.oasis.opendocument.chart mime type. */
  ApplicationVndOasisOpendocumentChart = 'APPLICATION_VND_OASIS_OPENDOCUMENT_CHART',
  /** application/vnd.oasis.opendocument.database mime type. */
  ApplicationVndOasisOpendocumentDatabase = 'APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE',
  /** application/vnd.oasis.opendocument.formula mime type. */
  ApplicationVndOasisOpendocumentFormula = 'APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA',
  /** application/vnd.oasis.opendocument.graphics mime type. */
  ApplicationVndOasisOpendocumentGraphics = 'APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS',
  /** application/vnd.oasis.opendocument.presentation mime type. */
  ApplicationVndOasisOpendocumentPresentation = 'APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION',
  /** application/vnd.oasis.opendocument.spreadsheet mime type. */
  ApplicationVndOasisOpendocumentSpreadsheet = 'APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET',
  /** application/vnd.oasis.opendocument.text mime type. */
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** application/vnd.openxmlformats-officedocument.presentationml.presentation mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** application/vnd.openxmlformats-officedocument.presentationml.slide mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlide = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE',
  /** application/vnd.openxmlformats-officedocument.presentationml.slideshow mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** application/vnd.openxmlformats-officedocument.presentationml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.sheet mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.document mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE',
  /** application/wordperfect mime type. */
  ApplicationWordperfect = 'APPLICATION_WORDPERFECT',
  /** application/x-7z-compressed mime type. */
  ApplicationX_7ZCompressed = 'APPLICATION_X_7Z_COMPRESSED',
  /** application/x-gzip mime type. */
  ApplicationXGzip = 'APPLICATION_X_GZIP',
  /** application/x-tar mime type. */
  ApplicationXTar = 'APPLICATION_X_TAR',
  /** application/zip mime type. */
  ApplicationZip = 'APPLICATION_ZIP',
  /** audio/aac mime type. */
  AudioAac = 'AUDIO_AAC',
  /** audio/flac mime type. */
  AudioFlac = 'AUDIO_FLAC',
  /** audio/midi mime type. */
  AudioMidi = 'AUDIO_MIDI',
  /** audio/mpeg mime type. */
  AudioMpeg = 'AUDIO_MPEG',
  /** audio/ogg mime type. */
  AudioOgg = 'AUDIO_OGG',
  /** audio/wav mime type. */
  AudioWav = 'AUDIO_WAV',
  /** audio/x-matroska mime type. */
  AudioXMatroska = 'AUDIO_X_MATROSKA',
  /** audio/x-ms-wax mime type. */
  AudioXMsWax = 'AUDIO_X_MS_WAX',
  /** audio/x-ms-wma mime type. */
  AudioXMsWma = 'AUDIO_X_MS_WMA',
  /** audio/x-realaudio mime type. */
  AudioXRealaudio = 'AUDIO_X_REALAUDIO',
  /** image/avif mime type. */
  ImageAvif = 'IMAGE_AVIF',
  /** image/bmp mime type. */
  ImageBmp = 'IMAGE_BMP',
  /** image/gif mime type. */
  ImageGif = 'IMAGE_GIF',
  /** image/heic mime type. */
  ImageHeic = 'IMAGE_HEIC',
  /** image/jpeg mime type. */
  ImageJpeg = 'IMAGE_JPEG',
  /** image/png mime type. */
  ImagePng = 'IMAGE_PNG',
  /** image/tiff mime type. */
  ImageTiff = 'IMAGE_TIFF',
  /** image/webp mime type. */
  ImageWebp = 'IMAGE_WEBP',
  /** image/x-icon mime type. */
  ImageXIcon = 'IMAGE_X_ICON',
  /** text/calendar mime type. */
  TextCalendar = 'TEXT_CALENDAR',
  /** text/css mime type. */
  TextCss = 'TEXT_CSS',
  /** text/csv mime type. */
  TextCsv = 'TEXT_CSV',
  /** text/plain mime type. */
  TextPlain = 'TEXT_PLAIN',
  /** text/richtext mime type. */
  TextRichtext = 'TEXT_RICHTEXT',
  /** text/tab-separated-values mime type. */
  TextTabSeparatedValues = 'TEXT_TAB_SEPARATED_VALUES',
  /** text/vtt mime type. */
  TextVtt = 'TEXT_VTT',
  /** video/3gpp mime type. */
  Video_3Gpp = 'VIDEO_3GPP',
  /** video/3gpp2 mime type. */
  Video_3Gpp2 = 'VIDEO_3GPP2',
  /** video/avi mime type. */
  VideoAvi = 'VIDEO_AVI',
  /** video/divx mime type. */
  VideoDivx = 'VIDEO_DIVX',
  /** video/mp4 mime type. */
  VideoMp4 = 'VIDEO_MP4',
  /** video/mpeg mime type. */
  VideoMpeg = 'VIDEO_MPEG',
  /** video/ogg mime type. */
  VideoOgg = 'VIDEO_OGG',
  /** video/quicktime mime type. */
  VideoQuicktime = 'VIDEO_QUICKTIME',
  /** video/webm mime type. */
  VideoWebm = 'VIDEO_WEBM',
  /** video/x-flv mime type. */
  VideoXFlv = 'VIDEO_X_FLV',
  /** video/x-matroska mime type. */
  VideoXMatroska = 'VIDEO_X_MATROSKA',
  /** video/x-ms-asf mime type. */
  VideoXMsAsf = 'VIDEO_X_MS_ASF',
  /** video/x-ms-wm mime type. */
  VideoXMsWm = 'VIDEO_X_MS_WM',
  /** video/x-ms-wmv mime type. */
  VideoXMsWmv = 'VIDEO_X_MS_WMV',
  /** video/x-ms-wmx mime type. */
  VideoXMsWmx = 'VIDEO_X_MS_WMX'
}

/** An object with an ID */
export type Node = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that can have an author assigned to it */
export type NodeWithAuthor = {
  /** Connection between the NodeWithAuthor type and the User type */
  author: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the author of the node */
  authorId: Maybe<Scalars['ID']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** Connection between the NodeWithAuthor type and the User type */
export type NodeWithAuthorToUserConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'NodeWithAuthorToUserConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** A node that can have comments associated with it */
export type NodeWithComments = {
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount: Maybe<Scalars['Int']['output']>;
  /** Whether the comments are open or closed for this particular post. */
  commentStatus: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that supports the content editor */
export type NodeWithContentEditor = {
  /** The content of the post. */
  content: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that supports the content editor */
export type NodeWithContentEditorContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have an excerpt */
export type NodeWithExcerpt = {
  /** The excerpt of the post. */
  excerpt: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that can have an excerpt */
export type NodeWithExcerptExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have a featured image set */
export type NodeWithFeaturedImage = {
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId: Maybe<Scalars['ID']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** Connection between the NodeWithFeaturedImage type and the MediaItem type */
export type NodeWithFeaturedImageToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge &
  OneToOneConnection & {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: MediaItem;
  };

/** A node that can have page attributes */
export type NodeWithPageAttributes = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: Maybe<Scalars['Int']['output']>;
};

/** A node that can have revisions */
export type NodeWithRevisions = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** True if the node is a revision of another node */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
};

/** Connection between the NodeWithRevisions type and the ContentNode type */
export type NodeWithRevisionsToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'NodeWithRevisionsToContentNodeConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** A node that can have a template associated with it */
export type NodeWithTemplate = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** The template assigned to the node */
  template: Maybe<ContentTemplate>;
};

/** A node that NodeWith a title */
export type NodeWithTitle = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title: Maybe<Scalars['String']['output']>;
};

/** A node that NodeWith a title */
export type NodeWithTitleTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have trackbacks and pingbacks */
export type NodeWithTrackbacks = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the pings are open or closed for this particular post. */
  pingStatus: Maybe<Scalars['String']['output']>;
  /** URLs that have been pinged. */
  pinged: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** URLs queued to be pinged. */
  toPing: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** A singular connection from one Node to another, with support for relational data on the &quot;edge&quot; of the connection. */
export type OneToOneConnection = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected node */
  node: Node;
};

/** A order object */
export type Order = Node &
  NodeWithComments & {
    __typename?: 'Order';
    /** Order billing properties */
    billing: Maybe<CustomerAddress>;
    /** Cart hash */
    cartHash: Maybe<Scalars['String']['output']>;
    /** Cart tax amount */
    cartTax: Maybe<Scalars['String']['output']>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** Connection between the Order type and the CouponLine type */
    couponLines: Maybe<OrderToCouponLineConnection>;
    /** How order was created */
    createdVia: Maybe<Scalars['String']['output']>;
    /** Order currency */
    currency: Maybe<Scalars['String']['output']>;
    /** Order customer */
    customer: Maybe<Customer>;
    /** Customer IP Address */
    customerIpAddress: Maybe<Scalars['String']['output']>;
    /** Customer note */
    customerNote: Maybe<Scalars['String']['output']>;
    /** Customer User Agent */
    customerUserAgent: Maybe<Scalars['String']['output']>;
    /** The ID of the order in the database */
    databaseId: Maybe<Scalars['Int']['output']>;
    /** Date order was created */
    date: Maybe<Scalars['String']['output']>;
    /** Date order was completed */
    dateCompleted: Maybe<Scalars['String']['output']>;
    /** Date order was paid */
    datePaid: Maybe<Scalars['String']['output']>;
    /** Discount tax amount */
    discountTax: Maybe<Scalars['String']['output']>;
    /** Discount total amount */
    discountTotal: Maybe<Scalars['String']['output']>;
    /** Connection between the Order type and the DownloadableItem type */
    downloadableItems: Maybe<OrderToDownloadableItemConnection>;
    /** Connection between the Order type and the FeeLine type */
    feeLines: Maybe<OrderToFeeLineConnection>;
    /** Order has a billing address? */
    hasBillingAddress: Maybe<Scalars['Boolean']['output']>;
    /** If order contains a downloadable product */
    hasDownloadableItem: Maybe<Scalars['Boolean']['output']>;
    /** Order has a shipping address? */
    hasShippingAddress: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier for the order */
    id: Scalars['ID']['output'];
    /** Is product download is permitted */
    isDownloadPermitted: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the Order type and the LineItem type */
    lineItems: Maybe<OrderToLineItemConnection>;
    /** Object meta data */
    metaData: Maybe<Array<Maybe<MetaData>>>;
    /** Date order was last updated */
    modified: Maybe<Scalars['String']['output']>;
    /** If order needs payment */
    needsPayment: Maybe<Scalars['Boolean']['output']>;
    /** If order needs processing before it can be completed */
    needsProcessing: Maybe<Scalars['Boolean']['output']>;
    /** If order needs shipping address */
    needsShippingAddress: Maybe<Scalars['Boolean']['output']>;
    /** Order key */
    orderKey: Maybe<Scalars['String']['output']>;
    /** Connection between the Order type and the Comment type */
    orderNotes: Maybe<OrderToCommentConnection>;
    /** Order number */
    orderNumber: Maybe<Scalars['String']['output']>;
    /** Order version */
    orderVersion: Maybe<Scalars['String']['output']>;
    /** Parent order */
    parent: Maybe<Order>;
    /** Payment method */
    paymentMethod: Maybe<Scalars['String']['output']>;
    /** Payment method title */
    paymentMethodTitle: Maybe<Scalars['String']['output']>;
    /** Prices include taxes? */
    pricesIncludeTax: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the Order type and the Refund type */
    refunds: Maybe<OrderToRefundConnection>;
    /** Order shipping properties */
    shipping: Maybe<CustomerAddress>;
    /** Order customer */
    shippingAddressMapUrl: Maybe<Scalars['String']['output']>;
    /** Connection between the Order type and the ShippingLine type */
    shippingLines: Maybe<OrderToShippingLineConnection>;
    /** Shipping tax amount */
    shippingTax: Maybe<Scalars['String']['output']>;
    /** Shipping total amount */
    shippingTotal: Maybe<Scalars['String']['output']>;
    /** Order status */
    status: Maybe<OrderStatusEnum>;
    /** Order subtotal */
    subtotal: Maybe<Scalars['String']['output']>;
    /** Connection between the Order type and the TaxLine type */
    taxLines: Maybe<OrderToTaxLineConnection>;
    /** Order grand total */
    total: Maybe<Scalars['String']['output']>;
    /** Order taxes */
    totalTax: Maybe<Scalars['String']['output']>;
    /** Transaction ID */
    transactionId: Maybe<Scalars['String']['output']>;
  };

/** A order object */
export type OrderCartTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderCouponLinesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A order object */
export type OrderDiscountTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderDiscountTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderDownloadableItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<OrderToDownloadableItemConnectionWhereArgs>;
};

/** A order object */
export type OrderFeeLinesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A order object */
export type OrderLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A order object */
export type OrderMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A order object */
export type OrderOrderNotesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<OrderToCommentConnectionWhereArgs>;
};

/** A order object */
export type OrderRefundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<OrderToRefundConnectionWhereArgs>;
};

/** A order object */
export type OrderShippingLinesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A order object */
export type OrderShippingTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderShippingTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderSubtotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderTaxLinesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A order object */
export type OrderTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A order object */
export type OrderTotalTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Connection to Order Nodes */
export type OrderConnection = {
  /** A list of edges (relational context) between RootQuery and connected Order Nodes */
  edges: Array<OrderConnectionEdge>;
  /** A list of connected Order Nodes */
  nodes: Array<Order>;
  /** Information about pagination in a connection. */
  pageInfo: OrderConnectionPageInfo;
};

/** Edge between a Node and a connected Order */
export type OrderConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Order Node */
  node: Order;
};

/** Page Info on the connected OrderConnectionEdge */
export type OrderConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = 'ASC',
  /** Sort the query result set in a descending order */
  Desc = 'DESC'
}

/** The Type of Identifier used to fetch a single Order. Default is ID. */
export enum OrderIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Order number. */
  OrderNumber = 'ORDER_NUMBER'
}

/** Order item tax statement */
export type OrderItemTax = {
  __typename?: 'OrderItemTax';
  /** Amount taxed */
  amount: Maybe<Scalars['Float']['output']>;
  /** Subtotal */
  subtotal: Maybe<Scalars['Float']['output']>;
  /** Tax line connected to this statement */
  taxLine: Maybe<TaxLine>;
  /** Order item ID for tax line connected to this statement */
  taxLineId: Scalars['Int']['output'];
  /** Total */
  total: Maybe<Scalars['Float']['output']>;
};

/** Order status enumeration */
export enum OrderStatusEnum {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Draft */
  CheckoutDraft = 'CHECKOUT_DRAFT',
  /** Completed */
  Completed = 'COMPLETED',
  /** Failed */
  Failed = 'FAILED',
  /** On hold */
  OnHold = 'ON_HOLD',
  /** Pending payment */
  Pending = 'PENDING',
  /** Processing */
  Processing = 'PROCESSING',
  /** Refunded */
  Refunded = 'REFUNDED'
}

/** Connection between the Order type and the Comment type */
export type OrderToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'OrderToCommentConnection';
    /** Edges for the OrderToCommentConnection connection */
    edges: Array<OrderToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'OrderToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** Is this a customer note? */
    isCustomerNote: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;OrderToCommentConnection&quot; */
export type OrderToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'OrderToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the OrderToCommentConnection connection */
export type OrderToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Order type and the CouponLine type */
export type OrderToCouponLineConnection = Connection &
  CouponLineConnection & {
    __typename?: 'OrderToCouponLineConnection';
    /** Edges for the OrderToCouponLineConnection connection */
    edges: Array<OrderToCouponLineConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CouponLine>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToCouponLineConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToCouponLineConnectionEdge = CouponLineConnectionEdge &
  Edge & {
    __typename?: 'OrderToCouponLineConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: CouponLine;
  };

/** Page Info on the &quot;OrderToCouponLineConnection&quot; */
export type OrderToCouponLineConnectionPageInfo = CouponLineConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'OrderToCouponLineConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the Order type and the DownloadableItem type */
export type OrderToDownloadableItemConnection = Connection &
  DownloadableItemConnection & {
    __typename?: 'OrderToDownloadableItemConnection';
    /** Edges for the OrderToDownloadableItemConnection connection */
    edges: Array<OrderToDownloadableItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<DownloadableItem>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToDownloadableItemConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToDownloadableItemConnectionEdge = DownloadableItemConnectionEdge &
  Edge & {
    __typename?: 'OrderToDownloadableItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: DownloadableItem;
  };

/** Page Info on the &quot;OrderToDownloadableItemConnection&quot; */
export type OrderToDownloadableItemConnectionPageInfo = DownloadableItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'OrderToDownloadableItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the OrderToDownloadableItemConnection connection */
export type OrderToDownloadableItemConnectionWhereArgs = {
  /** Limit results to downloadable items that can be downloaded now. */
  active: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit results to downloadable items that are expired. */
  expired: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit results to downloadable items that have downloads remaining. */
  hasDownloadsRemaining: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Order type and the FeeLine type */
export type OrderToFeeLineConnection = Connection &
  FeeLineConnection & {
    __typename?: 'OrderToFeeLineConnection';
    /** Edges for the OrderToFeeLineConnection connection */
    edges: Array<OrderToFeeLineConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<FeeLine>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToFeeLineConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToFeeLineConnectionEdge = Edge &
  FeeLineConnectionEdge & {
    __typename?: 'OrderToFeeLineConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: FeeLine;
  };

/** Page Info on the &quot;OrderToFeeLineConnection&quot; */
export type OrderToFeeLineConnectionPageInfo = FeeLineConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'OrderToFeeLineConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the Order type and the LineItem type */
export type OrderToLineItemConnection = Connection &
  LineItemConnection & {
    __typename?: 'OrderToLineItemConnection';
    /** Edges for the OrderToLineItemConnection connection */
    edges: Array<OrderToLineItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<LineItem>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToLineItemConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToLineItemConnectionEdge = Edge &
  LineItemConnectionEdge & {
    __typename?: 'OrderToLineItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: LineItem;
  };

/** Page Info on the &quot;OrderToLineItemConnection&quot; */
export type OrderToLineItemConnectionPageInfo = LineItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'OrderToLineItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the Order type and the Refund type */
export type OrderToRefundConnection = Connection &
  RefundConnection & {
    __typename?: 'OrderToRefundConnection';
    /** Edges for the OrderToRefundConnection connection */
    edges: Array<OrderToRefundConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Refund>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToRefundConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToRefundConnectionEdge = Edge &
  RefundConnectionEdge & {
    __typename?: 'OrderToRefundConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Refund;
  };

/** Page Info on the &quot;OrderToRefundConnection&quot; */
export type OrderToRefundConnectionPageInfo = PageInfo &
  RefundConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'OrderToRefundConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the OrderToRefundConnection connection */
export type OrderToRefundConnectionWhereArgs = {
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to refunds from a specific group of order IDs. */
  orderIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostTypeOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to refunds assigned a specific status. */
  statuses: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Connection between the Order type and the ShippingLine type */
export type OrderToShippingLineConnection = Connection &
  ShippingLineConnection & {
    __typename?: 'OrderToShippingLineConnection';
    /** Edges for the OrderToShippingLineConnection connection */
    edges: Array<OrderToShippingLineConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ShippingLine>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToShippingLineConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToShippingLineConnectionEdge = Edge &
  ShippingLineConnectionEdge & {
    __typename?: 'OrderToShippingLineConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ShippingLine;
  };

/** Page Info on the &quot;OrderToShippingLineConnection&quot; */
export type OrderToShippingLineConnectionPageInfo = PageInfo &
  ShippingLineConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'OrderToShippingLineConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the Order type and the TaxLine type */
export type OrderToTaxLineConnection = Connection &
  TaxLineConnection & {
    __typename?: 'OrderToTaxLineConnection';
    /** Edges for the OrderToTaxLineConnection connection */
    edges: Array<OrderToTaxLineConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxLine>;
    /** Information about pagination in a connection. */
    pageInfo: OrderToTaxLineConnectionPageInfo;
  };

/** An edge in a connection */
export type OrderToTaxLineConnectionEdge = Edge &
  TaxLineConnectionEdge & {
    __typename?: 'OrderToTaxLineConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxLine;
  };

/** Page Info on the &quot;OrderToTaxLineConnection&quot; */
export type OrderToTaxLineConnectionPageInfo = PageInfo &
  TaxLineConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'OrderToTaxLineConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Fields to order the Orders connection by */
export enum OrdersOrderByEnum {
  /** Order by publish date */
  Date = 'DATE',
  /** Order by date the order was completed */
  DateCompleted = 'DATE_COMPLETED',
  /** Order by date the order was paid */
  DatePaid = 'DATE_PAID',
  /** Order by order discount amount */
  Discount = 'DISCOUNT',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Order by name */
  Name = 'NAME',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by order key */
  OrderKey = 'ORDER_KEY',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by order total */
  Tax = 'TAX',
  /** Order by order total */
  Total = 'TOTAL'
}

/** Options for ordering the connection */
export type OrdersOrderbyInput = {
  field: OrdersOrderByEnum;
  order: InputMaybe<OrderEnum>;
};

/** The paDefault type */
export type PaDefault = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'PaDefault';
    /** Connection between the PaDefault type and the ContentNode type */
    contentNodes: Maybe<PaDefaultToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    paDefaultId: Maybe<Scalars['Int']['output']>;
    /** Connection between the PaDefault type and the Product type */
    products: Maybe<PaDefaultToProductConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the PaDefault type and the Taxonomy type */
    taxonomy: Maybe<PaDefaultToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Connection between the PaDefault type and the ProductVariation type */
    variations: Maybe<PaDefaultToProductVariationConnection>;
  };

/** The paDefault type */
export type PaDefaultContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PaDefaultToContentNodeConnectionWhereArgs>;
};

/** The paDefault type */
export type PaDefaultEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The paDefault type */
export type PaDefaultEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The paDefault type */
export type PaDefaultProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PaDefaultToProductConnectionWhereArgs>;
};

/** The paDefault type */
export type PaDefaultVariationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PaDefaultToProductVariationConnectionWhereArgs>;
};

/** Connection to paDefault Nodes */
export type PaDefaultConnection = {
  /** A list of edges (relational context) between RootQuery and connected paDefault Nodes */
  edges: Array<PaDefaultConnectionEdge>;
  /** A list of connected paDefault Nodes */
  nodes: Array<PaDefault>;
  /** Information about pagination in a connection. */
  pageInfo: PaDefaultConnectionPageInfo;
};

/** Edge between a Node and a connected paDefault */
export type PaDefaultConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected paDefault Node */
  node: PaDefault;
};

/** Page Info on the connected PaDefaultConnectionEdge */
export type PaDefaultConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PaDefaultIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the PaDefault type and the ContentNode type */
export type PaDefaultToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'PaDefaultToContentNodeConnection';
    /** Edges for the PaDefaultToContentNodeConnection connection */
    edges: Array<PaDefaultToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: PaDefaultToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PaDefaultToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'PaDefaultToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;PaDefaultToContentNodeConnection&quot; */
export type PaDefaultToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PaDefaultToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PaDefaultToContentNodeConnection connection */
export type PaDefaultToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfPaDefaultEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the PaDefault type and the Product type */
export type PaDefaultToProductConnection = Connection &
  ProductConnection & {
    __typename?: 'PaDefaultToProductConnection';
    /** Edges for the PaDefaultToProductConnection connection */
    edges: Array<PaDefaultToProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Product>;
    /** Information about pagination in a connection. */
    pageInfo: PaDefaultToProductConnectionPageInfo;
  };

/** An edge in a connection */
export type PaDefaultToProductConnectionEdge = Edge &
  ProductConnectionEdge & {
    __typename?: 'PaDefaultToProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Product;
  };

/** Page Info on the &quot;PaDefaultToProductConnection&quot; */
export type PaDefaultToProductConnectionPageInfo = PageInfo &
  ProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PaDefaultToProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PaDefaultToProductConnection connection */
export type PaDefaultToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the PaDefault type and the ProductVariation type */
export type PaDefaultToProductVariationConnection = Connection &
  ProductVariationConnection & {
    __typename?: 'PaDefaultToProductVariationConnection';
    /** Edges for the PaDefaultToProductVariationConnection connection */
    edges: Array<PaDefaultToProductVariationConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductVariation>;
    /** Information about pagination in a connection. */
    pageInfo: PaDefaultToProductVariationConnectionPageInfo;
  };

/** An edge in a connection */
export type PaDefaultToProductVariationConnectionEdge = Edge &
  ProductVariationConnectionEdge & {
    __typename?: 'PaDefaultToProductVariationConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductVariation;
  };

/** Page Info on the &quot;PaDefaultToProductVariationConnection&quot; */
export type PaDefaultToProductVariationConnectionPageInfo = PageInfo &
  ProductVariationConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PaDefaultToProductVariationConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PaDefaultToProductVariationConnection connection */
export type PaDefaultToProductVariationConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the PaDefault type and the Taxonomy type */
export type PaDefaultToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'PaDefaultToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The paPrimaryCurrency type */
export type PaPrimaryCurrency = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'PaPrimaryCurrency';
    /** Connection between the PaPrimaryCurrency type and the ContentNode type */
    contentNodes: Maybe<PaPrimaryCurrencyToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    paPrimaryCurrencyId: Maybe<Scalars['Int']['output']>;
    /** Connection between the PaPrimaryCurrency type and the Product type */
    products: Maybe<PaPrimaryCurrencyToProductConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the PaPrimaryCurrency type and the Taxonomy type */
    taxonomy: Maybe<PaPrimaryCurrencyToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Connection between the PaPrimaryCurrency type and the ProductVariation type */
    variations: Maybe<PaPrimaryCurrencyToProductVariationConnection>;
  };

/** The paPrimaryCurrency type */
export type PaPrimaryCurrencyContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PaPrimaryCurrencyToContentNodeConnectionWhereArgs>;
};

/** The paPrimaryCurrency type */
export type PaPrimaryCurrencyEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The paPrimaryCurrency type */
export type PaPrimaryCurrencyEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The paPrimaryCurrency type */
export type PaPrimaryCurrencyProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PaPrimaryCurrencyToProductConnectionWhereArgs>;
};

/** The paPrimaryCurrency type */
export type PaPrimaryCurrencyVariationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PaPrimaryCurrencyToProductVariationConnectionWhereArgs>;
};

/** Connection to paPrimaryCurrency Nodes */
export type PaPrimaryCurrencyConnection = {
  /** A list of edges (relational context) between RootQuery and connected paPrimaryCurrency Nodes */
  edges: Array<PaPrimaryCurrencyConnectionEdge>;
  /** A list of connected paPrimaryCurrency Nodes */
  nodes: Array<PaPrimaryCurrency>;
  /** Information about pagination in a connection. */
  pageInfo: PaPrimaryCurrencyConnectionPageInfo;
};

/** Edge between a Node and a connected paPrimaryCurrency */
export type PaPrimaryCurrencyConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected paPrimaryCurrency Node */
  node: PaPrimaryCurrency;
};

/** Page Info on the connected PaPrimaryCurrencyConnectionEdge */
export type PaPrimaryCurrencyConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PaPrimaryCurrencyIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the PaPrimaryCurrency type and the ContentNode type */
export type PaPrimaryCurrencyToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'PaPrimaryCurrencyToContentNodeConnection';
    /** Edges for the PaPrimaryCurrencyToContentNodeConnection connection */
    edges: Array<PaPrimaryCurrencyToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: PaPrimaryCurrencyToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PaPrimaryCurrencyToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'PaPrimaryCurrencyToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;PaPrimaryCurrencyToContentNodeConnection&quot; */
export type PaPrimaryCurrencyToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PaPrimaryCurrencyToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PaPrimaryCurrencyToContentNodeConnection connection */
export type PaPrimaryCurrencyToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfPaPrimaryCurrencyEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the PaPrimaryCurrency type and the Product type */
export type PaPrimaryCurrencyToProductConnection = Connection &
  ProductConnection & {
    __typename?: 'PaPrimaryCurrencyToProductConnection';
    /** Edges for the PaPrimaryCurrencyToProductConnection connection */
    edges: Array<PaPrimaryCurrencyToProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Product>;
    /** Information about pagination in a connection. */
    pageInfo: PaPrimaryCurrencyToProductConnectionPageInfo;
  };

/** An edge in a connection */
export type PaPrimaryCurrencyToProductConnectionEdge = Edge &
  ProductConnectionEdge & {
    __typename?: 'PaPrimaryCurrencyToProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Product;
  };

/** Page Info on the &quot;PaPrimaryCurrencyToProductConnection&quot; */
export type PaPrimaryCurrencyToProductConnectionPageInfo = PageInfo &
  ProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PaPrimaryCurrencyToProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PaPrimaryCurrencyToProductConnection connection */
export type PaPrimaryCurrencyToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the PaPrimaryCurrency type and the ProductVariation type */
export type PaPrimaryCurrencyToProductVariationConnection = Connection &
  ProductVariationConnection & {
    __typename?: 'PaPrimaryCurrencyToProductVariationConnection';
    /** Edges for the PaPrimaryCurrencyToProductVariationConnection connection */
    edges: Array<PaPrimaryCurrencyToProductVariationConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductVariation>;
    /** Information about pagination in a connection. */
    pageInfo: PaPrimaryCurrencyToProductVariationConnectionPageInfo;
  };

/** An edge in a connection */
export type PaPrimaryCurrencyToProductVariationConnectionEdge = Edge &
  ProductVariationConnectionEdge & {
    __typename?: 'PaPrimaryCurrencyToProductVariationConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductVariation;
  };

/** Page Info on the &quot;PaPrimaryCurrencyToProductVariationConnection&quot; */
export type PaPrimaryCurrencyToProductVariationConnectionPageInfo = PageInfo &
  ProductVariationConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PaPrimaryCurrencyToProductVariationConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PaPrimaryCurrencyToProductVariationConnection connection */
export type PaPrimaryCurrencyToProductVariationConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the PaPrimaryCurrency type and the Taxonomy type */
export type PaPrimaryCurrencyToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'PaPrimaryCurrencyToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The page type */
export type Page = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Page';
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId: Maybe<Scalars['ID']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** Connection between the Page type and the Comment type */
    comments: Maybe<PageToCommentConnection>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the page object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the page object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether this page is set to the privacy page. */
    isPrivacyPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    pageId: Scalars['Int']['output'];
    /** The parent of the node. The parent object can be of various types */
    parent: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId: Maybe<Scalars['ID']['output']>;
    /** The password for the page object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the Page type and the page type */
    preview: Maybe<PageToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Page type and the page type */
    revisions: Maybe<PageToRevisionConnection>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** The template assigned to a node of content */
    template: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The page type */
export type PageAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The page type */
export type PageChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The page type */
export type PageCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PageToCommentConnectionWhereArgs>;
};

/** The page type */
export type PageContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The page type */
export type PageEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The page type */
export type PageEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The page type */
export type PageRevisionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PageToRevisionConnectionWhereArgs>;
};

/** The page type */
export type PageTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to page Nodes */
export type PageConnection = {
  /** A list of edges (relational context) between RootQuery and connected page Nodes */
  edges: Array<PageConnectionEdge>;
  /** A list of connected page Nodes */
  nodes: Array<Page>;
  /** Information about pagination in a connection. */
  pageInfo: PageConnectionPageInfo;
};

/** Edge between a Node and a connected page */
export type PageConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected page Node */
  node: Page;
};

/** Page Info on the connected PageConnectionEdge */
export type PageConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Connection between the Page type and the Comment type */
export type PageToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'PageToCommentConnection';
    /** Edges for the PageToCommentConnection connection */
    edges: Array<PageToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: PageToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type PageToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'PageToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;PageToCommentConnection&quot; */
export type PageToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PageToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PageToCommentConnection connection */
export type PageToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Page type and the page type */
export type PageToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PageConnectionEdge & {
    __typename?: 'PageToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Page;
  };

/** Connection between the Page type and the page type */
export type PageToRevisionConnection = Connection &
  PageConnection & {
    __typename?: 'PageToRevisionConnection';
    /** Edges for the PageToRevisionConnection connection */
    edges: Array<PageToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: PageToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type PageToRevisionConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'PageToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;PageToRevisionConnection&quot; */
export type PageToRevisionConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PageToRevisionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** A payment gateway object */
export type PaymentGateway = Node & {
  __typename?: 'PaymentGateway';
  /** gateway&#039;s description */
  description: Maybe<Scalars['String']['output']>;
  /** gateway&#039;s icon */
  icon: Maybe<Scalars['String']['output']>;
  /** gateway&#039;s title */
  id: Scalars['ID']['output'];
  /** gateway&#039;s title */
  title: Maybe<Scalars['String']['output']>;
};

/** Connection to PaymentGateway Nodes */
export type PaymentGatewayConnection = {
  /** A list of edges (relational context) between RootQuery and connected PaymentGateway Nodes */
  edges: Array<PaymentGatewayConnectionEdge>;
  /** A list of connected PaymentGateway Nodes */
  nodes: Array<PaymentGateway>;
  /** Information about pagination in a connection. */
  pageInfo: PaymentGatewayConnectionPageInfo;
};

/** Edge between a Node and a connected PaymentGateway */
export type PaymentGatewayConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected PaymentGateway Node */
  node: PaymentGateway;
};

/** Page Info on the connected PaymentGatewayConnectionEdge */
export type PaymentGatewayConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Payment token object */
export type PaymentToken = {
  /** Token payment gateway */
  gateway: Maybe<PaymentGateway>;
  /** Token ID unique identifier */
  id: Scalars['ID']['output'];
  /** Is token connected to user&#039;s preferred payment method */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** Token database ID. */
  tokenId: Scalars['Int']['output'];
  /** Token type */
  type: Scalars['String']['output'];
};

/** A credit cart payment token */
export type PaymentTokenCc = Node &
  PaymentToken & {
    __typename?: 'PaymentTokenCC';
    /** Card type (visa, mastercard, etc) */
    cardType: Maybe<Scalars['String']['output']>;
    /** Card&#039;s expiration month */
    expiryMonth: Maybe<Scalars['String']['output']>;
    /** Card&#039;s expiration year. */
    expiryYear: Maybe<Scalars['String']['output']>;
    /** Token payment gateway */
    gateway: Maybe<PaymentGateway>;
    /** Token ID unique identifier */
    id: Scalars['ID']['output'];
    /** Is token connected to user&#039;s preferred payment method */
    isDefault: Maybe<Scalars['Boolean']['output']>;
    /** Last 4 digits of the stored credit card number */
    last4: Maybe<Scalars['Int']['output']>;
    /** Token database ID. */
    tokenId: Scalars['Int']['output'];
    /** Token type */
    type: Scalars['String']['output'];
  };

/** A electronic check payment token */
export type PaymentTokenECheck = Node &
  PaymentToken & {
    __typename?: 'PaymentTokenECheck';
    /** Token payment gateway */
    gateway: Maybe<PaymentGateway>;
    /** Token ID unique identifier */
    id: Scalars['ID']['output'];
    /** Is token connected to user&#039;s preferred payment method */
    isDefault: Maybe<Scalars['Boolean']['output']>;
    /** Last 4 digits of the stored account number */
    last4: Maybe<Scalars['Int']['output']>;
    /** Token database ID. */
    tokenId: Scalars['Int']['output'];
    /** Token type */
    type: Scalars['String']['output'];
  };

/** An plugin object */
export type Plugin = Node & {
  __typename?: 'Plugin';
  /** Name of the plugin author(s), may also be a company name. */
  author: Maybe<Scalars['String']['output']>;
  /** URI for the related author(s)/company website. */
  authorUri: Maybe<Scalars['String']['output']>;
  /** Description of the plugin. */
  description: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier of the plugin object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Display name of the plugin. */
  name: Maybe<Scalars['String']['output']>;
  /** Plugin path. */
  path: Maybe<Scalars['String']['output']>;
  /** URI for the plugin website. This is useful for directing users for support requests etc. */
  pluginUri: Maybe<Scalars['String']['output']>;
  /** Current version of the plugin. */
  version: Maybe<Scalars['String']['output']>;
};

/** Connection to Plugin Nodes */
export type PluginConnection = {
  /** A list of edges (relational context) between RootQuery and connected Plugin Nodes */
  edges: Array<PluginConnectionEdge>;
  /** A list of connected Plugin Nodes */
  nodes: Array<Plugin>;
  /** Information about pagination in a connection. */
  pageInfo: PluginConnectionPageInfo;
};

/** Edge between a Node and a connected Plugin */
export type PluginConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Plugin Node */
  node: Plugin;
};

/** Page Info on the connected PluginConnectionEdge */
export type PluginConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  Active = 'ACTIVE',
  /** The plugin is a drop-in plugin. */
  DropIn = 'DROP_IN',
  /** The plugin is currently inactive. */
  Inactive = 'INACTIVE',
  /** The plugin is a must-use plugin. */
  MustUse = 'MUST_USE',
  /** The plugin is technically active but was paused while loading. */
  Paused = 'PAUSED',
  /** The plugin was active recently. */
  RecentlyActive = 'RECENTLY_ACTIVE',
  /** The plugin has an upgrade available. */
  Upgrade = 'UPGRADE'
}

/** The post type */
export type Post = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  NodeWithTrackbacks &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Post';
    /** Connection between the NodeWithAuthor type and the User type */
    author: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Post type and the category type */
    categories: Maybe<PostToCategoryConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** Connection between the Post type and the Comment type */
    comments: Maybe<PostToCommentConnection>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt: Maybe<Scalars['String']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the post object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the post object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision: Maybe<Scalars['Boolean']['output']>;
    /** Whether this page is sticky */
    isSticky: Scalars['Boolean']['output'];
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** The password for the post object. */
    password: Maybe<Scalars['String']['output']>;
    /** Whether the pings are open or closed for this particular post. */
    pingStatus: Maybe<Scalars['String']['output']>;
    /** URLs that have been pinged. */
    pinged: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Connection between the Post type and the postFormat type */
    postFormats: Maybe<PostToPostFormatConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    postId: Scalars['Int']['output'];
    /** Connection between the Post type and the post type */
    preview: Maybe<PostToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Post type and the post type */
    revisions: Maybe<PostToRevisionConnection>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** Connection between the Post type and the tag type */
    tags: Maybe<PostToTagConnection>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** Connection between the Post type and the TermNode type */
    terms: Maybe<PostToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** URLs queued to be pinged. */
    toPing: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The post type */
export type PostCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostToCategoryConnectionWhereArgs>;
};

/** The post type */
export type PostCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostToCommentConnectionWhereArgs>;
};

/** The post type */
export type PostContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The post type */
export type PostEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The post type */
export type PostExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostPostFormatsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostToPostFormatConnectionWhereArgs>;
};

/** The post type */
export type PostRevisionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostToRevisionConnectionWhereArgs>;
};

/** The post type */
export type PostTagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostToTagConnectionWhereArgs>;
};

/** The post type */
export type PostTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostToTermNodeConnectionWhereArgs>;
};

/** The post type */
export type PostTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
};

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Connection to post Nodes */
export type PostConnection = {
  /** A list of edges (relational context) between RootQuery and connected post Nodes */
  edges: Array<PostConnectionEdge>;
  /** A list of connected post Nodes */
  nodes: Array<Post>;
  /** Information about pagination in a connection. */
  pageInfo: PostConnectionPageInfo;
};

/** Edge between a Node and a connected post */
export type PostConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected post Node */
  node: Post;
};

/** Page Info on the connected PostConnectionEdge */
export type PostConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The postFormat type */
export type PostFormat = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'PostFormat';
    /** Connection between the PostFormat type and the ContentNode type */
    contentNodes: Maybe<PostFormatToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    postFormatId: Maybe<Scalars['Int']['output']>;
    /** Connection between the PostFormat type and the post type */
    posts: Maybe<PostFormatToPostConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the PostFormat type and the Taxonomy type */
    taxonomy: Maybe<PostFormatToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The postFormat type */
export type PostFormatContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostFormatToContentNodeConnectionWhereArgs>;
};

/** The postFormat type */
export type PostFormatEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The postFormat type */
export type PostFormatEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The postFormat type */
export type PostFormatPostsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<PostFormatToPostConnectionWhereArgs>;
};

/** Connection to postFormat Nodes */
export type PostFormatConnection = {
  /** A list of edges (relational context) between RootQuery and connected postFormat Nodes */
  edges: Array<PostFormatConnectionEdge>;
  /** A list of connected postFormat Nodes */
  nodes: Array<PostFormat>;
  /** Information about pagination in a connection. */
  pageInfo: PostFormatConnectionPageInfo;
};

/** Edge between a Node and a connected postFormat */
export type PostFormatConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected postFormat Node */
  node: PostFormat;
};

/** Page Info on the connected PostFormatConnectionEdge */
export type PostFormatConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the PostFormat type and the ContentNode type */
export type PostFormatToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'PostFormatToContentNodeConnection';
    /** Edges for the PostFormatToContentNodeConnection connection */
    edges: Array<PostFormatToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: PostFormatToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PostFormatToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'PostFormatToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;PostFormatToContentNodeConnection&quot; */
export type PostFormatToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostFormatToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the PostFormat type and the post type */
export type PostFormatToPostConnection = Connection &
  PostConnection & {
    __typename?: 'PostFormatToPostConnection';
    /** Edges for the PostFormatToPostConnection connection */
    edges: Array<PostFormatToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: PostFormatToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type PostFormatToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostFormatToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;PostFormatToPostConnection&quot; */
export type PostFormatToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostFormatToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the PostFormat type and the Taxonomy type */
export type PostFormatToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'PostFormatToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  Raw = 'RAW',
  /** Provide the field value as rendered by WordPress. Default. */
  Rendered = 'RENDERED'
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = 'DATE',
  /** The most recent modification date of the comment. */
  Modified = 'MODIFIED'
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  CommentCount = 'COMMENT_COUNT',
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by title */
  Title = 'TITLE'
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
};

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
};

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostPostFormatsNodeInput = {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the draft status */
  Draft = 'DRAFT',
  /** Objects with the future status */
  Future = 'FUTURE',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the pending status */
  Pending = 'PENDING',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the publish status */
  Publish = 'PUBLISH',
  /** Objects with the request-completed status */
  RequestCompleted = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  RequestConfirmed = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  RequestFailed = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  RequestPending = 'REQUEST_PENDING',
  /** Objects with the spam status */
  Spam = 'SPAM',
  /** Objects with the trash status */
  Trash = 'TRASH',
  /** Objects with the wc-cancelled status */
  WcCancelled = 'WC_CANCELLED',
  /** Objects with the wc-checkout-draft status */
  WcCheckoutDraft = 'WC_CHECKOUT_DRAFT',
  /** Objects with the wc-completed status */
  WcCompleted = 'WC_COMPLETED',
  /** Objects with the wc-failed status */
  WcFailed = 'WC_FAILED',
  /** Objects with the wc-on-hold status */
  WcOnHold = 'WC_ON_HOLD',
  /** Objects with the wc-pending status */
  WcPending = 'WC_PENDING',
  /** Objects with the wc-processing status */
  WcProcessing = 'WC_PROCESSING',
  /** Objects with the wc-refunded status */
  WcRefunded = 'WC_REFUNDED'
}

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
};

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Post type and the category type */
export type PostToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'PostToCategoryConnection';
    /** Edges for the PostToCategoryConnection connection */
    edges: Array<PostToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: PostToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'PostToCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;PostToCategoryConnection&quot; */
export type PostToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostToCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Post type and the Comment type */
export type PostToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'PostToCommentConnection';
    /** Edges for the PostToCommentConnection connection */
    edges: Array<PostToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: PostToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'PostToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;PostToCommentConnection&quot; */
export type PostToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToCommentConnection connection */
export type PostToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Post type and the postFormat type */
export type PostToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: 'PostToPostFormatConnection';
    /** Edges for the PostToPostFormatConnection connection */
    edges: Array<PostToPostFormatConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PostFormat>;
    /** Information about pagination in a connection. */
    pageInfo: PostToPostFormatConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: 'PostToPostFormatConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PostFormat;
  };

/** Page Info on the &quot;PostToPostFormatConnection&quot; */
export type PostToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToPostFormatConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Post type and the post type */
export type PostToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PostConnectionEdge & {
    __typename?: 'PostToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Post;
  };

/** Connection between the Post type and the post type */
export type PostToRevisionConnection = Connection &
  PostConnection & {
    __typename?: 'PostToRevisionConnection';
    /** Edges for the PostToRevisionConnection connection */
    edges: Array<PostToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: PostToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToRevisionConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;PostToRevisionConnection&quot; */
export type PostToRevisionConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToRevisionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Post type and the tag type */
export type PostToTagConnection = Connection &
  TagConnection & {
    __typename?: 'PostToTagConnection';
    /** Edges for the PostToTagConnection connection */
    edges: Array<PostToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: PostToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: 'PostToTagConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;PostToTagConnection&quot; */
export type PostToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToTagConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Post type and the TermNode type */
export type PostToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'PostToTermNodeConnection';
    /** Edges for the PostToTermNodeConnection connection */
    edges: Array<PostToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: PostToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'PostToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;PostToTermNodeConnection&quot; */
export type PostToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Details for labels of the PostType */
export type PostTypeLabelDetails = {
  __typename?: 'PostTypeLabelDetails';
  /** Default is ‘Add New’ for both hierarchical and non-hierarchical types. */
  addNew: Maybe<Scalars['String']['output']>;
  /** Label for adding a new singular item. */
  addNewItem: Maybe<Scalars['String']['output']>;
  /** Label to signify all items in a submenu link. */
  allItems: Maybe<Scalars['String']['output']>;
  /** Label for archives in nav menus */
  archives: Maybe<Scalars['String']['output']>;
  /** Label for the attributes meta box. */
  attributes: Maybe<Scalars['String']['output']>;
  /** Label for editing a singular item. */
  editItem: Maybe<Scalars['String']['output']>;
  /** Label for the Featured Image meta box title. */
  featuredImage: Maybe<Scalars['String']['output']>;
  /** Label for the table views hidden heading. */
  filterItemsList: Maybe<Scalars['String']['output']>;
  /** Label for the media frame button. */
  insertIntoItem: Maybe<Scalars['String']['output']>;
  /** Label for the table hidden heading. */
  itemsList: Maybe<Scalars['String']['output']>;
  /** Label for the table pagination hidden heading. */
  itemsListNavigation: Maybe<Scalars['String']['output']>;
  /** Label for the menu name. */
  menuName: Maybe<Scalars['String']['output']>;
  /** General name for the post type, usually plural. */
  name: Maybe<Scalars['String']['output']>;
  /** Label for the new item page title. */
  newItem: Maybe<Scalars['String']['output']>;
  /** Label used when no items are found. */
  notFound: Maybe<Scalars['String']['output']>;
  /** Label used when no items are in the trash. */
  notFoundInTrash: Maybe<Scalars['String']['output']>;
  /** Label used to prefix parents of hierarchical items. */
  parentItemColon: Maybe<Scalars['String']['output']>;
  /** Label for removing the featured image. */
  removeFeaturedImage: Maybe<Scalars['String']['output']>;
  /** Label for searching plural items. */
  searchItems: Maybe<Scalars['String']['output']>;
  /** Label for setting the featured image. */
  setFeaturedImage: Maybe<Scalars['String']['output']>;
  /** Name for one object of this post type. */
  singularName: Maybe<Scalars['String']['output']>;
  /** Label for the media frame filter. */
  uploadedToThisItem: Maybe<Scalars['String']['output']>;
  /** Label in the media frame for using a featured image. */
  useFeaturedImage: Maybe<Scalars['String']['output']>;
  /** Label for viewing a singular item. */
  viewItem: Maybe<Scalars['String']['output']>;
  /** Label for viewing post type archives. */
  viewItems: Maybe<Scalars['String']['output']>;
};

/** Fields to order the PostType connection by */
export enum PostTypeOrderByEnum {
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Order by name */
  Name = 'NAME',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG'
}

/** Options for ordering the connection */
export type PostTypeOrderbyInput = {
  field: PostTypeOrderByEnum;
  order: InputMaybe<OrderEnum>;
};

/** Nodes that can be seen in a preview (unpublished) state. */
export type Previewable = {
  /** Whether the object is a node in the preview state */
  isPreview: Maybe<Scalars['Boolean']['output']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId: Maybe<Scalars['ID']['output']>;
};

/** Price range */
export type PriceRange = {
  __typename?: 'PriceRange';
  /** Maximum price */
  maxPrice: Maybe<Scalars['String']['output']>;
  /** Minimum price */
  minPrice: Maybe<Scalars['String']['output']>;
};

/** Price range */
export type PriceRangeMaxPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Price range */
export type PriceRangeMinPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Pricing field format enumeration */
export enum PricingFieldFormatEnum {
  Formatted = 'FORMATTED',
  Raw = 'RAW'
}

/** The Product type */
export type Product = {
  /** Connection between the Product type and the paDefault type */
  allPaDefault: Maybe<ProductToPaDefaultConnection>;
  /** Connection between the Product type and the paPrimaryCurrency type */
  allPaPrimaryCurrency: Maybe<ProductToPaPrimaryCurrencyConnection>;
  /** Connection between the Product type and the ProductAttribute type */
  attributes: Maybe<ProductToProductAttributeConnection>;
  /** Product average count */
  averageRating: Maybe<Scalars['Float']['output']>;
  /** Catalog visibility */
  catalogVisibility: Maybe<CatalogVisibilityEnum>;
  /** The content of the post. */
  content: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** Post publishing date. */
  date: Maybe<Scalars['String']['output']>;
  /** The publishing date set in GMT. */
  dateGmt: Maybe<Scalars['String']['output']>;
  /** Date on sale from */
  dateOnSaleFrom: Maybe<Scalars['String']['output']>;
  /** Date on sale to */
  dateOnSaleTo: Maybe<Scalars['String']['output']>;
  /** Product description */
  description: Maybe<Scalars['String']['output']>;
  /** The desired slug of the post */
  desiredSlug: Maybe<Scalars['String']['output']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The excerpt of the post. */
  excerpt: Maybe<Scalars['String']['output']>;
  /** If the product is featured */
  featured: Maybe<Scalars['Boolean']['output']>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId: Maybe<Scalars['ID']['output']>;
  /** Connection between the Product type and the MediaItem type */
  galleryImages: Maybe<ProductToMediaItemConnection>;
  /** Connection between the Product type and the GlobalProductAttribute type */
  globalAttributes: Maybe<ProductToGlobalProductAttributeConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid: Maybe<Scalars['String']['output']>;
  /** Whether the product object is password protected. */
  hasPassword: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique identifier of the product object. */
  id: Scalars['ID']['output'];
  /** Main image */
  image: Maybe<MediaItem>;
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is a node in the preview state */
  isPreview: Maybe<Scalars['Boolean']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The user that most recently edited the node */
  lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link: Maybe<Scalars['String']['output']>;
  /** Connection between the Product type and the LocalProductAttribute type */
  localAttributes: Maybe<ProductToLocalProductAttributeConnection>;
  /** Menu order */
  menuOrder: Maybe<Scalars['Int']['output']>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified: Maybe<Scalars['String']['output']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt: Maybe<Scalars['String']['output']>;
  /** Product name */
  name: Maybe<Scalars['String']['output']>;
  /** Is product on sale? */
  onSale: Maybe<Scalars['Boolean']['output']>;
  /** The password for the product object. */
  password: Maybe<Scalars['String']['output']>;
  /** Connection between the Product type and the Product type */
  preview: Maybe<ProductToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId: Maybe<Scalars['ID']['output']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  productId: Scalars['Int']['output'];
  /** Connection between the Product type and the productType type */
  productTypes: Maybe<ProductToProductTypeConnection>;
  /** Can product be purchased? */
  purchasable: Maybe<Scalars['Boolean']['output']>;
  /** Purchase note */
  purchaseNote: Maybe<Scalars['String']['output']>;
  /** Connection between the Product type and the ProductUnion type */
  related: Maybe<ProductToProductUnionConnection>;
  /** Product review count */
  reviewCount: Maybe<Scalars['Int']['output']>;
  /** Connection between the Product type and the Comment type */
  reviews: Maybe<ProductToCommentConnection>;
  /** If reviews are allowed */
  reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Connection between the Product type and the shippingClass type */
  shippingClasses: Maybe<ProductToShippingClassConnection>;
  /** Product short description */
  shortDescription: Maybe<Scalars['String']['output']>;
  /** Product SKU */
  sku: Maybe<Scalars['String']['output']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug: Maybe<Scalars['String']['output']>;
  /** The current status of the object */
  status: Maybe<Scalars['String']['output']>;
  /** The template assigned to the node */
  template: Maybe<ContentTemplate>;
  /** Connection between the Product type and the TermNode type */
  terms: Maybe<ProductToTermNodeConnection>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title: Maybe<Scalars['String']['output']>;
  /** Number total of sales */
  totalSales: Maybe<Scalars['Int']['output']>;
  /** Product type */
  type: Maybe<ProductTypesEnum>;
  /** Connection between the Product type and the ProductUnion type */
  upsell: Maybe<ProductToUpsellConnection>;
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
  /** Connection between the Product type and the visibleProduct type */
  visibleProducts: Maybe<ProductToVisibleProductConnection>;
};

/** The Product type */
export type ProductAllPaDefaultArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaDefaultConnectionWhereArgs>;
};

/** The Product type */
export type ProductAllPaPrimaryCurrencyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaPrimaryCurrencyConnectionWhereArgs>;
};

/** The Product type */
export type ProductAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductAttributeConnectionWhereArgs>;
};

/** The Product type */
export type ProductContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Product type */
export type ProductDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Product type */
export type ProductEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The Product type */
export type ProductEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The Product type */
export type ProductExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Product type */
export type ProductGalleryImagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToMediaItemConnectionWhereArgs>;
};

/** The Product type */
export type ProductGlobalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The Product type */
export type ProductLocalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The Product type */
export type ProductMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** The Product type */
export type ProductPreviewArgs = {
  where: InputMaybe<ProductToPreviewConnectionWhereArgs>;
};

/** The Product type */
export type ProductProductTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductTypeConnectionWhereArgs>;
};

/** The Product type */
export type ProductRelatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductUnionConnectionWhereArgs>;
};

/** The Product type */
export type ProductReviewsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToCommentConnectionWhereArgs>;
};

/** The Product type */
export type ProductShippingClassesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToShippingClassConnectionWhereArgs>;
};

/** The Product type */
export type ProductShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Product type */
export type ProductTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToTermNodeConnectionWhereArgs>;
};

/** The Product type */
export type ProductTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Product type */
export type ProductUpsellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToUpsellConnectionWhereArgs>;
};

/** The Product type */
export type ProductVisibleProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToVisibleProductConnectionWhereArgs>;
};

/** Set relationships between the Product to allPaDefault */
export type ProductAllPaDefaultInput = {
  /** If true, this will append the paDefault to existing related allPaDefault. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<ProductAllPaDefaultNodeInput>>>;
};

/** List of allPaDefault to connect the Product to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProductAllPaDefaultNodeInput = {
  /** The description of the paDefault. This field is used to set a description of the paDefault if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the paDefault. If present, this will be used to connect to the Product. If no existing paDefault exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the paDefault. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the paDefault. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Set relationships between the Product to allPaPrimaryCurrency */
export type ProductAllPaPrimaryCurrencyInput = {
  /** If true, this will append the paPrimaryCurrency to existing related allPaPrimaryCurrency. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<ProductAllPaPrimaryCurrencyNodeInput>>>;
};

/** List of allPaPrimaryCurrency to connect the Product to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProductAllPaPrimaryCurrencyNodeInput = {
  /** The description of the paPrimaryCurrency. This field is used to set a description of the paPrimaryCurrency if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the paPrimaryCurrency. If present, this will be used to connect to the Product. If no existing paPrimaryCurrency exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the paPrimaryCurrency. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the paPrimaryCurrency. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Product attribute object */
export type ProductAttribute = {
  /** Attribute ID */
  attributeId: Scalars['Int']['output'];
  /** Attribute Global ID */
  id: Scalars['ID']['output'];
  /** Attribute label */
  label: Maybe<Scalars['String']['output']>;
  /** Attribute name */
  name: Maybe<Scalars['String']['output']>;
  /** Attribute options */
  options: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Attribute position */
  position: Maybe<Scalars['Int']['output']>;
  /** Product attribute scope. */
  scope: ProductAttributeTypesEnum;
  /** Is attribute on product variation */
  variation: Maybe<Scalars['Boolean']['output']>;
  /** Is attribute visible */
  visible: Maybe<Scalars['Boolean']['output']>;
};

/** Connection to ProductAttribute Nodes */
export type ProductAttributeConnection = {
  /** A list of edges (relational context) between Product and connected ProductAttribute Nodes */
  edges: Array<ProductAttributeConnectionEdge>;
  /** A list of connected ProductAttribute Nodes */
  nodes: Array<ProductAttribute>;
  /** Information about pagination in a connection. */
  pageInfo: ProductAttributeConnectionPageInfo;
};

/** Edge between a Node and a connected ProductAttribute */
export type ProductAttributeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ProductAttribute Node */
  node: ProductAttribute;
};

/** Page Info on the connected ProductAttributeConnectionEdge */
export type ProductAttributeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Product attribute taxonomies */
export enum ProductAttributeEnum {
  PaDefault = 'PA_DEFAULT',
  PaPrimaryCurrency = 'PA_PRIMARY_CURRENCY'
}

/** Product filter */
export type ProductAttributeFilterInput = {
  /** A list of term ids */
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Filter operation type */
  operator: InputMaybe<AttributeOperatorEnum>;
  /** Which field to select taxonomy term by. */
  taxonomy: ProductAttributeEnum;
  /** A list of term slugs */
  terms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Options for ordering the connection */
export type ProductAttributeInput = {
  attributeName: Scalars['String']['input'];
  attributeValue: InputMaybe<Scalars['String']['input']>;
};

/** A simple product attribute object */
export type ProductAttributeOutput = {
  __typename?: 'ProductAttributeOutput';
  /** Attribute name. */
  attributeName: Maybe<Scalars['String']['output']>;
  /** Attribute value. */
  attributeValue: Maybe<Scalars['String']['output']>;
};

/** Product attribute type enumeration */
export enum ProductAttributeTypesEnum {
  /** A global product attribute */
  Global = 'GLOBAL',
  /** A local product attribute */
  Local = 'LOCAL'
}

/** Product bonding curve */
export type ProductBondingCurve = Node & {
  __typename?: 'ProductBondingCurve';
  /** Active Discount Curve */
  active: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Maximum Discount (%) */
  maxDiscount: Maybe<Scalars['Int']['output']>;
  /** Minimum Discount (%) */
  minDiscount: Maybe<Scalars['Int']['output']>;
  /** Discount amount, bonding curve */
  percentage: Maybe<Scalars['Float']['output']>;
  /** Apply to quantity */
  quantityLimit: Maybe<Scalars['Int']['output']>;
  /** Discount pool remaining */
  remainingDiscountPool: Maybe<Scalars['Float']['output']>;
};

/** The productCategory type */
export type ProductCategory = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ProductCategory';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors: Maybe<ProductCategoryToAncestorsProductCategoryConnection>;
    /** Connection between the productCategory type and its children productCategories. */
    children: Maybe<ProductCategoryToProductCategoryConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Product category display type */
    display: Maybe<ProductCategoryDisplay>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Product category image */
    image: Maybe<MediaItem>;
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** Product category menu order */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /** Connection between the productCategory type and its parent productCategory. */
    parent: Maybe<ProductCategoryToParentProductCategoryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId: Maybe<Scalars['ID']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    productCategoryId: Maybe<Scalars['Int']['output']>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductCategory type and the Taxonomy type */
    taxonomy: Maybe<ProductCategoryToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The productCategory type */
export type ProductCategoryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The productCategory type */
export type ProductCategoryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductCategoryToProductCategoryConnectionWhereArgs>;
};

/** The productCategory type */
export type ProductCategoryEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The productCategory type */
export type ProductCategoryEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to productCategory Nodes */
export type ProductCategoryConnection = {
  /** A list of edges (relational context) between RootQuery and connected productCategory Nodes */
  edges: Array<ProductCategoryConnectionEdge>;
  /** A list of connected productCategory Nodes */
  nodes: Array<ProductCategory>;
  /** Information about pagination in a connection. */
  pageInfo: ProductCategoryConnectionPageInfo;
};

/** Edge between a Node and a connected productCategory */
export type ProductCategoryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected productCategory Node */
  node: ProductCategory;
};

/** Page Info on the connected ProductCategoryConnectionEdge */
export type ProductCategoryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Product category display type enumeration */
export enum ProductCategoryDisplay {
  /** Display both products and subcategories of this category. */
  Both = 'BOTH',
  /** Display default content connected to this category. */
  Default = 'DEFAULT',
  /** Display products associated with this category. */
  Products = 'PRODUCTS',
  /** Display subcategories of this category. */
  Subcategories = 'SUBCATEGORIES'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProductCategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the ProductCategory type and the productCategory type */
export type ProductCategoryToAncestorsProductCategoryConnection = Connection &
  ProductCategoryConnection & {
    __typename?: 'ProductCategoryToAncestorsProductCategoryConnection';
    /** Edges for the ProductCategoryToAncestorsProductCategoryConnection connection */
    edges: Array<ProductCategoryToAncestorsProductCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductCategory>;
    /** Information about pagination in a connection. */
    pageInfo: ProductCategoryToAncestorsProductCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductCategoryToAncestorsProductCategoryConnectionEdge = Edge &
  ProductCategoryConnectionEdge & {
    __typename?: 'ProductCategoryToAncestorsProductCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductCategory;
  };

/** Page Info on the &quot;ProductCategoryToAncestorsProductCategoryConnection&quot; */
export type ProductCategoryToAncestorsProductCategoryConnectionPageInfo = PageInfo &
  ProductCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductCategoryToAncestorsProductCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the ProductCategory type and the productCategory type */
export type ProductCategoryToParentProductCategoryConnectionEdge = Edge &
  OneToOneConnection &
  ProductCategoryConnectionEdge & {
    __typename?: 'ProductCategoryToParentProductCategoryConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ProductCategory;
  };

/** Connection between the ProductCategory type and the productCategory type */
export type ProductCategoryToProductCategoryConnection = Connection &
  ProductCategoryConnection & {
    __typename?: 'ProductCategoryToProductCategoryConnection';
    /** Edges for the ProductCategoryToProductCategoryConnection connection */
    edges: Array<ProductCategoryToProductCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductCategory>;
    /** Information about pagination in a connection. */
    pageInfo: ProductCategoryToProductCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductCategoryToProductCategoryConnectionEdge = Edge &
  ProductCategoryConnectionEdge & {
    __typename?: 'ProductCategoryToProductCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductCategory;
  };

/** Page Info on the &quot;ProductCategoryToProductCategoryConnection&quot; */
export type ProductCategoryToProductCategoryConnectionPageInfo = PageInfo &
  ProductCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductCategoryToProductCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductCategoryToProductCategoryConnection connection */
export type ProductCategoryToProductCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the ProductCategory type and the Taxonomy type */
export type ProductCategoryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'ProductCategoryToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Connection to Product Nodes */
export type ProductConnection = {
  /** A list of edges (relational context) between ProductType and connected Product Nodes */
  edges: Array<ProductConnectionEdge>;
  /** A list of connected Product Nodes */
  nodes: Array<Product>;
  /** Information about pagination in a connection. */
  pageInfo: ProductConnectionPageInfo;
};

/** Edge between a Node and a connected Product */
export type ProductConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Product Node */
  node: Product;
};

/** Page Info on the connected ProductConnectionEdge */
export type ProductConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Product discount curve */
export type ProductDiscountCurve = Node & {
  __typename?: 'ProductDiscountCurve';
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Name of Discount Curve */
  name: Maybe<Scalars['String']['output']>;
  /** Discount amount, discount curve */
  percentage: Maybe<Scalars['Float']['output']>;
  /** Discount pool remaining */
  remainingDiscountPool: Maybe<Scalars['Float']['output']>;
};

/** A product object */
export type ProductDownload = {
  __typename?: 'ProductDownload';
  /** Is file allowed */
  allowedFileType: Maybe<Scalars['Boolean']['output']>;
  /** Product download ID */
  downloadId: Scalars['String']['output'];
  /** Download file */
  file: Maybe<Scalars['String']['output']>;
  /** Validate file exists */
  fileExists: Maybe<Scalars['Boolean']['output']>;
  /** File extension */
  fileExt: Maybe<Scalars['String']['output']>;
  /** Type of file path set */
  filePathType: Maybe<Scalars['String']['output']>;
  /** File type */
  fileType: Maybe<Scalars['String']['output']>;
  /** Product download name */
  name: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single Product. Default is ID. */
export enum ProductIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Unique store identifier for product. */
  Sku = 'SKU',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG'
}

/** Multi-currency pricing */
export type ProductPricingAlternateCurrencies = Node & {
  __typename?: 'ProductPricingAlternateCurrencies';
  /** Currency code */
  currency: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Price in the selected currency */
  price: Maybe<Scalars['String']['output']>;
  /** Product&#039;s regular price */
  regularPrice: Maybe<Scalars['String']['output']>;
  /** Product&#039;s sale price */
  salePrice: Maybe<Scalars['String']['output']>;
};

/** Set relationships between the Product to productTypes */
export type ProductProductTypesInput = {
  /** If true, this will append the productType to existing related productTypes. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<ProductProductTypesNodeInput>>>;
};

/** List of productTypes to connect the Product to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProductProductTypesNodeInput = {
  /** The description of the productType. This field is used to set a description of the productType if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productType. If present, this will be used to connect to the Product. If no existing productType exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the productType. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the productType. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Set relationships between the Product to shippingClasses */
export type ProductShippingClassesInput = {
  /** If true, this will append the shippingClass to existing related shippingClasses. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<ProductShippingClassesNodeInput>>>;
};

/** List of shippingClasses to connect the Product to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProductShippingClassesNodeInput = {
  /** The description of the shippingClass. This field is used to set a description of the shippingClass if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the shippingClass. If present, this will be used to connect to the Product. If no existing shippingClass exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the shippingClass. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the shippingClass. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The productTag type */
export type ProductTag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ProductTag';
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    productTagId: Maybe<Scalars['Int']['output']>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductTag type and the Taxonomy type */
    taxonomy: Maybe<ProductTagToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The productTag type */
export type ProductTagEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The productTag type */
export type ProductTagEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to productTag Nodes */
export type ProductTagConnection = {
  /** A list of edges (relational context) between RootQuery and connected productTag Nodes */
  edges: Array<ProductTagConnectionEdge>;
  /** A list of connected productTag Nodes */
  nodes: Array<ProductTag>;
  /** Information about pagination in a connection. */
  pageInfo: ProductTagConnectionPageInfo;
};

/** Edge between a Node and a connected productTag */
export type ProductTagConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected productTag Node */
  node: ProductTag;
};

/** Page Info on the connected ProductTagConnectionEdge */
export type ProductTagConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProductTagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the ProductTag type and the Taxonomy type */
export type ProductTagToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'ProductTagToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Product taxonomies */
export enum ProductTaxonomyEnum {
  PaDefault = 'PA_DEFAULT',
  PaPrimaryCurrency = 'PA_PRIMARY_CURRENCY',
  ProductShippingClass = 'PRODUCT_SHIPPING_CLASS',
  ProductType = 'PRODUCT_TYPE',
  ProductVisibility = 'PRODUCT_VISIBILITY'
}

/** Product filter */
export type ProductTaxonomyFilterInput = {
  /** A list of term ids */
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Filter operation type */
  operator: InputMaybe<TaxonomyOperatorEnum>;
  /** Which field to select taxonomy term by. */
  taxonomy: ProductTaxonomyEnum;
  /** A list of term slugs */
  terms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Product taxonomy filter type */
export type ProductTaxonomyInput = {
  /** Product taxonomy rules to be filter results by */
  filters: InputMaybe<Array<InputMaybe<ProductTaxonomyFilterInput>>>;
  /** Logic relation between each filter. */
  relation: InputMaybe<RelationEnum>;
};

/** Connection between the Product type and the Comment type */
export type ProductToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'ProductToCommentConnection';
    /** Average review rating for this product. */
    averageRating: Maybe<Scalars['Float']['output']>;
    /** Edges for the ProductToCommentConnection connection */
    edges: Array<ProductToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'ProductToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
    /** Review rating */
    rating: Maybe<Scalars['Float']['output']>;
  };

/** Page Info on the &quot;ProductToCommentConnection&quot; */
export type ProductToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ProductToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToCommentConnection connection */
export type ProductToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Product type and the GlobalProductAttribute type */
export type ProductToGlobalProductAttributeConnection = Connection &
  GlobalProductAttributeConnection & {
    __typename?: 'ProductToGlobalProductAttributeConnection';
    /** Edges for the ProductToGlobalProductAttributeConnection connection */
    edges: Array<ProductToGlobalProductAttributeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GlobalProductAttribute>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToGlobalProductAttributeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToGlobalProductAttributeConnectionEdge = Edge &
  GlobalProductAttributeConnectionEdge & {
    __typename?: 'ProductToGlobalProductAttributeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GlobalProductAttribute;
  };

/** Page Info on the &quot;ProductToGlobalProductAttributeConnection&quot; */
export type ProductToGlobalProductAttributeConnectionPageInfo =
  GlobalProductAttributeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ProductToGlobalProductAttributeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Connection between the Product type and the LocalProductAttribute type */
export type ProductToLocalProductAttributeConnection = Connection &
  LocalProductAttributeConnection & {
    __typename?: 'ProductToLocalProductAttributeConnection';
    /** Edges for the ProductToLocalProductAttributeConnection connection */
    edges: Array<ProductToLocalProductAttributeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<LocalProductAttribute>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToLocalProductAttributeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToLocalProductAttributeConnectionEdge = Edge &
  LocalProductAttributeConnectionEdge & {
    __typename?: 'ProductToLocalProductAttributeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: LocalProductAttribute;
  };

/** Page Info on the &quot;ProductToLocalProductAttributeConnection&quot; */
export type ProductToLocalProductAttributeConnectionPageInfo =
  LocalProductAttributeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ProductToLocalProductAttributeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Connection between the Product type and the MediaItem type */
export type ProductToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'ProductToMediaItemConnection';
    /** Edges for the ProductToMediaItemConnection connection */
    edges: Array<ProductToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'ProductToMediaItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;ProductToMediaItemConnection&quot; */
export type ProductToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ProductToMediaItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToMediaItemConnection connection */
export type ProductToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Product type and the paDefault type */
export type ProductToPaDefaultConnection = Connection &
  PaDefaultConnection & {
    __typename?: 'ProductToPaDefaultConnection';
    /** Edges for the ProductToPaDefaultConnection connection */
    edges: Array<ProductToPaDefaultConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PaDefault>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToPaDefaultConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToPaDefaultConnectionEdge = Edge &
  PaDefaultConnectionEdge & {
    __typename?: 'ProductToPaDefaultConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PaDefault;
  };

/** Page Info on the &quot;ProductToPaDefaultConnection&quot; */
export type ProductToPaDefaultConnectionPageInfo = PaDefaultConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ProductToPaDefaultConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToPaDefaultConnection connection */
export type ProductToPaDefaultConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Product type and the paPrimaryCurrency type */
export type ProductToPaPrimaryCurrencyConnection = Connection &
  PaPrimaryCurrencyConnection & {
    __typename?: 'ProductToPaPrimaryCurrencyConnection';
    /** Edges for the ProductToPaPrimaryCurrencyConnection connection */
    edges: Array<ProductToPaPrimaryCurrencyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PaPrimaryCurrency>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToPaPrimaryCurrencyConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToPaPrimaryCurrencyConnectionEdge = Edge &
  PaPrimaryCurrencyConnectionEdge & {
    __typename?: 'ProductToPaPrimaryCurrencyConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PaPrimaryCurrency;
  };

/** Page Info on the &quot;ProductToPaPrimaryCurrencyConnection&quot; */
export type ProductToPaPrimaryCurrencyConnectionPageInfo = PaPrimaryCurrencyConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ProductToPaPrimaryCurrencyConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToPaPrimaryCurrencyConnection connection */
export type ProductToPaPrimaryCurrencyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Product type and the Product type */
export type ProductToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  ProductConnectionEdge & {
    __typename?: 'ProductToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Product;
  };

/** Arguments for filtering the ProductToPreviewConnection connection */
export type ProductToPreviewConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the Product type and the ProductAttribute type */
export type ProductToProductAttributeConnection = Connection &
  ProductAttributeConnection & {
    __typename?: 'ProductToProductAttributeConnection';
    /** Edges for the ProductToProductAttributeConnection connection */
    edges: Array<ProductToProductAttributeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductAttribute>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToProductAttributeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToProductAttributeConnectionEdge = Edge &
  ProductAttributeConnectionEdge & {
    __typename?: 'ProductToProductAttributeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductAttribute;
  };

/** Page Info on the &quot;ProductToProductAttributeConnection&quot; */
export type ProductToProductAttributeConnectionPageInfo = PageInfo &
  ProductAttributeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToProductAttributeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToProductAttributeConnection connection */
export type ProductToProductAttributeConnectionWhereArgs = {
  /** Filter results by attribute scope. */
  type: InputMaybe<ProductAttributeTypesEnum>;
};

/** Connection between the Product type and the productType type */
export type ProductToProductTypeConnection = Connection &
  ProductTypeConnection & {
    __typename?: 'ProductToProductTypeConnection';
    /** Edges for the ProductToProductTypeConnection connection */
    edges: Array<ProductToProductTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductType>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToProductTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToProductTypeConnectionEdge = Edge &
  ProductTypeConnectionEdge & {
    __typename?: 'ProductToProductTypeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductType;
  };

/** Page Info on the &quot;ProductToProductTypeConnection&quot; */
export type ProductToProductTypeConnectionPageInfo = PageInfo &
  ProductTypeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToProductTypeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToProductTypeConnection connection */
export type ProductToProductTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Product type and the ProductUnion type */
export type ProductToProductUnionConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'ProductToProductUnionConnection';
    /** Edges for the ProductToProductUnionConnection connection */
    edges: Array<ProductToProductUnionConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToProductUnionConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToProductUnionConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'ProductToProductUnionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;ProductToProductUnionConnection&quot; */
export type ProductToProductUnionConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToProductUnionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToProductUnionConnection connection */
export type ProductToProductUnionConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Shuffle results? (Pagination currently not support by this argument) */
  shuffle: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the Product type and the shippingClass type */
export type ProductToShippingClassConnection = Connection &
  ShippingClassConnection & {
    __typename?: 'ProductToShippingClassConnection';
    /** Edges for the ProductToShippingClassConnection connection */
    edges: Array<ProductToShippingClassConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ShippingClass>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToShippingClassConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToShippingClassConnectionEdge = Edge &
  ShippingClassConnectionEdge & {
    __typename?: 'ProductToShippingClassConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ShippingClass;
  };

/** Page Info on the &quot;ProductToShippingClassConnection&quot; */
export type ProductToShippingClassConnectionPageInfo = PageInfo &
  ShippingClassConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToShippingClassConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToShippingClassConnection connection */
export type ProductToShippingClassConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Product type and the TermNode type */
export type ProductToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'ProductToTermNodeConnection';
    /** Edges for the ProductToTermNodeConnection connection */
    edges: Array<ProductToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'ProductToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;ProductToTermNodeConnection&quot; */
export type ProductToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToTermNodeConnection connection */
export type ProductToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Product type and the ProductUnion type */
export type ProductToUpsellConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'ProductToUpsellConnection';
    /** Edges for the ProductToUpsellConnection connection */
    edges: Array<ProductToUpsellConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToUpsellConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToUpsellConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'ProductToUpsellConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;ProductToUpsellConnection&quot; */
export type ProductToUpsellConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToUpsellConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToUpsellConnection connection */
export type ProductToUpsellConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the Product type and the visibleProduct type */
export type ProductToVisibleProductConnection = Connection &
  VisibleProductConnection & {
    __typename?: 'ProductToVisibleProductConnection';
    /** Edges for the ProductToVisibleProductConnection connection */
    edges: Array<ProductToVisibleProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<VisibleProduct>;
    /** Information about pagination in a connection. */
    pageInfo: ProductToVisibleProductConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductToVisibleProductConnectionEdge = Edge &
  VisibleProductConnectionEdge & {
    __typename?: 'ProductToVisibleProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: VisibleProduct;
  };

/** Page Info on the &quot;ProductToVisibleProductConnection&quot; */
export type ProductToVisibleProductConnectionPageInfo = PageInfo &
  VisibleProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductToVisibleProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductToVisibleProductConnection connection */
export type ProductToVisibleProductConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** The productType type */
export type ProductType = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ProductType';
    /** Connection between the ProductType type and the ContentNode type */
    contentNodes: Maybe<ProductTypeToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    productTypeId: Maybe<Scalars['Int']['output']>;
    /** Connection between the ProductType type and the Product type */
    products: Maybe<ProductTypeToProductConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductType type and the Taxonomy type */
    taxonomy: Maybe<ProductTypeToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The productType type */
export type ProductTypeContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductTypeToContentNodeConnectionWhereArgs>;
};

/** The productType type */
export type ProductTypeEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The productType type */
export type ProductTypeEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The productType type */
export type ProductTypeProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductTypeToProductConnectionWhereArgs>;
};

/** Connection to productType Nodes */
export type ProductTypeConnection = {
  /** A list of edges (relational context) between RootQuery and connected productType Nodes */
  edges: Array<ProductTypeConnectionEdge>;
  /** A list of connected productType Nodes */
  nodes: Array<ProductType>;
  /** Information about pagination in a connection. */
  pageInfo: ProductTypeConnectionPageInfo;
};

/** Edge between a Node and a connected productType */
export type ProductTypeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected productType Node */
  node: ProductType;
};

/** Page Info on the connected ProductTypeConnectionEdge */
export type ProductTypeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProductTypeIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the ProductType type and the ContentNode type */
export type ProductTypeToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'ProductTypeToContentNodeConnection';
    /** Edges for the ProductTypeToContentNodeConnection connection */
    edges: Array<ProductTypeToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ProductTypeToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductTypeToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'ProductTypeToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ProductTypeToContentNodeConnection&quot; */
export type ProductTypeToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ProductTypeToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductTypeToContentNodeConnection connection */
export type ProductTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfProductTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the ProductType type and the Product type */
export type ProductTypeToProductConnection = Connection &
  ProductConnection & {
    __typename?: 'ProductTypeToProductConnection';
    /** Edges for the ProductTypeToProductConnection connection */
    edges: Array<ProductTypeToProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Product>;
    /** Information about pagination in a connection. */
    pageInfo: ProductTypeToProductConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductTypeToProductConnectionEdge = Edge &
  ProductConnectionEdge & {
    __typename?: 'ProductTypeToProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Product;
  };

/** Page Info on the &quot;ProductTypeToProductConnection&quot; */
export type ProductTypeToProductConnectionPageInfo = PageInfo &
  ProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductTypeToProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductTypeToProductConnection connection */
export type ProductTypeToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the ProductType type and the Taxonomy type */
export type ProductTypeToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'ProductTypeToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Product type enumeration */
export enum ProductTypesEnum {
  /** An external product */
  External = 'EXTERNAL',
  /** A product group */
  Grouped = 'GROUPED',
  /** A simple product */
  Simple = 'SIMPLE',
  /** A variable product */
  Variable = 'VARIABLE',
  /** A product variation */
  Variation = 'VARIATION'
}

/** Union between the product and product variation types */
export type ProductUnion = {
  /** Product average count */
  averageRating: Maybe<Scalars['Float']['output']>;
  /** Catalog visibility */
  catalogVisibility: Maybe<CatalogVisibilityEnum>;
  /** NFT collection type */
  collectionType: Maybe<Scalars['String']['output']>;
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Date on sale from */
  dateOnSaleFrom: Maybe<Scalars['String']['output']>;
  /** Date on sale to */
  dateOnSaleTo: Maybe<Scalars['String']['output']>;
  /** Product description */
  description: Maybe<Scalars['String']['output']>;
  /** If the product is featured */
  featured: Maybe<Scalars['Boolean']['output']>;
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Main image */
  image: Maybe<MediaItem>;
  /** Check if user is allowlist to see the product */
  isAllowlisted: Maybe<Scalars['Boolean']['output']>;
  /** Menu order */
  menuOrder: Maybe<Scalars['Int']['output']>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Product name */
  name: Maybe<Scalars['String']['output']>;
  /** Is product on sale? */
  onSale: Maybe<Scalars['Boolean']['output']>;
  /** Pricing group with group discounts */
  pricing: Maybe<ProductWithGroupDiscount>;
  /** Can product be purchased? */
  purchasable: Maybe<Scalars['Boolean']['output']>;
  /** Purchase note */
  purchaseNote: Maybe<Scalars['String']['output']>;
  /** Product review count */
  reviewCount: Maybe<Scalars['Int']['output']>;
  /** If reviews are allowed */
  reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Product short description */
  shortDescription: Maybe<Scalars['String']['output']>;
  /** Product SKU */
  sku: Maybe<Scalars['String']['output']>;
  /** Product slug */
  slug: Maybe<Scalars['String']['output']>;
  /** Number total of sales */
  totalSales: Maybe<Scalars['Int']['output']>;
  /** Product type */
  type: Maybe<ProductTypesEnum>;
};

/** Union between the product and product variation types */
export type ProductUnionDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Union between the product and product variation types */
export type ProductUnionIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** Union between the product and product variation types */
export type ProductUnionMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** Union between the product and product variation types */
export type ProductUnionPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** Union between the product and product variation types */
export type ProductUnionShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to ProductUnion Nodes */
export type ProductUnionConnection = {
  /** A list of edges (relational context) between RootQuery and connected ProductUnion Nodes */
  edges: Array<ProductUnionConnectionEdge>;
  /** A list of connected ProductUnion Nodes */
  nodes: Array<ProductUnion>;
  /** Information about pagination in a connection. */
  pageInfo: ProductUnionConnectionPageInfo;
};

/** Edge between a Node and a connected ProductUnion */
export type ProductUnionConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ProductUnion Node */
  node: ProductUnion;
};

/** Page Info on the connected ProductUnionConnectionEdge */
export type ProductUnionConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** A product variation. */
export type ProductVariation = {
  /** Connection between the ProductVariation type and the VariationAttribute type */
  attributes: Maybe<ProductVariationToVariationAttributeConnection>;
  /** Product average count */
  averageRating: Maybe<Scalars['Float']['output']>;
  /** Product variation backorders */
  backorders: Maybe<BackordersEnum>;
  /** Can product be backordered? */
  backordersAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Product variation catalog visibility */
  catalogVisibility: Maybe<CatalogVisibilityEnum>;
  /** NFT collection type */
  collectionType: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output'];
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Date variation created */
  date: Maybe<Scalars['String']['output']>;
  /** The publishing date set in GMT. */
  dateGmt: Maybe<Scalars['String']['output']>;
  /** Date on sale from */
  dateOnSaleFrom: Maybe<Scalars['String']['output']>;
  /** Date on sale to */
  dateOnSaleTo: Maybe<Scalars['String']['output']>;
  /** Product description */
  description: Maybe<Scalars['String']['output']>;
  /** The desired slug of the post */
  desiredSlug: Maybe<Scalars['String']['output']>;
  /** Download expiry */
  downloadExpiry: Maybe<Scalars['Int']['output']>;
  /** Download limit */
  downloadLimit: Maybe<Scalars['Int']['output']>;
  /** Is downloadable? */
  downloadable: Maybe<Scalars['Boolean']['output']>;
  /** Product downloads */
  downloads: Maybe<Array<Maybe<ProductDownload>>>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** If the product is featured */
  featured: Maybe<Scalars['Boolean']['output']>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId: Maybe<Scalars['ID']['output']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid: Maybe<Scalars['String']['output']>;
  /** Does product variation have any visible attributes */
  hasAttributes: Maybe<Scalars['Boolean']['output']>;
  /** Product variation height */
  height: Maybe<Scalars['String']['output']>;
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Product variation main image */
  image: Maybe<MediaItem>;
  /** Check if user is allowlist to see the product */
  isAllowlisted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is a node in the preview state */
  isPreview: Maybe<Scalars['Boolean']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The user that most recently edited the node */
  lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** Product variation length */
  length: Maybe<Scalars['String']['output']>;
  /** The permalink of the post */
  link: Maybe<Scalars['String']['output']>;
  /** Low stock amount */
  lowStockAmount: Maybe<Scalars['Int']['output']>;
  /** if/how product variation stock is managed */
  manageStock: Maybe<ManageStockEnum>;
  /** Menu order */
  menuOrder: Maybe<Scalars['Int']['output']>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Date variation last updated */
  modified: Maybe<Scalars['String']['output']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt: Maybe<Scalars['String']['output']>;
  /** Product name */
  name: Maybe<Scalars['String']['output']>;
  /** Is variation on sale? */
  onSale: Maybe<Scalars['Boolean']['output']>;
  /** The parent of the node. The parent object can be of various types */
  parent: Maybe<ProductVariationToVariableProductConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId: Maybe<Scalars['ID']['output']>;
  /** Product variation&#039;s active price */
  price: Maybe<Scalars['String']['output']>;
  /** Pricing group with group discounts */
  pricing: Maybe<ProductWithGroupDiscount>;
  /** If product variation can be bought */
  purchasable: Maybe<Scalars['Boolean']['output']>;
  /** Product variation purchase_note */
  purchaseNote: Maybe<Scalars['String']['output']>;
  /** Product variation&#039;s regular price */
  regularPrice: Maybe<Scalars['String']['output']>;
  /** Product review count */
  reviewCount: Maybe<Scalars['Int']['output']>;
  /** If reviews are allowed */
  reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Product variation&#039;s sale price */
  salePrice: Maybe<Scalars['String']['output']>;
  /** Product variation shipping class */
  shippingClass: Maybe<Scalars['String']['output']>;
  /** shipping class ID */
  shippingClassId: Maybe<Scalars['Int']['output']>;
  /** Does product need to be shipped? */
  shippingRequired: Maybe<Scalars['Boolean']['output']>;
  /** Is product shipping taxable? */
  shippingTaxable: Maybe<Scalars['Boolean']['output']>;
  /** Product short description */
  shortDescription: Maybe<Scalars['String']['output']>;
  /** Product variation SKU (Stock-keeping unit) */
  sku: Maybe<Scalars['String']['output']>;
  /** Product slug */
  slug: Maybe<Scalars['String']['output']>;
  /** If should be sold individually */
  soldIndividually: Maybe<Scalars['Boolean']['output']>;
  /** Variation status */
  status: Maybe<Scalars['String']['output']>;
  /** Product variation stock quantity */
  stockQuantity: Maybe<Scalars['Int']['output']>;
  /** Product stock status */
  stockStatus: Maybe<StockStatusEnum>;
  /** Product variation tax class */
  taxClass: Maybe<TaxClassEnum>;
  /** Tax status */
  taxStatus: Maybe<TaxStatusEnum>;
  /** The template assigned to a node of content */
  template: Maybe<ContentTemplate>;
  /** Number total of sales */
  totalSales: Maybe<Scalars['Int']['output']>;
  /** Product type */
  type: Maybe<ProductTypesEnum>;
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
  /** Is product virtual? */
  virtual: Maybe<Scalars['Boolean']['output']>;
  /** Product variation weight */
  weight: Maybe<Scalars['String']['output']>;
  /** Product variation width */
  width: Maybe<Scalars['String']['output']>;
};

/** A product variation. */
export type ProductVariationAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product variation. */
export type ProductVariationDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A product variation. */
export type ProductVariationEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product variation. */
export type ProductVariationEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product variation. */
export type ProductVariationIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A product variation. */
export type ProductVariationMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A product variation. */
export type ProductVariationPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product variation. */
export type ProductVariationPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A product variation. */
export type ProductVariationRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product variation. */
export type ProductVariationSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product variation. */
export type ProductVariationShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to ProductVariation Nodes */
export type ProductVariationConnection = {
  /** A list of edges (relational context) between PaDefault and connected ProductVariation Nodes */
  edges: Array<ProductVariationConnectionEdge>;
  /** A list of connected ProductVariation Nodes */
  nodes: Array<ProductVariation>;
  /** Information about pagination in a connection. */
  pageInfo: ProductVariationConnectionPageInfo;
};

/** Edge between a Node and a connected ProductVariation */
export type ProductVariationConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ProductVariation Node */
  node: ProductVariation;
};

/** Page Info on the connected ProductVariationConnectionEdge */
export type ProductVariationConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single ProductVariation. Default is ID. */
export enum ProductVariationIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Connection between the ProductVariation type and the VariableProduct type */
export type ProductVariationToVariableProductConnectionEdge = Edge &
  OneToOneConnection &
  VariableProductConnectionEdge & {
    __typename?: 'ProductVariationToVariableProductConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: VariableProduct;
  };

/** Connection between the ProductVariation type and the VariationAttribute type */
export type ProductVariationToVariationAttributeConnection = Connection &
  VariationAttributeConnection & {
    __typename?: 'ProductVariationToVariationAttributeConnection';
    /** Edges for the ProductVariationToVariationAttributeConnection connection */
    edges: Array<ProductVariationToVariationAttributeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<VariationAttribute>;
    /** Information about pagination in a connection. */
    pageInfo: ProductVariationToVariationAttributeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductVariationToVariationAttributeConnectionEdge = Edge &
  VariationAttributeConnectionEdge & {
    __typename?: 'ProductVariationToVariationAttributeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: VariationAttribute;
  };

/** Page Info on the &quot;ProductVariationToVariationAttributeConnection&quot; */
export type ProductVariationToVariationAttributeConnectionPageInfo = PageInfo &
  VariationAttributeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductVariationToVariationAttributeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Set relationships between the Product to visibleProducts */
export type ProductVisibleProductsInput = {
  /** If true, this will append the visibleProduct to existing related visibleProducts. If false, this will replace existing relationships. Default true. */
  append: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes: InputMaybe<Array<InputMaybe<ProductVisibleProductsNodeInput>>>;
};

/** List of visibleProducts to connect the Product to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProductVisibleProductsNodeInput = {
  /** The description of the visibleProduct. This field is used to set a description of the visibleProduct if a new one is created during the mutation. */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the visibleProduct. If present, this will be used to connect to the Product. If no existing visibleProduct exists with this ID, no connection will be made. */
  id: InputMaybe<Scalars['ID']['input']>;
  /** The name of the visibleProduct. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** The slug of the visibleProduct. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Products with default attributes. */
export type ProductWithAttributes = {
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Connection between the ProductWithAttributes type and the VariationAttribute type */
  defaultAttributes: Maybe<ProductWithAttributesToVariationAttributeConnection>;
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
};

/** Products with default attributes. */
export type ProductWithAttributesDefaultAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection between the ProductWithAttributes type and the VariationAttribute type */
export type ProductWithAttributesToVariationAttributeConnection = Connection &
  VariationAttributeConnection & {
    __typename?: 'ProductWithAttributesToVariationAttributeConnection';
    /** Edges for the ProductWithAttributesToVariationAttributeConnection connection */
    edges: Array<ProductWithAttributesToVariationAttributeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<VariationAttribute>;
    /** Information about pagination in a connection. */
    pageInfo: ProductWithAttributesToVariationAttributeConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductWithAttributesToVariationAttributeConnectionEdge = Edge &
  VariationAttributeConnectionEdge & {
    __typename?: 'ProductWithAttributesToVariationAttributeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: VariationAttribute;
  };

/** Page Info on the &quot;ProductWithAttributesToVariationAttributeConnection&quot; */
export type ProductWithAttributesToVariationAttributeConnectionPageInfo = PageInfo &
  VariationAttributeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductWithAttributesToVariationAttributeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** A physical product. */
export type ProductWithDimensions = {
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Product&#039;s height */
  height: Maybe<Scalars['String']['output']>;
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Product&#039;s length */
  length: Maybe<Scalars['String']['output']>;
  /** shipping class ID */
  shippingClassId: Maybe<Scalars['Int']['output']>;
  /** Does product need to be shipped? */
  shippingRequired: Maybe<Scalars['Boolean']['output']>;
  /** Is product shipping taxable? */
  shippingTaxable: Maybe<Scalars['Boolean']['output']>;
  /** Product&#039;s weight */
  weight: Maybe<Scalars['String']['output']>;
  /** Product&#039;s width */
  width: Maybe<Scalars['String']['output']>;
};

/** Pricing group with group discounts */
export type ProductWithGroupDiscount = Node & {
  __typename?: 'ProductWithGroupDiscount';
  /** Product&#039;s pricing in alternate currencies */
  alternateCurrencies: Maybe<Array<Maybe<ProductPricingAlternateCurrencies>>>;
  /** Bonding curve price discount for this product */
  bondingCurve: Maybe<ProductBondingCurve>;
  /** Selected currency used by this store */
  currency: Maybe<Scalars['String']['output']>;
  /** Discount curve price discount for this product */
  discountCurve: Maybe<ProductDiscountCurve>;
  /** Discount amount, if the given address is included in a group discount */
  groupDiscountPercentage: Maybe<Scalars['Float']['output']>;
  /** Product&#039;s price after group discount */
  groupDiscountPrice: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** True if the product specified a preferred currency */
  preferredCurrencySet: Maybe<Scalars['Boolean']['output']>;
  /** Product&#039;s active price */
  price: Maybe<Scalars['String']['output']>;
  /** Product&#039;s regular price */
  regularPrice: Maybe<Scalars['String']['output']>;
  /** Product&#039;s sale price */
  salePrice: Maybe<Scalars['String']['output']>;
  /** Total discount amount */
  totalDiscountPercentage: Maybe<Scalars['Float']['output']>;
};

/** Products with pricing. */
export type ProductWithPricing = {
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Product&#039;s active price */
  price: Maybe<Scalars['String']['output']>;
  /** Product&#039;s regular price */
  regularPrice: Maybe<Scalars['String']['output']>;
  /** Product&#039;s sale price */
  salePrice: Maybe<Scalars['String']['output']>;
  /** Tax class */
  taxClass: Maybe<TaxClassEnum>;
  /** Tax status */
  taxStatus: Maybe<TaxStatusEnum>;
};

/** Products with pricing. */
export type ProductWithPricingPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Products with pricing. */
export type ProductWithPricingRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** Products with pricing. */
export type ProductWithPricingSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product with variations. */
export type ProductWithVariations = {
  /** Product or variation ID */
  databaseId: Scalars['Int']['output'];
  /** Product or variation global ID */
  id: Scalars['ID']['output'];
  /** Connection between the ProductWithVariations type and the ProductVariation type */
  variations: Maybe<ProductWithVariationsToProductVariationConnection>;
};

/** A product with variations. */
export type ProductWithVariationsVariationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductWithVariationsToProductVariationConnectionWhereArgs>;
};

/** Connection between the ProductWithVariations type and the ProductVariation type */
export type ProductWithVariationsToProductVariationConnection = Connection &
  ProductVariationConnection & {
    __typename?: 'ProductWithVariationsToProductVariationConnection';
    /** Edges for the ProductWithVariationsToProductVariationConnection connection */
    edges: Array<ProductWithVariationsToProductVariationConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductVariation>;
    /** Information about pagination in a connection. */
    pageInfo: ProductWithVariationsToProductVariationConnectionPageInfo;
  };

/** An edge in a connection */
export type ProductWithVariationsToProductVariationConnectionEdge = Edge &
  ProductVariationConnectionEdge & {
    __typename?: 'ProductWithVariationsToProductVariationConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductVariation;
  };

/** Page Info on the &quot;ProductWithVariationsToProductVariationConnection&quot; */
export type ProductWithVariationsToProductVariationConnectionPageInfo = PageInfo &
  ProductVariationConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ProductWithVariationsToProductVariationConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ProductWithVariationsToProductVariationConnection connection */
export type ProductWithVariationsToProductVariationConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Fields to order the Products connection by */
export enum ProductsOrderByEnum {
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Order by name */
  Name = 'NAME',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by date product sale starts */
  OnSaleFrom = 'ON_SALE_FROM',
  /** Order by date product sale ends */
  OnSaleTo = 'ON_SALE_TO',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by product's current price */
  Price = 'PRICE',
  /** Order by product average rating */
  Rating = 'RATING',
  /** Order by product's regular price */
  RegularPrice = 'REGULAR_PRICE',
  /** Order by number of reviews on product */
  ReviewCount = 'REVIEW_COUNT',
  /** Order by product's sale price */
  SalePrice = 'SALE_PRICE',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by total sales of products sold */
  TotalSales = 'TOTAL_SALES'
}

/** Options for ordering the connection */
export type ProductsOrderbyInput = {
  field: ProductsOrderByEnum;
  order: InputMaybe<OrderEnum>;
};

/** Single rating count */
export type RatingCount = {
  __typename?: 'RatingCount';
  /** Number of products */
  count: Maybe<Scalars['Int']['output']>;
  /** Average rating */
  rating: Scalars['Int']['output'];
};

/** The reading setting type */
export type ReadingSettings = {
  __typename?: 'ReadingSettings';
  /** The ID of the page that should display the latest posts */
  pageForPosts: Maybe<Scalars['Int']['output']>;
  /** The ID of the page that should be displayed on the front page */
  pageOnFront: Maybe<Scalars['Int']['output']>;
  /** Blog pages show at most. */
  postsPerPage: Maybe<Scalars['Int']['output']>;
  /** What to show on the front page */
  showOnFront: Maybe<Scalars['String']['output']>;
};

/** A refund object */
export type Refund = Node & {
  __typename?: 'Refund';
  /** Refunded amount */
  amount: Maybe<Scalars['Float']['output']>;
  /** The ID of the refund in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** The date of the refund */
  date: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier for the refund */
  id: Scalars['ID']['output'];
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Reason for refund */
  reason: Maybe<Scalars['String']['output']>;
  /** User who completed the refund */
  refundedBy: Maybe<User>;
  /** A title for the new post type */
  title: Maybe<Scalars['String']['output']>;
};

/** A refund object */
export type RefundMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection to Refund Nodes */
export type RefundConnection = {
  /** A list of edges (relational context) between RootQuery and connected Refund Nodes */
  edges: Array<RefundConnectionEdge>;
  /** A list of connected Refund Nodes */
  nodes: Array<Refund>;
  /** Information about pagination in a connection. */
  pageInfo: RefundConnectionPageInfo;
};

/** Edge between a Node and a connected Refund */
export type RefundConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Refund Node */
  node: Refund;
};

/** Page Info on the connected RefundConnectionEdge */
export type RefundConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single Refund. Default is ID. */
export enum RefundIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Input for the registerCustomer mutation. */
export type RegisterCustomerInput = {
  /** User's AOL IM account. */
  aim: InputMaybe<Scalars['String']['input']>;
  /** Customer billing information */
  billing: InputMaybe<CustomerAddressInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** User's Jabber account. */
  jabber: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale: InputMaybe<Scalars['String']['input']>;
  /** Meta data. */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password: InputMaybe<Scalars['String']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered: InputMaybe<Scalars['String']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing: InputMaybe<Scalars['String']['input']>;
  /** Customer shipping address */
  shipping: InputMaybe<CustomerAddressInput>;
  /** Customer shipping is identical to billing address */
  shippingSameAsBilling: InputMaybe<Scalars['Boolean']['input']>;
  /** A string that contains the user's username. */
  username: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the registerCustomer mutation. */
export type RegisterCustomerPayload = {
  __typename?: 'RegisterCustomerPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Maybe<Customer>;
  viewer: Maybe<User>;
};

/** Input for the registerUser mutation. */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** User's Jabber account. */
  jabber: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale: InputMaybe<Scalars['String']['input']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password: InputMaybe<Scalars['String']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered: InputMaybe<Scalars['String']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the user's username. */
  username: Scalars['String']['input'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the registerUser mutation. */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user: Maybe<User>;
};

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = 'OR'
}

/** Input for the removeCoupons mutation. */
export type RemoveCouponsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Code of coupon being applied */
  codes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the removeCoupons mutation. */
export type RemoveCouponsPayload = {
  __typename?: 'RemoveCouponsPayload';
  cart: Maybe<Cart>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the removeItemsFromCart mutation. */
export type RemoveItemsFromCartInput = {
  /** Remove all cart items */
  all: InputMaybe<Scalars['Boolean']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Item keys of the items being removed */
  keys: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** The payload for the removeItemsFromCart mutation. */
export type RemoveItemsFromCartPayload = {
  __typename?: 'RemoveItemsFromCartPayload';
  cart: Maybe<Cart>;
  cartItems: Maybe<Array<Maybe<CartItem>>>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the resetUserPassword mutation. */
export type ResetUserPasswordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Password reset key */
  key: InputMaybe<Scalars['String']['input']>;
  /** The user's login (username). */
  login: InputMaybe<Scalars['String']['input']>;
  /** The new password. */
  password: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the resetUserPassword mutation. */
export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user: Maybe<User>;
};

/** Input for the restoreCartItems mutation. */
export type RestoreCartItemsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Cart item key of the item being removed */
  keys: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** The payload for the restoreCartItems mutation. */
export type RestoreCartItemsPayload = {
  __typename?: 'RestoreCartItemsPayload';
  cart: Maybe<Cart>;
  cartItems: Maybe<Array<Maybe<CartItem>>>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the restoreComment mutation. */
export type RestoreCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the comment to be restored */
  id: Scalars['ID']['input'];
};

/** The payload for the restoreComment mutation. */
export type RestoreCommentPayload = {
  __typename?: 'RestoreCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The restored comment object */
  comment: Maybe<Comment>;
  /** The ID of the restored comment */
  restoredId: Maybe<Scalars['ID']['output']>;
};

/** Input for the restoreReview mutation. */
export type RestoreReviewInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The ID of the target product review */
  id: Scalars['ID']['input'];
};

/** The payload for the restoreReview mutation. */
export type RestoreReviewPayload = {
  __typename?: 'RestoreReviewPayload';
  /** The affected product review ID */
  affectedId: Maybe<Scalars['ID']['output']>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The product rating of the affected product review */
  rating: Maybe<Scalars['Float']['output']>;
  /** The affected product review */
  review: Maybe<Comment>;
};

/** The root mutation */
export type RootMutation = {
  __typename?: 'RootMutation';
  /** The addCartItems mutation */
  addCartItems: Maybe<AddCartItemsPayload>;
  /** The addFee mutation */
  addFee: Maybe<AddFeePayload>;
  /** The addToCart mutation */
  addToCart: Maybe<AddToCartPayload>;
  /** The applyCoupon mutation */
  applyCoupon: Maybe<ApplyCouponPayload>;
  /** The checkout mutation */
  checkout: Maybe<CheckoutPayload>;
  /** The createBanner mutation */
  createBanner: Maybe<CreateBannerPayload>;
  /** The createCategory mutation */
  createCategory: Maybe<CreateCategoryPayload>;
  /** The createComment mutation */
  createComment: Maybe<CreateCommentPayload>;
  /** The createConfirmationScreen mutation */
  createConfirmationScreen: Maybe<CreateConfirmationScreenPayload>;
  /** The createCoupon mutation */
  createCoupon: Maybe<CreateCouponPayload>;
  /** The createGraphqlDocument mutation */
  createGraphqlDocument: Maybe<CreateGraphqlDocumentPayload>;
  /** The createGraphqlDocumentGroup mutation */
  createGraphqlDocumentGroup: Maybe<CreateGraphqlDocumentGroupPayload>;
  /** The createMediaItem mutation */
  createMediaItem: Maybe<CreateMediaItemPayload>;
  /** The createOrder mutation */
  createOrder: Maybe<CreateOrderPayload>;
  /** The createPaDefault mutation */
  createPaDefault: Maybe<CreatePaDefaultPayload>;
  /** The createPaPrimaryCurrency mutation */
  createPaPrimaryCurrency: Maybe<CreatePaPrimaryCurrencyPayload>;
  /** The createPage mutation */
  createPage: Maybe<CreatePagePayload>;
  /** The createPost mutation */
  createPost: Maybe<CreatePostPayload>;
  /** The createPostFormat mutation */
  createPostFormat: Maybe<CreatePostFormatPayload>;
  /** The createProduct mutation */
  createProduct: Maybe<CreateProductPayload>;
  /** The createProductCategory mutation */
  createProductCategory: Maybe<CreateProductCategoryPayload>;
  /** The createProductTag mutation */
  createProductTag: Maybe<CreateProductTagPayload>;
  /** The createProductType mutation */
  createProductType: Maybe<CreateProductTypePayload>;
  /** The createShippingClass mutation */
  createShippingClass: Maybe<CreateShippingClassPayload>;
  /** The createTag mutation */
  createTag: Maybe<CreateTagPayload>;
  /** The createUser mutation */
  createUser: Maybe<CreateUserPayload>;
  /** The createVisibleProduct mutation */
  createVisibleProduct: Maybe<CreateVisibleProductPayload>;
  /** The deleteBanner mutation */
  deleteBanner: Maybe<DeleteBannerPayload>;
  /** The deleteCategory mutation */
  deleteCategory: Maybe<DeleteCategoryPayload>;
  /** The deleteComment mutation */
  deleteComment: Maybe<DeleteCommentPayload>;
  /** The deleteConfirmationScreen mutation */
  deleteConfirmationScreen: Maybe<DeleteConfirmationScreenPayload>;
  /** The deleteCoupon mutation */
  deleteCoupon: Maybe<DeleteCouponPayload>;
  /** The deleteGraphqlDocument mutation */
  deleteGraphqlDocument: Maybe<DeleteGraphqlDocumentPayload>;
  /** The deleteGraphqlDocumentGroup mutation */
  deleteGraphqlDocumentGroup: Maybe<DeleteGraphqlDocumentGroupPayload>;
  /** The deleteMediaItem mutation */
  deleteMediaItem: Maybe<DeleteMediaItemPayload>;
  /** The deleteOrder mutation */
  deleteOrder: Maybe<DeleteOrderPayload>;
  /** The deleteOrderItems mutation */
  deleteOrderItems: Maybe<DeleteOrderItemsPayload>;
  /** The deletePaDefault mutation */
  deletePaDefault: Maybe<DeletePaDefaultPayload>;
  /** The deletePaPrimaryCurrency mutation */
  deletePaPrimaryCurrency: Maybe<DeletePaPrimaryCurrencyPayload>;
  /** The deletePage mutation */
  deletePage: Maybe<DeletePagePayload>;
  /** The deletePaymentMethod mutation */
  deletePaymentMethod: Maybe<DeletePaymentMethodPayload>;
  /** The deletePost mutation */
  deletePost: Maybe<DeletePostPayload>;
  /** The deletePostFormat mutation */
  deletePostFormat: Maybe<DeletePostFormatPayload>;
  /** The deleteProduct mutation */
  deleteProduct: Maybe<DeleteProductPayload>;
  /** The deleteProductCategory mutation */
  deleteProductCategory: Maybe<DeleteProductCategoryPayload>;
  /** The deleteProductTag mutation */
  deleteProductTag: Maybe<DeleteProductTagPayload>;
  /** The deleteProductType mutation */
  deleteProductType: Maybe<DeleteProductTypePayload>;
  /** The deleteReview mutation */
  deleteReview: Maybe<DeleteReviewPayload>;
  /** The deleteShippingClass mutation */
  deleteShippingClass: Maybe<DeleteShippingClassPayload>;
  /** The deleteTag mutation */
  deleteTag: Maybe<DeleteTagPayload>;
  /** The deleteUser mutation */
  deleteUser: Maybe<DeleteUserPayload>;
  /** The deleteVisibleProduct mutation */
  deleteVisibleProduct: Maybe<DeleteVisibleProductPayload>;
  /** The emptyCart mutation */
  emptyCart: Maybe<EmptyCartPayload>;
  /** The fillCart mutation */
  fillCart: Maybe<FillCartPayload>;
  /** Increase the count. */
  increaseCount: Maybe<Scalars['Int']['output']>;
  /** The registerCustomer mutation */
  registerCustomer: Maybe<RegisterCustomerPayload>;
  /** The registerUser mutation */
  registerUser: Maybe<RegisterUserPayload>;
  /** The removeCoupons mutation */
  removeCoupons: Maybe<RemoveCouponsPayload>;
  /** The removeItemsFromCart mutation */
  removeItemsFromCart: Maybe<RemoveItemsFromCartPayload>;
  /** The resetUserPassword mutation */
  resetUserPassword: Maybe<ResetUserPasswordPayload>;
  /** The restoreCartItems mutation */
  restoreCartItems: Maybe<RestoreCartItemsPayload>;
  /** The restoreComment mutation */
  restoreComment: Maybe<RestoreCommentPayload>;
  /** The restoreReview mutation */
  restoreReview: Maybe<RestoreReviewPayload>;
  /** Send password reset email to user */
  sendPasswordResetEmail: Maybe<SendPasswordResetEmailPayload>;
  /** The setDefaultPaymentMethod mutation */
  setDefaultPaymentMethod: Maybe<SetDefaultPaymentMethodPayload>;
  /** The updateBanner mutation */
  updateBanner: Maybe<UpdateBannerPayload>;
  /** The updateCategory mutation */
  updateCategory: Maybe<UpdateCategoryPayload>;
  /** The updateComment mutation */
  updateComment: Maybe<UpdateCommentPayload>;
  /** The updateConfirmationScreen mutation */
  updateConfirmationScreen: Maybe<UpdateConfirmationScreenPayload>;
  /** The updateCoupon mutation */
  updateCoupon: Maybe<UpdateCouponPayload>;
  /** The updateCustomer mutation */
  updateCustomer: Maybe<UpdateCustomerPayload>;
  /** The updateGraphqlDocument mutation */
  updateGraphqlDocument: Maybe<UpdateGraphqlDocumentPayload>;
  /** The updateGraphqlDocumentGroup mutation */
  updateGraphqlDocumentGroup: Maybe<UpdateGraphqlDocumentGroupPayload>;
  /** The updateItemQuantities mutation */
  updateItemQuantities: Maybe<UpdateItemQuantitiesPayload>;
  /** The updateMediaItem mutation */
  updateMediaItem: Maybe<UpdateMediaItemPayload>;
  /** The updateOrder mutation */
  updateOrder: Maybe<UpdateOrderPayload>;
  /** The updatePaDefault mutation */
  updatePaDefault: Maybe<UpdatePaDefaultPayload>;
  /** The updatePaPrimaryCurrency mutation */
  updatePaPrimaryCurrency: Maybe<UpdatePaPrimaryCurrencyPayload>;
  /** The updatePage mutation */
  updatePage: Maybe<UpdatePagePayload>;
  /** The updatePost mutation */
  updatePost: Maybe<UpdatePostPayload>;
  /** The updatePostFormat mutation */
  updatePostFormat: Maybe<UpdatePostFormatPayload>;
  /** The updateProduct mutation */
  updateProduct: Maybe<UpdateProductPayload>;
  /** The updateProductCategory mutation */
  updateProductCategory: Maybe<UpdateProductCategoryPayload>;
  /** The updateProductTag mutation */
  updateProductTag: Maybe<UpdateProductTagPayload>;
  /** The updateProductType mutation */
  updateProductType: Maybe<UpdateProductTypePayload>;
  /** The updateReview mutation */
  updateReview: Maybe<UpdateReviewPayload>;
  /** The updateSession mutation */
  updateSession: Maybe<UpdateSessionPayload>;
  /** The updateSettings mutation */
  updateSettings: Maybe<UpdateSettingsPayload>;
  /** The updateShippingClass mutation */
  updateShippingClass: Maybe<UpdateShippingClassPayload>;
  /** The updateShippingMethod mutation */
  updateShippingMethod: Maybe<UpdateShippingMethodPayload>;
  /** The updateTag mutation */
  updateTag: Maybe<UpdateTagPayload>;
  /** The updateUser mutation */
  updateUser: Maybe<UpdateUserPayload>;
  /** The updateVisibleProduct mutation */
  updateVisibleProduct: Maybe<UpdateVisibleProductPayload>;
  /** The writeReview mutation */
  writeReview: Maybe<WriteReviewPayload>;
};

/** The root mutation */
export type RootMutationAddCartItemsArgs = {
  input: AddCartItemsInput;
};

/** The root mutation */
export type RootMutationAddFeeArgs = {
  input: AddFeeInput;
};

/** The root mutation */
export type RootMutationAddToCartArgs = {
  input: AddToCartInput;
};

/** The root mutation */
export type RootMutationApplyCouponArgs = {
  input: ApplyCouponInput;
};

/** The root mutation */
export type RootMutationCheckoutArgs = {
  input: CheckoutInput;
};

/** The root mutation */
export type RootMutationCreateBannerArgs = {
  input: CreateBannerInput;
};

/** The root mutation */
export type RootMutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

/** The root mutation */
export type RootMutationCreateCommentArgs = {
  input: CreateCommentInput;
};

/** The root mutation */
export type RootMutationCreateConfirmationScreenArgs = {
  input: CreateConfirmationScreenInput;
};

/** The root mutation */
export type RootMutationCreateCouponArgs = {
  input: CreateCouponInput;
};

/** The root mutation */
export type RootMutationCreateGraphqlDocumentArgs = {
  input: CreateGraphqlDocumentInput;
};

/** The root mutation */
export type RootMutationCreateGraphqlDocumentGroupArgs = {
  input: CreateGraphqlDocumentGroupInput;
};

/** The root mutation */
export type RootMutationCreateMediaItemArgs = {
  input: CreateMediaItemInput;
};

/** The root mutation */
export type RootMutationCreateOrderArgs = {
  input: CreateOrderInput;
};

/** The root mutation */
export type RootMutationCreatePaDefaultArgs = {
  input: CreatePaDefaultInput;
};

/** The root mutation */
export type RootMutationCreatePaPrimaryCurrencyArgs = {
  input: CreatePaPrimaryCurrencyInput;
};

/** The root mutation */
export type RootMutationCreatePageArgs = {
  input: CreatePageInput;
};

/** The root mutation */
export type RootMutationCreatePostArgs = {
  input: CreatePostInput;
};

/** The root mutation */
export type RootMutationCreatePostFormatArgs = {
  input: CreatePostFormatInput;
};

/** The root mutation */
export type RootMutationCreateProductArgs = {
  input: CreateProductInput;
};

/** The root mutation */
export type RootMutationCreateProductCategoryArgs = {
  input: CreateProductCategoryInput;
};

/** The root mutation */
export type RootMutationCreateProductTagArgs = {
  input: CreateProductTagInput;
};

/** The root mutation */
export type RootMutationCreateProductTypeArgs = {
  input: CreateProductTypeInput;
};

/** The root mutation */
export type RootMutationCreateShippingClassArgs = {
  input: CreateShippingClassInput;
};

/** The root mutation */
export type RootMutationCreateTagArgs = {
  input: CreateTagInput;
};

/** The root mutation */
export type RootMutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation */
export type RootMutationCreateVisibleProductArgs = {
  input: CreateVisibleProductInput;
};

/** The root mutation */
export type RootMutationDeleteBannerArgs = {
  input: DeleteBannerInput;
};

/** The root mutation */
export type RootMutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};

/** The root mutation */
export type RootMutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

/** The root mutation */
export type RootMutationDeleteConfirmationScreenArgs = {
  input: DeleteConfirmationScreenInput;
};

/** The root mutation */
export type RootMutationDeleteCouponArgs = {
  input: DeleteCouponInput;
};

/** The root mutation */
export type RootMutationDeleteGraphqlDocumentArgs = {
  input: DeleteGraphqlDocumentInput;
};

/** The root mutation */
export type RootMutationDeleteGraphqlDocumentGroupArgs = {
  input: DeleteGraphqlDocumentGroupInput;
};

/** The root mutation */
export type RootMutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};

/** The root mutation */
export type RootMutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};

/** The root mutation */
export type RootMutationDeleteOrderItemsArgs = {
  input: DeleteOrderItemsInput;
};

/** The root mutation */
export type RootMutationDeletePaDefaultArgs = {
  input: DeletePaDefaultInput;
};

/** The root mutation */
export type RootMutationDeletePaPrimaryCurrencyArgs = {
  input: DeletePaPrimaryCurrencyInput;
};

/** The root mutation */
export type RootMutationDeletePageArgs = {
  input: DeletePageInput;
};

/** The root mutation */
export type RootMutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

/** The root mutation */
export type RootMutationDeletePostArgs = {
  input: DeletePostInput;
};

/** The root mutation */
export type RootMutationDeletePostFormatArgs = {
  input: DeletePostFormatInput;
};

/** The root mutation */
export type RootMutationDeleteProductArgs = {
  input: DeleteProductInput;
};

/** The root mutation */
export type RootMutationDeleteProductCategoryArgs = {
  input: DeleteProductCategoryInput;
};

/** The root mutation */
export type RootMutationDeleteProductTagArgs = {
  input: DeleteProductTagInput;
};

/** The root mutation */
export type RootMutationDeleteProductTypeArgs = {
  input: DeleteProductTypeInput;
};

/** The root mutation */
export type RootMutationDeleteReviewArgs = {
  input: DeleteReviewInput;
};

/** The root mutation */
export type RootMutationDeleteShippingClassArgs = {
  input: DeleteShippingClassInput;
};

/** The root mutation */
export type RootMutationDeleteTagArgs = {
  input: DeleteTagInput;
};

/** The root mutation */
export type RootMutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation */
export type RootMutationDeleteVisibleProductArgs = {
  input: DeleteVisibleProductInput;
};

/** The root mutation */
export type RootMutationEmptyCartArgs = {
  input: EmptyCartInput;
};

/** The root mutation */
export type RootMutationFillCartArgs = {
  input: FillCartInput;
};

/** The root mutation */
export type RootMutationIncreaseCountArgs = {
  count: InputMaybe<Scalars['Int']['input']>;
};

/** The root mutation */
export type RootMutationRegisterCustomerArgs = {
  input: RegisterCustomerInput;
};

/** The root mutation */
export type RootMutationRegisterUserArgs = {
  input: RegisterUserInput;
};

/** The root mutation */
export type RootMutationRemoveCouponsArgs = {
  input: RemoveCouponsInput;
};

/** The root mutation */
export type RootMutationRemoveItemsFromCartArgs = {
  input: RemoveItemsFromCartInput;
};

/** The root mutation */
export type RootMutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

/** The root mutation */
export type RootMutationRestoreCartItemsArgs = {
  input: RestoreCartItemsInput;
};

/** The root mutation */
export type RootMutationRestoreCommentArgs = {
  input: RestoreCommentInput;
};

/** The root mutation */
export type RootMutationRestoreReviewArgs = {
  input: RestoreReviewInput;
};

/** The root mutation */
export type RootMutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};

/** The root mutation */
export type RootMutationSetDefaultPaymentMethodArgs = {
  input: SetDefaultPaymentMethodInput;
};

/** The root mutation */
export type RootMutationUpdateBannerArgs = {
  input: UpdateBannerInput;
};

/** The root mutation */
export type RootMutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

/** The root mutation */
export type RootMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

/** The root mutation */
export type RootMutationUpdateConfirmationScreenArgs = {
  input: UpdateConfirmationScreenInput;
};

/** The root mutation */
export type RootMutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};

/** The root mutation */
export type RootMutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

/** The root mutation */
export type RootMutationUpdateGraphqlDocumentArgs = {
  input: UpdateGraphqlDocumentInput;
};

/** The root mutation */
export type RootMutationUpdateGraphqlDocumentGroupArgs = {
  input: UpdateGraphqlDocumentGroupInput;
};

/** The root mutation */
export type RootMutationUpdateItemQuantitiesArgs = {
  input: UpdateItemQuantitiesInput;
};

/** The root mutation */
export type RootMutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput;
};

/** The root mutation */
export type RootMutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

/** The root mutation */
export type RootMutationUpdatePaDefaultArgs = {
  input: UpdatePaDefaultInput;
};

/** The root mutation */
export type RootMutationUpdatePaPrimaryCurrencyArgs = {
  input: UpdatePaPrimaryCurrencyInput;
};

/** The root mutation */
export type RootMutationUpdatePageArgs = {
  input: UpdatePageInput;
};

/** The root mutation */
export type RootMutationUpdatePostArgs = {
  input: UpdatePostInput;
};

/** The root mutation */
export type RootMutationUpdatePostFormatArgs = {
  input: UpdatePostFormatInput;
};

/** The root mutation */
export type RootMutationUpdateProductArgs = {
  input: UpdateProductInput;
};

/** The root mutation */
export type RootMutationUpdateProductCategoryArgs = {
  input: UpdateProductCategoryInput;
};

/** The root mutation */
export type RootMutationUpdateProductTagArgs = {
  input: UpdateProductTagInput;
};

/** The root mutation */
export type RootMutationUpdateProductTypeArgs = {
  input: UpdateProductTypeInput;
};

/** The root mutation */
export type RootMutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};

/** The root mutation */
export type RootMutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

/** The root mutation */
export type RootMutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

/** The root mutation */
export type RootMutationUpdateShippingClassArgs = {
  input: UpdateShippingClassInput;
};

/** The root mutation */
export type RootMutationUpdateShippingMethodArgs = {
  input: UpdateShippingMethodInput;
};

/** The root mutation */
export type RootMutationUpdateTagArgs = {
  input: UpdateTagInput;
};

/** The root mutation */
export type RootMutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root mutation */
export type RootMutationUpdateVisibleProductArgs = {
  input: UpdateVisibleProductInput;
};

/** The root mutation */
export type RootMutationWriteReviewArgs = {
  input: WriteReviewInput;
};

/** The root entry point into the Graph */
export type RootQuery = {
  __typename?: 'RootQuery';
  /** Connection between the RootQuery type and the paDefault type */
  allPaDefault: Maybe<RootQueryToPaDefaultConnection>;
  /** Connection between the RootQuery type and the paPrimaryCurrency type */
  allPaPrimaryCurrency: Maybe<RootQueryToPaPrimaryCurrencyConnection>;
  /** Entry point to get all settings for the site */
  allSettings: Maybe<Settings>;
  /** Countries that the store sells to */
  allowedCountries: Maybe<Array<Maybe<CountriesEnum>>>;
  /** An object of the banner Type.  */
  banner: Maybe<Banner>;
  /**
   * A banner object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  bannerBy: Maybe<Banner>;
  /** Connection between the RootQuery type and the banner type */
  banners: Maybe<RootQueryToBannerConnection>;
  /** The cart object */
  cart: Maybe<Cart>;
  /** The cart object */
  cartFee: Maybe<CartFee>;
  /** The cart object */
  cartItem: Maybe<CartItem>;
  /** Connection between the RootQuery type and the category type */
  categories: Maybe<RootQueryToCategoryConnection>;
  /** A 0bject */
  category: Maybe<Category>;
  /** Statistics for a product taxonomy query */
  collectionStats: Maybe<CollectionStats>;
  /** Returns a Comment */
  comment: Maybe<Comment>;
  /** Connection between the RootQuery type and the Comment type */
  comments: Maybe<RootQueryToCommentConnection>;
  /** An object of the confirmationScreen Type.  */
  confirmationScreen: Maybe<ConfirmationScreen>;
  /**
   * A confirmationScreen object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  confirmationScreenBy: Maybe<ConfirmationScreen>;
  /** Connection between the RootQuery type and the confirmationScreen type */
  confirmationScreens: Maybe<RootQueryToConfirmationScreenConnection>;
  /** A node used to manage content */
  contentNode: Maybe<ContentNode>;
  /** Connection between the RootQuery type and the ContentNode type */
  contentNodes: Maybe<RootQueryToContentNodeConnection>;
  /** Fetch a Content Type node by unique Identifier */
  contentType: Maybe<ContentType>;
  /** Connection between the RootQuery type and the ContentType type */
  contentTypes: Maybe<RootQueryToContentTypeConnection>;
  /** Countries */
  countries: Maybe<Array<Maybe<CountriesEnum>>>;
  /** Countries that the store sells to */
  countryStates: Maybe<Array<Maybe<CountryState>>>;
  /** A coupon object */
  coupon: Maybe<Coupon>;
  /** Connection between the RootQuery type and the Coupon type */
  coupons: Maybe<RootQueryToCouponConnection>;
  /** A customer object */
  customer: Maybe<Customer>;
  /** Connection between the RootQuery type and the Customer type */
  customers: Maybe<RootQueryToCustomerConnection>;
  /** Fields of the &#039;DiscussionSettings&#039; settings group */
  discussionSettings: Maybe<DiscussionSettings>;
  /**
   * A external product object
   * @deprecated Use &quot;product&quot; instead.
   */
  externalProduct: Maybe<ExternalProduct>;
  /** Fields of the &#039;GeneralSettings&#039; settings group */
  generalSettings: Maybe<GeneralSettings>;
  /** An object of the graphqlDocument Type. Saved GraphQL Documents */
  graphqlDocument: Maybe<GraphqlDocument>;
  /**
   * A graphqlDocument object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  graphqlDocumentBy: Maybe<GraphqlDocument>;
  /** A 0bject */
  graphqlDocumentGroup: Maybe<GraphqlDocumentGroup>;
  /** Connection between the RootQuery type and the graphqlDocumentGroup type */
  graphqlDocumentGroups: Maybe<RootQueryToGraphqlDocumentGroupConnection>;
  /** Connection between the RootQuery type and the graphqlDocument type */
  graphqlDocuments: Maybe<RootQueryToGraphqlDocumentConnection>;
  /**
   * A grouped product object
   * @deprecated Use &quot;product&quot; instead.
   */
  groupedProduct: Maybe<GroupProduct>;
  /** Pricing group with group discounts */
  maintenance: Maybe<MaintenanceMode>;
  /** An object of the mediaItem Type.  */
  mediaItem: Maybe<MediaItem>;
  /**
   * A mediaItem object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  mediaItemBy: Maybe<MediaItem>;
  /** Connection between the RootQuery type and the mediaItem type */
  mediaItems: Maybe<RootQueryToMediaItemConnection>;
  /** A WordPress navigation menu */
  menu: Maybe<Menu>;
  /** A WordPress navigation menu item */
  menuItem: Maybe<MenuItem>;
  /** Connection between the RootQuery type and the MenuItem type */
  menuItems: Maybe<RootQueryToMenuItemConnection>;
  /** Connection between the RootQuery type and the Menu type */
  menus: Maybe<RootQueryToMenuConnection>;
  /** Fetches an object given its ID */
  node: Maybe<Node>;
  /** Fetches an object given its Unique Resource Identifier */
  nodeByUri: Maybe<UniformResourceIdentifiable>;
  /** A order object */
  order: Maybe<Order>;
  /** Connection between the RootQuery type and the Order type */
  orders: Maybe<RootQueryToOrderConnection>;
  /** A 0bject */
  paDefault: Maybe<PaDefault>;
  /** A 0bject */
  paPrimaryCurrency: Maybe<PaPrimaryCurrency>;
  /** An object of the page Type.  */
  page: Maybe<Page>;
  /**
   * A page object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  pageBy: Maybe<Page>;
  /** Connection between the RootQuery type and the page type */
  pages: Maybe<RootQueryToPageConnection>;
  /** Connection between the RootQuery type and the PaymentGateway type */
  paymentGateways: Maybe<RootQueryToPaymentGatewayConnection>;
  /** A WordPress plugin */
  plugin: Maybe<Plugin>;
  /** Connection between the RootQuery type and the Plugin type */
  plugins: Maybe<RootQueryToPluginConnection>;
  /** An object of the post Type.  */
  post: Maybe<Post>;
  /**
   * A post object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  postBy: Maybe<Post>;
  /** A 0bject */
  postFormat: Maybe<PostFormat>;
  /** Connection between the RootQuery type and the postFormat type */
  postFormats: Maybe<RootQueryToPostFormatConnection>;
  /** Connection between the RootQuery type and the post type */
  posts: Maybe<RootQueryToPostConnection>;
  /** A product object */
  product: Maybe<Product>;
  /** Connection between the RootQuery type and the productCategory type */
  productCategories: Maybe<RootQueryToProductCategoryConnection>;
  /** A 0bject */
  productCategory: Maybe<ProductCategory>;
  /** A 0bject */
  productTag: Maybe<ProductTag>;
  /** Connection between the RootQuery type and the productTag type */
  productTags: Maybe<RootQueryToProductTagConnection>;
  /** A 0bject */
  productType: Maybe<ProductType>;
  /** Connection between the RootQuery type and the productType type */
  productTypes: Maybe<RootQueryToProductTypeConnection>;
  /** A product variation object */
  productVariation: Maybe<ProductVariation>;
  /** Connection between the RootQuery type and the ProductUnion type */
  products: Maybe<RootQueryToProductUnionConnection>;
  /** Fields of the &#039;ReadingSettings&#039; settings group */
  readingSettings: Maybe<ReadingSettings>;
  /** A refund object */
  refund: Maybe<Refund>;
  /** Connection between the RootQuery type and the Refund type */
  refunds: Maybe<RootQueryToRefundConnection>;
  /** Connection between the RootQuery type and the EnqueuedScript type */
  registeredScripts: Maybe<RootQueryToEnqueuedScriptConnection>;
  /** Connection between the RootQuery type and the EnqueuedStylesheet type */
  registeredStylesheets: Maybe<RootQueryToEnqueuedStylesheetConnection>;
  /** Connection between the RootQuery type and the ContentNode type */
  revisions: Maybe<RootQueryToRevisionsConnection>;
  /** A 0bject */
  shippingClass: Maybe<ShippingClass>;
  /** Connection between the RootQuery type and the shippingClass type */
  shippingClasses: Maybe<RootQueryToShippingClassConnection>;
  /** A shipping method object */
  shippingMethod: Maybe<ShippingMethod>;
  /** Connection between the RootQuery type and the ShippingMethod type */
  shippingMethods: Maybe<RootQueryToShippingMethodConnection>;
  /**
   * A simple product object
   * @deprecated Use &quot;product&quot; instead.
   */
  simpleProduct: Maybe<SimpleProduct>;
  /** A 0bject */
  tag: Maybe<Tag>;
  /** Connection between the RootQuery type and the tag type */
  tags: Maybe<RootQueryToTagConnection>;
  /** A tax rate object */
  taxRate: Maybe<TaxRate>;
  /** Connection between the RootQuery type and the TaxRate type */
  taxRates: Maybe<RootQueryToTaxRateConnection>;
  /** Connection between the RootQuery type and the Taxonomy type */
  taxonomies: Maybe<RootQueryToTaxonomyConnection>;
  /** Fetch a Taxonomy node by unique Identifier */
  taxonomy: Maybe<Taxonomy>;
  /** A node in a taxonomy used to group and relate content nodes */
  termNode: Maybe<TermNode>;
  /** Connection between the RootQuery type and the TermNode type */
  terms: Maybe<RootQueryToTermNodeConnection>;
  /** A Theme object */
  theme: Maybe<Theme>;
  /** Connection between the RootQuery type and the Theme type */
  themes: Maybe<RootQueryToThemeConnection>;
  /** Returns a user */
  user: Maybe<User>;
  /** Returns a user role */
  userRole: Maybe<UserRole>;
  /** Connection between the RootQuery type and the UserRole type */
  userRoles: Maybe<RootQueryToUserRoleConnection>;
  /** Connection between the RootQuery type and the User type */
  users: Maybe<RootQueryToUserConnection>;
  /**
   * A variable product object
   * @deprecated Use &quot;product&quot; instead.
   */
  variableProduct: Maybe<VariableProduct>;
  /** Returns the current user */
  viewer: Maybe<User>;
  /** A 0bject */
  visibleProduct: Maybe<VisibleProduct>;
  /** Connection between the RootQuery type and the visibleProduct type */
  visibleProducts: Maybe<RootQueryToVisibleProductConnection>;
  /** Fields of the &#039;WritingSettings&#039; settings group */
  writingSettings: Maybe<WritingSettings>;
};

/** The root entry point into the Graph */
export type RootQueryAllPaDefaultArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPaDefaultConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryAllPaPrimaryCurrencyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPaPrimaryCurrencyConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryBannerArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<BannerIdType>;
};

/** The root entry point into the Graph */
export type RootQueryBannerByArgs = {
  bannerId: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryBannersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToBannerConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCartArgs = {
  recalculateTotals: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryCartFeeArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryCartItemArgs = {
  key: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToCategoryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCategoryArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<CategoryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryCollectionStatsArgs = {
  calculatePriceRange: InputMaybe<Scalars['Boolean']['input']>;
  calculateRatingCounts: InputMaybe<Scalars['Boolean']['input']>;
  calculateStockStatusCounts: InputMaybe<Scalars['Boolean']['input']>;
  taxonomies: InputMaybe<Array<InputMaybe<CollectionStatsQueryInput>>>;
  where: InputMaybe<CollectionStatsWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCommentArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<CommentNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToCommentConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryConfirmationScreenArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<ConfirmationScreenIdType>;
};

/** The root entry point into the Graph */
export type RootQueryConfirmationScreenByArgs = {
  confirmationScreenId: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryConfirmationScreensArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToConfirmationScreenConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentNodeArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  contentType: InputMaybe<ContentTypeEnum>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<ContentNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToContentNodeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentTypeArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<ContentTypeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryContentTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryCountryStatesArgs = {
  country: CountriesEnum;
};

/** The root entry point into the Graph */
export type RootQueryCouponArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<CouponIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryCouponsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToCouponConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCustomerArgs = {
  customerId: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryCustomersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToCustomerConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryExternalProductArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<ProductIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<GraphqlDocumentIdType>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentByArgs = {
  graphqlDocumentId: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentGroupArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<GraphqlDocumentGroupIdType>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToGraphqlDocumentGroupConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToGraphqlDocumentConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryGroupedProductArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<ProductIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<MediaItemIdType>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemByArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  mediaItemId: InputMaybe<Scalars['Int']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToMediaItemConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMenuArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<MenuNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMenuItemArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<MenuItemNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMenuItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMenusArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToMenuConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryNodeArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryNodeByUriArgs = {
  uri: Scalars['String']['input'];
};

/** The root entry point into the Graph */
export type RootQueryOrderArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<OrderIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryOrdersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToOrderConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPaDefaultArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<PaDefaultIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPaPrimaryCurrencyArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<PaPrimaryCurrencyIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPageArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<PageIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPageByArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  pageId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryPagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPageConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPaymentGatewaysArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPaymentGatewayConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPluginArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryPluginsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPluginConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPostArgs = {
  asPreview: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType: InputMaybe<PostIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPostByArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  postId: InputMaybe<Scalars['Int']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryPostFormatArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<PostFormatIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPostFormatsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPostFormatConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPostsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToPostConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryProductArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<ProductIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryProductCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToProductCategoryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryProductCategoryArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<ProductCategoryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryProductTagArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<ProductTagIdType>;
};

/** The root entry point into the Graph */
export type RootQueryProductTagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToProductTagConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryProductTypeArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<ProductTypeIdType>;
};

/** The root entry point into the Graph */
export type RootQueryProductTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToProductTypeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryProductVariationArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<ProductVariationIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToProductUnionConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryRefundArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<RefundIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryRefundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToRefundConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryRegisteredScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryRegisteredStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryRevisionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToRevisionsConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryShippingClassArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<ShippingClassIdType>;
};

/** The root entry point into the Graph */
export type RootQueryShippingClassesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToShippingClassConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryShippingMethodArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<ShippingMethodIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryShippingMethodsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQuerySimpleProductArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<ProductIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTagArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<TagIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToTagConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxRateArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<TaxRateIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTaxRatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToTaxRateConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<TaxonomyIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTermNodeArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<TermNodeIdTypeEnum>;
  taxonomy: InputMaybe<TaxonomyEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToTermNodeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryThemeArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryThemesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryUserArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<UserNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryUserRoleArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryUserRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToUserConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryVariableProductArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  idType: InputMaybe<ProductIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryVisibleProductArgs = {
  id: Scalars['ID']['input'];
  idType: InputMaybe<VisibleProductIdType>;
};

/** The root entry point into the Graph */
export type RootQueryVisibleProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<RootQueryToVisibleProductConnectionWhereArgs>;
};

/** Connection between the RootQuery type and the banner type */
export type RootQueryToBannerConnection = BannerConnection &
  Connection & {
    __typename?: 'RootQueryToBannerConnection';
    /** Edges for the RootQueryToBannerConnection connection */
    edges: Array<RootQueryToBannerConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Banner>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToBannerConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToBannerConnectionEdge = BannerConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToBannerConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Banner;
  };

/** Page Info on the &quot;RootQueryToBannerConnection&quot; */
export type RootQueryToBannerConnectionPageInfo = BannerConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToBannerConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToBannerConnection connection */
export type RootQueryToBannerConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the category type */
export type RootQueryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'RootQueryToCategoryConnection';
    /** Edges for the RootQueryToCategoryConnection connection */
    edges: Array<RootQueryToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;RootQueryToCategoryConnection&quot; */
export type RootQueryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the Comment type */
export type RootQueryToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'RootQueryToCommentConnection';
    /** Edges for the RootQueryToCommentConnection connection */
    edges: Array<RootQueryToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;RootQueryToCommentConnection&quot; */
export type RootQueryToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the RootQuery type and the confirmationScreen type */
export type RootQueryToConfirmationScreenConnection = ConfirmationScreenConnection &
  Connection & {
    __typename?: 'RootQueryToConfirmationScreenConnection';
    /** Edges for the RootQueryToConfirmationScreenConnection connection */
    edges: Array<RootQueryToConfirmationScreenConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ConfirmationScreen>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToConfirmationScreenConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToConfirmationScreenConnectionEdge = ConfirmationScreenConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToConfirmationScreenConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ConfirmationScreen;
  };

/** Page Info on the &quot;RootQueryToConfirmationScreenConnection&quot; */
export type RootQueryToConfirmationScreenConnectionPageInfo = ConfirmationScreenConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToConfirmationScreenConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToConfirmationScreenConnection connection */
export type RootQueryToConfirmationScreenConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'RootQueryToContentNodeConnection';
    /** Edges for the RootQueryToContentNodeConnection connection */
    edges: Array<RootQueryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RootQueryToContentNodeConnection&quot; */
export type RootQueryToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the ContentType type */
export type RootQueryToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: 'RootQueryToContentTypeConnection';
    /** Edges for the RootQueryToContentTypeConnection connection */
    edges: Array<RootQueryToContentTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentType>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToContentTypeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentType;
  };

/** Page Info on the &quot;RootQueryToContentTypeConnection&quot; */
export type RootQueryToContentTypeConnectionPageInfo = ContentTypeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToContentTypeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the Coupon type */
export type RootQueryToCouponConnection = Connection &
  CouponConnection & {
    __typename?: 'RootQueryToCouponConnection';
    /** Edges for the RootQueryToCouponConnection connection */
    edges: Array<RootQueryToCouponConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Coupon>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCouponConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCouponConnectionEdge = CouponConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCouponConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Coupon;
  };

/** Page Info on the &quot;RootQueryToCouponConnection&quot; */
export type RootQueryToCouponConnectionPageInfo = CouponConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCouponConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToCouponConnection connection */
export type RootQueryToCouponConnectionWhereArgs = {
  /** Limit result set to resources with a specific code. */
  code: InputMaybe<Scalars['String']['input']>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostTypeOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Customer type */
export type RootQueryToCustomerConnection = Connection &
  CustomerConnection & {
    __typename?: 'RootQueryToCustomerConnection';
    /** Edges for the RootQueryToCustomerConnection connection */
    edges: Array<RootQueryToCustomerConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Customer>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCustomerConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCustomerConnectionEdge = CustomerConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCustomerConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Customer;
  };

/** Page Info on the &quot;RootQueryToCustomerConnection&quot; */
export type RootQueryToCustomerConnectionPageInfo = CustomerConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCustomerConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToCustomerConnection connection */
export type RootQueryToCustomerConnectionWhereArgs = {
  /** Limit result set to resources with a specific email. */
  email: InputMaybe<Scalars['String']['input']>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Order of results. */
  order: InputMaybe<OrderEnum>;
  /** Order results by a specific field. */
  orderby: InputMaybe<CustomerConnectionOrderbyEnum>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the EnqueuedScript type */
export type RootQueryToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'RootQueryToEnqueuedScriptConnection';
    /** Edges for the RootQueryToEnqueuedScriptConnection connection */
    edges: Array<RootQueryToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'RootQueryToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;RootQueryToEnqueuedScriptConnection&quot; */
export type RootQueryToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToEnqueuedScriptConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the EnqueuedStylesheet type */
export type RootQueryToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnection';
    /** Edges for the RootQueryToEnqueuedStylesheetConnection connection */
    edges: Array<RootQueryToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;RootQueryToEnqueuedStylesheetConnection&quot; */
export type RootQueryToEnqueuedStylesheetConnectionPageInfo = EnqueuedStylesheetConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the graphqlDocument type */
export type RootQueryToGraphqlDocumentConnection = Connection &
  GraphqlDocumentConnection & {
    __typename?: 'RootQueryToGraphqlDocumentConnection';
    /** Edges for the RootQueryToGraphqlDocumentConnection connection */
    edges: Array<RootQueryToGraphqlDocumentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocument>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToGraphqlDocumentConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToGraphqlDocumentConnectionEdge = Edge &
  GraphqlDocumentConnectionEdge & {
    __typename?: 'RootQueryToGraphqlDocumentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocument;
  };

/** Page Info on the &quot;RootQueryToGraphqlDocumentConnection&quot; */
export type RootQueryToGraphqlDocumentConnectionPageInfo = GraphqlDocumentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToGraphqlDocumentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToGraphqlDocumentConnection connection */
export type RootQueryToGraphqlDocumentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the graphqlDocumentGroup type */
export type RootQueryToGraphqlDocumentGroupConnection = Connection &
  GraphqlDocumentGroupConnection & {
    __typename?: 'RootQueryToGraphqlDocumentGroupConnection';
    /** Edges for the RootQueryToGraphqlDocumentGroupConnection connection */
    edges: Array<RootQueryToGraphqlDocumentGroupConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocumentGroup>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToGraphqlDocumentGroupConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToGraphqlDocumentGroupConnectionEdge = Edge &
  GraphqlDocumentGroupConnectionEdge & {
    __typename?: 'RootQueryToGraphqlDocumentGroupConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocumentGroup;
  };

/** Page Info on the &quot;RootQueryToGraphqlDocumentGroupConnection&quot; */
export type RootQueryToGraphqlDocumentGroupConnectionPageInfo =
  GraphqlDocumentGroupConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToGraphqlDocumentGroupConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** When paginating backwards, the cursor to continue. */
      startCursor: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToGraphqlDocumentGroupConnection connection */
export type RootQueryToGraphqlDocumentGroupConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the mediaItem type */
export type RootQueryToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'RootQueryToMediaItemConnection';
    /** Edges for the RootQueryToMediaItemConnection connection */
    edges: Array<RootQueryToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'RootQueryToMediaItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;RootQueryToMediaItemConnection&quot; */
export type RootQueryToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMediaItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Menu type */
export type RootQueryToMenuConnection = Connection &
  MenuConnection & {
    __typename?: 'RootQueryToMenuConnection';
    /** Edges for the RootQueryToMenuConnection connection */
    edges: Array<RootQueryToMenuConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Menu>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMenuConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMenuConnectionEdge = Edge &
  MenuConnectionEdge & {
    __typename?: 'RootQueryToMenuConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Menu;
  };

/** Page Info on the &quot;RootQueryToMenuConnection&quot; */
export type RootQueryToMenuConnectionPageInfo = MenuConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMenuConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** The menu location for the menu being queried */
  location: InputMaybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the MenuItem type */
export type RootQueryToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'RootQueryToMenuItemConnection';
    /** Edges for the RootQueryToMenuItemConnection connection */
    edges: Array<RootQueryToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'RootQueryToMenuItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;RootQueryToMenuItemConnection&quot; */
export type RootQueryToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMenuItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** The menu location for the menu being queried */
  location: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent menu object */
  parentId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the RootQuery type and the Order type */
export type RootQueryToOrderConnection = Connection &
  OrderConnection & {
    __typename?: 'RootQueryToOrderConnection';
    /** Edges for the RootQueryToOrderConnection connection */
    edges: Array<RootQueryToOrderConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Order>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToOrderConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToOrderConnectionEdge = Edge &
  OrderConnectionEdge & {
    __typename?: 'RootQueryToOrderConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Order;
  };

/** Page Info on the &quot;RootQueryToOrderConnection&quot; */
export type RootQueryToOrderConnectionPageInfo = OrderConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToOrderConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToOrderConnection connection */
export type RootQueryToOrderConnectionWhereArgs = {
  /** Limit result set to orders assigned a specific billing email. */
  billingEmail: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to orders assigned a specific customer. */
  customerId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to orders assigned a specific group of customers. */
  customersIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<OrdersOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to orders assigned a specific product. */
  productId: InputMaybe<Scalars['Int']['input']>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to orders assigned a specific status. */
  statuses: InputMaybe<Array<InputMaybe<OrderStatusEnum>>>;
};

/** Connection between the RootQuery type and the paDefault type */
export type RootQueryToPaDefaultConnection = Connection &
  PaDefaultConnection & {
    __typename?: 'RootQueryToPaDefaultConnection';
    /** Edges for the RootQueryToPaDefaultConnection connection */
    edges: Array<RootQueryToPaDefaultConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PaDefault>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPaDefaultConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPaDefaultConnectionEdge = Edge &
  PaDefaultConnectionEdge & {
    __typename?: 'RootQueryToPaDefaultConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PaDefault;
  };

/** Page Info on the &quot;RootQueryToPaDefaultConnection&quot; */
export type RootQueryToPaDefaultConnectionPageInfo = PaDefaultConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPaDefaultConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPaDefaultConnection connection */
export type RootQueryToPaDefaultConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the paPrimaryCurrency type */
export type RootQueryToPaPrimaryCurrencyConnection = Connection &
  PaPrimaryCurrencyConnection & {
    __typename?: 'RootQueryToPaPrimaryCurrencyConnection';
    /** Edges for the RootQueryToPaPrimaryCurrencyConnection connection */
    edges: Array<RootQueryToPaPrimaryCurrencyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PaPrimaryCurrency>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPaPrimaryCurrencyConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPaPrimaryCurrencyConnectionEdge = Edge &
  PaPrimaryCurrencyConnectionEdge & {
    __typename?: 'RootQueryToPaPrimaryCurrencyConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PaPrimaryCurrency;
  };

/** Page Info on the &quot;RootQueryToPaPrimaryCurrencyConnection&quot; */
export type RootQueryToPaPrimaryCurrencyConnectionPageInfo = PaPrimaryCurrencyConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPaPrimaryCurrencyConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPaPrimaryCurrencyConnection connection */
export type RootQueryToPaPrimaryCurrencyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the page type */
export type RootQueryToPageConnection = Connection &
  PageConnection & {
    __typename?: 'RootQueryToPageConnection';
    /** Edges for the RootQueryToPageConnection connection */
    edges: Array<RootQueryToPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPageConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'RootQueryToPageConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;RootQueryToPageConnection&quot; */
export type RootQueryToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPageConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the PaymentGateway type */
export type RootQueryToPaymentGatewayConnection = Connection &
  PaymentGatewayConnection & {
    __typename?: 'RootQueryToPaymentGatewayConnection';
    /** Edges for the RootQueryToPaymentGatewayConnection connection */
    edges: Array<RootQueryToPaymentGatewayConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PaymentGateway>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPaymentGatewayConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPaymentGatewayConnectionEdge = Edge &
  PaymentGatewayConnectionEdge & {
    __typename?: 'RootQueryToPaymentGatewayConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PaymentGateway;
  };

/** Page Info on the &quot;RootQueryToPaymentGatewayConnection&quot; */
export type RootQueryToPaymentGatewayConnectionPageInfo = PageInfo &
  PaymentGatewayConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPaymentGatewayConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPaymentGatewayConnection connection */
export type RootQueryToPaymentGatewayConnectionWhereArgs = {
  /** Include disabled payment gateways? */
  all: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the Plugin type */
export type RootQueryToPluginConnection = Connection &
  PluginConnection & {
    __typename?: 'RootQueryToPluginConnection';
    /** Edges for the RootQueryToPluginConnection connection */
    edges: Array<RootQueryToPluginConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Plugin>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPluginConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPluginConnectionEdge = Edge &
  PluginConnectionEdge & {
    __typename?: 'RootQueryToPluginConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Plugin;
  };

/** Page Info on the &quot;RootQueryToPluginConnection&quot; */
export type RootQueryToPluginConnectionPageInfo = PageInfo &
  PluginConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPluginConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPluginConnection connection */
export type RootQueryToPluginConnectionWhereArgs = {
  /** Show plugin based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve plugins where plugin status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status: InputMaybe<PluginStatusEnum>;
};

/** Connection between the RootQuery type and the post type */
export type RootQueryToPostConnection = Connection &
  PostConnection & {
    __typename?: 'RootQueryToPostConnection';
    /** Edges for the RootQueryToPostConnection connection */
    edges: Array<RootQueryToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'RootQueryToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;RootQueryToPostConnection&quot; */
export type RootQueryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the postFormat type */
export type RootQueryToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: 'RootQueryToPostFormatConnection';
    /** Edges for the RootQueryToPostFormatConnection connection */
    edges: Array<RootQueryToPostFormatConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PostFormat>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPostFormatConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: 'RootQueryToPostFormatConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PostFormat;
  };

/** Page Info on the &quot;RootQueryToPostFormatConnection&quot; */
export type RootQueryToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPostFormatConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the productCategory type */
export type RootQueryToProductCategoryConnection = Connection &
  ProductCategoryConnection & {
    __typename?: 'RootQueryToProductCategoryConnection';
    /** Edges for the RootQueryToProductCategoryConnection connection */
    edges: Array<RootQueryToProductCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductCategory>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToProductCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToProductCategoryConnectionEdge = Edge &
  ProductCategoryConnectionEdge & {
    __typename?: 'RootQueryToProductCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductCategory;
  };

/** Page Info on the &quot;RootQueryToProductCategoryConnection&quot; */
export type RootQueryToProductCategoryConnectionPageInfo = PageInfo &
  ProductCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToProductCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToProductCategoryConnection connection */
export type RootQueryToProductCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the productTag type */
export type RootQueryToProductTagConnection = Connection &
  ProductTagConnection & {
    __typename?: 'RootQueryToProductTagConnection';
    /** Edges for the RootQueryToProductTagConnection connection */
    edges: Array<RootQueryToProductTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductTag>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToProductTagConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToProductTagConnectionEdge = Edge &
  ProductTagConnectionEdge & {
    __typename?: 'RootQueryToProductTagConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductTag;
  };

/** Page Info on the &quot;RootQueryToProductTagConnection&quot; */
export type RootQueryToProductTagConnectionPageInfo = PageInfo &
  ProductTagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToProductTagConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToProductTagConnection connection */
export type RootQueryToProductTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the productType type */
export type RootQueryToProductTypeConnection = Connection &
  ProductTypeConnection & {
    __typename?: 'RootQueryToProductTypeConnection';
    /** Edges for the RootQueryToProductTypeConnection connection */
    edges: Array<RootQueryToProductTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductType>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToProductTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToProductTypeConnectionEdge = Edge &
  ProductTypeConnectionEdge & {
    __typename?: 'RootQueryToProductTypeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductType;
  };

/** Page Info on the &quot;RootQueryToProductTypeConnection&quot; */
export type RootQueryToProductTypeConnectionPageInfo = PageInfo &
  ProductTypeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToProductTypeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToProductTypeConnection connection */
export type RootQueryToProductTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the ProductUnion type */
export type RootQueryToProductUnionConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'RootQueryToProductUnionConnection';
    /** Edges for the RootQueryToProductUnionConnection connection */
    edges: Array<RootQueryToProductUnionConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToProductUnionConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToProductUnionConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'RootQueryToProductUnionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;RootQueryToProductUnionConnection&quot; */
export type RootQueryToProductUnionConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToProductUnionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToProductUnionConnection connection */
export type RootQueryToProductUnionConnectionWhereArgs = {
  /** Wallet address requesting the product */
  address: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the RootQuery type and the Refund type */
export type RootQueryToRefundConnection = Connection &
  RefundConnection & {
    __typename?: 'RootQueryToRefundConnection';
    /** Edges for the RootQueryToRefundConnection connection */
    edges: Array<RootQueryToRefundConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Refund>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToRefundConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToRefundConnectionEdge = Edge &
  RefundConnectionEdge & {
    __typename?: 'RootQueryToRefundConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Refund;
  };

/** Page Info on the &quot;RootQueryToRefundConnection&quot; */
export type RootQueryToRefundConnectionPageInfo = PageInfo &
  RefundConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToRefundConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToRefundConnection connection */
export type RootQueryToRefundConnectionWhereArgs = {
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to refunds from a specific group of order IDs. */
  orderIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostTypeOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit results to those matching a string. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to refunds assigned a specific status. */
  statuses: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'RootQueryToRevisionsConnection';
    /** Edges for the RootQueryToRevisionsConnection connection */
    edges: Array<RootQueryToRevisionsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToRevisionsConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToRevisionsConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RootQueryToRevisionsConnection&quot; */
export type RootQueryToRevisionsConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToRevisionsConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToRevisionsConnection connection */
export type RootQueryToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the shippingClass type */
export type RootQueryToShippingClassConnection = Connection &
  ShippingClassConnection & {
    __typename?: 'RootQueryToShippingClassConnection';
    /** Edges for the RootQueryToShippingClassConnection connection */
    edges: Array<RootQueryToShippingClassConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ShippingClass>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToShippingClassConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToShippingClassConnectionEdge = Edge &
  ShippingClassConnectionEdge & {
    __typename?: 'RootQueryToShippingClassConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ShippingClass;
  };

/** Page Info on the &quot;RootQueryToShippingClassConnection&quot; */
export type RootQueryToShippingClassConnectionPageInfo = PageInfo &
  ShippingClassConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToShippingClassConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToShippingClassConnection connection */
export type RootQueryToShippingClassConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the ShippingMethod type */
export type RootQueryToShippingMethodConnection = Connection &
  ShippingMethodConnection & {
    __typename?: 'RootQueryToShippingMethodConnection';
    /** Edges for the RootQueryToShippingMethodConnection connection */
    edges: Array<RootQueryToShippingMethodConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ShippingMethod>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToShippingMethodConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToShippingMethodConnectionEdge = Edge &
  ShippingMethodConnectionEdge & {
    __typename?: 'RootQueryToShippingMethodConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ShippingMethod;
  };

/** Page Info on the &quot;RootQueryToShippingMethodConnection&quot; */
export type RootQueryToShippingMethodConnectionPageInfo = PageInfo &
  ShippingMethodConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToShippingMethodConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the tag type */
export type RootQueryToTagConnection = Connection &
  TagConnection & {
    __typename?: 'RootQueryToTagConnection';
    /** Edges for the RootQueryToTagConnection connection */
    edges: Array<RootQueryToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: 'RootQueryToTagConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;RootQueryToTagConnection&quot; */
export type RootQueryToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTagConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the TaxRate type */
export type RootQueryToTaxRateConnection = Connection &
  TaxRateConnection & {
    __typename?: 'RootQueryToTaxRateConnection';
    /** Edges for the RootQueryToTaxRateConnection connection */
    edges: Array<RootQueryToTaxRateConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxRate>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxRateConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxRateConnectionEdge = Edge &
  TaxRateConnectionEdge & {
    __typename?: 'RootQueryToTaxRateConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxRate;
  };

/** Page Info on the &quot;RootQueryToTaxRateConnection&quot; */
export type RootQueryToTaxRateConnectionPageInfo = PageInfo &
  TaxRateConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxRateConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTaxRateConnection connection */
export type RootQueryToTaxRateConnectionWhereArgs = {
  /** Sort by tax class. */
  class: InputMaybe<TaxClassEnum>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<TaxRateConnectionOrderbyInput>>>;
  /** Filter results by a post code. */
  postCode: InputMaybe<Scalars['String']['input']>;
  /** Filter results by a group of post codes. */
  postCodeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Connection between the RootQuery type and the Taxonomy type */
export type RootQueryToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: 'RootQueryToTaxonomyConnection';
    /** Edges for the RootQueryToTaxonomyConnection connection */
    edges: Array<RootQueryToTaxonomyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Taxonomy>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomyConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: 'RootQueryToTaxonomyConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Taxonomy;
  };

/** Page Info on the &quot;RootQueryToTaxonomyConnection&quot; */
export type RootQueryToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomyConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the TermNode type */
export type RootQueryToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'RootQueryToTermNodeConnection';
    /** Edges for the RootQueryToTermNodeConnection connection */
    edges: Array<RootQueryToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'RootQueryToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;RootQueryToTermNodeConnection&quot; */
export type RootQueryToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the Theme type */
export type RootQueryToThemeConnection = Connection &
  ThemeConnection & {
    __typename?: 'RootQueryToThemeConnection';
    /** Edges for the RootQueryToThemeConnection connection */
    edges: Array<RootQueryToThemeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Theme>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToThemeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToThemeConnectionEdge = Edge &
  ThemeConnectionEdge & {
    __typename?: 'RootQueryToThemeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Theme;
  };

/** Page Info on the &quot;RootQueryToThemeConnection&quot; */
export type RootQueryToThemeConnectionPageInfo = PageInfo &
  ThemeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToThemeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the User type */
export type RootQueryToUserConnection = Connection &
  UserConnection & {
    __typename?: 'RootQueryToUserConnection';
    /** Edges for the RootQueryToUserConnection connection */
    edges: Array<RootQueryToUserConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<User>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToUserConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToUserConnectionEdge = Edge &
  UserConnectionEdge & {
    __typename?: 'RootQueryToUserConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: User;
  };

/** Page Info on the &quot;RootQueryToUserConnection&quot; */
export type RootQueryToUserConnectionPageInfo = PageInfo &
  UserConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToUserConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** Array of userIds to exclude. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** The user login. */
  login: InputMaybe<Scalars['String']['input']>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The user nicename. */
  nicename: InputMaybe<Scalars['String']['input']>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role: InputMaybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns: InputMaybe<Array<InputMaybe<UsersConnectionSearchColumnEnum>>>;
};

/** Connection between the RootQuery type and the UserRole type */
export type RootQueryToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: 'RootQueryToUserRoleConnection';
    /** Edges for the RootQueryToUserRoleConnection connection */
    edges: Array<RootQueryToUserRoleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<UserRole>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToUserRoleConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: 'RootQueryToUserRoleConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: UserRole;
  };

/** Page Info on the &quot;RootQueryToUserRoleConnection&quot; */
export type RootQueryToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToUserRoleConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the visibleProduct type */
export type RootQueryToVisibleProductConnection = Connection &
  VisibleProductConnection & {
    __typename?: 'RootQueryToVisibleProductConnection';
    /** Edges for the RootQueryToVisibleProductConnection connection */
    edges: Array<RootQueryToVisibleProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<VisibleProduct>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToVisibleProductConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToVisibleProductConnectionEdge = Edge &
  VisibleProductConnectionEdge & {
    __typename?: 'RootQueryToVisibleProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: VisibleProduct;
  };

/** Page Info on the &quot;RootQueryToVisibleProductConnection&quot; */
export type RootQueryToVisibleProductConnectionPageInfo = PageInfo &
  VisibleProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToVisibleProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToVisibleProductConnection connection */
export type RootQueryToVisibleProductConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache: InputMaybe<Scalars['Boolean']['input']>;
};

/** The strategy to use when loading the script */
export enum ScriptLoadingStrategyEnum {
  /** Use the script `async` attribute */
  Async = 'ASYNC',
  /** Use the script `defer` attribute */
  Defer = 'DEFER'
}

/** Input for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the user's username or email address. */
  username: Scalars['String']['input'];
};

/** The payload for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Whether the mutation completed successfully. This does NOT necessarily mean that an email was sent. */
  success: Maybe<Scalars['Boolean']['output']>;
  /**
   * The user that the password reset email was sent to
   * @deprecated This field will be removed in a future version of WPGraphQL
   */
  user: Maybe<User>;
};

/** Input for the setDefaultPaymentMethod mutation. */
export type SetDefaultPaymentMethodInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Token ID of the payment token being deleted. */
  tokenId: Scalars['Int']['input'];
};

/** The payload for the setDefaultPaymentMethod mutation. */
export type SetDefaultPaymentMethodPayload = {
  __typename?: 'SetDefaultPaymentMethodPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Status of the request */
  status: Maybe<Scalars['String']['output']>;
  /** Preferred payment method token */
  token: Maybe<PaymentToken>;
};

/** All of the registered settings */
export type Settings = {
  __typename?: 'Settings';
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultCommentStatus: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultPingStatus: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsDateFormat: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsDescription: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsEmail: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsLanguage: Maybe<Scalars['String']['output']>;
  /** Settings of the the integer Settings Group */
  generalSettingsStartOfWeek: Maybe<Scalars['Int']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsTimeFormat: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsTimezone: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsTitle: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsUrl: Maybe<Scalars['String']['output']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPageForPosts: Maybe<Scalars['Int']['output']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPageOnFront: Maybe<Scalars['Int']['output']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPostsPerPage: Maybe<Scalars['Int']['output']>;
  /** Settings of the the string Settings Group */
  readingSettingsShowOnFront: Maybe<Scalars['String']['output']>;
  /** Settings of the the integer Settings Group */
  writingSettingsDefaultCategory: Maybe<Scalars['Int']['output']>;
  /** Settings of the the string Settings Group */
  writingSettingsDefaultPostFormat: Maybe<Scalars['String']['output']>;
  /** Settings of the the boolean Settings Group */
  writingSettingsUseSmilies: Maybe<Scalars['Boolean']['output']>;
};

/** The shippingClass type */
export type ShippingClass = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ShippingClass';
    /** Connection between the ShippingClass type and the ContentNode type */
    contentNodes: Maybe<ShippingClassToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /** Connection between the ShippingClass type and the Product type */
    products: Maybe<ShippingClassToProductConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    shippingClassId: Maybe<Scalars['Int']['output']>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the ShippingClass type and the Taxonomy type */
    taxonomy: Maybe<ShippingClassToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The shippingClass type */
export type ShippingClassContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ShippingClassToContentNodeConnectionWhereArgs>;
};

/** The shippingClass type */
export type ShippingClassEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The shippingClass type */
export type ShippingClassEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The shippingClass type */
export type ShippingClassProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ShippingClassToProductConnectionWhereArgs>;
};

/** Connection to shippingClass Nodes */
export type ShippingClassConnection = {
  /** A list of edges (relational context) between RootQuery and connected shippingClass Nodes */
  edges: Array<ShippingClassConnectionEdge>;
  /** A list of connected shippingClass Nodes */
  nodes: Array<ShippingClass>;
  /** Information about pagination in a connection. */
  pageInfo: ShippingClassConnectionPageInfo;
};

/** Edge between a Node and a connected shippingClass */
export type ShippingClassConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected shippingClass Node */
  node: ShippingClass;
};

/** Page Info on the connected ShippingClassConnectionEdge */
export type ShippingClassConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ShippingClassIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the ShippingClass type and the ContentNode type */
export type ShippingClassToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'ShippingClassToContentNodeConnection';
    /** Edges for the ShippingClassToContentNodeConnection connection */
    edges: Array<ShippingClassToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ShippingClassToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ShippingClassToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'ShippingClassToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ShippingClassToContentNodeConnection&quot; */
export type ShippingClassToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'ShippingClassToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ShippingClassToContentNodeConnection connection */
export type ShippingClassToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfShippingClassEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the ShippingClass type and the Product type */
export type ShippingClassToProductConnection = Connection &
  ProductConnection & {
    __typename?: 'ShippingClassToProductConnection';
    /** Edges for the ShippingClassToProductConnection connection */
    edges: Array<ShippingClassToProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Product>;
    /** Information about pagination in a connection. */
    pageInfo: ShippingClassToProductConnectionPageInfo;
  };

/** An edge in a connection */
export type ShippingClassToProductConnectionEdge = Edge &
  ProductConnectionEdge & {
    __typename?: 'ShippingClassToProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Product;
  };

/** Page Info on the &quot;ShippingClassToProductConnection&quot; */
export type ShippingClassToProductConnectionPageInfo = PageInfo &
  ProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ShippingClassToProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ShippingClassToProductConnection connection */
export type ShippingClassToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the ShippingClass type and the Taxonomy type */
export type ShippingClassToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'ShippingClassToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** a shipping line object */
export type ShippingLine = Node & {
  __typename?: 'ShippingLine';
  /** The ID of the order item in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** The ID of the order item in the database */
  id: Scalars['ID']['output'];
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** Shipping Line&#039;s shipping method name */
  methodTitle: Maybe<Scalars['String']['output']>;
  /** The Id of the order the order item belongs to. */
  orderId: Maybe<Scalars['Int']['output']>;
  /** Shipping Line&#039;s shipping method */
  shippingMethod: Maybe<ShippingMethod>;
  /** Line tax class */
  taxClass: Maybe<TaxClassEnum>;
  /** Line taxes */
  taxes: Maybe<Array<Maybe<OrderItemTax>>>;
  /** Line total (after discounts) */
  total: Maybe<Scalars['String']['output']>;
  /** Line total tax (after discounts) */
  totalTax: Maybe<Scalars['String']['output']>;
};

/** a shipping line object */
export type ShippingLineMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection to ShippingLine Nodes */
export type ShippingLineConnection = {
  /** A list of edges (relational context) between Order and connected ShippingLine Nodes */
  edges: Array<ShippingLineConnectionEdge>;
  /** A list of connected ShippingLine Nodes */
  nodes: Array<ShippingLine>;
  /** Information about pagination in a connection. */
  pageInfo: ShippingLineConnectionPageInfo;
};

/** Edge between a Node and a connected ShippingLine */
export type ShippingLineConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ShippingLine Node */
  node: ShippingLine;
};

/** Page Info on the connected ShippingLineConnectionEdge */
export type ShippingLineConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Shipping lines data. */
export type ShippingLineInput = {
  /** Shipping Line ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** Shipping instance ID. */
  instanceId: InputMaybe<Scalars['String']['input']>;
  /** Meta data. */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Shipping method ID. */
  methodId: Scalars['String']['input'];
  /** Shipping method name. */
  methodTitle: Scalars['String']['input'];
  /** Line total (after discounts). */
  total: Scalars['String']['input'];
};

/** A shipping method object */
export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  /** The ID of the shipping method in the database */
  databaseId: Scalars['ID']['output'];
  /** Shipping method description. */
  description: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier for the tax rate. */
  id: Scalars['ID']['output'];
  /** Shipping method title. */
  title: Maybe<Scalars['String']['output']>;
};

/** Connection to ShippingMethod Nodes */
export type ShippingMethodConnection = {
  /** A list of edges (relational context) between RootQuery and connected ShippingMethod Nodes */
  edges: Array<ShippingMethodConnectionEdge>;
  /** A list of connected ShippingMethod Nodes */
  nodes: Array<ShippingMethod>;
  /** Information about pagination in a connection. */
  pageInfo: ShippingMethodConnectionPageInfo;
};

/** Edge between a Node and a connected ShippingMethod */
export type ShippingMethodConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected ShippingMethod Node */
  node: ShippingMethod;
};

/** Page Info on the connected ShippingMethodConnectionEdge */
export type ShippingMethodConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single Shipping Method. Default is ID. */
export enum ShippingMethodIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Shipping package object */
export type ShippingPackage = {
  __typename?: 'ShippingPackage';
  /** Shipping package details */
  packageDetails: Maybe<Scalars['String']['output']>;
  /** Shipping package rates */
  rates: Maybe<Array<Maybe<ShippingRate>>>;
  /** This shipping package supports the shipping calculator. */
  supportsShippingCalculator: Maybe<Scalars['Boolean']['output']>;
};

/** Shipping rate object */
export type ShippingRate = {
  __typename?: 'ShippingRate';
  /** Shipping rate cost */
  cost: Maybe<Scalars['String']['output']>;
  /** Shipping rate ID */
  id: Scalars['ID']['output'];
  /** Shipping instance ID */
  instanceId: Maybe<Scalars['Int']['output']>;
  /** Shipping rate label */
  label: Maybe<Scalars['String']['output']>;
  /** Shipping method ID */
  methodId: Scalars['ID']['output'];
};

/** A simple attribute object */
export type SimpleAttribute = Attribute &
  Node & {
    __typename?: 'SimpleAttribute';
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Name of attribute */
    name: Maybe<Scalars['String']['output']>;
    /** Selected value of attribute */
    value: Maybe<Scalars['String']['output']>;
  };

/** A item in the cart */
export type SimpleCartItem = CartItem &
  Node & {
    __typename?: 'SimpleCartItem';
    /** Object meta data */
    extraData: Maybe<Array<Maybe<MetaData>>>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** CartItem ID */
    key: Scalars['ID']['output'];
    /** Connection between the CartItem type and the Product type */
    product: Maybe<CartItemToProductConnectionEdge>;
    /** Quantity of the product */
    quantity: Maybe<Scalars['Int']['output']>;
    /** Item&#039;s subtotal */
    subtotal: Maybe<Scalars['String']['output']>;
    /** Item&#039;s subtotal tax */
    subtotalTax: Maybe<Scalars['String']['output']>;
    /** Item&#039;s tax */
    tax: Maybe<Scalars['String']['output']>;
    /** Item&#039;s total */
    total: Maybe<Scalars['String']['output']>;
    /** Connection between the CartItem type and the ProductVariation type */
    variation: Maybe<CartItemToProductVariationConnectionEdge>;
  };

/** A item in the cart */
export type SimpleCartItemExtraDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A item in the cart */
export type SimpleCartItemProductArgs = {
  where: InputMaybe<CartItemToProductConnectionWhereArgs>;
};

/** A item in the cart */
export type SimpleCartItemSubtotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A item in the cart */
export type SimpleCartItemSubtotalTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A item in the cart */
export type SimpleCartItemTaxArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A item in the cart */
export type SimpleCartItemTotalArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProduct = ContentNode &
  DatabaseIdentifier &
  DownloadableProduct &
  InventoriedProduct &
  MenuItemLinkable &
  Node &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  Product &
  ProductUnion &
  ProductWithAttributes &
  ProductWithDimensions &
  ProductWithPricing &
  UniformResourceIdentifiable & {
    __typename?: 'SimpleProduct';
    /** Connection between the Product type and the paDefault type */
    allPaDefault: Maybe<ProductToPaDefaultConnection>;
    /** Connection between the Product type and the paPrimaryCurrency type */
    allPaPrimaryCurrency: Maybe<ProductToPaPrimaryCurrencyConnection>;
    /** Connection between the Product type and the ProductAttribute type */
    attributes: Maybe<ProductToProductAttributeConnection>;
    /** Product average count */
    averageRating: Maybe<Scalars['Float']['output']>;
    /** Product backorders status */
    backorders: Maybe<BackordersEnum>;
    /** Can product be backordered? */
    backordersAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Catalog visibility */
    catalogVisibility: Maybe<CatalogVisibilityEnum>;
    /** NFT collection type */
    collectionType: Maybe<Scalars['String']['output']>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** Connection between the SimpleProduct type and the ProductUnion type */
    crossSell: Maybe<SimpleProductToProductUnionConnection>;
    /** Product or variation ID */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Date on sale from */
    dateOnSaleFrom: Maybe<Scalars['String']['output']>;
    /** Date on sale to */
    dateOnSaleTo: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductWithAttributes type and the VariationAttribute type */
    defaultAttributes: Maybe<ProductWithAttributesToVariationAttributeConnection>;
    /** Product description */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** Download expiry */
    downloadExpiry: Maybe<Scalars['Int']['output']>;
    /** Download limit */
    downloadLimit: Maybe<Scalars['Int']['output']>;
    /** Is downloadable? */
    downloadable: Maybe<Scalars['Boolean']['output']>;
    /** Product downloads */
    downloads: Maybe<Array<Maybe<ProductDownload>>>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt: Maybe<Scalars['String']['output']>;
    /** If the product is featured */
    featured: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Product type and the MediaItem type */
    galleryImages: Maybe<ProductToMediaItemConnection>;
    /** Connection between the Product type and the GlobalProductAttribute type */
    globalAttributes: Maybe<ProductToGlobalProductAttributeConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the product object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s height */
    height: Maybe<Scalars['String']['output']>;
    /** Product or variation global ID */
    id: Scalars['ID']['output'];
    /** Main image */
    image: Maybe<MediaItem>;
    /** Check if user is allowlist to see the product */
    isAllowlisted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** Product&#039;s length */
    length: Maybe<Scalars['String']['output']>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the LocalProductAttribute type */
    localAttributes: Maybe<ProductToLocalProductAttributeConnection>;
    /** Low stock amount */
    lowStockAmount: Maybe<Scalars['Int']['output']>;
    /** If product manage stock */
    manageStock: Maybe<ManageStockEnum>;
    /** Menu order */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** Object meta data */
    metaData: Maybe<Array<Maybe<MetaData>>>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** Product name */
    name: Maybe<Scalars['String']['output']>;
    /** Is product on sale? */
    onSale: Maybe<Scalars['Boolean']['output']>;
    /** The password for the product object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the Product type */
    preview: Maybe<ProductToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** Product&#039;s active price */
    price: Maybe<Scalars['String']['output']>;
    /** Pricing group with group discounts */
    pricing: Maybe<ProductWithGroupDiscount>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    productId: Scalars['Int']['output'];
    /** Connection between the Product type and the productType type */
    productTypes: Maybe<ProductToProductTypeConnection>;
    /** Can product be purchased? */
    purchasable: Maybe<Scalars['Boolean']['output']>;
    /** Purchase note */
    purchaseNote: Maybe<Scalars['String']['output']>;
    /** Product&#039;s regular price */
    regularPrice: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the ProductUnion type */
    related: Maybe<ProductToProductUnionConnection>;
    /** Product review count */
    reviewCount: Maybe<Scalars['Int']['output']>;
    /** Connection between the Product type and the Comment type */
    reviews: Maybe<ProductToCommentConnection>;
    /** If reviews are allowed */
    reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s sale price */
    salePrice: Maybe<Scalars['String']['output']>;
    /** shipping class ID */
    shippingClassId: Maybe<Scalars['Int']['output']>;
    /** Connection between the Product type and the shippingClass type */
    shippingClasses: Maybe<ProductToShippingClassConnection>;
    /** Does product need to be shipped? */
    shippingRequired: Maybe<Scalars['Boolean']['output']>;
    /** Is product shipping taxable? */
    shippingTaxable: Maybe<Scalars['Boolean']['output']>;
    /** Product short description */
    shortDescription: Maybe<Scalars['String']['output']>;
    /** Product SKU */
    sku: Maybe<Scalars['String']['output']>;
    /** Product slug */
    slug: Maybe<Scalars['String']['output']>;
    /** If should be sold individually */
    soldIndividually: Maybe<Scalars['Boolean']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** Number of items available for sale */
    stockQuantity: Maybe<Scalars['Int']['output']>;
    /** Product stock status */
    stockStatus: Maybe<StockStatusEnum>;
    /** Tax class */
    taxClass: Maybe<TaxClassEnum>;
    /** Tax status */
    taxStatus: Maybe<TaxStatusEnum>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** Connection between the Product type and the TermNode type */
    terms: Maybe<ProductToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** Number total of sales */
    totalSales: Maybe<Scalars['Int']['output']>;
    /** Product type */
    type: Maybe<ProductTypesEnum>;
    /** Connection between the Product type and the ProductUnion type */
    upsell: Maybe<ProductToUpsellConnection>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Is product virtual? */
    virtual: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the Product type and the visibleProduct type */
    visibleProducts: Maybe<ProductToVisibleProductConnection>;
    /** Product&#039;s weight */
    weight: Maybe<Scalars['String']['output']>;
    /** Product&#039;s width */
    width: Maybe<Scalars['String']['output']>;
  };

/** A simple product object */
export type SimpleProductAllPaDefaultArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaDefaultConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductAllPaPrimaryCurrencyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaPrimaryCurrencyConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductAttributeConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductCrossSellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<SimpleProductToProductUnionConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductDefaultAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A simple product object */
export type SimpleProductDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A simple product object */
export type SimpleProductEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A simple product object */
export type SimpleProductExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductGalleryImagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToMediaItemConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductGlobalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A simple product object */
export type SimpleProductIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A simple product object */
export type SimpleProductLocalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A simple product object */
export type SimpleProductMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A simple product object */
export type SimpleProductPreviewArgs = {
  where: InputMaybe<ProductToPreviewConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A simple product object */
export type SimpleProductProductTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductTypeConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductRelatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductUnionConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductReviewsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToCommentConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductShippingClassesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToShippingClassConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToTermNodeConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A simple product object */
export type SimpleProductUpsellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToUpsellConnectionWhereArgs>;
};

/** A simple product object */
export type SimpleProductVisibleProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToVisibleProductConnectionWhereArgs>;
};

/** Connection between the SimpleProduct type and the ProductUnion type */
export type SimpleProductToProductUnionConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'SimpleProductToProductUnionConnection';
    /** Edges for the SimpleProductToProductUnionConnection connection */
    edges: Array<SimpleProductToProductUnionConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: SimpleProductToProductUnionConnectionPageInfo;
  };

/** An edge in a connection */
export type SimpleProductToProductUnionConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'SimpleProductToProductUnionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;SimpleProductToProductUnionConnection&quot; */
export type SimpleProductToProductUnionConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'SimpleProductToProductUnionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the SimpleProductToProductUnionConnection connection */
export type SimpleProductToProductUnionConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** A product variation */
export type SimpleProductVariation = ContentNode &
  DownloadableProduct &
  InventoriedProduct &
  Node &
  NodeWithFeaturedImage &
  ProductUnion &
  ProductVariation &
  ProductWithDimensions &
  ProductWithPricing &
  UniformResourceIdentifiable & {
    __typename?: 'SimpleProductVariation';
    /** Connection between the ProductVariation type and the VariationAttribute type */
    attributes: Maybe<ProductVariationToVariationAttributeConnection>;
    /** Product average count */
    averageRating: Maybe<Scalars['Float']['output']>;
    /** Product backorders status */
    backorders: Maybe<BackordersEnum>;
    /** Can product be backordered? */
    backordersAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Catalog visibility */
    catalogVisibility: Maybe<CatalogVisibilityEnum>;
    /** NFT collection type */
    collectionType: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** Product or variation ID */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Date on sale from */
    dateOnSaleFrom: Maybe<Scalars['String']['output']>;
    /** Date on sale to */
    dateOnSaleTo: Maybe<Scalars['String']['output']>;
    /** Product description */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** Download expiry */
    downloadExpiry: Maybe<Scalars['Int']['output']>;
    /** Download limit */
    downloadLimit: Maybe<Scalars['Int']['output']>;
    /** Is downloadable? */
    downloadable: Maybe<Scalars['Boolean']['output']>;
    /** Product downloads */
    downloads: Maybe<Array<Maybe<ProductDownload>>>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** If the product is featured */
    featured: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Does product variation have any visible attributes */
    hasAttributes: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s height */
    height: Maybe<Scalars['String']['output']>;
    /** Product or variation global ID */
    id: Scalars['ID']['output'];
    /** Main image */
    image: Maybe<MediaItem>;
    /** Check if user is allowlist to see the product */
    isAllowlisted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** Product&#039;s length */
    length: Maybe<Scalars['String']['output']>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** Low stock amount */
    lowStockAmount: Maybe<Scalars['Int']['output']>;
    /** If product manage stock */
    manageStock: Maybe<ManageStockEnum>;
    /** Menu order */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** Object meta data */
    metaData: Maybe<Array<Maybe<MetaData>>>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** Product name */
    name: Maybe<Scalars['String']['output']>;
    /** Is product on sale? */
    onSale: Maybe<Scalars['Boolean']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent: Maybe<ProductVariationToVariableProductConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** Product&#039;s active price */
    price: Maybe<Scalars['String']['output']>;
    /** Pricing group with group discounts */
    pricing: Maybe<ProductWithGroupDiscount>;
    /** Can product be purchased? */
    purchasable: Maybe<Scalars['Boolean']['output']>;
    /** Purchase note */
    purchaseNote: Maybe<Scalars['String']['output']>;
    /** Product&#039;s regular price */
    regularPrice: Maybe<Scalars['String']['output']>;
    /** Product review count */
    reviewCount: Maybe<Scalars['Int']['output']>;
    /** If reviews are allowed */
    reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s sale price */
    salePrice: Maybe<Scalars['String']['output']>;
    /** Product variation shipping class */
    shippingClass: Maybe<Scalars['String']['output']>;
    /** shipping class ID */
    shippingClassId: Maybe<Scalars['Int']['output']>;
    /** Does product need to be shipped? */
    shippingRequired: Maybe<Scalars['Boolean']['output']>;
    /** Is product shipping taxable? */
    shippingTaxable: Maybe<Scalars['Boolean']['output']>;
    /** Product short description */
    shortDescription: Maybe<Scalars['String']['output']>;
    /** Product SKU */
    sku: Maybe<Scalars['String']['output']>;
    /** Product slug */
    slug: Maybe<Scalars['String']['output']>;
    /** If should be sold individually */
    soldIndividually: Maybe<Scalars['Boolean']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** Number of items available for sale */
    stockQuantity: Maybe<Scalars['Int']['output']>;
    /** Product stock status */
    stockStatus: Maybe<StockStatusEnum>;
    /** Tax class */
    taxClass: Maybe<TaxClassEnum>;
    /** Tax status */
    taxStatus: Maybe<TaxStatusEnum>;
    /** The template assigned to a node of content */
    template: Maybe<ContentTemplate>;
    /** Number total of sales */
    totalSales: Maybe<Scalars['Int']['output']>;
    /** Product type */
    type: Maybe<ProductTypesEnum>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Is product virtual? */
    virtual: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s weight */
    weight: Maybe<Scalars['String']['output']>;
    /** Product&#039;s width */
    width: Maybe<Scalars['String']['output']>;
  };

/** A product variation */
export type SimpleProductVariationAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product variation */
export type SimpleProductVariationDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A product variation */
export type SimpleProductVariationEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product variation */
export type SimpleProductVariationEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A product variation */
export type SimpleProductVariationIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A product variation */
export type SimpleProductVariationMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A product variation */
export type SimpleProductVariationPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product variation */
export type SimpleProductVariationPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A product variation */
export type SimpleProductVariationRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product variation */
export type SimpleProductVariationSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A product variation */
export type SimpleProductVariationShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Single attribute term count */
export type SingleAttributeCount = {
  __typename?: 'SingleAttributeCount';
  /** Number of products. */
  count: Maybe<Scalars['Int']['output']>;
  /** Term object. */
  node: Maybe<TermNode>;
  /** Term ID */
  termId: Scalars['ID']['output'];
};

/** Single stock status count */
export type StockStatusCount = {
  __typename?: 'StockStatusCount';
  /** Number of products. */
  count: Maybe<Scalars['Int']['output']>;
  /** Status */
  status: StockStatusEnum;
};

/** Product stock status enumeration */
export enum StockStatusEnum {
  InStock = 'IN_STOCK',
  OnBackorder = 'ON_BACKORDER',
  OutOfStock = 'OUT_OF_STOCK'
}

/** The tag type */
export type Tag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Tag';
    /** Connection between the Tag type and the ContentNode type */
    contentNodes: Maybe<TagToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /** Connection between the Tag type and the post type */
    posts: Maybe<TagToPostConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    tagId: Maybe<Scalars['Int']['output']>;
    /** Connection between the Tag type and the Taxonomy type */
    taxonomy: Maybe<TagToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
  };

/** The tag type */
export type TagContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<TagToContentNodeConnectionWhereArgs>;
};

/** The tag type */
export type TagEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The tag type */
export type TagEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The tag type */
export type TagPostsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<TagToPostConnectionWhereArgs>;
};

/** Connection to tag Nodes */
export type TagConnection = {
  /** A list of edges (relational context) between RootQuery and connected tag Nodes */
  edges: Array<TagConnectionEdge>;
  /** A list of connected tag Nodes */
  nodes: Array<Tag>;
  /** Information about pagination in a connection. */
  pageInfo: TagConnectionPageInfo;
};

/** Edge between a Node and a connected tag */
export type TagConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected tag Node */
  node: Tag;
};

/** Page Info on the connected TagConnectionEdge */
export type TagConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the Tag type and the ContentNode type */
export type TagToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TagToContentNodeConnection';
    /** Edges for the TagToContentNodeConnection connection */
    edges: Array<TagToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TagToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'TagToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;TagToContentNodeConnection&quot; */
export type TagToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TagToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Tag type and the post type */
export type TagToPostConnection = Connection &
  PostConnection & {
    __typename?: 'TagToPostConnection';
    /** Edges for the TagToPostConnection connection */
    edges: Array<TagToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: TagToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'TagToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;TagToPostConnection&quot; */
export type TagToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TagToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Tag type and the Taxonomy type */
export type TagToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TagToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Tax class enumeration */
export enum TaxClassEnum {
  /** Inherits Tax class from cart */
  InheritCart = 'INHERIT_CART',
  ReducedRate = 'REDUCED_RATE',
  /** Standard Tax rate */
  Standard = 'STANDARD',
  ZeroRate = 'ZERO_RATE'
}

/** a tax line object */
export type TaxLine = Node & {
  __typename?: 'TaxLine';
  /** The ID of the order item in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** The ID of the order item in the database */
  id: Scalars['ID']['output'];
  /** Is this a compound tax rate? */
  isCompound: Maybe<Scalars['Boolean']['output']>;
  /** Tax rate label */
  label: Maybe<Scalars['String']['output']>;
  /** Object meta data */
  metaData: Maybe<Array<Maybe<MetaData>>>;
  /** The Id of the order the order item belongs to. */
  orderId: Maybe<Scalars['Int']['output']>;
  /** Tax rate code/name */
  rateCode: Maybe<Scalars['String']['output']>;
  /** Tax line&#039;s shipping tax total */
  shippingTaxTotal: Maybe<Scalars['String']['output']>;
  /** Tax line&#039;s tax rate */
  taxRate: Maybe<TaxRate>;
  /** Tax total (not including shipping taxes) */
  taxTotal: Maybe<Scalars['String']['output']>;
};

/** a tax line object */
export type TaxLineMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection to TaxLine Nodes */
export type TaxLineConnection = {
  /** A list of edges (relational context) between Order and connected TaxLine Nodes */
  edges: Array<TaxLineConnectionEdge>;
  /** A list of connected TaxLine Nodes */
  nodes: Array<TaxLine>;
  /** Information about pagination in a connection. */
  pageInfo: TaxLineConnectionPageInfo;
};

/** Edge between a Node and a connected TaxLine */
export type TaxLineConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected TaxLine Node */
  node: TaxLine;
};

/** Page Info on the connected TaxLineConnectionEdge */
export type TaxLineConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** A Tax rate object */
export type TaxRate = Node & {
  __typename?: 'TaxRate';
  /** City name. */
  city: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Tax class. Default is standard. */
  class: Maybe<TaxClassEnum>;
  /** Whether or not this is a compound rate. */
  compound: Maybe<Scalars['Boolean']['output']>;
  /** Country ISO 3166 code. */
  country: Maybe<Scalars['String']['output']>;
  /** The ID of the customer in the database */
  databaseId: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier for the tax rate. */
  id: Scalars['ID']['output'];
  /** Tax rate name. */
  name: Maybe<Scalars['String']['output']>;
  /** Indicates the order that will appear in queries. */
  order: Maybe<Scalars['Int']['output']>;
  /** Postcode/ZIP. */
  postcode: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Tax priority. */
  priority: Maybe<Scalars['Int']['output']>;
  /** Tax rate. */
  rate: Maybe<Scalars['String']['output']>;
  /** Whether or not this tax rate also gets applied to shipping. */
  shipping: Maybe<Scalars['Boolean']['output']>;
  /** State code. */
  state: Maybe<Scalars['String']['output']>;
};

/** Connection to TaxRate Nodes */
export type TaxRateConnection = {
  /** A list of edges (relational context) between RootQuery and connected TaxRate Nodes */
  edges: Array<TaxRateConnectionEdge>;
  /** A list of connected TaxRate Nodes */
  nodes: Array<TaxRate>;
  /** Information about pagination in a connection. */
  pageInfo: TaxRateConnectionPageInfo;
};

/** Edge between a Node and a connected TaxRate */
export type TaxRateConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected TaxRate Node */
  node: TaxRate;
};

/** Field to order the connection by */
export enum TaxRateConnectionOrderbyEnum {
  Id = 'ID',
  Order = 'ORDER'
}

/** Options for ordering the connection */
export type TaxRateConnectionOrderbyInput = {
  field: TaxRateConnectionOrderbyEnum;
  order: InputMaybe<OrderEnum>;
};

/** Page Info on the connected TaxRateConnectionEdge */
export type TaxRateConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single Tax rate. Default is ID. */
export enum TaxRateIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Product tax status enumeration */
export enum TaxStatusEnum {
  None = 'NONE',
  Shipping = 'SHIPPING',
  Taxable = 'TAXABLE'
}

/** A taxonomy object */
export type Taxonomy = Node & {
  __typename?: 'Taxonomy';
  /** List of Content Types associated with the Taxonomy */
  connectedContentTypes: Maybe<TaxonomyToContentTypeConnection>;
  /** List of Term Nodes associated with the Taxonomy */
  connectedTerms: Maybe<TaxonomyToTermNodeConnection>;
  /** Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description */
  description: Maybe<Scalars['String']['output']>;
  /** The plural name of the post type within the GraphQL Schema. */
  graphqlPluralName: Maybe<Scalars['String']['output']>;
  /** The singular name of the post type within the GraphQL Schema. */
  graphqlSingleName: Maybe<Scalars['String']['output']>;
  /** Whether the taxonomy is hierarchical */
  hierarchical: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique identifier of the taxonomy object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Name of the taxonomy shown in the menu. Usually plural. */
  label: Maybe<Scalars['String']['output']>;
  /** The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label */
  name: Maybe<Scalars['String']['output']>;
  /** Whether the taxonomy is publicly queryable */
  public: Maybe<Scalars['Boolean']['output']>;
  /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
  restBase: Maybe<Scalars['String']['output']>;
  /** The REST Controller class assigned to handling this content type. */
  restControllerClass: Maybe<Scalars['String']['output']>;
  /** Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud */
  showCloud: Maybe<Scalars['Boolean']['output']>;
  /** Whether to display a column for the taxonomy on its post type listing screens. */
  showInAdminColumn: Maybe<Scalars['Boolean']['output']>;
  /** Whether to add the post type to the GraphQL Schema. */
  showInGraphql: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the taxonomy in the admin menu */
  showInMenu: Maybe<Scalars['Boolean']['output']>;
  /** Whether the taxonomy is available for selection in navigation menus. */
  showInNavMenus: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the taxonomy in the quick/bulk edit panel. */
  showInQuickEdit: Maybe<Scalars['Boolean']['output']>;
  /** Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace. */
  showInRest: Maybe<Scalars['Boolean']['output']>;
  /** Whether to generate and allow a UI for managing terms in this taxonomy in the admin */
  showUi: Maybe<Scalars['Boolean']['output']>;
};

/** A taxonomy object */
export type TaxonomyConnectedContentTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A taxonomy object */
export type TaxonomyConnectedTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to Taxonomy Nodes */
export type TaxonomyConnection = {
  /** A list of edges (relational context) between RootQuery and connected Taxonomy Nodes */
  edges: Array<TaxonomyConnectionEdge>;
  /** A list of connected Taxonomy Nodes */
  nodes: Array<Taxonomy>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomyConnectionPageInfo;
};

/** Edge between a Node and a connected Taxonomy */
export type TaxonomyConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Taxonomy Node */
  node: Taxonomy;
};

/** Page Info on the connected TaxonomyConnectionEdge */
export type TaxonomyConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  Category = 'CATEGORY',
  /** Taxonomy enum graphql_document_group */
  Graphqldocumentgroup = 'GRAPHQLDOCUMENTGROUP',
  /** Taxonomy enum pa_default */
  Padefault = 'PADEFAULT',
  /** Taxonomy enum pa_primary-currency */
  Paprimarycurrency = 'PAPRIMARYCURRENCY',
  /** Taxonomy enum post_format */
  Postformat = 'POSTFORMAT',
  /** Taxonomy enum product_cat */
  Productcategory = 'PRODUCTCATEGORY',
  /** Taxonomy enum product_tag */
  Producttag = 'PRODUCTTAG',
  /** Taxonomy enum product_type */
  Producttype = 'PRODUCTTYPE',
  /** Taxonomy enum product_shipping_class */
  Shippingclass = 'SHIPPINGCLASS',
  /** Taxonomy enum post_tag */
  Tag = 'TAG',
  /** Taxonomy enum product_visibility */
  Visibleproduct = 'VISIBLEPRODUCT'
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the taxonomy */
  Name = 'NAME'
}

/** Taxonomy query operators */
export enum TaxonomyOperatorEnum {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN'
}

/** Connection between the Taxonomy type and the ContentType type */
export type TaxonomyToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: 'TaxonomyToContentTypeConnection';
    /** Edges for the TaxonomyToContentTypeConnection connection */
    edges: Array<TaxonomyToContentTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentType>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyToContentTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: 'TaxonomyToContentTypeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentType;
  };

/** Page Info on the &quot;TaxonomyToContentTypeConnection&quot; */
export type TaxonomyToContentTypeConnectionPageInfo = ContentTypeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyToContentTypeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the Taxonomy type and the TermNode type */
export type TaxonomyToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'TaxonomyToTermNodeConnection';
    /** Edges for the TaxonomyToTermNodeConnection connection */
    edges: Array<TaxonomyToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'TaxonomyToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;TaxonomyToTermNodeConnection&quot; */
export type TaxonomyToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** The template assigned to the node */
export type Template_Blank = ContentTemplate & {
  __typename?: 'Template_Blank';
  /** The name of the template */
  templateName: Maybe<Scalars['String']['output']>;
};

/** The template assigned to the node */
export type Template_BlogAlternative = ContentTemplate & {
  __typename?: 'Template_BlogAlternative';
  /** The name of the template */
  templateName: Maybe<Scalars['String']['output']>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNode = {
  /** The number of objects connected to the object */
  count: Maybe<Scalars['Int']['output']>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output'];
  /** The description of the object */
  description: Maybe<Scalars['String']['output']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The link to the term */
  link: Maybe<Scalars['String']['output']>;
  /** The human friendly name of the object. */
  name: Maybe<Scalars['String']['output']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug: Maybe<Scalars['String']['output']>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName: Maybe<Scalars['String']['output']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId: Maybe<Scalars['Int']['output']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId: Maybe<Scalars['Int']['output']>;
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to TermNode Nodes */
export type TermNodeConnection = {
  /** A list of edges (relational context) between RootQuery and connected TermNode Nodes */
  edges: Array<TermNodeConnectionEdge>;
  /** A list of connected TermNode Nodes */
  nodes: Array<TermNode>;
  /** Information about pagination in a connection. */
  pageInfo: TermNodeConnectionPageInfo;
};

/** Edge between a Node and a connected TermNode */
export type TermNodeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected TermNode Node */
  node: TermNode;
};

/** Page Info on the connected TermNodeConnectionEdge */
export type TermNodeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the TermNode type and the EnqueuedScript type */
export type TermNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'TermNodeToEnqueuedScriptConnection';
    /** Edges for the TermNodeToEnqueuedScriptConnection connection */
    edges: Array<TermNodeToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: TermNodeToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type TermNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'TermNodeToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;TermNodeToEnqueuedScriptConnection&quot; */
export type TermNodeToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TermNodeToEnqueuedScriptConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the TermNode type and the EnqueuedStylesheet type */
export type TermNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnection';
    /** Edges for the TermNodeToEnqueuedStylesheetConnection connection */
    edges: Array<TermNodeToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type TermNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;TermNodeToEnqueuedStylesheetConnection&quot; */
export type TermNodeToEnqueuedStylesheetConnectionPageInfo = EnqueuedStylesheetConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = 'COUNT',
  /** Order the connection by description. */
  Description = 'DESCRIPTION',
  /** Order the connection by name. */
  Name = 'NAME',
  /** Order the connection by slug. */
  Slug = 'SLUG',
  /** Order the connection by term group. */
  TermGroup = 'TERM_GROUP',
  /** Order the connection by term id. */
  TermId = 'TERM_ID',
  /** Order the connection by term order. */
  TermOrder = 'TERM_ORDER'
}

/** A theme object */
export type Theme = Node & {
  __typename?: 'Theme';
  /** Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ). */
  author: Maybe<Scalars['String']['output']>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ). */
  authorUri: Maybe<Scalars['String']['output']>;
  /** The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ). */
  description: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier of the theme object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ). */
  name: Maybe<Scalars['String']['output']>;
  /** The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot(). */
  screenshot: Maybe<Scalars['String']['output']>;
  /** The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet(). */
  slug: Maybe<Scalars['String']['output']>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ). */
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ). */
  themeUri: Maybe<Scalars['String']['output']>;
  /** The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ). */
  version: Maybe<Scalars['String']['output']>;
};

/** Connection to Theme Nodes */
export type ThemeConnection = {
  /** A list of edges (relational context) between RootQuery and connected Theme Nodes */
  edges: Array<ThemeConnectionEdge>;
  /** A list of connected Theme Nodes */
  nodes: Array<Theme>;
  /** Information about pagination in a connection. */
  pageInfo: ThemeConnectionPageInfo;
};

/** Edge between a Node and a connected Theme */
export type ThemeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected Theme Node */
  node: Theme;
};

/** Page Info on the connected ThemeConnectionEdge */
export type ThemeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Any node that has a URI */
export type UniformResourceIdentifiable = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The unique resource identifier path */
  uri: Maybe<Scalars['String']['output']>;
};

/** Input for the updateBanner mutation. */
export type UpdateBannerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The ID of the banner object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateBanner mutation. */
export type UpdateBannerPayload = {
  __typename?: 'UpdateBannerPayload';
  /** The Post object mutation type. */
  banner: Maybe<Banner>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the updateCategory mutation. */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the category object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category object to update */
  id: Scalars['ID']['input'];
  /** The name of the category object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category that should be set as the parent */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateCategory mutation. */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  /** The created category */
  category: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the updateComment mutation. */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved: InputMaybe<Scalars['String']['input']>;
  /** The name of the comment's author. */
  author: InputMaybe<Scalars['String']['input']>;
  /** The email of the comment's author. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** The url of the comment's author. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn: InputMaybe<Scalars['Int']['input']>;
  /** Content of the comment. */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The ID of the comment being updated. */
  id: Scalars['ID']['input'];
  /** Parent comment ID of current comment. */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** The approval status of the comment */
  status: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateComment mutation. */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The comment that was created */
  comment: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success: Maybe<Scalars['Boolean']['output']>;
};

/** Input for the updateConfirmationScreen mutation. */
export type UpdateConfirmationScreenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The ID of the confirmationScreen object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateConfirmationScreen mutation. */
export type UpdateConfirmationScreenPayload = {
  __typename?: 'UpdateConfirmationScreenPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  confirmationScreen: Maybe<ConfirmationScreen>;
};

/** Input for the updateCoupon mutation. */
export type UpdateCouponInput = {
  /** The amount of discount. Should always be numeric, even if setting a percentage. */
  amount: InputMaybe<Scalars['Float']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Coupon code. */
  code: InputMaybe<Scalars['String']['input']>;
  /** The date the coupon expires, in the site's timezone. */
  dateExpires: InputMaybe<Scalars['String']['input']>;
  /** The date the coupon expires, as GMT. */
  dateExpiresGmt: InputMaybe<Scalars['String']['input']>;
  /** Coupon description. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Determines the type of discount that will be applied. */
  discountType: InputMaybe<DiscountTypeEnum>;
  /** List of email addresses that can use this coupon. */
  emailRestrictions: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** If true, this coupon will not be applied to items that have sale prices. */
  excludeSaleItems: InputMaybe<Scalars['Boolean']['input']>;
  /** List of category IDs the coupon does not apply to. */
  excludedProductCategories: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** List of product IDs the coupon cannot be used on. */
  excludedProductIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** If true and if the free shipping method requires a coupon, this coupon will enable free shipping. */
  freeShipping: InputMaybe<Scalars['Boolean']['input']>;
  /** Unique identifier for the object. */
  id: Scalars['ID']['input'];
  /** If true, the coupon can only be used individually. Other applied coupons will be removed from the cart. */
  individualUse: InputMaybe<Scalars['Boolean']['input']>;
  /** Max number of items in the cart the coupon can be applied to. */
  limitUsageToXItems: InputMaybe<Scalars['Int']['input']>;
  /** Maximum order amount allowed when using the coupon. */
  maximumAmount: InputMaybe<Scalars['String']['input']>;
  /** Meta data. */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Minimum order amount that needs to be in the cart before coupon applies. */
  minimumAmount: InputMaybe<Scalars['String']['input']>;
  /** List of category IDs the coupon applies to. */
  productCategories: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** List of product IDs the coupon can be used on. */
  productIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** How many times the coupon can be used in total. */
  usageLimit: InputMaybe<Scalars['Int']['input']>;
  /** How many times the coupon can be used per customer. */
  usageLimitPerUser: InputMaybe<Scalars['Int']['input']>;
};

/** The payload for the updateCoupon mutation. */
export type UpdateCouponPayload = {
  __typename?: 'UpdateCouponPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  coupon: Maybe<Coupon>;
};

/** Input for the updateCustomer mutation. */
export type UpdateCustomerInput = {
  /** User's AOL IM account. */
  aim: InputMaybe<Scalars['String']['input']>;
  /** Customer billing information */
  billing: InputMaybe<CustomerAddressInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user */
  id: InputMaybe<Scalars['ID']['input']>;
  /** User's Jabber account. */
  jabber: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale: InputMaybe<Scalars['String']['input']>;
  /** Meta data. */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password: InputMaybe<Scalars['String']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered: InputMaybe<Scalars['String']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing: InputMaybe<Scalars['String']['input']>;
  /** An array of roles to be assigned to the user. */
  roles: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Customer shipping address */
  shipping: InputMaybe<CustomerAddressInput>;
  /** Customer shipping is identical to billing address */
  shippingSameAsBilling: InputMaybe<Scalars['Boolean']['input']>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateCustomer mutation. */
export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Maybe<Customer>;
};

/** Input for the updateGraphqlDocumentGroup mutation. */
export type UpdateGraphqlDocumentGroupInput = {
  /** The slug that the graphql_document_group will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the graphql_document_group object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the graphqlDocumentGroup object to update */
  id: Scalars['ID']['input'];
  /** The name of the graphql_document_group object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateGraphqlDocumentGroup mutation. */
export type UpdateGraphqlDocumentGroupPayload = {
  __typename?: 'UpdateGraphqlDocumentGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created graphql_document_group */
  graphqlDocumentGroup: Maybe<GraphqlDocumentGroup>;
};

/** Input for the updateGraphqlDocument mutation. */
export type UpdateGraphqlDocumentInput = {
  /** Alias names for saved GraphQL query documents */
  alias: InputMaybe<Array<Scalars['String']['input']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** Description for the saved GraphQL document */
  description: InputMaybe<Scalars['String']['input']>;
  /** Allow, deny or default access grant for specific query */
  grant: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the graphqlDocument and graphqlDocumentGroups */
  graphqlDocumentGroups: InputMaybe<GraphqlDocumentGraphqlDocumentGroupsInput>;
  /** The ID of the graphqlDocument object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
  /** HTTP Cache-Control max-age directive for a saved GraphQL document */
  maxAgeHeader: InputMaybe<Scalars['Int']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateGraphqlDocument mutation. */
export type UpdateGraphqlDocumentPayload = {
  __typename?: 'UpdateGraphqlDocumentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  graphqlDocument: Maybe<GraphqlDocument>;
};

/** Input for the updateItemQuantities mutation. */
export type UpdateItemQuantitiesInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Cart item being updated */
  items: InputMaybe<Array<InputMaybe<CartItemQuantityInput>>>;
};

/** The payload for the updateItemQuantities mutation. */
export type UpdateItemQuantitiesPayload = {
  __typename?: 'UpdateItemQuantitiesPayload';
  cart: Maybe<Cart>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  items: Maybe<Array<Maybe<CartItem>>>;
  removed: Maybe<Array<Maybe<CartItem>>>;
  updated: Maybe<Array<Maybe<CartItem>>>;
};

/** Input for the updateMediaItem mutation. */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText: InputMaybe<Scalars['String']['input']>;
  /** The userId to assign as the author of the mediaItem */
  authorId: InputMaybe<Scalars['ID']['input']>;
  /** The caption for the mediaItem */
  caption: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the mediaItem */
  commentStatus: InputMaybe<Scalars['String']['input']>;
  /** The date of the mediaItem */
  date: InputMaybe<Scalars['String']['input']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt: InputMaybe<Scalars['String']['input']>;
  /** Description of the mediaItem */
  description: InputMaybe<Scalars['String']['input']>;
  /** The file name of the mediaItem */
  filePath: InputMaybe<Scalars['String']['input']>;
  /** The file type of the mediaItem */
  fileType: InputMaybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars['ID']['input'];
  /** The ID of the parent object */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** The ping status for the mediaItem */
  pingStatus: InputMaybe<Scalars['String']['input']>;
  /** The slug of the mediaItem */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the mediaItem */
  status: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateMediaItem mutation. */
export type UpdateMediaItemPayload = {
  __typename?: 'UpdateMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The MediaItem object mutation type. */
  mediaItem: Maybe<MediaItem>;
};

/** Input for the updateOrder mutation. */
export type UpdateOrderInput = {
  /** Order billing address */
  billing: InputMaybe<CustomerAddressInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Coupons codes to be applied to order */
  coupons: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Currency the order was created with, in ISO format. */
  currency: InputMaybe<Scalars['String']['input']>;
  /** Order customer ID */
  customerId: InputMaybe<Scalars['Int']['input']>;
  /** Note left by customer during checkout. */
  customerNote: InputMaybe<Scalars['String']['input']>;
  /** Order shipping lines */
  feeLines: InputMaybe<Array<InputMaybe<FeeLineInput>>>;
  /** Order global ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** Define if the order is paid. It will set the status to processing and reduce stock items. */
  isPaid: InputMaybe<Scalars['Boolean']['input']>;
  /** Order line items */
  lineItems: InputMaybe<Array<InputMaybe<LineItemInput>>>;
  /** Order meta data */
  metaData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
  /** Order WP ID */
  orderId: InputMaybe<Scalars['Int']['input']>;
  /** Parent order ID. */
  parentId: InputMaybe<Scalars['Int']['input']>;
  /** Payment method ID. */
  paymentMethod: InputMaybe<Scalars['String']['input']>;
  /** Payment method title. */
  paymentMethodTitle: InputMaybe<Scalars['String']['input']>;
  /** Order shipping address */
  shipping: InputMaybe<CustomerAddressInput>;
  /** Order shipping lines */
  shippingLines: InputMaybe<Array<InputMaybe<ShippingLineInput>>>;
  /** Order status */
  status: InputMaybe<OrderStatusEnum>;
  /** Order transaction ID */
  transactionId: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateOrder mutation. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
};

/** Input for the updatePaDefault mutation. */
export type UpdatePaDefaultInput = {
  /** The slug that the pa_default will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the pa_default object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the paDefault object to update */
  id: Scalars['ID']['input'];
  /** The name of the pa_default object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePaDefault mutation. */
export type UpdatePaDefaultPayload = {
  __typename?: 'UpdatePaDefaultPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created pa_default */
  paDefault: Maybe<PaDefault>;
};

/** Input for the updatePaPrimaryCurrency mutation. */
export type UpdatePaPrimaryCurrencyInput = {
  /** The slug that the pa_primary-currency will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the pa_primary-currency object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the paPrimaryCurrency object to update */
  id: Scalars['ID']['input'];
  /** The name of the pa_primary-currency object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePaPrimaryCurrency mutation. */
export type UpdatePaPrimaryCurrencyPayload = {
  __typename?: 'UpdatePaPrimaryCurrencyPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created pa_primary-currency */
  paPrimaryCurrency: Maybe<PaPrimaryCurrency>;
};

/** Input for the updatePage mutation. */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The ID of the page object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePage mutation. */
export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  page: Maybe<Page>;
};

/** Input for the updatePostFormat mutation. */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_format object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the postFormat object to update */
  id: Scalars['ID']['input'];
  /** The name of the post_format object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePostFormat mutation. */
export type UpdatePostFormatPayload = {
  __typename?: 'UpdatePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created post_format */
  postFormat: Maybe<PostFormat>;
};

/** Input for the updatePost mutation. */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId: InputMaybe<Scalars['ID']['input']>;
  /** Set connections between the post and categories */
  categories: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The excerpt of the object */
  excerpt: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** The ping status for the object */
  pingStatus: InputMaybe<Scalars['String']['input']>;
  /** URLs that have been pinged. */
  pinged: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Set connections between the post and postFormats */
  postFormats: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
  /** URLs queued to be pinged. */
  toPing: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the updatePost mutation. */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  post: Maybe<Post>;
};

/** Input for the updateProductCategory mutation. */
export type UpdateProductCategoryInput = {
  /** The slug that the product_cat will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_cat object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productCategory object to update */
  id: Scalars['ID']['input'];
  /** The name of the product_cat object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** The ID of the product_cat that should be set as the parent */
  parentId: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateProductCategory mutation. */
export type UpdateProductCategoryPayload = {
  __typename?: 'UpdateProductCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_cat */
  productCategory: Maybe<ProductCategory>;
};

/** Input for the updateProduct mutation. */
export type UpdateProductInput = {
  /** Set connections between the Product and allPaDefault */
  allPaDefault: InputMaybe<ProductAllPaDefaultInput>;
  /** Set connections between the Product and allPaPrimaryCurrency */
  allPaPrimaryCurrency: InputMaybe<ProductAllPaPrimaryCurrencyInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The excerpt of the object */
  excerpt: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Product object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the Product and productTypes */
  productTypes: InputMaybe<ProductProductTypesInput>;
  /** Set connections between the Product and shippingClasses */
  shippingClasses: InputMaybe<ProductShippingClassesInput>;
  /** The slug of the object */
  slug: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the Product and visibleProducts */
  visibleProducts: InputMaybe<ProductVisibleProductsInput>;
};

/** The payload for the updateProduct mutation. */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  product: Maybe<Product>;
};

/** Input for the updateProductTag mutation. */
export type UpdateProductTagInput = {
  /** The slug that the product_tag will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_tag object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productTag object to update */
  id: Scalars['ID']['input'];
  /** The name of the product_tag object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateProductTag mutation. */
export type UpdateProductTagPayload = {
  __typename?: 'UpdateProductTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_tag */
  productTag: Maybe<ProductTag>;
};

/** Input for the updateProductType mutation. */
export type UpdateProductTypeInput = {
  /** The slug that the product_type will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_type object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the productType object to update */
  id: Scalars['ID']['input'];
  /** The name of the product_type object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateProductType mutation. */
export type UpdateProductTypePayload = {
  __typename?: 'UpdateProductTypePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_type */
  productType: Maybe<ProductType>;
};

/** Input for the updateReview mutation. */
export type UpdateReviewInput = {
  /** The approval status of the comment. */
  approved: InputMaybe<Scalars['String']['input']>;
  /** The name of the comment's author. */
  author: InputMaybe<Scalars['String']['input']>;
  /** The email of the comment's author. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** The url of the comment's author. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn: InputMaybe<Scalars['Int']['input']>;
  /** Content of the comment. */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** The ID of the review being updated. */
  id: Scalars['ID']['input'];
  /** Parent comment ID of current comment. */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Product rating */
  rating: Scalars['Int']['input'];
  /** The approval status of the comment */
  status: InputMaybe<CommentStatusEnum>;
};

/** The payload for the updateReview mutation. */
export type UpdateReviewPayload = {
  __typename?: 'UpdateReviewPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The product rating of the review that was created */
  rating: Maybe<Scalars['Float']['output']>;
  /** The product review that was created */
  review: Maybe<Comment>;
};

/** Input for the updateSession mutation. */
export type UpdateSessionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Data to be persisted in the session. */
  sessionData: InputMaybe<Array<InputMaybe<MetaDataInput>>>;
};

/** The payload for the updateSession mutation. */
export type UpdateSessionPayload = {
  __typename?: 'UpdateSessionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Maybe<Customer>;
  session: Maybe<Array<Maybe<MetaData>>>;
};

/** Input for the updateSettings mutation. */
export type UpdateSettingsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus: InputMaybe<Scalars['String']['input']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus: InputMaybe<Scalars['String']['input']>;
  /** A date format for all date strings. */
  generalSettingsDateFormat: InputMaybe<Scalars['String']['input']>;
  /** Site tagline. */
  generalSettingsDescription: InputMaybe<Scalars['String']['input']>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail: InputMaybe<Scalars['String']['input']>;
  /** WordPress locale code. */
  generalSettingsLanguage: InputMaybe<Scalars['String']['input']>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek: InputMaybe<Scalars['Int']['input']>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat: InputMaybe<Scalars['String']['input']>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone: InputMaybe<Scalars['String']['input']>;
  /** Site title. */
  generalSettingsTitle: InputMaybe<Scalars['String']['input']>;
  /** Site URL. */
  generalSettingsUrl: InputMaybe<Scalars['String']['input']>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront: InputMaybe<Scalars['Int']['input']>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage: InputMaybe<Scalars['Int']['input']>;
  /** What to show on the front page */
  readingSettingsShowOnFront: InputMaybe<Scalars['String']['input']>;
  /** Default post category. */
  writingSettingsDefaultCategory: InputMaybe<Scalars['Int']['input']>;
  /** Default post format. */
  writingSettingsDefaultPostFormat: InputMaybe<Scalars['String']['input']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the updateSettings mutation. */
export type UpdateSettingsPayload = {
  __typename?: 'UpdateSettingsPayload';
  /** Update all settings. */
  allSettings: Maybe<Settings>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Update the DiscussionSettings setting. */
  discussionSettings: Maybe<DiscussionSettings>;
  /** Update the GeneralSettings setting. */
  generalSettings: Maybe<GeneralSettings>;
  /** Update the ReadingSettings setting. */
  readingSettings: Maybe<ReadingSettings>;
  /** Update the WritingSettings setting. */
  writingSettings: Maybe<WritingSettings>;
};

/** Input for the updateShippingClass mutation. */
export type UpdateShippingClassInput = {
  /** The slug that the product_shipping_class will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_shipping_class object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the shippingClass object to update */
  id: Scalars['ID']['input'];
  /** The name of the product_shipping_class object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateShippingClass mutation. */
export type UpdateShippingClassPayload = {
  __typename?: 'UpdateShippingClassPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_shipping_class */
  shippingClass: Maybe<ShippingClass>;
};

/** Input for the updateShippingMethod mutation. */
export type UpdateShippingMethodInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  shippingMethods: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the updateShippingMethod mutation. */
export type UpdateShippingMethodPayload = {
  __typename?: 'UpdateShippingMethodPayload';
  cart: Maybe<Cart>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Input for the updateTag mutation. */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_tag object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tag object to update */
  id: Scalars['ID']['input'];
  /** The name of the post_tag object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTag mutation. */
export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created post_tag */
  tag: Maybe<Tag>;
};

/** Input for the updateUser mutation. */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user */
  id: Scalars['ID']['input'];
  /** User's Jabber account. */
  jabber: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale: InputMaybe<Scalars['String']['input']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password: InputMaybe<Scalars['String']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered: InputMaybe<Scalars['String']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing: InputMaybe<Scalars['String']['input']>;
  /** An array of roles to be assigned to the user. */
  roles: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateUser mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user: Maybe<User>;
};

/** Input for the updateVisibleProduct mutation. */
export type UpdateVisibleProductInput = {
  /** The slug that the product_visibility will be an alias of */
  aliasOf: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The description of the product_visibility object */
  description: InputMaybe<Scalars['String']['input']>;
  /** The ID of the visibleProduct object to update */
  id: Scalars['ID']['input'];
  /** The name of the product_visibility object to mutate */
  name: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateVisibleProduct mutation. */
export type UpdateVisibleProductPayload = {
  __typename?: 'UpdateVisibleProductPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created product_visibility */
  visibleProduct: Maybe<VisibleProduct>;
};

/** A User object */
export type User = Commenter &
  DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'User';
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar: Maybe<Avatar>;
    /** User metadata option name. Usually it will be &quot;wp_capabilities&quot;. */
    capKey: Maybe<Scalars['String']['output']>;
    /** A list of capabilities (permissions) granted to the user */
    capabilities: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Connection between the User type and the Comment type */
    comments: Maybe<UserToCommentConnection>;
    /** Identifies the primary key from the database. */
    databaseId: Scalars['Int']['output'];
    /** Description of the user. */
    description: Maybe<Scalars['String']['output']>;
    /** Email address of the user. This is equivalent to the WP_User-&gt;user_email property. */
    email: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the EnqueuedScript type */
    enqueuedScripts: Maybe<UserToEnqueuedScriptConnection>;
    /** Connection between the User type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<UserToEnqueuedStylesheetConnection>;
    /** A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps. */
    extraCapabilities: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** First name of the user. This is equivalent to the WP_User-&gt;user_first_name property. */
    firstName: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier for the user object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property. */
    lastName: Maybe<Scalars['String']['output']>;
    /** The preferred language locale set for the user. Value derived from get_user_locale(). */
    locale: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the mediaItem type */
    mediaItems: Maybe<UserToMediaItemConnection>;
    /** Display name of the user. This is equivalent to the WP_User-&gt;display_name property. */
    name: Maybe<Scalars['String']['output']>;
    /** The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename */
    nicename: Maybe<Scalars['String']['output']>;
    /** Nickname of the user. */
    nickname: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the page type */
    pages: Maybe<UserToPageConnection>;
    /** Connection between the User type and the post type */
    posts: Maybe<UserToPostConnection>;
    /** The date the user registered or was created. The field follows a full ISO8601 date string format. */
    registeredDate: Maybe<Scalars['String']['output']>;
    /** Connection between the User and Revisions authored by the user */
    revisions: Maybe<UserToRevisionsConnection>;
    /** Connection between the User type and the UserRole type */
    roles: Maybe<UserToUserRoleConnection>;
    /** Whether the Toolbar should be displayed when the user is viewing the site. */
    shouldShowAdminToolbar: Maybe<Scalars['Boolean']['output']>;
    /** The slug for the user. This field is equivalent to WP_User-&gt;user_nicename */
    slug: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** A website url that is associated with the user. */
    url: Maybe<Scalars['String']['output']>;
    /**
     * The Id of the user. Equivalent to WP_User-&gt;ID
     * @deprecated Deprecated in favor of the databaseId field
     */
    userId: Maybe<Scalars['Int']['output']>;
    /** Username for the user. This field is equivalent to WP_User-&gt;user_login. */
    username: Maybe<Scalars['String']['output']>;
    /** A JWT token that can be used in future requests to for WooCommerce session identification */
    wooSessionToken: Maybe<Scalars['String']['output']>;
  };

/** A User object */
export type UserAvatarArgs = {
  forceDefault: InputMaybe<Scalars['Boolean']['input']>;
  rating: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<UserToCommentConnectionWhereArgs>;
};

/** A User object */
export type UserEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserMediaItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<UserToMediaItemConnectionWhereArgs>;
};

/** A User object */
export type UserPagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<UserToPageConnectionWhereArgs>;
};

/** A User object */
export type UserPostsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<UserToPostConnectionWhereArgs>;
};

/** A User object */
export type UserRevisionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<UserToRevisionsConnectionWhereArgs>;
};

/** A User object */
export type UserRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to User Nodes */
export type UserConnection = {
  /** A list of edges (relational context) between RootQuery and connected User Nodes */
  edges: Array<UserConnectionEdge>;
  /** A list of connected User Nodes */
  nodes: Array<User>;
  /** Information about pagination in a connection. */
  pageInfo: UserConnectionPageInfo;
};

/** Edge between a Node and a connected User */
export type UserConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected User Node */
  node: User;
};

/** Page Info on the connected UserConnectionEdge */
export type UserConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The Email of the User */
  Email = 'EMAIL',
  /** The hashed Global ID */
  Id = 'ID',
  /** The slug of the User */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
  /** The username the User uses to login with */
  Username = 'USERNAME'
}

/** A user role object */
export type UserRole = Node & {
  __typename?: 'UserRole';
  /** The capabilities that belong to this role */
  capabilities: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The display name of the role */
  displayName: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier for the user role object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted: Maybe<Scalars['Boolean']['output']>;
  /** The registered name of the role */
  name: Maybe<Scalars['String']['output']>;
};

/** Connection to UserRole Nodes */
export type UserRoleConnection = {
  /** A list of edges (relational context) between RootQuery and connected UserRole Nodes */
  edges: Array<UserRoleConnectionEdge>;
  /** A list of connected UserRole Nodes */
  nodes: Array<UserRole>;
  /** Information about pagination in a connection. */
  pageInfo: UserRoleConnectionPageInfo;
};

/** Edge between a Node and a connected UserRole */
export type UserRoleConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected UserRole Node */
  node: UserRole;
};

/** Page Info on the connected UserRoleConnectionEdge */
export type UserRoleConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  Author = 'AUTHOR',
  /** User role with specific capabilities */
  Contributor = 'CONTRIBUTOR',
  /** User role with specific capabilities */
  Customer = 'CUSTOMER',
  /** User role with specific capabilities */
  Editor = 'EDITOR',
  /** User role with specific capabilities */
  ShopManager = 'SHOP_MANAGER',
  /** User role with specific capabilities */
  Subscriber = 'SUBSCRIBER'
}

/** Connection between the User type and the Comment type */
export type UserToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'UserToCommentConnection';
    /** Edges for the UserToCommentConnection connection */
    edges: Array<UserToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: UserToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'UserToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;UserToCommentConnection&quot; */
export type UserToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the User type and the EnqueuedScript type */
export type UserToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'UserToEnqueuedScriptConnection';
    /** Edges for the UserToEnqueuedScriptConnection connection */
    edges: Array<UserToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: UserToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'UserToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;UserToEnqueuedScriptConnection&quot; */
export type UserToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToEnqueuedScriptConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the User type and the EnqueuedStylesheet type */
export type UserToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'UserToEnqueuedStylesheetConnection';
    /** Edges for the UserToEnqueuedStylesheetConnection connection */
    edges: Array<UserToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: UserToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'UserToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;UserToEnqueuedStylesheetConnection&quot; */
export type UserToEnqueuedStylesheetConnectionPageInfo = EnqueuedStylesheetConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToEnqueuedStylesheetConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Connection between the User type and the mediaItem type */
export type UserToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'UserToMediaItemConnection';
    /** Edges for the UserToMediaItemConnection connection */
    edges: Array<UserToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: UserToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'UserToMediaItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;UserToMediaItemConnection&quot; */
export type UserToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToMediaItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the page type */
export type UserToPageConnection = Connection &
  PageConnection & {
    __typename?: 'UserToPageConnection';
    /** Edges for the UserToPageConnection connection */
    edges: Array<UserToPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPageConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'UserToPageConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;UserToPageConnection&quot; */
export type UserToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToPageConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the post type */
export type UserToPostConnection = Connection &
  PostConnection & {
    __typename?: 'UserToPostConnection';
    /** Edges for the UserToPostConnection connection */
    edges: Array<UserToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'UserToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;UserToPostConnection&quot; */
export type UserToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the ContentNode type */
export type UserToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'UserToRevisionsConnection';
    /** Edges for the UserToRevisionsConnection connection */
    edges: Array<UserToRevisionsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: UserToRevisionsConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'UserToRevisionsConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;UserToRevisionsConnection&quot; */
export type UserToRevisionsConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToRevisionsConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToRevisionsConnection connection */
export type UserToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the UserRole type */
export type UserToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: 'UserToUserRoleConnection';
    /** Edges for the UserToUserRoleConnection connection */
    edges: Array<UserToUserRoleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<UserRole>;
    /** Information about pagination in a connection. */
    pageInfo: UserToUserRoleConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: 'UserToUserRoleConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: UserRole;
  };

/** Page Info on the &quot;UserToUserRoleConnection&quot; */
export type UserToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToUserRoleConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = 'DISPLAY_NAME',
  /** Order by email address */
  Email = 'EMAIL',
  /** Order by login */
  Login = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = 'LOGIN_IN',
  /** Order by nice name */
  NiceName = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = 'NICE_NAME_IN',
  /** Order by registration date */
  Registered = 'REGISTERED',
  /** Order by URL */
  Url = 'URL'
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order: InputMaybe<OrderEnum>;
};

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = 'EMAIL',
  /** The globally unique ID. */
  Id = 'ID',
  /** The username the User uses to login with. */
  Login = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = 'NICENAME',
  /** The URL of the user's website. */
  Url = 'URL'
}

/** A variable product object */
export type VariableProduct = ContentNode &
  DatabaseIdentifier &
  InventoriedProduct &
  MenuItemLinkable &
  Node &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  Product &
  ProductUnion &
  ProductWithAttributes &
  ProductWithDimensions &
  ProductWithPricing &
  ProductWithVariations &
  UniformResourceIdentifiable & {
    __typename?: 'VariableProduct';
    /** Connection between the Product type and the paDefault type */
    allPaDefault: Maybe<ProductToPaDefaultConnection>;
    /** Connection between the Product type and the paPrimaryCurrency type */
    allPaPrimaryCurrency: Maybe<ProductToPaPrimaryCurrencyConnection>;
    /** Connection between the Product type and the ProductAttribute type */
    attributes: Maybe<ProductToProductAttributeConnection>;
    /** Product average count */
    averageRating: Maybe<Scalars['Float']['output']>;
    /** Product backorders status */
    backorders: Maybe<BackordersEnum>;
    /** Can product be backordered? */
    backordersAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Catalog visibility */
    catalogVisibility: Maybe<CatalogVisibilityEnum>;
    /** NFT collection type */
    collectionType: Maybe<Scalars['String']['output']>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus: Maybe<Scalars['String']['output']>;
    /** The content of the post. */
    content: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** Connection between the VariableProduct type and the ProductUnion type */
    crossSell: Maybe<VariableProductToProductUnionConnection>;
    /** Product or variation ID */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt: Maybe<Scalars['String']['output']>;
    /** Date on sale from */
    dateOnSaleFrom: Maybe<Scalars['String']['output']>;
    /** Date on sale to */
    dateOnSaleTo: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductWithAttributes type and the VariationAttribute type */
    defaultAttributes: Maybe<ProductWithAttributesToVariationAttributeConnection>;
    /** Product description */
    description: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt: Maybe<Scalars['String']['output']>;
    /** If the product is featured */
    featured: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId: Maybe<Scalars['ID']['output']>;
    /** Connection between the Product type and the MediaItem type */
    galleryImages: Maybe<ProductToMediaItemConnection>;
    /** Connection between the Product type and the GlobalProductAttribute type */
    globalAttributes: Maybe<ProductToGlobalProductAttributeConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid: Maybe<Scalars['String']['output']>;
    /** Whether the product object is password protected. */
    hasPassword: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s height */
    height: Maybe<Scalars['String']['output']>;
    /** Product or variation global ID */
    id: Scalars['ID']['output'];
    /** Main image */
    image: Maybe<MediaItem>;
    /** Check if user is allowlist to see the product */
    isAllowlisted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** Product&#039;s length */
    length: Maybe<Scalars['String']['output']>;
    /** The permalink of the post */
    link: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the LocalProductAttribute type */
    localAttributes: Maybe<ProductToLocalProductAttributeConnection>;
    /** Low stock amount */
    lowStockAmount: Maybe<Scalars['Int']['output']>;
    /** If product manage stock */
    manageStock: Maybe<ManageStockEnum>;
    /** Menu order */
    menuOrder: Maybe<Scalars['Int']['output']>;
    /** Object meta data */
    metaData: Maybe<Array<Maybe<MetaData>>>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt: Maybe<Scalars['String']['output']>;
    /** Product name */
    name: Maybe<Scalars['String']['output']>;
    /** Is product on sale? */
    onSale: Maybe<Scalars['Boolean']['output']>;
    /** The password for the product object. */
    password: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the Product type */
    preview: Maybe<ProductToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId: Maybe<Scalars['ID']['output']>;
    /** Product&#039;s active price */
    price: Maybe<Scalars['String']['output']>;
    /** Pricing group with group discounts */
    pricing: Maybe<ProductWithGroupDiscount>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    productId: Scalars['Int']['output'];
    /** Connection between the Product type and the productType type */
    productTypes: Maybe<ProductToProductTypeConnection>;
    /** Can product be purchased? */
    purchasable: Maybe<Scalars['Boolean']['output']>;
    /** Purchase note */
    purchaseNote: Maybe<Scalars['String']['output']>;
    /** Product&#039;s regular price */
    regularPrice: Maybe<Scalars['String']['output']>;
    /** Connection between the Product type and the ProductUnion type */
    related: Maybe<ProductToProductUnionConnection>;
    /** Product review count */
    reviewCount: Maybe<Scalars['Int']['output']>;
    /** Connection between the Product type and the Comment type */
    reviews: Maybe<ProductToCommentConnection>;
    /** If reviews are allowed */
    reviewsAllowed: Maybe<Scalars['Boolean']['output']>;
    /** Product&#039;s sale price */
    salePrice: Maybe<Scalars['String']['output']>;
    /** shipping class ID */
    shippingClassId: Maybe<Scalars['Int']['output']>;
    /** Connection between the Product type and the shippingClass type */
    shippingClasses: Maybe<ProductToShippingClassConnection>;
    /** Does product need to be shipped? */
    shippingRequired: Maybe<Scalars['Boolean']['output']>;
    /** Is product shipping taxable? */
    shippingTaxable: Maybe<Scalars['Boolean']['output']>;
    /** Product short description */
    shortDescription: Maybe<Scalars['String']['output']>;
    /** Product SKU */
    sku: Maybe<Scalars['String']['output']>;
    /** Product slug */
    slug: Maybe<Scalars['String']['output']>;
    /** If should be sold individually */
    soldIndividually: Maybe<Scalars['Boolean']['output']>;
    /** The current status of the object */
    status: Maybe<Scalars['String']['output']>;
    /** Number of items available for sale */
    stockQuantity: Maybe<Scalars['Int']['output']>;
    /** Product stock status */
    stockStatus: Maybe<StockStatusEnum>;
    /** Tax class */
    taxClass: Maybe<TaxClassEnum>;
    /** Tax status */
    taxStatus: Maybe<TaxStatusEnum>;
    /** The template assigned to the node */
    template: Maybe<ContentTemplate>;
    /** Connection between the Product type and the TermNode type */
    terms: Maybe<ProductToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title: Maybe<Scalars['String']['output']>;
    /** Number total of sales */
    totalSales: Maybe<Scalars['Int']['output']>;
    /** Product type */
    type: Maybe<ProductTypesEnum>;
    /** Connection between the Product type and the ProductUnion type */
    upsell: Maybe<ProductToUpsellConnection>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /** Connection between the ProductWithVariations type and the ProductVariation type */
    variations: Maybe<ProductWithVariationsToProductVariationConnection>;
    /** Connection between the Product type and the visibleProduct type */
    visibleProducts: Maybe<ProductToVisibleProductConnection>;
    /** Product&#039;s weight */
    weight: Maybe<Scalars['String']['output']>;
    /** Product&#039;s width */
    width: Maybe<Scalars['String']['output']>;
  };

/** A variable product object */
export type VariableProductAllPaDefaultArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaDefaultConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductAllPaPrimaryCurrencyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToPaPrimaryCurrencyConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductAttributeConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductContentArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductCrossSellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<VariableProductToProductUnionConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductDefaultAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A variable product object */
export type VariableProductDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A variable product object */
export type VariableProductEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A variable product object */
export type VariableProductExcerptArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductGalleryImagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToMediaItemConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductGlobalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A variable product object */
export type VariableProductIsAllowlistedArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A variable product object */
export type VariableProductLocalAttributesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** A variable product object */
export type VariableProductMetaDataArgs = {
  key: InputMaybe<Scalars['String']['input']>;
  keysIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  multiple: InputMaybe<Scalars['Boolean']['input']>;
};

/** A variable product object */
export type VariableProductPreviewArgs = {
  where: InputMaybe<ProductToPreviewConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductPricingArgs = {
  address: InputMaybe<Scalars['String']['input']>;
};

/** A variable product object */
export type VariableProductProductTypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductTypeConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductRegularPriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductRelatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToProductUnionConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductReviewsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToCommentConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductSalePriceArgs = {
  format: InputMaybe<PricingFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductShippingClassesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToShippingClassConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductShortDescriptionArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductTermsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToTermNodeConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductTitleArgs = {
  format: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A variable product object */
export type VariableProductUpsellArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToUpsellConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductVariationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductWithVariationsToProductVariationConnectionWhereArgs>;
};

/** A variable product object */
export type VariableProductVisibleProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<ProductToVisibleProductConnectionWhereArgs>;
};

/** Edge between a Node and a connected VariableProduct */
export type VariableProductConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected VariableProduct Node */
  node: VariableProduct;
};

/** Connection between the VariableProduct type and the ProductUnion type */
export type VariableProductToProductUnionConnection = Connection &
  ProductUnionConnection & {
    __typename?: 'VariableProductToProductUnionConnection';
    /** Edges for the VariableProductToProductUnionConnection connection */
    edges: Array<VariableProductToProductUnionConnectionEdge>;
    /** Total products founds */
    found: Maybe<Scalars['Int']['output']>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ProductUnion>;
    /** Information about pagination in a connection. */
    pageInfo: VariableProductToProductUnionConnectionPageInfo;
  };

/** An edge in a connection */
export type VariableProductToProductUnionConnectionEdge = Edge &
  ProductUnionConnectionEdge & {
    __typename?: 'VariableProductToProductUnionConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ProductUnion;
  };

/** Page Info on the &quot;VariableProductToProductUnionConnection&quot; */
export type VariableProductToProductUnionConnectionPageInfo = PageInfo &
  ProductUnionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'VariableProductToProductUnionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the VariableProductToProductUnionConnection connection */
export type VariableProductToProductUnionConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** A product variation attribute object */
export type VariationAttribute = Attribute &
  Node & {
    __typename?: 'VariationAttribute';
    /** The Database ID of the attribute. */
    attributeId: Maybe<Scalars['Int']['output']>;
    /** The Global ID of the attribute. */
    id: Scalars['ID']['output'];
    /** Label of attribute */
    label: Maybe<Scalars['String']['output']>;
    /** Name of attribute */
    name: Maybe<Scalars['String']['output']>;
    /** Selected value of attribute */
    value: Maybe<Scalars['String']['output']>;
  };

/** Connection to VariationAttribute Nodes */
export type VariationAttributeConnection = {
  /** A list of edges (relational context) between ProductVariation and connected VariationAttribute Nodes */
  edges: Array<VariationAttributeConnectionEdge>;
  /** A list of connected VariationAttribute Nodes */
  nodes: Array<VariationAttribute>;
  /** Information about pagination in a connection. */
  pageInfo: VariationAttributeConnectionPageInfo;
};

/** Edge between a Node and a connected VariationAttribute */
export type VariationAttributeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected VariationAttribute Node */
  node: VariationAttribute;
};

/** Page Info on the connected VariationAttributeConnectionEdge */
export type VariationAttributeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The visibleProduct type */
export type VisibleProduct = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'VisibleProduct';
    /** Connection between the VisibleProduct type and the ContentNode type */
    contentNodes: Maybe<VisibleProductToContentNodeConnection>;
    /** The number of objects connected to the object */
    count: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name: Maybe<Scalars['String']['output']>;
    /** Connection between the VisibleProduct type and the Product type */
    products: Maybe<VisibleProductToProductConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug: Maybe<Scalars['String']['output']>;
    /** Connection between the VisibleProduct type and the Taxonomy type */
    taxonomy: Maybe<VisibleProductToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    visibleProductId: Maybe<Scalars['Int']['output']>;
  };

/** The visibleProduct type */
export type VisibleProductContentNodesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<VisibleProductToContentNodeConnectionWhereArgs>;
};

/** The visibleProduct type */
export type VisibleProductEnqueuedScriptsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The visibleProduct type */
export type VisibleProductEnqueuedStylesheetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The visibleProduct type */
export type VisibleProductProductsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<VisibleProductToProductConnectionWhereArgs>;
};

/** Connection to visibleProduct Nodes */
export type VisibleProductConnection = {
  /** A list of edges (relational context) between RootQuery and connected visibleProduct Nodes */
  edges: Array<VisibleProductConnectionEdge>;
  /** A list of connected visibleProduct Nodes */
  nodes: Array<VisibleProduct>;
  /** Information about pagination in a connection. */
  pageInfo: VisibleProductConnectionPageInfo;
};

/** Edge between a Node and a connected visibleProduct */
export type VisibleProductConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor: Maybe<Scalars['String']['output']>;
  /** The connected visibleProduct Node */
  node: VisibleProduct;
};

/** Page Info on the connected VisibleProductConnectionEdge */
export type VisibleProductConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum VisibleProductIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the VisibleProduct type and the ContentNode type */
export type VisibleProductToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'VisibleProductToContentNodeConnection';
    /** Edges for the VisibleProductToContentNodeConnection connection */
    edges: Array<VisibleProductToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: VisibleProductToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type VisibleProductToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'VisibleProductToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;VisibleProductToContentNodeConnection&quot; */
export type VisibleProductToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'VisibleProductToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the VisibleProductToContentNodeConnection connection */
export type VisibleProductToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes: InputMaybe<Array<InputMaybe<ContentTypesOfVisibleProductEnum>>>;
  /** Filter the connection based on dates */
  dateQuery: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the VisibleProduct type and the Product type */
export type VisibleProductToProductConnection = Connection &
  ProductConnection & {
    __typename?: 'VisibleProductToProductConnection';
    /** Edges for the VisibleProductToProductConnection connection */
    edges: Array<VisibleProductToProductConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Product>;
    /** Information about pagination in a connection. */
    pageInfo: VisibleProductToProductConnectionPageInfo;
  };

/** An edge in a connection */
export type VisibleProductToProductConnectionEdge = Edge &
  ProductConnectionEdge & {
    __typename?: 'VisibleProductToProductConnectionEdge';
    /** A cursor for use in pagination */
    cursor: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Product;
  };

/** Page Info on the &quot;VisibleProductToProductConnection&quot; */
export type VisibleProductToProductConnectionPageInfo = PageInfo &
  ProductConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'VisibleProductToProductConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the VisibleProductToProductConnection connection */
export type VisibleProductToProductConnectionWhereArgs = {
  /** Limit result set to products with a specific attribute. Use the taxonomy name/attribute slug. */
  attribute: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with a specific attribute term ID (required an assigned attribute). */
  attributeTerm: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  category: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific category name. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of category IDs. */
  categoryIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of category IDs. */
  categoryIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a group of specific categories by name. */
  categoryIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a group of specific categories by name. */
  categoryNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter the connection based on dates. */
  dateQuery: InputMaybe<DateQueryInput>;
  /** Ensure result set excludes specific IDs. */
  exclude: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to featured products. */
  featured: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to specific ids. */
  include: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Include variations in the result set. */
  includeVariations: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products based on a maximum price. */
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products based on a minimum price. */
  minPrice: InputMaybe<Scalars['Float']['input']>;
  /** Limit result set to products on sale. */
  onSale: InputMaybe<Scalars['Boolean']['input']>;
  /** What paramater to use to order the objects by. */
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items. */
  parent: InputMaybe<Scalars['Int']['input']>;
  /** Specify objects whose parent is in an array. */
  parentIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Specify objects whose parent is not in an array. */
  parentNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products with a specific average rating. Must be between 1 and 5 */
  rating: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products based on a keyword search. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific shipping class ID. */
  shippingClassId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products with specific SKU(s). Use commas to separate. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products with specific slugs. */
  slugIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products assigned a specific status. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products in stock or out of stock. */
  stockStatus: InputMaybe<Array<InputMaybe<StockStatusEnum>>>;
  /** Limit result types to types supported by WooGraphQL. */
  supportedTypesOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Limit result set to products assigned a specific tag name. */
  tag: InputMaybe<Scalars['String']['input']>;
  /** Limit result set to products assigned a specific tag ID. */
  tagId: InputMaybe<Scalars['Int']['input']>;
  /** Limit result set to products assigned to a specific group of tag IDs. */
  tagIdIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tag IDs. */
  tagIdNotIn: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Limit result set to products assigned to a specific group of tags by name. */
  tagIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set to products not assigned to a specific group of tags by name. */
  tagNotIn: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Limit result set with complex set of taxonomy filters. */
  taxonomyFilter: InputMaybe<ProductTaxonomyInput>;
  /** Limit result set to products assigned a specific type. */
  type: InputMaybe<ProductTypesEnum>;
  /** Limit result set to products assigned to a group of specific types. */
  typeIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products not assigned to a group of specific types. */
  typeNotIn: InputMaybe<Array<InputMaybe<ProductTypesEnum>>>;
  /** Limit result set to products with a specific visibility level. */
  visibility: InputMaybe<CatalogVisibilityEnum>;
};

/** Connection between the VisibleProduct type and the Taxonomy type */
export type VisibleProductToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'VisibleProductToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Information about pagination in a connection. */
export type WpPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Input for the writeReview mutation. */
export type WriteReviewInput = {
  /** The approval status of the comment. */
  approved: InputMaybe<Scalars['String']['input']>;
  /** The name of the comment's author. */
  author: InputMaybe<Scalars['String']['input']>;
  /** The email of the comment's author. */
  authorEmail: InputMaybe<Scalars['String']['input']>;
  /** The url of the comment's author. */
  authorUrl: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: InputMaybe<Scalars['String']['input']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn: InputMaybe<Scalars['Int']['input']>;
  /** Content of the comment. */
  content: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date: InputMaybe<Scalars['String']['input']>;
  /** Parent comment ID of current comment. */
  parent: InputMaybe<Scalars['ID']['input']>;
  /** Product rating */
  rating: Scalars['Int']['input'];
  /** The approval status of the comment */
  status: InputMaybe<CommentStatusEnum>;
};

/** The payload for the writeReview mutation. */
export type WriteReviewPayload = {
  __typename?: 'WriteReviewPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The product rating of the review that was created */
  rating: Maybe<Scalars['Float']['output']>;
  /** The product review that was created */
  review: Maybe<Comment>;
};

/** The writing setting type */
export type WritingSettings = {
  __typename?: 'WritingSettings';
  /** Default post category. */
  defaultCategory: Maybe<Scalars['Int']['output']>;
  /** Default post format. */
  defaultPostFormat: Maybe<Scalars['String']['output']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  useSmilies: Maybe<Scalars['Boolean']['output']>;
};

export type BannerFragment = {
  __typename?: 'Banner';
  id: string;
  title: string;
  slug: string;
  status: string;
  bannerInfo: {
    __typename?: 'Banner_Bannerinfo';
    copy: string;
    copyEnd: string;
    title: string;
    titleEnd: string;
    endDate: string;
    startDate: string;
    video: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
    image: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
  };
};

export type ProductCategoryFragment = {
  __typename?: 'ProductCategory';
  id: string;
  uri: string;
  name: string;
  count: number;
  slug: string;
  description: string;
  parentId: string;
};

export type CategoryFragment = {
  __typename?: 'Category';
  id: string;
  uri: string;
  name: string;
  count: number;
  slug: string;
  description: string;
  parentId: string;
};

export type ConfirmationScreenFragment = {
  __typename?: 'ConfirmationScreen';
  id: string;
  title: string;
  slug: string;
  status: string;
  config: { __typename?: 'ConfirmationScreen_Config'; collectionType: string; wallet: string };
  fields: {
    __typename?: 'ConfirmationScreen_Fields';
    description: string;
    cta: string;
    ctaText: string;
    image: { __typename?: 'MediaItem'; sourceUrl: string };
  };
};

export type MaintenanceFragment = {
  __typename?: 'MaintenanceMode';
  enabled: boolean;
  title: string;
  message: string;
  image: string;
  background: string;
};

export type SimpleProductFragment = {
  __typename: 'SimpleProduct';
  id: string;
  name: string;
  sku: string;
  type: ProductTypesEnum;
  collectionType: string;
  isAllowlisted: boolean;
  slug: string;
  description: string;
  shortDescription: string;
  purchasable: boolean;
  onSale: boolean;
  stockStatus: StockStatusEnum;
  stockQuantity: number;
  totalSales: number;
  pricing: {
    __typename?: 'ProductWithGroupDiscount';
    price: string;
    currency: string;
    salePrice: string;
    regularPrice: string;
    groupDiscountPrice: string;
    groupDiscountPercentage: number;
    preferredCurrencySet: boolean;
    totalDiscountPercentage: number;
    alternateCurrencies: Array<{
      __typename?: 'ProductPricingAlternateCurrencies';
      price: string;
      currency: string;
      salePrice: string;
      regularPrice: string;
    }>;
    bondingCurve: {
      __typename?: 'ProductBondingCurve';
      active: boolean;
      maxDiscount: number;
      minDiscount: number;
      quantityLimit: number;
      percentage: number;
      remainingDiscountPool: number;
    };
    discountCurve: {
      __typename?: 'ProductDiscountCurve';
      name: string;
      percentage: number;
      remainingDiscountPool: number;
    };
  };
  image: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
  galleryImages: {
    __typename?: 'ProductToMediaItemConnection';
    nodes: Array<{ __typename?: 'MediaItem'; altText: string; sourceUrl: string }>;
  };
  attributes: {
    __typename?: 'ProductToProductAttributeConnection';
    nodes: Array<
      | { __typename?: 'GlobalProductAttribute' }
      | { __typename?: 'LocalProductAttribute'; name: string; options: Array<string> }
    >;
  };
};

export type MediaItemFragment = { __typename?: 'MediaItem'; altText: string; sourceUrl: string };

export type ProductAttributesFragment = {
  __typename?: 'LocalProductAttribute';
  name: string;
  options: Array<string>;
};

export type GetBannersQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  orderby: InputMaybe<
    | Array<InputMaybe<PostObjectsConnectionOrderbyInput>>
    | InputMaybe<PostObjectsConnectionOrderbyInput>
  >;
}>;

export type GetBannersQuery = {
  __typename?: 'RootQuery';
  banners: {
    __typename?: 'RootQueryToBannerConnection';
    nodes: Array<{
      __typename?: 'Banner';
      id: string;
      title: string;
      slug: string;
      status: string;
      bannerInfo: {
        __typename?: 'Banner_Bannerinfo';
        copy: string;
        copyEnd: string;
        title: string;
        titleEnd: string;
        endDate: string;
        startDate: string;
        video: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
        image: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
      };
    }>;
  };
};

export type GetProductCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductCategoriesQuery = {
  __typename?: 'RootQuery';
  productCategories: {
    __typename?: 'RootQueryToProductCategoryConnection';
    nodes: Array<{
      __typename?: 'ProductCategory';
      id: string;
      uri: string;
      name: string;
      count: number;
      slug: string;
      description: string;
      parentId: string;
    }>;
  };
};

export type GetProductCategoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  idType: InputMaybe<ProductCategoryIdType>;
}>;

export type GetProductCategoryQuery = {
  __typename?: 'RootQuery';
  productCategory: {
    __typename?: 'ProductCategory';
    id: string;
    uri: string;
    name: string;
    count: number;
    slug: string;
    description: string;
    parentId: string;
  };
};

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  __typename?: 'RootQuery';
  categories: {
    __typename?: 'RootQueryToCategoryConnection';
    nodes: Array<{
      __typename?: 'Category';
      id: string;
      uri: string;
      name: string;
      count: number;
      slug: string;
      description: string;
      parentId: string;
    }>;
  };
};

export type GetConfirmationScreensQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  orderby: InputMaybe<
    | Array<InputMaybe<PostObjectsConnectionOrderbyInput>>
    | InputMaybe<PostObjectsConnectionOrderbyInput>
  >;
}>;

export type GetConfirmationScreensQuery = {
  __typename?: 'RootQuery';
  confirmationScreens: {
    __typename?: 'RootQueryToConfirmationScreenConnection';
    nodes: Array<{
      __typename?: 'ConfirmationScreen';
      id: string;
      title: string;
      slug: string;
      status: string;
      config: { __typename?: 'ConfirmationScreen_Config'; collectionType: string; wallet: string };
      fields: {
        __typename?: 'ConfirmationScreen_Fields';
        description: string;
        cta: string;
        ctaText: string;
        image: { __typename?: 'MediaItem'; sourceUrl: string };
      };
    }>;
  };
};

export type GetAllowlistAndGroupDiscountQueryVariables = Exact<{
  address: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAllowlistAndGroupDiscountQuery = {
  __typename?: 'RootQuery';
  discounts: {
    __typename?: 'RootQueryToProductUnionConnection';
    nodes: Array<
      | {
          __typename?: 'ExternalProduct';
          isAllowlisted: boolean;
          groupDiscount: { __typename?: 'ProductWithGroupDiscount'; percentage: number };
        }
      | {
          __typename?: 'GroupProduct';
          isAllowlisted: boolean;
          groupDiscount: { __typename?: 'ProductWithGroupDiscount'; percentage: number };
        }
      | {
          __typename?: 'SimpleProduct';
          isAllowlisted: boolean;
          groupDiscount: { __typename?: 'ProductWithGroupDiscount'; percentage: number };
        }
      | {
          __typename?: 'SimpleProductVariation';
          isAllowlisted: boolean;
          groupDiscount: { __typename?: 'ProductWithGroupDiscount'; percentage: number };
        }
      | {
          __typename?: 'VariableProduct';
          isAllowlisted: boolean;
          groupDiscount: { __typename?: 'ProductWithGroupDiscount'; percentage: number };
        }
    >;
  };
};

export type GetMaintenanceSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMaintenanceSettingsQuery = {
  __typename?: 'RootQuery';
  maintenance: {
    __typename?: 'MaintenanceMode';
    enabled: boolean;
    title: string;
    message: string;
    image: string;
    background: string;
  };
};

export type GetProductsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  orderby: InputMaybe<Array<InputMaybe<ProductsOrderbyInput>> | InputMaybe<ProductsOrderbyInput>>;
  categoryIn: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  address?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProductsQuery = {
  __typename?: 'RootQuery';
  products: {
    __typename?: 'RootQueryToProductUnionConnection';
    nodes: Array<
      | { __typename?: 'ExternalProduct' }
      | { __typename?: 'GroupProduct' }
      | {
          __typename: 'SimpleProduct';
          id: string;
          name: string;
          sku: string;
          type: ProductTypesEnum;
          collectionType: string;
          isAllowlisted: boolean;
          slug: string;
          description: string;
          shortDescription: string;
          purchasable: boolean;
          onSale: boolean;
          stockStatus: StockStatusEnum;
          stockQuantity: number;
          totalSales: number;
          pricing: {
            __typename?: 'ProductWithGroupDiscount';
            price: string;
            currency: string;
            salePrice: string;
            regularPrice: string;
            groupDiscountPrice: string;
            groupDiscountPercentage: number;
            preferredCurrencySet: boolean;
            totalDiscountPercentage: number;
            alternateCurrencies: Array<{
              __typename?: 'ProductPricingAlternateCurrencies';
              price: string;
              currency: string;
              salePrice: string;
              regularPrice: string;
            }>;
            bondingCurve: {
              __typename?: 'ProductBondingCurve';
              active: boolean;
              maxDiscount: number;
              minDiscount: number;
              quantityLimit: number;
              percentage: number;
              remainingDiscountPool: number;
            };
            discountCurve: {
              __typename?: 'ProductDiscountCurve';
              name: string;
              percentage: number;
              remainingDiscountPool: number;
            };
          };
          image: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
          galleryImages: {
            __typename?: 'ProductToMediaItemConnection';
            nodes: Array<{ __typename?: 'MediaItem'; altText: string; sourceUrl: string }>;
          };
          attributes: {
            __typename?: 'ProductToProductAttributeConnection';
            nodes: Array<
              | { __typename?: 'GlobalProductAttribute' }
              | { __typename?: 'LocalProductAttribute'; name: string; options: Array<string> }
            >;
          };
        }
      | { __typename?: 'SimpleProductVariation' }
      | { __typename?: 'VariableProduct' }
    >;
  };
};

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  idType?: InputMaybe<ProductIdTypeEnum>;
  address?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProductQuery = {
  __typename?: 'RootQuery';
  product:
    | { __typename?: 'ExternalProduct' }
    | { __typename?: 'GroupProduct' }
    | {
        __typename: 'SimpleProduct';
        id: string;
        name: string;
        sku: string;
        type: ProductTypesEnum;
        collectionType: string;
        isAllowlisted: boolean;
        slug: string;
        description: string;
        shortDescription: string;
        purchasable: boolean;
        onSale: boolean;
        stockStatus: StockStatusEnum;
        stockQuantity: number;
        totalSales: number;
        pricing: {
          __typename?: 'ProductWithGroupDiscount';
          price: string;
          currency: string;
          salePrice: string;
          regularPrice: string;
          groupDiscountPrice: string;
          groupDiscountPercentage: number;
          preferredCurrencySet: boolean;
          totalDiscountPercentage: number;
          alternateCurrencies: Array<{
            __typename?: 'ProductPricingAlternateCurrencies';
            price: string;
            currency: string;
            salePrice: string;
            regularPrice: string;
          }>;
          bondingCurve: {
            __typename?: 'ProductBondingCurve';
            active: boolean;
            maxDiscount: number;
            minDiscount: number;
            quantityLimit: number;
            percentage: number;
            remainingDiscountPool: number;
          };
          discountCurve: {
            __typename?: 'ProductDiscountCurve';
            name: string;
            percentage: number;
            remainingDiscountPool: number;
          };
        };
        image: { __typename?: 'MediaItem'; altText: string; sourceUrl: string };
        galleryImages: {
          __typename?: 'ProductToMediaItemConnection';
          nodes: Array<{ __typename?: 'MediaItem'; altText: string; sourceUrl: string }>;
        };
        attributes: {
          __typename?: 'ProductToProductAttributeConnection';
          nodes: Array<
            | { __typename?: 'GlobalProductAttribute' }
            | { __typename?: 'LocalProductAttribute'; name: string; options: Array<string> }
          >;
        };
      }
    | { __typename?: 'VariableProduct' };
};

export const BannerFragmentDoc = gql`
  fragment Banner on Banner {
    id
    title
    slug
    status
    bannerInfo {
      copy
      copyEnd
      title
      titleEnd
      video {
        altText
        sourceUrl: mediaItemUrl
      }
      endDate
      startDate
      image {
        altText
        sourceUrl
      }
    }
  }
`;
export const ProductCategoryFragmentDoc = gql`
  fragment ProductCategory on ProductCategory {
    id
    uri
    name
    count
    slug
    description
    parentId
  }
`;
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    uri
    name
    count
    slug
    description
    parentId
  }
`;
export const ConfirmationScreenFragmentDoc = gql`
  fragment ConfirmationScreen on ConfirmationScreen {
    id
    title
    slug
    status
    config {
      collectionType
      wallet
    }
    fields {
      description
      cta
      ctaText
      image {
        sourceUrl
      }
    }
  }
`;
export const MaintenanceFragmentDoc = gql`
  fragment Maintenance on MaintenanceMode {
    enabled
    title
    message
    image
    background
  }
`;
export const MediaItemFragmentDoc = gql`
  fragment MediaItem on MediaItem {
    altText
    sourceUrl
  }
`;
export const ProductAttributesFragmentDoc = gql`
  fragment ProductAttributes on LocalProductAttribute {
    name
    options
  }
`;
export const SimpleProductFragmentDoc = gql`
  fragment SimpleProduct on SimpleProduct {
    __typename
    id
    name
    sku
    type
    collectionType
    pricing(address: $address) {
      price
      currency
      salePrice
      regularPrice
      groupDiscountPrice
      groupDiscountPercentage
      preferredCurrencySet
      alternateCurrencies {
        price
        currency
        salePrice
        regularPrice
      }
      bondingCurve {
        active
        maxDiscount
        minDiscount
        quantityLimit
        percentage
        remainingDiscountPool
      }
      discountCurve {
        name
        percentage
        remainingDiscountPool
      }
      totalDiscountPercentage
    }
    isAllowlisted(address: $address)
    id
    sku
    slug
    name
    description
    shortDescription
    image {
      ...MediaItem
    }
    galleryImages {
      nodes {
        ...MediaItem
      }
    }
    purchasable
    onSale
    stockStatus
    stockQuantity
    totalSales
    attributes {
      nodes {
        ...ProductAttributes
      }
    }
  }
  ${MediaItemFragmentDoc}
  ${ProductAttributesFragmentDoc}
`;
export const GetBannersDocument = gql`
  query getBanners(
    $first: Int
    $last: Int
    $search: String
    $orderby: [PostObjectsConnectionOrderbyInput]
  ) {
    banners(first: $first, last: $last, where: { search: $search, orderby: $orderby }) {
      nodes {
        ...Banner
      }
    }
  }
  ${BannerFragmentDoc}
`;
export const GetProductCategoriesDocument = gql`
  query getProductCategories {
    productCategories {
      nodes {
        ...ProductCategory
      }
    }
  }
  ${ProductCategoryFragmentDoc}
`;
export const GetProductCategoryDocument = gql`
  query getProductCategory($id: ID!, $idType: ProductCategoryIdType) {
    productCategory(id: $id, idType: $idType) {
      ...ProductCategory
    }
  }
  ${ProductCategoryFragmentDoc}
`;
export const GetCategoriesDocument = gql`
  query getCategories {
    categories {
      nodes {
        ...Category
      }
    }
  }
  ${CategoryFragmentDoc}
`;
export const GetConfirmationScreensDocument = gql`
  query getConfirmationScreens(
    $first: Int
    $last: Int
    $search: String
    $orderby: [PostObjectsConnectionOrderbyInput]
  ) {
    confirmationScreens(first: $first, last: $last, where: { search: $search, orderby: $orderby }) {
      nodes {
        ...ConfirmationScreen
      }
    }
  }
  ${ConfirmationScreenFragmentDoc}
`;
export const GetAllowlistAndGroupDiscountDocument = gql`
  query getAllowlistAndGroupDiscount($address: String) {
    discounts: products(first: 1) {
      nodes {
        isAllowlisted(address: $address)
        groupDiscount: pricing(address: $address) {
          percentage: groupDiscountPercentage
        }
      }
    }
  }
`;
export const GetMaintenanceSettingsDocument = gql`
  query getMaintenanceSettings {
    maintenance {
      ...Maintenance
    }
  }
  ${MaintenanceFragmentDoc}
`;
export const GetProductsDocument = gql`
  query getProducts(
    $first: Int
    $last: Int
    $search: String
    $orderby: [ProductsOrderbyInput]
    $categoryIn: [String]
    $address: String = ""
  ) {
    products(
      first: $first
      last: $last
      where: { search: $search, orderby: $orderby, categoryIn: $categoryIn, address: $address }
    ) {
      nodes {
        ...SimpleProduct
      }
    }
  }
  ${SimpleProductFragmentDoc}
`;
export const GetProductDocument = gql`
  query getProduct($id: ID!, $idType: ProductIdTypeEnum = ID, $address: String = "") {
    product(id: $id, idType: $idType) {
      ...SimpleProduct
    }
  }
  ${SimpleProductFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) =>
  action();
const GetBannersDocumentString = print(GetBannersDocument);
const GetProductCategoriesDocumentString = print(GetProductCategoriesDocument);
const GetProductCategoryDocumentString = print(GetProductCategoryDocument);
const GetCategoriesDocumentString = print(GetCategoriesDocument);
const GetConfirmationScreensDocumentString = print(GetConfirmationScreensDocument);
const GetAllowlistAndGroupDiscountDocumentString = print(GetAllowlistAndGroupDiscountDocument);
const GetMaintenanceSettingsDocumentString = print(GetMaintenanceSettingsDocument);
const GetProductsDocumentString = print(GetProductsDocument);
const GetProductDocumentString = print(GetProductDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getBanners(
      variables?: GetBannersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetBannersQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetBannersQuery>(GetBannersDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders
          }),
        'getBanners',
        'query',
        variables
      );
    },
    getProductCategories(
      variables?: GetProductCategoriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetProductCategoriesQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetProductCategoriesQuery>(
            GetProductCategoriesDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getProductCategories',
        'query',
        variables
      );
    },
    getProductCategory(
      variables: GetProductCategoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetProductCategoryQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetProductCategoryQuery>(GetProductCategoryDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders
          }),
        'getProductCategory',
        'query',
        variables
      );
    },
    getCategories(
      variables?: GetCategoriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetCategoriesQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetCategoriesQuery>(GetCategoriesDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders
          }),
        'getCategories',
        'query',
        variables
      );
    },
    getConfirmationScreens(
      variables?: GetConfirmationScreensQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetConfirmationScreensQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetConfirmationScreensQuery>(
            GetConfirmationScreensDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getConfirmationScreens',
        'query',
        variables
      );
    },
    getAllowlistAndGroupDiscount(
      variables?: GetAllowlistAndGroupDiscountQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetAllowlistAndGroupDiscountQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetAllowlistAndGroupDiscountQuery>(
            GetAllowlistAndGroupDiscountDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getAllowlistAndGroupDiscount',
        'query',
        variables
      );
    },
    getMaintenanceSettings(
      variables?: GetMaintenanceSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetMaintenanceSettingsQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetMaintenanceSettingsQuery>(
            GetMaintenanceSettingsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getMaintenanceSettings',
        'query',
        variables
      );
    },
    getProducts(
      variables?: GetProductsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetProductsQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetProductsQuery>(GetProductsDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders
          }),
        'getProducts',
        'query',
        variables
      );
    },
    getProduct(
      variables: GetProductQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<{
      data: GetProductQuery;
      errors?: GraphQLError[];
      extensions?: any;
      headers: Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<GetProductQuery>(GetProductDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders
          }),
        'getProduct',
        'query',
        variables
      );
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
