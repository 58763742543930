import { GraphQLClient } from 'graphql-request';

import { graphQLServerURL } from 'src/utils/env';
import {
  ConfirmationScreen,
  GetBannersQueryVariables,
  GetConfirmationScreensQueryVariables,
  GetProductQueryVariables,
  GetProductsQueryVariables,
  ProductIdTypeEnum,
  getSdk
} from './__generated__/graphql';
import { Banner, ConfirmationScreenMap, MaintenanceSettings, Product } from './types';

/* ------------------------------ Setup ------------------------------ */
const gqlClient = new GraphQLClient(graphQLServerURL, {
  fetch
});

const sdk = getSdk(gqlClient);

/* -------------------------- Provider Methods ----------------------- */
export const getProduct = async (
  id: GetProductQueryVariables['id'],
  idType: GetProductQueryVariables['idType'] = ProductIdTypeEnum.Sku,
  address: string = ''
): Promise<Product> => {
  const { data } = await sdk.getProduct({ id, idType, address });
  return data.product as Product;
};

export const getProducts = async (
  variables: Partial<GetProductsQueryVariables>
): Promise<Product[]> => {
  const { data } = await sdk.getProducts(variables as GetProductsQueryVariables);
  return data.products.nodes.map((product) => product as Product);
};

export const getBanners = async (
  variables: Partial<GetBannersQueryVariables>
): Promise<Banner[]> => {
  const { data } = await sdk.getBanners(variables as GetBannersQueryVariables);
  return data.banners.nodes.map((banner) => banner as Banner);
};

export const getAllowlistAndGroupDiscount = async (address: string) => {
  const { data } = await sdk.getAllowlistAndGroupDiscount({ address });
  return data;
};

export const getConfirmationScreens = async (
  variables: Partial<GetConfirmationScreensQueryVariables>
): Promise<ConfirmationScreenMap> => {
  const { data } = await sdk.getConfirmationScreens(
    variables as GetConfirmationScreensQueryVariables
  );
  const screens: ConfirmationScreenMap = {};

  for (const screen of data.confirmationScreens.nodes) {
    screens[`${screen.config.collectionType}-${screen.config.wallet}`] =
      screen as ConfirmationScreen;
  }

  return screens;
};

export const getMaintenanceSettings = async (): Promise<MaintenanceSettings> => {
  const { data } = await sdk.getMaintenanceSettings();
  return data.maintenance as MaintenanceSettings;
};
