import {
  getBanners,
  getConfirmationScreens,
  getMaintenanceSettings,
  getProducts
} from 'lib/woocomerce';
import { OrderEnum, ProductsOrderByEnum } from 'lib/woocomerce/__generated__/graphql';
import { Banner, ConfirmationScreenMap, Product } from 'lib/woocomerce/types';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';

import { CollectionView, Header, Layout, Overlay } from 'src/components';
import { Background } from 'src/components/background';
import { ProductsProvider } from 'src/context/products-context';
import { StoreProvider } from 'src/context/store-context';
import { useTrackPageView } from 'src/hooks/use-track-page-view';
import { storefrontName } from 'src/utils';

type PageProps = {
  banner: Banner;
  initialProducts: Product[];
  confirmationScreens: ConfirmationScreenMap;
};

const Content = () => {
  useTrackPageView();

  return (
    <>
      <Head>
        <title>{storefrontName}</title>
      </Head>
      <Background />
      <Layout>
        <Header />
        <CollectionView />
      </Layout>
      <Overlay />
    </>
  );
};
export default function Index({ banner, initialProducts, confirmationScreens }: PageProps) {
  return (
    <StoreProvider value={{ banner, confirmationScreens }}>
      <ProductsProvider value={{ initialProducts }}>
        <Content />
      </ProductsProvider>
    </StoreProvider>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  context.res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=10');

  const maintenanceSettings = await getMaintenanceSettings();
  if (maintenanceSettings?.enabled) {
    return {
      redirect: {
        destination: '/maintenance',
        permanent: false
      }
    };
  }

  const banner = (await getBanners({ first: 1, search: '[active]' })).pop() || null;
  const initialProducts = await getProducts({
    first: 1000,
    orderby: [{ order: OrderEnum.Asc, field: ProductsOrderByEnum.MenuOrder }]
  });

  const confirmationScreens = await getConfirmationScreens({ first: 4 });

  return {
    props: {
      banner,
      initialProducts,
      confirmationScreens
    }
  };
}
