import { LocalProductAttribute, StockStatusEnum } from 'lib/woocomerce/__generated__/graphql';
import { Product } from 'lib/woocomerce/types';
import { STORE_MAX_QUANTITY } from 'src/utils';

import { checkout as ImtblCheckout } from '@imtbl/sdk';

export const getProductAttributes = (
  product?: Product,
  selectedQuantity = 0,
  walletAddress?: string
) => {
  if (!product) {
    return {
      attributes: [],
      bundleSize: 1,
      maxQuantity: 0,
      stockQty: 0,
      productName: '',
      excludePaymentTypes: []
    };
  }

  const attributes = product.attributes?.nodes as LocalProductAttribute[];
  const bundleSize = Number(
    attributes?.find((attr) => attr.name === 'bundle_size')?.options[0] || 1
  );
  const itemMaxQuantity = Number(
    attributes?.find((attr) => attr.name === 'max_quantity')?.options[0] || 0
  );

  const productName =
    attributes?.find((attr) => attr.name === 'bundle_item_name')?.options[0] || product?.name;

  const disabledPaymentTypes = (attributes
    ?.find((attr) => attr.name === 'exclude_payment_types')
    ?.options[0]?.split(',') || []) as ImtblCheckout.SalePaymentTypes[];

  const allowlistedWallets = (attributes
    ?.find((attr) => attr.name === 'payment_types_allowlist')
    ?.options[0]?.toLowerCase()
    .split(',') || []) as string[];

  // if stockQuantity is null, means product stock is unlimited
  const unlimitedStock =
    product.stockQuantity === null && product.stockStatus === StockStatusEnum.InStock;
  const stockQty = unlimitedStock ? 1e6 : Math.floor(product.stockQuantity / (bundleSize || 1));
  // allow to select as much items as crypto processing limit allows
  const storeMaxQty = itemMaxQuantity || STORE_MAX_QUANTITY['crypto'];
  const maxQuantity = Math.min(stockQty, storeMaxQty) * bundleSize;

  const paymentTypesToExclude = allowlistedWallets.includes(`${walletAddress}`)
    ? []
    : disabledPaymentTypes;

  // however, if selection exceeds fiat processing limit, disable card payments
  const exceedsMaxFiatQty = selectedQuantity > STORE_MAX_QUANTITY['fiat'];
  const excludePaymentTypes = exceedsMaxFiatQty
    ? [ImtblCheckout.SalePaymentTypes.CREDIT, ImtblCheckout.SalePaymentTypes.DEBIT]
    : paymentTypesToExclude;

  return {
    attributes,
    bundleSize,
    maxQuantity,
    stockQty,
    productName,
    excludePaymentTypes
  };
};
