import { Body, Box, Heading } from '@biom3/react';
import { useStore } from 'src/context/store-context';
import { ConnectWallet } from './connect-wallet';
import { SaleCountdownTimer } from './sale-timer';

export const Header = () => {
  const [{ saleEnded, banner }] = useStore();

  const heading = saleEnded ? banner?.bannerInfo.titleEnd : banner?.bannerInfo.title;
  const description = saleEnded ? banner?.bannerInfo.copyEnd : banner?.bannerInfo.copy;
  const withTimer = banner?.bannerInfo.startDate && banner?.bannerInfo.endDate;

  return (
    <Box
      rc={<header />}
      sx={{
        display: 'flex',
        position: 'relative',
        userSelect: 'none',
        flexDirection: ['column', 'column', 'column', 'row'],
        justifyContent: ['center', 'center', 'space-between'],
        alignItems: ['center', 'flex-start', 'flex-end'],
        minHeight: 'base.spacing.x20',
        color: 'base.color.text.body.inverse.primary',
        width: '100%'
      }}
    >
      <Box
        rc={<nav />}
        sx={{
          width: '100%',
          flexGrow: [1.2, 1.2, 1],
          flexShrink: [1, 1, 1, 0],
          flexBasis: ['0%', '0%', '0%', '100%'],
          mr: ['0', '0', '0', 'base.spacing.x4'],
          my: ['0', '0', 'base.spacing.x6', '0'],
          mb: ['base.spacing.x2', '0']
        }}
      >
        <Heading size={'medium'} sx={{ mb: ['0', '0', 'base.spacing.x4'] }}>
          {heading}
        </Heading>
        {!description && withTimer && (
          <SaleCountdownTimer
            sx={{
              mx: ['auto', 'auto', '0'],
              my: ['base.spacing.x4', 'base.spacing.x4', '0'],
              display: 'flex'
            }}
          />
        )}
        {description && (
          <Body
            weight="bold"
            sx={{
              mt: 'base.spacing.x4',
              display: ['none', 'none', 'block'],
              textShadow: '2px 2px 2px rgb(0 0 0 / 50%)'
            }}
          >
            {description}
          </Body>
        )}
      </Box>
      <Box
        sx={{
          flexGrow: [1, 1, 1, 0],
          flexShrink: [1, 1, 1, 0],
          flexBasis: ['0%', '0%', '0%', '70%'],
          width: ['100%', '100%', '100%', 'auto'],
          display: 'flex',
          flexDirection: ['column', 'column', 'column', 'row'],
          justifyContent: ['center', 'center', 'flex-end']
        }}
      >
        <ConnectWallet />
      </Box>
    </Box>
  );
};
