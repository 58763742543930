import { useEffect } from 'react';

export const useBlockScroll = (hidden: boolean) => {
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;

    if (hidden) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalOverflow;
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [hidden]);
};
