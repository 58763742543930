import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useEffect } from 'react';
import { useWidgets } from 'src/context/widgets-context';
import { notifyError } from '../utils';

export const useSwapWidget = () => {
  const [{ factory, swapWidget }, dispatch] = useWidgets();

  useEffect(() => {
    // create swap widget
    if (!factory || swapWidget) return;

    (async () => {
      try {
        const widget = factory.create(ImtblCheckout.WidgetType.SWAP);
        dispatch({ payload: { type: 'SET_WIDGET', widget: { swapWidget: widget } } });
      } catch (error) {
        notifyError(error);
      }
    })();
  }, [factory, dispatch, swapWidget]);

  return { swapWidget };
};
