import { Product } from 'lib/woocomerce/types';

const getProductExtras = (product?: Product) => ({
  id: product?.id || '',
  sku: product?.sku || '',
  productName: product?.name || '',
  price:
    parseFloat(product?.pricing.price ?? '')
      .toFixed(20)
      .toString() || '',
  currency: product?.pricing.currency || '',
  stockQuantity: product?.stockQuantity || '',
  onSale: Boolean(product?.onSale),
  isAllowlisted: Boolean(product?.isAllowlisted),
  originalPrice: product?.pricing?.salePrice ?? product?.pricing?.regularPrice,
  bondingCurve: product?.pricing?.bondingCurve || '',
  totalDiscountPercentage: product?.pricing?.totalDiscountPercentage || ''
});

export const getScreen = (path: string, product?: Product) => {
  const isRoot = path.split('?')[0] === '/';

  if (isRoot && !product?.id) {
    return '_landing';
  }

  const title = product?.name
    .replace(' ', '-')
    .toLowerCase()
    .replace(/[^a-z-]/g, '');

  if (title) {
    return `_${title}`;
  }

  return '';
};

export const getExtras = (
  product?: Product,
  walletAddress?: string,
  walletProviderName?: string,
  userInfo?: Record<string, unknown>,
  quantity?: number
) => ({
  walletAddress,
  walletProviderName,
  ...(!product?.id
    ? {}
    : {
        ...getProductExtras(product),
        quantity
      }),
  ...(!userInfo ? {} : { userInfo })
});
