import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useEffect } from 'react';
import { useWidgets } from 'src/context/widgets-context';
import { notifyError } from '../utils';

export const useOnRampWidget = () => {
  const [{ factory, onRampWidget }, dispatch] = useWidgets();

  useEffect(() => {
    // create onramp widget
    if (!factory || onRampWidget) return;

    (async () => {
      try {
        const widget = factory.create(ImtblCheckout.WidgetType.ONRAMP);
        dispatch({ payload: { type: 'SET_WIDGET', widget: { onRampWidget: widget } } });
      } catch (error) {
        notifyError(error);
      }
    })();
  }, [factory, dispatch, onRampWidget]);

  return { onRampWidget };
};
