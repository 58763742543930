import { BoxProps, CountdownTimer } from '@biom3/react';
import { useStore } from 'src/context/store-context';

export const SaleCountdownTimer = ({ sx }: { sx?: BoxProps['sx'] }) => {
  const [{ saleEndDate, saleEnded }] = useStore();

  if (saleEnded || !saleEndDate) return;

  return (
    <CountdownTimer
      size="large"
      variant="inverse"
      futureTarget={saleEndDate}
      sx={{
        width: 'fit-content',
        '& .CountdownTile': {
          brad: 'base.borderRadius.x1'
        },
        ...sx
      }}
    />
  );
};
