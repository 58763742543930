import { Box } from '@biom3/react';
import { useStore } from 'src/context/store-context';

const GradientMask = () => (
  <Box
    sx={{
      display: 'block',
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      pointerEvents: 'none',
      background: 'base.gradient.2'
    }}
  />
);

const VideoBackground = ({ url }: { url: string }) => {
  if (!url) return null;

  return (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none'
        }}
      >
        <source src={url} type="video/mp4" />
      </video>
      <GradientMask />
    </>
  );
};

const FixedBackground = ({ url }: { url?: string }) => {
  if (!url) return null;

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          pointerEvents: 'none',
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <GradientMask />
    </>
  );
};

export const Background = () => {
  const [{ banner }] = useStore();
  const videoUrl = banner?.bannerInfo?.video?.sourceUrl;

  if (videoUrl) {
    return <VideoBackground url={videoUrl} />;
  }

  const imageUrl = banner?.bannerInfo?.image?.sourceUrl;
  return <FixedBackground url={imageUrl} />;
};
