import { useCallback, useEffect } from 'react';

import { Product } from 'lib/woocomerce/types';
import { useStore } from 'src/context/store-context';
import { SALE_WIDGET_ROOT, saleWidgetConfig, sanitizeToLatin1, storefrontName } from 'src/utils';
import { getProductAttributes } from 'src/utils/product-attributes';
import { useSaleWidget } from './use-sale-widget';

export const useBuyProduct = () => {
  const [{ modalContent, selectedQuantity, selectedProduct, walletAddress }, dispatch] = useStore();
  const { productName, excludePaymentTypes } = getProductAttributes(
    selectedProduct,
    selectedQuantity,
    walletAddress
  );

  const buyProduct = (selected: Product, qty: number) => {
    dispatch({
      payload: { type: 'SET_SELECTED_PRODUCT', selectedProduct: selected, selectedQuantity: qty }
    });
  };

  const setQuantity = (quantity: number) => {
    dispatch({ payload: { type: 'SET_SELECTED_QUANTITY', quantity } });
  };

  const preferredCurrency = selectedProduct?.pricing.preferredCurrencySet
    ? selectedProduct?.pricing.currency
    : undefined;

  const { environmentId, language } = saleWidgetConfig;
  const { saleWidget, mountSaleWidget } = useSaleWidget({
    onClose: () => handleOnClose(),
    params: {
      language,
      environmentId,
      collectionName: storefrontName,
      excludePaymentTypes,
      preferredCurrency,
      items: [
        {
          qty: selectedQuantity,
          productId: selectedProduct?.sku || '',
          image: selectedProduct?.image?.sourceUrl || '',
          name: sanitizeToLatin1(productName || ''),
          description: sanitizeToLatin1(selectedProduct?.description || '')
        }
      ]
    },
    config: {
      config: {
        hideExcludedPaymentTypes: true
      }
    }
  });

  const handleOnClose = useCallback(() => {
    dispatch({ payload: { type: 'SET_TOGGLE_MODAL_OPEN', open: false } });
    dispatch({ payload: { type: 'RESET_SELECTED_PRODUCT' } });
    saleWidget?.unmount();
  }, [saleWidget, dispatch]);

  // mount widget when product is selected
  useEffect(() => {
    if (selectedProduct === undefined || modalContent !== null) return;

    dispatch({
      payload: {
        type: 'SET_MODAL_CONTENT',
        open: true,
        content: <div id={SALE_WIDGET_ROOT} ref={mountSaleWidget} />,
        onModalClose: handleOnClose
      }
    });
  }, [selectedProduct, mountSaleWidget, modalContent, dispatch, handleOnClose]);

  return {
    buyProduct,
    setQuantity,
    quantity: selectedQuantity
  };
};
