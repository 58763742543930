export const useScrollTo = (id: string, delayMs = 150) => {
  const scrollTo = () => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, delayMs);
    }
  };

  return scrollTo;
};
