/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useAnalytics } from 'src/context/analytics-context';
import { useStore } from 'src/context/store-context';
import { useWidgets } from 'src/context/widgets-context';
import { getExtras, getScreen } from 'src/utils/analytics';

export function useTrackPageView() {
  const router = useRouter();
  const { page, track } = useAnalytics();
  const mounted = useRef(false);
  const connected = useRef(false);

  const [{ selectedProduct, walletAddress, selectedQuantity }] = useStore();
  const [{ walletProviderName, userInfo }] = useWidgets();

  const path = router.asPath;

  const trackPageView = () => {
    const screen = getScreen(path, selectedProduct);
    const extras = {
      title: screen,
      ...getExtras(selectedProduct, walletAddress, walletProviderName, userInfo, selectedQuantity)
    };
    page({
      userJourney: 'ViewPage',
      screen,
      extras
    });
  };

  const trackProductView = () => {
    track({
      userJourney: 'ViewProduct',
      screen: getScreen(path, selectedProduct),
      extras: getExtras(
        selectedProduct,
        walletAddress,
        walletProviderName,
        userInfo,
        selectedQuantity
      ),
      control: 'BuyNow',
      controlType: 'Button',
      action: 'Pressed'
    });
  };

  const trackWalletConnected = () => {
    track({
      userJourney: 'WalletConnected',
      screen: getScreen(path, selectedProduct),
      extras: getExtras(
        selectedProduct,
        walletAddress,
        walletProviderName,
        userInfo,
        selectedQuantity
      ),
      control: 'ConnectWallet',
      controlType: 'Button',
      action: 'Pressed'
    });
  };

  useEffect(() => {
    // first page view
    if (mounted.current) return;
    trackPageView();
    mounted.current = true;
  }, []);

  useEffect(() => {
    // wallet disconnected
    if (connected.current && !walletAddress) {
      connected.current = false;
      return;
    }

    // wallet connected
    if (!walletAddress || !walletProviderName || connected.current) return;
    trackWalletConnected();
    connected.current = true;
  }, [walletAddress, walletProviderName]);

  useEffect(() => {
    // product selected
    if (!selectedProduct) return;

    trackProductView();
  }, [selectedProduct]);
}
