/* eslint-disable no-unused-vars */
import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { Banner, Product, ConfirmationScreenMap } from 'lib/woocomerce/types';
import { Dispatch, ReactNode, createContext, useContext, useMemo, useReducer } from 'react';
import { notifyError } from '../utils';

export type StoreState = {
  banner?: Banner;
  confirmationScreens?: ConfirmationScreenMap;
  saleEnded: boolean;
  saleEndDate?: Date;
  modalOpen: boolean;
  modalContent: ReactNode | null;
  onModalClose?: () => void;
  walletOpen: boolean;
  walletAddress?: string;
  connected: boolean;
  connecting: boolean;
  selectedQuantity: number;
  selectedProduct?: Product;
  saleResult: {
    successful: boolean | undefined;
    product: Product;
  } & ImtblCheckout.SaleSuccess;
};

type SetToggleModalOpen = {
  type: 'SET_TOGGLE_MODAL_OPEN';
  open: boolean;
};

type SetModalContent = {
  type: 'SET_MODAL_CONTENT';
  open?: boolean;
  content: ReactNode | null;
  onModalClose?: () => void;
};

type SetToggleWalletOpen = {
  type: 'SET_TOGGLE_WALLET_OPEN';
  open: boolean;
};

type SetWalletAddress = {
  type: 'SET_WALLET_ADDRESS';
  address: string | undefined;
};

type SetWalletConnected = {
  type: 'SET_WALLET_CONNECTED';
  connected: boolean;
};

type SetWalletConnecting = {
  type: 'SET_WALLET_CONNECTING';
  connecting: boolean;
};

type SetWalletDisconnected = {
  type: 'SET_WALLET_DISCONNECTED';
};

type SetSelectedProduct = {
  type: 'SET_SELECTED_PRODUCT';
  selectedProduct: Product | undefined;
  selectedQuantity: number;
};

type ResetSelectedProduct = {
  type: 'RESET_SELECTED_PRODUCT';
};

type SetSelectedQuantity = {
  type: 'SET_SELECTED_QUANTITY';
  quantity: number;
};

type SetSaleResult = {
  type: 'SET_SALE_RESULT';
  successful: boolean | undefined;
  product: Product;
  result: ImtblCheckout.SaleSuccess;
};

type ResetSaleResult = {
  type: 'RESET_SALE_RESULT';
};

type StoreActionPayload =
  | SetModalContent
  | SetToggleModalOpen
  | SetToggleWalletOpen
  | SetWalletAddress
  | SetWalletConnected
  | SetWalletConnecting
  | SetWalletDisconnected
  | SetSelectedProduct
  | ResetSelectedProduct
  | SetSelectedQuantity
  | SetSaleResult
  | ResetSaleResult;

type StoreAction = {
  payload: StoreActionPayload;
};

type StoreContextState = {
  state: StoreState;
  dispatch: Dispatch<StoreAction>;
};

export const initialStoreState: StoreState = {
  banner: undefined,
  saleEnded: false,
  saleEndDate: undefined,
  modalOpen: false,
  modalContent: null,
  onModalClose: undefined,
  walletOpen: false,
  walletAddress: '',
  connected: false,
  connecting: false,
  selectedQuantity: 1,
  selectedProduct: undefined,
  saleResult: {
    successful: undefined,
    transactions: [],
    tokenIds: [],
    transactionId: '',
    product: undefined as unknown as Product,
    paymentMethod: undefined as ImtblCheckout.SaleSuccess['paymentMethod']
  }
};

export const StoreContext = createContext<StoreContextState>({
  state: initialStoreState,
  dispatch: () => {}
});

StoreContext.displayName = 'StoreContext';

type Reducer<S, A> = (prevState: S, action: A) => S;

const StoreReducer: Reducer<StoreState, StoreAction> = (state, action) => {
  switch (action.payload.type) {
    case 'SET_TOGGLE_MODAL_OPEN':
      return {
        ...state,
        modalOpen: action.payload.open,
        modalContent: !action.payload.open ? null : state.modalContent,
        onModalClose: !action.payload.open ? undefined : state.onModalClose
      };
    case 'SET_MODAL_CONTENT':
      if ('open' in action.payload) {
        return {
          ...state,
          modalContent: action.payload.content,
          modalOpen: !!action.payload.open,
          onModalClose: action.payload.onModalClose
        };
      }
      return { ...state, modalContent: action.payload.content };
    case 'SET_TOGGLE_WALLET_OPEN':
      return { ...state, walletOpen: action.payload.open };
    case 'SET_WALLET_ADDRESS':
      return { ...state, walletAddress: action.payload.address };
    case 'RESET_SELECTED_PRODUCT':
      return { ...state, selectedProduct: undefined, selectedQuantity: 1 };
    case 'SET_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: action.payload.selectedProduct,
        selectedQuantity: action.payload.selectedQuantity
      };
    case 'SET_SELECTED_QUANTITY':
      return { ...state, quantity: action.payload.quantity };
    case 'SET_WALLET_CONNECTED':
      return { ...state, connected: action.payload.connected };
    case 'SET_WALLET_CONNECTING':
      return { ...state, connecting: action.payload.connecting };
    case 'SET_WALLET_DISCONNECTED':
      return { ...state, connecting: false, connected: false, walletAddress: undefined };
    case 'SET_SALE_RESULT':
      const { successful, product, result } = action.payload;
      return {
        ...state,
        saleResult: { successful, product, ...result }
      };
    case 'RESET_SALE_RESULT':
      return { ...state, saleResult: initialStoreState.saleResult };
    default:
      return state;
  }
};

export const useStoreValue = (overrides: Partial<StoreState> = {}) => {
  const [state, dispatch] = useReducer(StoreReducer, { ...initialStoreState, ...overrides });
  const values = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return values;
};

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    const error = new Error('useWidgets must be used within a WidgetsContext.Provider');
    notifyError(error);
    throw error;
  }
  return [context.state, context.dispatch] as const;
};

export const StoreProvider = ({
  children,
  value
}: {
  children: ReactNode;
  value: Partial<StoreState>;
}) => {
  const { state, dispatch } = useStoreValue(value);
  const { banner } = state;

  const withSalesTimer =
    Boolean(banner?.bannerInfo.startDate) && Boolean(banner?.bannerInfo.endDate);
  let saleEnded = false;
  let saleEndDate: Date | undefined = undefined;

  if (withSalesTimer) {
    const now = new Date();
    saleEndDate = banner ? new Date(+banner.bannerInfo.endDate * 1000) : now;
    saleEnded = now >= saleEndDate;
  }

  const storeValue = useMemo(
    () => ({
      state: { ...state, saleEnded, saleEndDate },
      dispatch
    }),
    [state, dispatch, saleEnded, saleEndDate]
  );

  return <StoreContext.Provider value={storeValue}>{children}</StoreContext.Provider>;
};
