import { useEffect, useState } from 'react';

export const useQuantity = (initialQuantity: number, maxQuantity: number) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [exceeded, setExceeded] = useState(false);

  const handleSetQuantity = (amount: number) => {
    const nextQty = quantity + amount;
    const edgeQty = quantity + amount * 2;

    if (edgeQty > maxQuantity) {
      !exceeded && setExceeded(true);
    }

    if (nextQty < 1 || nextQty > maxQuantity) {
      return;
    }

    if (amount === 0) {
      setQuantity(1);
      setExceeded(false);
      return;
    }

    setQuantity(nextQty);
    exceeded && setExceeded(false);
  };

  useEffect(() => {
    if (initialQuantity > maxQuantity) {
      setExceeded(true);
    }
  }, [initialQuantity, maxQuantity]);

  return [quantity, handleSetQuantity, exceeded] as const;
};
