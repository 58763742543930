import { useCallback, useEffect, useRef, useState } from 'react';

export const useTimer = (initialValue: boolean, timeout: number) => {
  const [value, setValue] = useState(initialValue);
  const [remaining, setRemaining] = useState(timeout / 1000);
  const timerRef = useRef<NodeJS.Timeout>();
  const intervalRef = useRef<NodeJS.Timeout>();

  const cancelTimer = useCallback(() => {
    setValue(false);
    clearTimeout(timerRef.current);
    clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    setValue(initialValue);
    setRemaining(timeout / 1000);

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setValue(false);
    }, timeout);

    intervalRef.current = setInterval(() => {
      setRemaining((prevSeconds) => Math.max(0, prevSeconds - 1));
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timerRef.current);
    };
  }, [initialValue, timeout]);

  return [value, cancelTimer, remaining] as const;
};

export const useDelayedValue = <T>(value: T | undefined, delay: number) => {
  const [delayedValue, setDelayedValue] = useState<T | undefined>(undefined);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDelayedValue(value);
    }, delay);

    return () => clearTimeout(timerId);
  }, [value, delay]);

  return delayedValue;
};
