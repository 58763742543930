import { Box, BoxProps } from '@biom3/react';
import { LocalProductAttribute } from 'lib/woocomerce/__generated__/graphql';
import { Children, cloneElement } from 'react';
import { storefrontType } from 'src/utils';

const metalcoreColor = (type?: string): string => {
  switch (type) {
    case 'silver':
      return 'base.color.accent.6';
    case 'gold':
      return 'base.color.accent.7';
    case 'bronze':
      return 'base.color.accent.8';
    case 'obsidian':
      return '#7906ca'; // obsidian
    case 'shard-blue':
      return '#1b6ed3'; // blue shard
    case 'shard-green':
      return '#56c677'; // green shard
    case 'secondary':
      return '#ea733b'; // secondary
    default:
      return 'base.color.accent.1';
  }
};

const MetalCoreFrame = (props: CardFrameProps) => {
  const { attributes, children, ...boxProps } = props;
  const color = attributes?.find((attr) => attr.name === 'frame_color')?.options[0];

  return (
    <Box
      {...boxProps}
      sx={{
        '--frame-color': metalcoreColor(color) as any,
        '--block-size': '14px' as any,
        '--block-width': '2px' as any,
        '--offset': '2px' as any,
        '--tickness': '2px' as any,
        '--position':
          'calc(-1 * (var(--offset) + var(--block-width) + var(--tickness) / 2))' as any,

        width: '100%',
        ...boxProps.sx,
        position: 'relative',
        transitionDelay: '100ms',
        transitionTimingFunction: 'base.motion.normal.fast.cssEase',
        transitionDuration: 'base.motion.bounce.fast.cssDuration',
        transitionProperty: 'transform, outline',

        '&:hover': {
          transform: 'scale(1.01)',
          outlineStyle: 'solid',
          outlineWidth: 'var(--tickness)',
          outlineOffset: 'var(--offset)',
          outlineColor: 'var(--frame-color)',
          transitionTimingFunction: 'base.motion.bounce.fast.cssEase',

          '& > span': {
            opacity: 1,
            transform: 'scale(1.01)',
            transitionTimingFunction: 'base.motion.bounce.fast.cssEase'
          }
        },

        '& > span': {
          opacity: 0,
          transform: 'scale(0)',
          position: ' absolute',
          width: 'var(--block-size)',
          height: 'var(--block-size)',
          transitionTimingFunction: 'base.motion.normal.fast.cssEase',
          transitionDuration: 'base.motion.bounce.fast.cssDuration',
          transitionProperty: 'transform, opacity'
        }
      }}
    >
      <Box
        rc={<span />}
        sx={{
          borderTop: 'var(--block-width) solid var(--frame-color)',
          borderLeft: 'var(--block-width) solid var(--frame-color)',
          top: 'var(--position)',
          left: 'var(--position)'
        }}
      />
      <Box
        rc={<span />}
        sx={{
          borderTop: 'var(--block-width) solid var(--frame-color)',
          borderRight: 'var(--block-width) solid var(--frame-color)',
          top: 'var(--position)',
          right: 'var(--position)'
        }}
      />
      <Box
        rc={<span />}
        sx={{
          borderBottom: 'var(--block-width) solid var(--frame-color)',
          borderLeft: 'var(--block-width) solid var(--frame-color)',
          bottom: 'var(--position)',
          left: 'var(--position)'
        }}
      />
      <Box
        rc={<span />}
        sx={{
          borderBottom: 'var(--block-width) solid var(--frame-color)',
          borderRight: 'var(--block-width) solid var(--frame-color)',
          bottom: 'var(--position)',
          right: 'var(--position)'
        }}
      />
      {Children.map(children, (child) => {
        const childProps = (child as any)?.props ?? {};
        return cloneElement(child as any, { rc: <article />, sx: { ...childProps.sx, brad: '0' } });
      })}
    </Box>
  );
};

export type CardFrameProps = BoxProps & {
  attributes: LocalProductAttribute[];
};
export const CardFrame = ({ attributes, ...boxProps }: CardFrameProps) => {
  if (storefrontType === 'metalcore') {
    return <MetalCoreFrame {...boxProps} attributes={attributes} />;
  }

  return <Box {...boxProps} sx={{ width: '100%', ...boxProps.sx }} />;
};
