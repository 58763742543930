import { Body, BoxProps, ButtCon, Button, Card, Heading } from '@biom3/react';
import { ConfirmationScreen, ConfirmationScreenCTA, Product } from 'lib/woocomerce/types';
import NextLink from 'next/link';
import { gameConfig } from 'src/utils';

const htmlDescriptionStyles: BoxProps['sx'] = {
  a: {
    color: 'base.color.text.link.primary',
    textDecoration: 'none'
  },
  'a:hover': {
    textDecoration: 'base.font.decoration.underline'
  }
};

const Description = ({
  onClose,
  product,
  screenData,
  cta
}: {
  onClose: (reset?: boolean) => void; // eslint-disable-line no-unused-vars
  product: Product;
  screenData: ConfirmationScreen;
  cta: ConfirmationScreenCTA;
}) => {
  const LinkCTA = {
    [ConfirmationScreenCTA.LAUNCH_GAME]: gameConfig.gameClientUrl,
    [ConfirmationScreenCTA.PASSPORT]: gameConfig.passportUrl
  };

  return (
    <>
      <Body
        sx={{
          ...htmlDescriptionStyles,
          display: 'block',
          mt: 'base.spacing.x4',
          mb: 'base.spacing.x8'
        }}
        dangerouslySetInnerHTML={{
          __html: screenData.fields.description
            .replace('{product}', product.name)
            .replace('href="', 'target="_blank" href="')
        }}
      ></Body>
      {cta !== ConfirmationScreenCTA.NONE &&
        (cta === ConfirmationScreenCTA.GOBACK ? (
          <Button sx={{ width: '100%' }} onClick={() => onClose(true)}>
            {screenData.fields.ctaText}
          </Button>
        ) : (
          <Button sx={{ width: '100%' }} rc={<NextLink href={LinkCTA[cta]} target="_blank" />}>
            {screenData.fields.ctaText}
          </Button>
        ))}
    </>
  );
};

export type ConfirmationCardProps = {
  onClose: (reset?: boolean) => void; // eslint-disable-line no-unused-vars
  product?: Product;
  screenData?: ConfirmationScreen;
};
export const ConfirmationCard = ({ onClose, product, screenData }: ConfirmationCardProps) => {
  if (!product) {
    return null;
  }

  if (!screenData) {
    screenData = {
      title: 'It’s on it’s way!',
      fields: {
        description: 'Your order is being processed and will be delivered shortly.',
        cta: ConfirmationScreenCTA.NONE
      }
    } as ConfirmationScreen;
  }

  return (
    <Card
      rc={<div />}
      sx={{
        cursor: 'auto',
        userSelect: 'none',
        aspectRatio: ['initial', 'initial', 'initial', 1 / 1],
        '& .textContainer': {
          position: 'initial'
        },
        margin: '0 auto',
        minWidth: ['auto', 'auto', 'auto', 'auto', '512px'],
        maxWidth: ['100%', '100%', '100%', '90%', '70%'],
        top: ['initial', '50%', 'initial'],
        transform: ['none', 'translateY(-50%)', 'none']
      }}
    >
      <Card.AssetImage
        sx={{
          pointerEvents: 'none'
        }}
        aspectRatio="7:5"
        responsiveSizes={[640, 860, 1440]}
        imageUrl={product?.galleryImages?.nodes?.[0]?.sourceUrl || ''}
      />
      <Card.Caption
        sx={{
          position: 'absolute',
          top: 'base.spacing.x4',
          right: 'base.spacing.x4'
        }}
      >
        <ButtCon icon="Close" onClick={() => onClose(true)} variant="primary/inverse" />
      </Card.Caption>
      <Card.Title>
        <Heading>{screenData.title}</Heading>
      </Card.Title>
      <Card.Description>
        <Description
          product={product}
          screenData={screenData}
          onClose={onClose}
          cta={screenData.fields.cta as ConfirmationScreenCTA}
        />
      </Card.Description>
    </Card>
  );
};
