import { getAllowlistAndGroupDiscount, getProducts } from 'lib/woocomerce';
import {
  GetAllowlistAndGroupDiscountQuery,
  OrderEnum,
  ProductsOrderByEnum
} from 'lib/woocomerce/__generated__/graphql';
import { Product } from 'lib/woocomerce/types';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useStore } from 'src/context/store-context';
import { notifyError } from '../utils';

export type ProductsContextInput = {
  initialProducts: Product[];
};

export type ProductsContextState = {
  products: Product[];
  allowlisted: boolean;
  storeDiscount: number;
  hasDiscount: boolean;
  setHasDiscount: Dispatch<SetStateAction<boolean>>;
};

const defaultProductsContextValue: ProductsContextState = {
  products: [],
  storeDiscount: 0,
  allowlisted: true,
  hasDiscount: false,
  setHasDiscount: () => {}
};

const ProductsContext = createContext<ProductsContextState>(defaultProductsContextValue);

export const ProductsProvider = ({
  value,
  children
}: {
  value: ProductsContextInput;
  children: React.ReactNode;
}) => {
  const [{ walletAddress }] = useStore();
  const [products, setProducts] = useState<Product[]>(value.initialProducts || []);
  const [allowlisted, setAllowlisted] = useState<boolean>(defaultProductsContextValue.allowlisted);
  const [storeDiscount, setStoreDiscount] = useState<number>(
    defaultProductsContextValue.storeDiscount
  );
  const [hasDiscount, setHasDiscount] = useState<boolean>(defaultProductsContextValue.hasDiscount);

  const prevWalletAddress = useRef<string | undefined>();

  useEffect(() => {
    if (prevWalletAddress.current === walletAddress) return;

    const address = walletAddress || '';
    prevWalletAddress.current = walletAddress;
    // Set store discount and allowlist status
    (async () => {
      try {
        const { discounts } = (await getAllowlistAndGroupDiscount(
          address
        )) as GetAllowlistAndGroupDiscountQuery;

        const isAllowlisted =
          walletAddress && discounts.nodes.length > 0
            ? Boolean(discounts.nodes[0]?.isAllowlisted)
            : true;
        setAllowlisted(isAllowlisted);
        setStoreDiscount(Number(discounts.nodes[0]?.groupDiscount.percentage || 0));
      } catch (err) {
        notifyError(err);
      }
    })();

    // Refetch products
    (async () => {
      try {
        const products = (await getProducts({
          address,
          first: 1000,
          orderby: [{ order: OrderEnum.Asc, field: ProductsOrderByEnum.MenuOrder }]
        })) as Product[];
        setProducts(products);
      } catch (err) {
        notifyError(err);
      }
    })();
  }, [walletAddress]);

  return (
    <ProductsContext.Provider
      value={{
        products,
        allowlisted,
        storeDiscount,
        hasDiscount,
        setHasDiscount
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error('useProductsContext must be used within a ProductsProvider');
  }
  return context;
};
