import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useEffect } from 'react';
import { useWidgets } from 'src/context/widgets-context';
import { notifyError } from '../utils';

export const useBridgeWidget = () => {
  const [{ factory, bridgeWidget }, dispatch] = useWidgets();

  useEffect(() => {
    // create the bridge widget
    if (!factory || bridgeWidget) return;

    (async () => {
      try {
        const widget = factory.create(ImtblCheckout.WidgetType.BRIDGE);
        dispatch({ payload: { type: 'SET_WIDGET', widget: { bridgeWidget: widget } } });
      } catch (error) {
        notifyError(error);
      }
    })();
  }, [factory, dispatch, bridgeWidget]);

  return { bridgeWidget };
};
