import { Modal } from '@biom3/react';
import { useStore } from 'src/context/store-context';

export const Overlay = () => {
  const [{ modalOpen, modalContent }] = useStore();

  return (
    <Modal
      visible={!!modalOpen}
      bgOverlaySx={{
        backdropFilter: ['blur(10px)', 'blur(5px)'],
        backgroundColor: 'transparent'
      }}
      fullScreenUntilBreakpoint="medium"
    >
      <Modal.Content>{modalContent}</Modal.Content>
    </Modal>
  );
};
