import { Box } from '@biom3/react';
import { useStore } from 'src/context/store-context';
import { useBlockScroll } from '../hooks/use-block-scroll';
import { AllowlistBanner } from './allowlist-banner';
import { OrderConfirmation } from './order-confirmation';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [{ modalOpen, walletOpen, connecting }] = useStore();

  useBlockScroll(modalOpen || walletOpen || connecting);
  return (
    <Box
      sx={{
        display: 'block',
        margin: '0 auto',
        width: '100%',
        maxWidth: '1440px',
        paddingX: [
          'base.spacing.x4',
          'base.spacing.x8',
          'base.spacing.x32',
          'base.spacing.x12',
          'base.spacing.x32'
        ],
        position: 'relative',
        paddingY: ['base.spacing.x6', 'base.spacing.x8', 'base.spacing.x12', 'base.spacing.x32']
      }}
    >
      <AllowlistBanner />
      <OrderConfirmation />
      {children}
    </Box>
  );
};
